#seated-view-modal,
#wait-view-modal,
#booking-view-modal {
  &.booking-modal,
  .modal-overlay {
    z-index: 1;
  }

  .modal-box {
    left: 327px;
    transform: translate(0, 0);
    border-radius: 0;
    top: 0;
    width: calc(100% - 327px);
    height: 100%;
  }

  .booking-nav-bar {
    margin-bottom: 31px;
  }

  .booking-tag-box {
    margin-bottom: 10px;
  }

  .modal-header {
    padding: 68px 23px 11px;
  }

  .modal-body {
    height: 515px;
    height: calc(100% - 178px);

    .modal-body-inner {
      padding: 7px 24px;
    }
  }
}
