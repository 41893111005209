.paymentStatusTag {
  color: white;
  font-size: 16px;
  background-color: green;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
}
.dinein-paid {
  background-color: green;
}

.dinein-partiallyPaid {
  background-color: orange;
}
.dinein-unpaid {
  background-color: red;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.sync-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.sync-icon {
  /* opacity: 0; */
  color: #067aff;
  /* Add animation properties */
  /* animation: fadeIn 1.5s linear forwards infinite; */
  animation: rotation 1s infinite linear;
}

.sync-text {
  font-size: 12px;
}
