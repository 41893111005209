.bem-base-part {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--mainBG);
  box-shadow: var(--shadowSecondary);
  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
    width: 100%;
  }
  &__header {
    position: relative;
    z-index: 3;
    background-color: var(--mainBG);
  }
  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
  }
  &__footer {
    position: relative;
    z-index: 2;
    background-color: var(--mainBG);
    box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);
  }
}
