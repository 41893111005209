//https://codepen.io/aleksander351/pen/KzgKPo

.profile-main-loader {
  position: relative;
  width: 40px;
  height: 40px;
}

.circular-loader {
  display: block;
  -webkit-animation: loader-rotate 1s linear infinite;
  animation: loader-rotate 1s linear infinite;
  height: 100%;
  transform-origin: 50% 50%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -40;
}

@-webkit-keyframes loader-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loader-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
