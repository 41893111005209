.Dropdown {
  position: relative;

  &.opened {
  }
  select {
    padding: 5px 32px 5px 10px;
    border: none;
    color: #363636;
    //padding-right: 32px;
    background-image: url(../../assets/img/icon/down-chevron.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    //border-radius: 10px;
    text-transform: capitalize;
    option {
      display: block;
      padding: 10px 0;
      height: 32px;
      line-height: 32px;
      &:checked {
        background: #d9d5d5;
      }
    }
  }

  .options {
    position: absolute;
    top: 100%;
    right: 0;
    background: #000;
    box-shadow: 0 0 10px #000000;

    .option {
      padding: 15px;
    }
  }
}
