.split-bill-header {
  height: 58px;
  padding-right: 78px;

  a.search-btn,
  .search-btn {
    position: absolute;
    top: 9px;
    right: 20px;
  }

  a.back-btn,
  .back-btn {
    padding-top: 17px;
    padding-bottom: 18px;
  }
}

.switch-split-type {
  overflow: hidden;
  position: relative;
  display: block;
  float: right;
  margin-top: 12px;
  padding: 3px 4px 3px 8px;
  border-radius: 12px;
  background-color: #e9e9e9;

  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    top: 4px;
    left: 4px;
    width: 113px;
    height: calc(100% - 8px);
    border-radius: 8px;
    background-color: #ffffff;
    transition: left 0.35s;
    box-shadow: 0 1.5px 0.5px 0 rgba(0, 0, 0, 0.04),
      0 1.5px 4px 0 rgba(0, 0, 0, 0.12);
  }

  > :not(:last-child) {
    margin-right: 5px;
  }

  a,
  button {
    position: relative;
    z-index: 5;
    display: inline-block;
    padding: 3px 19px;
    font-size: 0;
    color: #3a3a3a;

    &:before {
      border-radius: 8px;
    }

    span {
      font-size: 17px;
      font-weight: $medium;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }

    &.active {
      color: #363636;
    }
  }
}
