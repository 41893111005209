.test-wrap {
  overflow: auto;
  margin: 20px;
  height: 726px;

  > span {
    display: inline-block;
    width: 19%;
    padding: 20px;
    text-align: center;

    > span {
      display: block;
      margin-bottom: 10px;
    }
  }
}
