.passPhrase {
  display: block;
  width: 100%;
  height: 48px;
  padding: 12px 15px;
  font: 400 1.8rem/1.6 'HelveticaNowText', sans-serif;
  font-size: 15px;
  letter-spacing: -0.025rem;
  color: #363636;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 4px;
  transition: none;
}

.passPhrase:focus {
  border: 2px solid #4285f4;
}

.label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.authCodeContainer {
  padding-bottom: 25px;
}
