.driver-sidebar {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: #ffffff;

  .nav-bar-header {
    padding: 10px 18px 2px;
  }

  .nav-bar-details {
    height: 100%;
    height: calc(100% - 170px);

    .all-driver-style & {
      height: calc(100% - 54px);
    }
  }

  a.scheduled-dropdown,
  .scheduled-dropdown {
    top: 10px;
    right: 15px;
    padding-right: 38px;

    &:after {
      margin-top: 1px;
    }
  }

  .order-actions-btn-box {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;

    a,
    button {
      margin: 0;
    }
  }

  a.btn-action,
  .btn-action {
    &.width-100 {
      font-size: 18.2px;
      letter-spacing: -0.02em;

      .inner-btn-box {
        padding: 18px 20px;

        > span {
          display: inline-block;
          vertical-align: middle;
        }

        .count {
          position: relative;
          width: 32px;
          height: 32px;
          margin-right: 16px;
          background-color: #ffffff;
          border-radius: 50rem;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 19px;
            font-weight: 500;
            line-height: 1;
            margin-top: -0.5px;
            margin-left: -0.5px;
            color: #0386f6;
            transform-origin: center;
            transform: translate(-50%, -50%);
          }
        }

        .text {
          width: 203px;
        }

        .btn-dummy {
          min-width: 144px;
          padding: 10px 10px;
          color: #0386f6;
          text-align: center;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.5);
        }

        .back-icon-box {
          position: relative;
          width: 32px;
          height: 32px;
          margin-right: 19px;
          background-color: #ffffff;
          border-radius: 50rem;

          &:after {
            position: absolute;
            content: '\e905';
            top: 50%;
            left: 50%;
            @include icomoon();
            color: #0386f6;
            margin-left: -2px;
            font-size: 16px;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &.has-selected {
      text-align: left;

      .inner-btn-box {
        padding: 8px 14px 8px 20px;
      }
    }

    &.select-driver {
      text-align: left;

      .inner-btn-box {
        padding: 13px 20px;
      }

      .text {
        width: 330px;
        letter-spacing: -0.03em;
      }
    }

    &.assign-driver {
      text-align: center;

      .inner-btn-box {
        padding: 8px 14px 8px 20px;
      }
    }
  }

  .order-time-btn-box {
    a,
    button {
      padding: 7px 20px;

      .icon-box {
        right: 22px;
      }
    }

    > :first-child {
      padding-left: 24px;

      .icon-box {
        right: 19px;
      }
    }
  }
}

.single-details-input-box {
  position: relative;
  width: 26px;
  height: 26px;

  .checkstyle {
    position: absolute;
    z-index: 3;
    display: inline-block;
    width: 26px;
    height: 26px;
    font-size: rem(14);
    border: none;
    background-color: #fafafa;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 20rem;
    pointer-events: none;
    cursor: pointer;

    &:after {
      position: absolute;
      z-index: 5;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      @include icomoon();
    }
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: inline-block;
    width: 29px;
    height: 29px;
    cursor: pointer;

    &:checked + .checkstyle {
      border-color: #5eb602;
      background-color: #5eb602;
      box-shadow: inset 0 0 rem(4) 0 rgba(99, 99, 99, 0.5);

      &:after {
        content: '\e901';
      }
    }
  }
}

.unassigned {
  .single-detail-box {
    position: relative;
    padding: 10px 26px 12px 69px;

    .detail-row.row-1 {
      margin-bottom: 6px;
    }

    .single-details-input-box {
      position: absolute;
      top: 50%;
      left: 23px;
      transform: translateY(-50%);
    }

    .status-btn {
      height: 20px;
      margin-top: 2px;
      padding: 2.5px 8px 0 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;

      &.red {
        background-color: #e02020;
      }

      &.orange {
        background-color: #fd6c00;
      }

      &.green {
        background-color: #5eb602;
      }
    }

    .client-name-box {
      .client-name {
        display: inline-block;
        font-size: 15px;
        line-height: 1.25;
        vertical-align: middle;
      }

      .pos_icon-shape-man {
        display: inline-block;
        margin-left: 14px;
        width: 20px;
        height: 20px;
        line-height: 1.25;
        vertical-align: middle;
      }

      .dine_icon-shape_man {
        display: inline-block;
        margin-left: 14px;
        font-size: 20px;
        line-height: 1.25;
        vertical-align: middle;
      }
    }

    .name {
      letter-spacing: -0.02em;
    }
  }
}
