.settings-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  .settings-inner-box {
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
  }

  .app-toggle-group {
    position: relative;
    right: auto;
    bottom: auto;

    & > button,
    & > div {
      max-width: 295px;
      display: block;
      min-width: 295px;
      text-align: left;
      margin: 0 0 20px 0;
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  .dark-mode & {
    background-color: #000;
  }
}
