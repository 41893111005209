@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-btn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: $line-height-heading-2;
  letter-spacing: -$letter-spacing-20;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  &__text {
    display: block;
  }

  svg,
  img {
    min-width: 10px;
    flex-grow: 0;
    max-width: 60px;
  }

  @include UIStyleTypeModifiers();
  @include UISizeTypeModifiers('bem-btn');
  @include UIInnerSpacingModifiers();
  @include UIFontSizeModifiers();
  @include UIFontWeightModifiers();
  @include UIRadiusModifiers();
  @include UIIconPositionModifiers();
  @include UIOtherModifiers();
}
