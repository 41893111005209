.view-order-main {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 18px 0 0;
  width: 61%;

  &:after {
    position: absolute;
    z-index: 6;
    content: '';
    top: 0;
    left: -100px;
    width: 100px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -4px 5px 12px -2px rgba(0, 0, 0, 0.5);
  }

  &.empty {
    background-color: #f7f7f7;

    &:after {
      height: 100%;
    }
  }

  .view-order-main-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform-origin: center;
    transform: translate(calc(-50% - 5px), calc(-50% - 28px));

    .pos_icon-bag-inverse {
      margin-bottom: 12px;
      width: 68px;
      height: 78px;

      svg {
        path {
          fill: rgb(209, 209, 209) !important;
          stroke: rgb(209, 209, 209) !important;
        }
      }
    }

    .text {
      display: block;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.25;
      color: #8a8a8a;
      letter-spacing: -0.02em;
    }
  }

  .orders-customer-name-title {
    @include font($medium, rem(24), $line-height-heading-2, $font-main);
    text-transform: capitalize;
  }

  .orders-customer-mobile-container {
    display: flex;
  }

  .orders-customer-mobile-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .orders-customer-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  .orders-customer-ready-for-pickup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #067aff;
    color: white;
    margin: 0 10px;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    width: 180px;

    &.hasBeenNotified {
      width: 150px;
      background-color: #5eb602;
      cursor: not-allowed;
    }
  }

  .booking-tag-box {
    min-height: 86px;
    margin-bottom: -50px !important;
  }

  .booking-inner-table {
    margin-bottom: 9px;
    padding: 0 16px 0 23px;
    font-size: 25px;

    .inner-cell:nth-child(2):last-child {
      width: 50%;
    }

    .booking-tag-box {
      a,
      button {
        margin-bottom: 3px;
        margin-right: 0;
        margin-left: 12px;

        &:not(.add-new) {
          box-shadow: none;
        }

        &:last-child:not(:nth-child(2)) {
          margin-top: 8px;
        }
      }
    }
  }
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: #067aff;
  }
  .circle > p {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-info-detail-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .order-info-detail-item-text {
    padding-left: 5px;
  }
  .order-info-detail-item-text > p {
    font-size: 16px;
  }
  .order-info-detail-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    z-index: 5;
    min-height: 56px;
    width: 100%;
    box-shadow: 3px 2.3px 4.5px 0 rgba(0, 0, 0, 0.5);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
  }

  .view-order-main-footer {
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 0;
    min-height: 56px;
    width: 100%;
    background-color: #ffffff;

    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 36px;
      transform-origin: center;
      transform: rotate(180deg);
      box-shadow: 3px -2.3px 4.5px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .initials-box {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    margin-left: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #f7b500;
    border-radius: 50%;
    vertical-align: middle;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      letter-spacing: 0;
    }
  }

  .seats-list-wrap {
    z-index: auto;
    background-color: #ffffff;
    height: 449px;
    height: calc(100% - 215px);

    &.single {
      height: calc(100% - 235px);
    }

    .single-seat-header {
      .seat-title {
        padding: 6px 19px;
        font-size: 17px;
      }

      &.red {
        background-color: #cd3f34;
      }

      &.green {
        background-color: #5eb602;
      }

      &.yellow {
        background-color: #fd6c00;
      }
    }

    .seat-items-wrap {
      > :nth-child(odd) {
        background-color: #f7f7f7;
      }

      .single-item {
        &:not(:last-child) {
          border-bottom: solid 2px #e9e9e9;
          border-bottom-color: rgba(233, 233, 233, 0.43);
        }
      }
    }

    .single-img-wrap {
      border-radius: 7px;
      box-shadow: 0 0 4.6px -1px rgba(70, 70, 70, 0.5);
    }

    .single-item {
      &:after {
        content: none;
      }

      .single-item-table {
        padding: 4px 32px 6px 11px;

        &.voided {
          opacity: 0.3;
          text-decoration: line-through;
        }
      }

      .single-item-cell {
        vertical-align: middle;
        &.img-cell {
          width: 70px;
        }
      }

      .single-body {
        padding-top: 3px;

        &:after {
          content: none;
        }
      }

      .single-title {
        margin-bottom: 3px;
        font-size: 17px;
        color: #363636;
      }

      .single-actions {
        a,
        button,
        span {
          max-width: 480px;
          font-size: 14px;
          color: #363636;
          opacity: 0.67;
          letter-spacing: -0.02em;
        }
      }

      .single-modify-btn {
        right: 8px;
        color: #494b4c;
        font-size: 22px;
        opacity: 0.57;

        &:after {
          background-size: 10px 25px;
        }

        .pos_icon-apper-circle {
          height: 24px;
        }
      }
    }
  }
  .single-item-price-group {
    display: table-cell;
    vertical-align: middle;
    &__inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      @include gapFlexSupportAlternative(8, 8);
    }
  }
  .seats-list-wrap.single {
    .single-item {
      border-bottom: solid 2px #e9e9e9;
      border-bottom-color: rgba(233, 233, 233, 0.43);

      .single-body {
        padding-top: 3px;

        > :last-child {
          margin-bottom: 0;
        }
      }

      .single-modify-btn {
        right: 7px;
      }
    }
  }
}

.guest-location-row {
  margin-bottom: 12px;

  .location-box {
    display: inline-block;
    padding: 0 15px;

    .icon-box,
    .desc {
      display: inline-block;
      vertical-align: middle;
    }

    .icon-box {
      position: relative;
      width: 42px;
      height: 42px;
      margin-right: 7px;

      [class^='pos_icon-'],
      [class*=' pos_icon-'],
      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 25px;
        transform: translate(-50%, -50%);
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        width: 24px;
        height: 26px;
      }

      .dine_icon-home {
        margin-top: -1px;
        margin-left: -1px;
      }

      .dine_icon-peoples {
        font-size: 22px;
      }
    }

    .desc {
      font-size: 17px;
      letter-spacing: -0.02em;
      color: #363636;
    }

    &.table {
      .icon-box {
        margin-right: 6px;
      }
    }

    &.people {
      .icon-box {
        margin-right: 0;
      }
    }
  }
}

.order-status-row {
  position: relative;
  z-index: 5;
  padding: 0;
  display: flex;
  background-color: #ffffff;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 31px;
    transform-origin: center;
    transform: rotate(180deg);
    box-shadow: 0 -4.6px 9px 0 rgba(0, 0, 0, 0.5);
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 31px;
    transform-origin: center;
    box-shadow: 6px -4.6px 9px 0 rgba(0, 0, 0, 0.5);
  }

  .order-status-box {
    // position: relative;
    z-index: 2;
    display: flex;
    // align-items: center;
    height: 58px;
    padding: 6px 14px 6px;
    // font-size: 0;
    background-color: #ffffff;

    // &:first-child:nth-last-child(2) {
    //   width: 62.4%;
    // }

    // &:last-child:nth-child(2) {
    //   width: 37.7%;
    //   padding-left: 10px;
    // }

    // &:only-child {
    //   width: 100%;
    // }

    // &:not(:last-child) {
    //   border-right: 1px solid #f2f2f2;
    // }

    .icon-box,
    .desc {
      display: flex;
      align-items: center;
    }

    .icon-box {
      position: relative;
      width: 42px;
      height: 42px;
      margin-right: 7px;

      [class^='pos_icon-'],
      [class*=' pos_icon-'],
      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 23px;
        transform: translate(-50%, -50%);
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        width: 23px;
        height: 23px;
      }

      .dine_icon-bike {
        font-size: 27px;
        margin-top: 2px;
      }

      .dine_icon-waiters {
        font-size: 26px;
        margin-top: 2px;
      }

      .dine_icon-rotate-switch {
        font-size: 22px;
        margin-top: 1px;
        margin-left: 1px;
      }
    }

    .desc {
      overflow: hidden;
      height: 42px;
      padding-top: 2px;
      font-size: 17px;
      line-height: 1.2;
      letter-spacing: -0.02em;
      color: #363636;

      span {
        display: block;
        font-weight: $medium;
      }

      .red {
        color: #e02020;
      }

      .orange {
        color: #fd6c00;
      }

      .green {
        color: #5eb602;
      }

      .grey {
        font-weight: 400;
        color: #757575;
      }

      &:only-child {
        height: 40px;
        padding-left: 10px;
      }
    }

    &.dropdown {
      &:after {
        position: absolute;
        content: '\e960';
        top: 50%;
        right: 19px;
        @include icomoon();
        font-weight: 700;
        color: #363636;
        font-size: 13px;
        transform: translate(0, -50%);
      }

      .desc {
        padding-right: 40px;
      }
    }
  }
}

.delivery-arriving-box {
  position: relative;
  display: inline-block;
  padding: 8px 13px 8px 34px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  color: #363636;
  font-size: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);

  &:after {
    position: absolute;
    content: '\e902';
    top: 50%;
    left: 8px;
    @include icomoon();
    font-size: 18px;
    transform-origin: center;
    transform: translate(0, -50%);
  }
}

.delivery-map-row {
  overflow: hidden;
  height: calc(100% - 276px);

  .delivery-status-map {
    overflow: hidden;
    height: 394px;
    height: 100%;
    background-image: url(../../../../assets/img/orders/delivery-map.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    iframe {
      opacity: 0;
      margin-top: -225px;
      margin-left: -2px;

      &.show {
        opacity: 1;
        background-image: none;
      }
    }
  }

  .delivery-arriving-box {
    position: absolute;
    z-index: 5;
    right: 23px;
    bottom: 18px;
  }
}

.current-order-status-box {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  width: 62.6%;
  padding: 2px 15px;
  color: #ffffff;
  background-color: #fd6c00;

  &.white {
    background-color: #ffffff;

    .dark-mode & {
      border-top: 1.6px solid #3b3a3a;
      background-color: #000000;
    }
  }

  &:only-child {
    width: 100%;
  }

  .icon-box {
    position: relative;
    width: 42px;
    height: 42px;
    margin-right: 7px;

    .pos-custom-icon-confirmed {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../../../../assets/img/icon/confirmed_new_white.svg);
      background-size: 26px;
      background-position: center;
      background-repeat: no-repeat;
    }

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 20px;
      transform: translate(-50%, -50%);
    }

    .dine_icon-confirmed_new {
      margin-left: -1px;
      font-size: 25px;
    }

    .dine_icon-finish {
      font-size: 25px;
    }

    .dine_icon-clock {
      font-size: 25px;
    }

    .dine_icon-bike {
      margin-top: 1px;
      margin-left: -1px;
      font-size: 30px;
    }

    .dine_icon-cook {
      margin-top: 1px;
      margin-left: -1px;
      font-size: 30px;
    }
  }

  .desc {
    overflow: hidden;
    max-height: 40px;
    padding: 3px 0;
    font-size: 18px;
    line-height: 1.2;
    font-weight: $medium;
    color: #ffffff;
  }

  &.dropdown {
    &:after {
      position: absolute;
      content: '\e960';
      top: 50%;
      right: 19px;
      @include icomoon();
      font-weight: 700;
      color: #ffffff;
      font-size: 10px;
      transform: translate(0, -50%);
    }
  }

  a.order-status-btn,
  .order-status-btn {
    position: absolute;
    top: 50%;
    right: 25px;
    transform-origin: center;
    transform: translate(0, -50%);
  }

  &.orange {
    height: 58px;
    background-color: #fd6c00;

    a.order-status-btn,
    .order-status-btn {
      color: #fd6c00;
    }
  }

  &.blue {
    height: 58px;
    background-color: #067aff;

    a.order-status-btn,
    .order-status-btn {
      color: #067aff;
    }
  }

  &.green {
    height: 58px;
    background-color: #5eb602;

    a.order-status-btn,
    .order-status-btn {
      color: #5eb602;
    }
  }

  &.purple {
    height: 58px;
    background-color: #b620e0;

    a.order-status-btn,
    .order-status-btn {
      color: #b620e0;
    }
  }
}

.order-total-paid-box {
  position: relative;
  z-index: 5;
  padding: 5px 52px 5px 23px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;

  .demo-app & {
    width: 37.4%;
    justify-content: space-between;
  }

  &.cash-payment {
    justify-content: flex-end;
  }

  .paycard-box {
    position: relative;
    overflow: hidden;
    width: 53px;
    height: 37px;
    padding: 5px;
    border-radius: 3.6px;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-right: 50px;

    .demo-app & {
      margin-right: 0;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 32px;
      height: auto;
      transform: translate(-50%, -50%);
    }

    &.master {
      img {
        margin-left: 1px;
      }
    }

    &.amex {
      background-color: #3498d8;

      img {
        max-width: 38px;
      }
    }
  }

  .price-wrap {
    margin-top: -3px;
    color: #363636;
    text-align: right;
    letter-spacing: -0.02em;

    > span {
      display: block;
      line-height: 1.25;
    }

    > :last-child {
      margin-bottom: 0;
    }

    .price-desc {
      margin-bottom: 6px;
      font-size: 14px;

      opacity: 0.67;
    }

    .price {
      font-weight: $medium;
      font-size: 17px;
    }
  }
}

.view-order-main-footer {
  display: flex;
}

.view-order-anim-wrap {
  height: 100%;
  transition: 0.2s ease-in-out;

  &.start-change {
    transform: translateX(-100%);
    opacity: 0;
    transition: 0s ease-in-out 0.15s, opacity 0.15s ease-in-out 0s;
  }
}

.dark-mode {
  .view-order-main {
    &.empty {
      background-color: #000000;
    }
  }
}

.estimated-time {
  color: #5eb602;
  padding-right: 5px;
}
.time-from-now {
  color: #363636;
  padding-right: 5px;
}

.refundedContainer {
  display: flex;
  justify-content: flex-end;
}
.refundedTag {
  border: 2px solid #f40000;
  border-radius: 15px;
  background-color: #f40000;
}
.refundedText {
  padding-left: 7px;
  padding-right: 7px;
  color: white;
}

.removeSeparator {
  border-right: none !important;
}
.maxWidht {
  width: 100%;
}
.uberMaxWidht {
  flex: 1;
}

.assign-driver-details {
  cursor: pointer;
  display: flex;
  background-color: #fff;
  width: 100%;
  padding-left: 6.6px;
  padding-right: 6.6px;
  align-items: center;
  height: 36px;
}

.assign-driver-details-button-text {
  width: 100%;
  font-family: HelveticaNowText;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #3a3a3a;
  padding-left: 10px;
}

.remove-driver-button {
  cursor: pointer;
  display: flex;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  background-color: #fff;
  width: 180px;
  padding-left: 8px;
  padding-right: 14px;
  align-items: center;
  height: 36px;
}

.remove-driver-button-icon {
  background-color: #f40000;
  width: 23px;
  height: 23px;
  padding: 5px;
  border-radius: 23px;
  margin-top: 5px;
  margin-right: 6px;
}

.remove-driver-button-icon > line {
  stroke: white;
}

.uber-driver-details-button-text {
  width: 80%;
  font-family: HelveticaNowText;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #3a3a3a;
  padding-left: 19px;
}

.assign-driver-button {
  cursor: pointer;
  display: flex;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  background-color: #fff;
  width: 169px;
  padding-left: 6.6px;
  padding-right: 6.6px;
  align-items: center;
  height: 36px;
}

.assign-driver-button-text {
  width: 120px;
  font-family: HelveticaNowText;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: center;
  color: #3a3a3a;
}

.track-button {
  cursor: pointer;
  display: flex;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  background-color: #fff;
  width: 81px;
  height: 36px;
  padding-left: 17px;
  padding-right: 17px;
  align-items: center;
}

.track-button-text {
  width: 50px;
  height: 25px;
  font-family: HelveticaNowText;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #067aff;
}

.rightPositioned {
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.assignDriverAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #b620e0;
  color: #fff;
  font-family: HelveticaNowText;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.72px;
}

.assignDriverAvatarText {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.65px;
  color: #fff;
}

.uber-driver-details {
  cursor: pointer;
  display: flex;
  background-color: #fff;
  width: 100%;
  padding-left: 6.6px;
  padding-right: 6.6px;
  align-items: center;
  height: 36px;
}

.loading-Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.uber-Icon {
  width: 50px;
}
