.app-toggle-group {
  position: fixed;
  z-index: 10;
  right: 54px;
  bottom: 29px;
  color: #363636;

  .btn-title {
    line-height: 1.2;
    font-size: 22px;
    letter-spacing: -0.02em;
    color: #363636;
  }

  @media all and (max-width: 1740px) {
    position: absolute;
  }
  @media all and (max-height: 980px) {
    position: absolute;
  }
}

/* .single-item {
  background: #2b2a2e !important;
  border-color: black !important;
}

.single-item:nth-child(2n) {
  background: #111 !important;
}

.bem-item-view-modal__row {
  background: #2b2a2e !important;
}

.bem-item-view-modal__group-title {
  color: white !important;
} */

.full-screen-btn {
  overflow: hidden;
  position: relative;
  display: inline-block;
  padding: 20px 24px 16px 66px;
  border-radius: 24px;
  background-color: #ffffff;
  margin-right: 38px;
  box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.5);

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 12px;
    width: 42px;
    height: 64px;

    background-image: url(../../../assets/img/icon/narrow_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px;
    transform: translate(0, -50%);
  }

  .iPad-mode-wrapper & {
    &:before {
      background-image: url(../../../assets/img/icon/expand_grey.svg);
    }
  }

  .dev-work.app-wrapper & {
    position: fixed;
    z-index: 99999;
    bottom: 10px;
    right: 40px;
  }

  .dev-work.app-wrapper .settings-page & {
    position: relative;
    bottom: auto;
    right: auto;
  }

  .dev-work.app-wrapper .settings-page + & {
    display: none;
    position: relative;
    z-index: 99999;
    bottom: auto;
    right: auto;
  }
}

.dark-mode-btn-wrap {
  position: relative;
  display: inline-block;
  padding: 20px 122px 16px 62px;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.5);

  .react-toggle {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
  }

  .react-toggle-track {
    width: 51px;
    height: 31px;
  }

  .react-toggle-thumb {
    width: 29px;
    height: 29px;
  }

  .react-toggle:active:not(react-toggle--disabeled) .react-toggle-thumb,
  .react-toggle.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 20px;
  }

  .pos_icon-moon {
    position: absolute;
    display: block;
    top: 50%;
    left: 21px;
    width: 24px;
    opacity: 0.44;
    transform: translate(0, -50%);
  }
}

.dark-mode {
  color: $color-white;

  a.btn.inverse,
  .btn.inverse {
    color: #ffffff;

    .inner-btn-box {
      border-color: #1c1c1e;
      background-color: #1c1c1e;
    }
  }

  .item-view-modal:after {
    background-color: #1f1e23;
  }

  .dine-modal-wrap a.close-modal-btn,
  .dine-modal-wrap .close-modal-btn,
  .alphabet-nav a,
  .alphabet-nav button,
  .counter-box .counter-value,
  .item-detail-cards .detail-card-price,
  .item-detail-cards .detail-card-title,
  .single-modify-btn .dine_icon-stacked-circles,
  .table-seats-box .table-box,
  .food-cards .card-item button,
  .search-btn,
  a:link,
  a:visited {
    color: $color-white;
  }

  .order-bar-top,
  .main-area-body,
  .main-special-area-body,
  .nav-bar,
  .main-area-header,
  .iPad-box .iPad-body,
  .iPad-box .iPad-inner-box,
  .order-bar,
  .table-seats-box {
    background-color: #000000;
  }

  .seats-list-wrap,
  .category-nav > ul li a {
    background-color: #1f1e23;
  }

  .category-nav .nav-link {
    border-color: #2b2a2e;
    border-bottom-color: #1f1e23;
    background-color: #1f1e23;

    &.green {
      background-color: #48a903;
      border-color: #48a903;

      & + .nav-link {
        border-top-color: transparent;

        &.active {
          border-color: #2b2a2e;
        }
      }
    }
  }

  .bottom-nav-box .category-nav {
    .nav-link:first-child {
      border-top: 1px solid #2b2a2e;
    }

    & + .support {
      .nav-link:first-child {
        border-top: none;
      }
    }
  }

  .item-view-modal .seats-list-wrap {
    border-color: #3b3a3a;
  }

  .category-nav .nav-link:last-child {
    border-color: #2b2a2e;
  }

  .category-nav .nav-link.active {
    border-color: #2b2a2e;
    background-color: #3a3a3c;

    &.green {
      background-color: #48a903;
      border-color: #48a903;
    }
  }

  .category-nav + .support {
    background-color: #1f1e23;
  }

  .seats-list-wrap .single-item .single-img-wrap,
  .order-actions-btn-box a.btn-action:not(.blue),
  .order-actions-btn-box .btn-action:not(.blue) {
    color: $color-white;
    background-color: #3a3a3c;
  }

  a.specials-single-card .specials-item-img-wrap,
  .specials-single-card .specials-item-img-wrap,
  .food-cards .card-item .card-img-wrap {
    border-color: #1c1c1e;
    background-color: #1c1c1e;
  }

  .nav-bar {
    border-color: #111213;
    border-right: 1px solid #111213;
  }

  .nav-bar .bottom-nav-box,
  .order-actions-btn-box,
  .order-actions-btn-box a.btn-action.width-50:nth-child(odd),
  .order-actions-btn-box .btn-action.width-50:nth-child(odd),
  .category-nav > ul li,
  .category-nav > ul li + li {
    border-color: #2b2a2e;
  }

  #deliver-order-modal
    .modal-body
    .seats-list-wrap
    .seat-items-wrap
    .single-item {
    border-color: #2b2a2e;
  }

  .seats-list-wrap .single-item:after {
    background-color: #2b2a2e;
  }

  .category-nav + .support > ul {
    background-color: #1f1e23;
  }

  .order-bar {
    border-color: #3b3a3a;
  }

  .table-seats-box .table-box {
    border-color: #1c1c1e;
    background-color: #1c1c1e;
  }

  .main-area-header a.btn,
  .main-area-header .btn {
    .inner-btn-box {
      border-color: #1c1c1e;
      background-color: #1c1c1e;
    }
  }

  .plus-icon {
    background-image: url(../../img/plus_icon_inverse.png);
  }

  .main-area-header {
    border-bottom: 1px solid #3b3a3a;
  }

  .category-nav > ul li.active a,
  .category-nav > ul li a.active {
    background-color: #3a3a3c;
  }

  .counter-box .counter-btn.minus {
    background-image: url(../../../assets/img/icon/minus_white.svg);
  }

  .counter-box .counter-btn.plus {
    background-image: url(../../../assets/img/icon/plus_white.svg);
  }

  .counter-box .counter-btn.minus:disabled {
    background-image: url(../../../assets/img/icon/minus_grey.svg);
  }

  .counter-box .counter-btn.plus:disabled {
    background-image: url(../../../assets/img/icon/plus_grey.svg);
  }

  .view-order-style,
  .delivery-page-style,
  .pickup-page-style {
    .status-bar {
      &:before,
      &:after {
        position: absolute;
        content: '';
        left: 197px;
        width: 1px;
        height: 100%;
        background-color: #3b3a3a;
      }

      &:after {
        left: auto;
        right: 326px;
      }
    }

    .nav-bar {
      border-right-color: #3b3a3a;
    }
  }

  .view-order-style {
    .status-bar {
      &:after {
        left: auto;
        right: 31.87%;
      }
    }
  }

  .pick-up + .order-bar {
    border-left: 1px solid #3b3a3a;
  }

  .item-view-modal .item-view-modal-box {
    background-color: #1f1e23;
  }

  .item-view-modal a.item-action-btn,
  .item-view-modal .item-action-btn {
    color: #ffffff;
    background-color: #000000;

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      color: #ffffff;
    }
  }

  .dine-modal-wrap .dine-modal-box {
    background-color: #1f1e23;
    background-image: url(../../../assets/img/modal_bg_inverse.jpg);
  }

  .main-bottom-btn-box:after {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8015581232492998) 0%,
      rgba(0, 0, 0, 0.7987570028011204) 60%,
      rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8015581232492998) 0%,
      rgba(0, 0, 0, 0.7987570028011204) 60%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8015581232492998) 0%,
      rgba(0, 0, 0, 0.7987570028011204) 60%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
  }

  .current-food-box .current-img-box:after {
    background-image: url(../../../assets/img/icon/expand-fullscreen--thick_white.svg);
  }

  .modifiers-style .current-food-box .current-img-box {
    background-color: #1c1c1e;
  }

  .counter-box .counter-btn {
    color: #ffffff;
    border-color: #ffffff;
    background-color: $color-dark-bg;
  }

  .counter-box .counter-btn:disabled {
    color: #808080;
    border-color: #808080;
  }

  .current-food-box .current-img-box {
    background-color: $color-dark-bg;
  }

  .item-cards .item-card label {
    border-color: $color-dark-bg;
    background-color: $color-dark-bg;
  }

  .alphabet-nav {
    background-color: $color-dark-bg;
  }

  .dark-mode .table-seats-box .table-box {
    border-color: #3a3a3a;
  }

  .single-seat-item .seat-item-input-box .checkstyle {
    background-color: #404040;
  }

  .single-details-input-box .checkstyle {
    border: 0.1rem solid #989898;
  }

  .single-details-input-box .checkstyle,
  .tag-modal-wrap .single-tag-box .radio-style,
  .item-detail-cards .detail-card-input-box .checkstyle {
    background-color: $color-dark-bg;
  }

  .search-form button[type='submit'] {
    top: 1px;
    left: 1px;
    width: 36px;
    height: 28px;
    color: #ffffff;
    background-color: $color-dark-bg;
  }

  .input-style {
    color: #ffffff;
    border-color: #3a3a3a;
    background-color: $color-dark-bg;
  }

  .main-special-date,
  a.btn.grey,
  .btn.grey {
    color: #ffffff;
  }

  .current-food-box .current-img-box:after {
    background-color: #6c6c6c;
  }

  a.btn:disabled,
  .btn:disabled {
    color: #3a3a3a;

    .inner-btn-box {
      border-color: #6c6c6c;
      background-color: #6c6c6c;
    }
  }

  .pickup-bar {
    background-color: #000000;
  }

  .next-btn-box,
  .single-form .btn-box,
  .single-form {
    background-color: #000000;
  }

  .single-form label,
  .single-form .form-desc,
  .single-form .single-input-box .clear-input-btn {
    color: #ffffff;
  }

  .suggested-value-box label span {
    background-color: $color-dark-bg;
  }

  #payment-view-modal a.close-modal-btn:before,
  #payment-view-modal .close-modal-btn:before,
  #payment-view-modal .modal-box,
  #payment-view-modal .terminal-total-price,
  #payment-view-modal .terminal-text,
  #payment-view-modal .payment-nav-box a,
  #payment-view-modal .payment-nav-box button,
  #sidebar-modal-print-bill .search-guest-box:after,
  a.scheduled-dropdown,
  .scheduled-dropdown,
  #sidebar-modal-print-bill .print-single-item,
  #sidebar-modal-print-bill .print-bill-price-single,
  .order-bar-top .title,
  .order-bar .single-pickup-details .text,
  .order-bar .single-pickup-details .input-style,
  .time-select-modal-wrap .swiper-container,
  .time-select-modal-wrap a.close-modal-btn,
  .time-select-modal-wrap .close-modal-btn,
  .time-select-modal-wrap .modal-title {
    color: #ffffff;
  }

  .time-select-modal-wrap .modal-header,
  .time-select-modal-wrap .modal-footer,
  .time-select-modal-wrap {
    background-color: #1f1e23;
  }

  .delivery-page-style .order-bar:after,
  .pickup-page-style .order-bar:after {
    content: none;
  }

  .input-style::-webkit-input-placeholder,
  .input-style::-moz-placeholder,
  .input-style:-ms-input-placeholder,
  .input-style:-moz-placeholder,
  .order-bar .single-pickup-details .grey {
    color: #838383;
  }

  .sidebar-modal-box .sidebar-modal-header,
  .sidebar-modal-box {
    background-color: #000000;
  }

  .view-order-style #sidebar-modal-print-bill .sidebar-modal-box {
    border-left: 1px solid #3b3a3a;

    &.split-bill {
      & + .sidebar-modal-box {
        border: none;
      }
    }
  }

  .order-actions-btn-box a.btn-action.green,
  .order-actions-btn-box .btn-action.green {
    color: #ffffff;
    background-color: #5eb602;
  }

  #payment-view-modal .modal-box {
    background-color: #1f1e23;
  }

  #payment-view-modal .cash-cards a,
  #payment-view-modal .cash-cards button {
    border-color: #1c1c1e;
  }

  #payment-view-modal .cash-cards a,
  #payment-view-modal .cash-cards button,
  #payment-view-modal .modal-footer,
  #payment-view-modal .modal-body {
    background-color: #000000;
  }

  .single-form .single-input-box > .icon-box {
    color: #ffffff;
    background-color: #1c1c1e;
  }

  .geosuggest-delivery-box {
    border-color: #1c1c1e;
  }

  .geosuggest-delivery-box input,
  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul .geosuggest__item,
  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul li > span,
  .geosuggest-delivery-box .geosuggest__suggests-wrapper,
  .geosuggest-delivery-box .geosuggest__input-wrapper {
    background-color: #1c1c1e;
  }

  .geosuggest-delivery-box input,
  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul .geosuggest__item,
  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul li > span,
  .geosuggest-delivery-box .clear-input-btn,
  .geosuggest-delivery-box .geosuggest__input-wrapper:before {
    color: #ffffff;
  }

  .geosuggest-delivery-box .geosuggest__input-wrapper:before {
    background-image: url(../../../assets/img/icon/location_white.svg);
  }

  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul li > span {
  }

  .geosuggest-delivery-box .geosuggest__suggests-wrapper > ul li {
    border-top-color: #3a3b3b;
  }

  .nav-bar-details {
    background-color: #111213;
  }

  .quick-date-selection .quick-date-slider .swiper-button-prev,
  .quick-date-selection
    .quick-date-slider
    .swiper-container-rtl
    .swiper-button-next,
  .quick-date-selection .quick-date-slider .swiper-button-next,
  .quick-date-selection
    .quick-date-slider
    .swiper-container-rtl
    .swiper-button-prev {
    background-color: #1b1c1e;
  }

  .header {
    background-color: #1b1c1e;
    border-bottom: 1px solid #1b1c1e;
  }

  .nav-bar-header {
    background-color: #111213;
  }

  .quick-date-selection .swiper-slide {
    background-color: #1b1c1e;
  }

  .booking-nav-box a,
  .booking-nav-box button,
  .booking-nav-box {
    background-color: #000000;
  }

  .details-list > li:nth-child(odd) {
    background-color: #1c1c1e;
  }

  .details-list > li:nth-child(even):last-child,
  .details-list > li:nth-child(odd) {
    border-color: #6e6e6e;
  }

  .timeline-box .time-line-list li span {
    color: #8e8e95;
  }

  .booking-actions-list > li a,
  .booking-actions-list > li button,
  .table-single-item .icon,
  .table-select-nav ul > li a,
  .table-select-nav ul > li button,
  .nav-bar-title,
  .visitors-count,
  .quantity-box,
  .single-detail-box .date-time .time,
  .single-detail-box .name {
    color: #ffffff;
  }

  .table-view.main-area {
    background-color: #000001;
  }

  .quick-date-selection a.select-day-btn,
  .quick-date-selection .select-day-btn {
    color: #ffffff;
    background-color: #292a2e;
  }

  a.scheduled-dropdown:after,
  .scheduled-dropdown:after,
  .quick-date-selection .quick-date-slider .swiper-button-prev:after,
  .quick-date-selection
    .quick-date-slider
    .swiper-container-rtl
    .swiper-button-next:after,
  .quick-date-selection .quick-date-slider .swiper-button-next:after,
  .quick-date-selection
    .quick-date-slider
    .swiper-container-rtl
    .swiper-button-prev:after {
    color: #747674;
  }

  a.btn.white,
  .btn.white {
    color: #ffffff;

    .inner-btn-box {
      background-color: #292a2e;
      border-color: #292a2e;
    }
  }

  .switch-split-type,
  .table-view-wrap .floor-switch-box {
    background-color: #292a2e;
  }

  .single-select-box .people-count-box,
  .switch-split-type a,
  .switch-split-type button,
  .table-view-wrap .floor-switch-box a,
  .table-view-wrap .floor-switch-box button {
    color: #ffffff;
  }

  .switch-split-type:before,
  .table-view-wrap .floor-switch-box:after {
    background-color: #64646b;
  }

  .split-bill-style,
  .table-view-style {
    .status-bar:before,
    .status-bar:after {
      content: none;
    }
  }

  a.table-wrap.empty .table-box,
  .table-wrap.empty .table-box {
    color: #ffffff;
  }

  .timeline-box {
    background-color: #000001;
  }

  .table-single-item,
  .table-select-nav .single-detail-box,
  a.table-wrap.empty .table-box,
  .table-wrap.empty .table-box {
    background-color: #2b2c2e;
  }

  #select-modal-status .single-select-box .name {
    color: #ffffff !important;
  }

  .select-modal-box,
  .select-modal-box .single-select-box {
    background-color: #1f1e23;
  }

  .custom-dropdown-wrap .custom-dropdown-box:before,
  a.close-modal-btn,
  .close-modal-btn,
  a.back-modal-btn,
  .back-modal-btn,
  .sidebar-modal-box a.close-modal-btn,
  .sidebar-modal-box .close-modal-btn,
  .sidebar-modal-box a.back-modal-btn,
  .sidebar-modal-box .back-modal-btn,
  .select-options-list > li.grey .single-select-box {
    color: #ffffff;
  }

  .item-cards .item-card:after,
  .sidebar-modal-box
    .guest-quantity-single-wrap
    input:checked
    + .guest-quantity-single-box,
  .sidebar-modal-box .sidebar-modal-footer {
    background-color: #3a3a3c;
  }

  .sidebar-modal-box {
    border-right: 1px solid #3b3a3a;
  }

  .sidebar-modal-box .guest-quantity-single-wrap .guest-quantity-single-box {
    background-color: #000000;
  }

  .sidebar-modal-box
    .guest-quantity-single-wrap
    .guest-quantity-single-box:before {
    border-color: #3a3a3c;
  }

  .sidebar-modal-box.available-table .single-detail-box .desc {
    color: #727272;
  }

  .modal-footer {
    border-top: 1px solid #3b3a3a;

    .modal-btn-box {
      padding-top: 13px;
    }
  }

  #seated-view-modal .modal-footer {
    border: none;
  }

  #new-booking-modal .modal-header {
    border-bottom: 1px solid #3b3a3a;
    padding-bottom: 17px;
  }

  .shadow {
    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      transition: 0.35s;
      background-color: #3b3a3a;
    }
  }

  .time-select-modal-wrap .modal-header {
    border-bottom: 1px solid #3b3a3a;
  }

  .modal-footer,
  .modal-header,
  .modal-box {
    background-color: #000000;
  }

  .message-modal-overlay,
  .tag-modal-overlay,
  #sidebar-modal-print-bill .sidebar-modal-overlay,
  .modal-overlay {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .custom-dropdown-wrap .custom-dropdown-box:before {
    background-color: #1c1c1e;
  }

  .custom-dropdown-wrap {
    border-color: #3a3a3a;
  }

  .custom-dropdown-wrap
    .dropdown-list-box
    > ul
    li
    label
    .dropdown-item-name
    span {
    font-weight: 700;
  }

  .single-suburb,
  .message-modal-wrap a.close-modal-btn,
  .message-modal-wrap .close-modal-btn,
  .tag-modal-wrap .single-tag-box,
  .tag-modal-wrap .tag-modal-nav a,
  .tag-modal-wrap .tag-modal-nav button,
  .tag-modal-wrap a.close-modal-btn,
  .tag-modal-wrap .close-modal-btn,
  .booking-note-box .icon-box,
  .booking-nav-bar a .icon-box,
  .booking-nav-bar button .icon-box,
  .booking-tag-box a,
  .booking-tag-box button,
  .booking-nav-bar a,
  .booking-nav-bar button,
  .modal-sliders-wrap .swiper-slide,
  .custom-dropdown-wrap
    .dropdown-list-box
    > ul
    li
    label
    .dropdown-item-name
    span,
  .custom-dropdown-wrap .dropdown-list-box > ul li label .dropdown-item-name {
    color: #ffffff;
  }

  .custom-dropdown-wrap .dropdown-list-box .dropdown-single-btn-box a.btn,
  .custom-dropdown-wrap .dropdown-list-box .dropdown-single-btn-box .btn {
    .inner-btn-box {
      border-color: #3a3a3a;
      background-color: #3a3a3a;
    }
  }

  .custom-dropdown-wrap .dropdown-list-box > ul li {
    border-top-color: #3b3a3a;
  }

  .swiper-grid-shadow {
    display: none;
  }

  .modal-sliders-wrap {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 26%,
      rgba(31, 30, 35, 1) 26%,
      rgba(31, 30, 35, 1) 50%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) 76%,
      rgba(31, 30, 35, 1) 76%,
      rgba(31, 30, 35, 1) 100%
    );
  }

  .custom-dropdown-wrap .custom-dropdown-box,
  .custom-dropdown-wrap .dropdown-list-box > ul li label .dropdown-item-name,
  .booking-note-list,
  .booking-tag-box a,
  .booking-tag-box button,
  .booking-nav-bar {
    background-color: #1c1c1e;
  }

  .modal-sliders-wrap .swiper-grid-box:before,
  .modal-sliders-wrap .swiper-grid-box:after {
    border: 1px solid rgba(86, 86, 86, 1);
  }

  .sidebar-modal-overlay {
    top: 21px;
  }

  .tag-modal-wrap {
    background-color: #000000;
  }

  .tag-modal-wrap .tag-modal-nav {
    background-color: #1c1c1e;
  }

  .tag-modal-wrap .tag-modal-nav a.active,
  .tag-modal-wrap .tag-modal-nav button.active {
    color: #ffffff;

    .inner-btn-box {
      border-color: rgba(109, 114, 120, 0.04);
      background-color: #6d7278;
    }
  }

  .tag-modal-wrap .single-tag-box {
    background-color: #000000;
  }

  .booking-tag-box a .icon-box,
  .booking-tag-box button .icon-box,
  .single-tag-box {
    .dine_icon-window,
    .dine_icon-graduation {
      color: #ffffff;
    }
  }

  .message-modal-wrap .message-modal-footer,
  .message-modal-wrap {
    background-color: #000000;
  }

  .message-modal-wrap .message-modal-fast-action a,
  .message-modal-wrap .message-modal-fast-action button {
    color: #fff;
    background-color: #1c1c1e;
  }

  .message-modal-wrap .message-modal-header {
    padding-bottom: 7px;
    border-bottom: 1px solid #3b3a3a;
  }

  .message-modal-footer {
    border-top: 1px solid #3b3a3a;
  }

  .table-view.nav-bar {
    &.tv-action-scroll {
      overflow: visible;
    }
  }

  .single-suburb {
    border-top-color: #3b3a3a;
  }

  .suburb-header-box {
    border-bottom-color: #3b3a3a;
  }

  .split-bill-seat-card .inner-box {
    background-color: #1c1c1e;
  }

  #sidebar-modal-print-bill .split-bill .sidebar-modal-header,
  #sidebar-modal-print-bill .split-bill,
  .split-bill-body {
    background-color: #000000;
  }

  #sidebar-modal-print-bill .split-price-box .name,
  .single-seat-item .seat-item-title,
  .single-seat-item .seat-item-instruction {
    color: #ffffff;
  }

  .single-seat-item .seat-price {
    color: #ffffff;
    background-color: #6e6e6e;
  }

  .split-bill-footer {
    background-color: #6d7278;
  }

  .split-bill-footer-nav > :not(:last-child) {
    border-color: #000000;
  }

  .split-bill-footer-nav > a.select-discount-items .icon-box,
  .split-bill-footer-nav > button.select-discount-items .icon-box,
  .split-bill-footer-nav > .btn-box.select-discount-items .icon-box,
  .split-bill-footer-nav > a.items-selected .icon-box,
  .split-bill-footer-nav > button.items-selected .icon-box,
  .split-bill-footer-nav > .btn-box.items-selected .icon-box {
    color: #6d7278;
  }

  #split-bill-discount-modal .item-cards .item-card label {
    border-color: #1c1c1e;
    background-color: #1c1c1e;
  }

  #sidebar-modal-print-bill .split-price-box {
    background-color: #1f1e23;
  }

  #sidebar-modal-print-bill .split-price-list,
  #sidebar-modal-print-bill .split-price-list > li {
    border-color: #2b2a2e;
  }

  #sidebar-modal-print-bill .split-price-box.active {
    background-color: #3a3a3c;
  }

  #sidebar-modal-print-bill .split-price-box.active .price {
    color: #cccccc;
  }

  .single-seat-item {
    background-color: #1c1c1e;
  }

  .split-bill-seats .single-seat-item {
    border-color: #3b3a3a;
  }

  .view-order-main .seats-list-wrap {
    background-color: #000000;
  }

  .view-order-main .view-order-main-footer,
  .order-total-paid-box,
  .view-order-main .seats-list-wrap .seat-items-wrap > :nth-child(odd) {
    background-color: #000000;
  }

  .view-order-main .seats-list-wrap .seat-items-wrap > :nth-child(even) {
    background-color: #1f1e23;
  }

  .order-status-row .order-status-box,
  .order-status-row {
    background-color: #1c1c1e;
  }

  .view-order-main .seats-list-wrap .single-item .single-actions a,
  .view-order-main .seats-list-wrap .single-item .single-actions button {
    color: #ffffff;
  }

  .order-status-row .order-status-box.dropdown:after,
  .order-total-paid-box .price-wrap,
  .view-order-main .seats-list-wrap .single-item .single-title,
  .order-status-row .order-status-box .desc,
  .order-time-btn-box a,
  .order-time-btn-box button,
  .guest-location-row .location-box .desc {
    color: #ffffff;
  }

  .nav-bar .bottom-nav-box {
    box-shadow: none;
  }

  a.order-status-btn,
  .order-status-btn {
    .inner-btn-box {
      border-color: #000000;
      background-color: #000000;
    }
  }

  .view-order-sidebar {
    background-color: #111213;
    border-right: 1px solid #3b3a3a;
  }

  .order-time-btn-box > :not(:last-child) {
    border-right-color: #3b3a3a;
  }

  .order-sidebar-bottom-nav {
    background-color: #000000;
    border-top-color: #3b3a3a;
  }

  .order-sidebar-bottom-nav,
  .order-total-paid-box {
    border-top: 1.6px solid #3b3a3a;
  }

  .order-sidebar-bottom-nav .booking-nav-box {
    border-top-color: #3b3a3a;
  }

  .order-total-paid-box .paycard-box.master,
  .order-time-btn-box a .icon-box,
  .order-time-btn-box button .icon-box {
    background-color: #1c1c1e;
  }

  .booking-nav-box a.active,
  .booking-nav-box button.active {
    color: #007aff;
  }

  .details-list > li.active {
    background-color: #067aff;
  }

  .single-detail-box .text-status {
    color: #ffffff;
    opacity: 0.67;

    &.orange {
      color: #fd6c00;
      opacity: 1;
    }

    &.red {
      color: #e02020;
      opacity: 1;
    }

    &.blue {
      color: #0386f6;
      opacity: 1;
    }

    &.grey {
      font-weight: 400;
      color: #757575;
      opacity: 1;
    }
  }

  .view-order-header a.header-action-btn:before,
  .view-order-header .header-action-btn:before {
    background-color: #ffffff;
  }

  .view-order-header a.header-action-btn:after,
  .view-order-header .header-action-btn:after {
    border-color: #ffffff;
  }

  .single-sidebar-action .icon-box,
  .single-sidebar-action .sidebar-action-title,
  .single-sidebar-action .sidebar-action-subtitle {
    color: #ffffff;
  }

  .sidebar-modal-view-orders-time .sidebar-modal-overlay,
  #sidebar-modal-view-orders-action.show .sidebar-modal-overlay {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .delivery-arriving-box,
  .single-sidebar-action {
    background-color: #1f1e23;
  }

  .view-order-main .seats-list-wrap .single-item .single-actions a,
  .view-order-main .seats-list-wrap .single-item .single-actions button,
  .view-order-main .seats-list-wrap .single-item .single-actions span {
    color: #717374;
  }

  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    a
    .icon-box,
  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    button
    .icon-box,
  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    a,
  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    button,
  #deliver-order-modal
    .modal-body
    .seats-list-wrap
    .seat-items-wrap
    .single-item
    #deliver-order-modal
    .modal-body
    .seats-list-wrap
    .seat-items-wrap
    .single-item
    .single-title,
  .order-status-row .order-status-box .icon-box,
  .guest-location-row .location-box .icon-box,
  #deliver-order-modal .modal-title,
  .sidebar-modal-view-orders-time .counter-desc,
  #sidebar-modal-view-orders-action a.close-modal-btn,
  #sidebar-modal-view-orders-action .close-modal-btn,
  .delivery-arriving-box {
    color: #ffffff;
  }

  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    a.red,
  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions
    > li
    button.red {
    color: #e12b2b;
  }

  .current-order-status-box.dropdown:after {
    color: #000000;
  }

  #payment-view-modal .payment-nav-box a,
  #payment-view-modal .payment-nav-box button,
  .order-status-row .order-status-box:not(:last-child) {
    border-color: #3b3a3a;
  }

  .view-order-header a.header-action-btn,
  .view-order-header .header-action-btn,
  #deliver-order-modal
    .modal-body
    .seats-list-wrap
    .seat-items-wrap
    > :nth-child(even) {
    background-color: #1f1e23;
  }

  .driver-main,
  .driver-main:before,
  #payment-view-modal .payment-nav-box a,
  #payment-view-modal .payment-nav-box button,
  #deliver-order-modal .modal-body .seats-list-wrap {
    background-color: #000000;
  }

  a.map-full-btn,
  .map-full-btn,
  #deliver-order-modal .deliver-order-actions-wrap .deliver-order-actions-box,
  .order-actions-btn-box,
  #deliver-order-modal .order-actions-btn-box .order-total-paid-box,
  .order-actions-btn-box a.btn-action:not(.green):not(.blue) .inner-btn-box,
  .order-actions-btn-box .btn-action:not(.green):not(.blue) .inner-btn-box {
    background-color: #3a3a3a;
  }

  .driver-main {
    border-left: 1px solid #3b3a3a;
  }

  #driver-map-modal a.close-modal-btn,
  #driver-map-modal .close-modal-btn,
  #driver-map-modal .modal-title,
  a.map-full-btn,
  .map-full-btn,
  .driver-main .single-detail-box .detail-row .detail-cell,
  .driver-sidebar .single-detail-box .detail-row .detail-cell,
  .driver-main .icon-box,
  .driver-single-box .driver-name {
    color: #ffffff;
  }

  #deliver-order-modal
    .deliver-order-actions-wrap
    .deliver-order-actions-overlay {
    background-color: #ffffff;
  }

  .booking-nav-bar a:not(:last-child),
  .booking-nav-bar button:not(:last-child) {
    border-right-color: #3b3a3a;
  }

  .booking-note-box .input-style {
    border-color: transparent;
  }

  .booking-note-list {
    border: 1px solid rgba(216, 216, 216, 0.26);
  }

  .guests-count-modal a.close-modal-btn,
  .guests-count-modal .close-modal-btn,
  .guests-count-modal a.back-modal-btn,
  .guests-count-modal .back-modal-btn {
    background-image: url(../../../assets/img/icon/close_white.svg);
  }

  .single-driver-style a.back-modal-btn,
  .single-driver-style .back-modal-btn,
  #sidebar-modal-available-table a.back-modal-btn,
  #sidebar-modal-waitlist a.back-modal-btn,
  #sidebar-modal-available-table .back-modal-btn,
  #sidebar-modal-waitlist .back-modal-btn {
    background-image: url(../../../assets/img/icon/left-chevron_white.svg);
  }

  .view-order-header a.header-action-btn:after,
  .view-order-header .header-action-btn:after {
    background-image: url(../../../assets/img/icon/burger_white.svg);
  }

  .order-time-btn-box a .icon-box,
  .order-time-btn-box button .icon-box {
    background-image: url(../../../assets/img/icon/down-chevron_white.svg);
  }

  #payment-view-modal a.close-modal-btn:after,
  #payment-view-modal .close-modal-btn:after {
    color: #ffffff;
  }

  .order-actions-btn-box a.btn-action.width-50:nth-child(odd) .inner-btn-box,
  .order-actions-btn-box .btn-action.width-50:nth-child(odd) .inner-btn-box {
    border-color: #2b2a2e;
  }

  .driver-accessibility-tabs
    .driver-accessibility-tab.grey
    .accessibility-header-box {
    background-color: #666666;
  }

  .drivers-style,
  .view-orders-style {
    .status-bar {
      &:before,
      &:after {
        content: none;
      }
    }
  }

  .iPad-box {
    a,
    button {
      &:before {
        background-color: rgb(255, 255, 255);
      }
    }
  }
}
