@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('../../fonts/HelveticaNowDisplay-Medium.eot');
  src: url('../../fonts/HelveticaNowDisplay-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('../../fonts/HelveticaNowDisplay-Medium.woff') format('woff'),
    url('../../fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('../../fonts/HelveticaNowText-Medium.eot');
  src: url('../../fonts/HelveticaNowText-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/HelveticaNowText-Medium.woff2') format('woff2'),
    url('../../fonts/HelveticaNowText-Medium.woff') format('woff'),
    url('../../fonts/HelveticaNowText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('../../fonts/HelveticaNowText-Regular.eot');
  src: url('../../fonts/HelveticaNowText-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/HelveticaNowText-Regular.woff2') format('woff2'),
    url('../../fonts/HelveticaNowText-Regular.woff') format('woff'),
    url('../../fonts/HelveticaNowText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('../../fonts/HelveticaNowText-Light.eot');
  src: url('../../fonts/HelveticaNowText-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/HelveticaNowText-Light.woff2') format('woff2'),
    url('../../fonts/HelveticaNowText-Light.woff') format('woff'),
    url('../../fonts/HelveticaNowText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('../../fonts/HelveticaNowText-Bold.eot');
  src: url('../../fonts/HelveticaNowText-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/HelveticaNowText-Bold.woff2') format('woff2'),
    url('../../fonts/HelveticaNowText-Bold.woff') format('woff'),
    url('../../fonts/HelveticaNowText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'dine-icons';
  src: url('../../fonts/dine-icons.eot?8oaxdk');
  src: url('../../fonts/dine-icons.eot?8oaxdk#iefix')
      format('embedded-opentype'),
    url('../../fonts/dine-icons.ttf?8oaxdk') format('truetype'),
    url('../../fonts/dine-icons.woff?8oaxdk') format('woff'),
    url('../../fonts/dine-icons.svg?8oaxdk#dine-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='dine_icon-'],
[class*=' dine_icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dine-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dine_icon-scooter-v2:before {
  content: '\e97a';
}

.dine_icon-store:before {
  content: '\e97b';
}

.dine_icon-widthdraw:before {
  content: '\e97c';
}

.dine_icon-deposit:before {
  content: '\e97d';
}

.dine_icon-chat-bubbles-inverse:before {
  content: '\e978';
}

.dine_icon-phone-inverse:before {
  content: '\e979';
}

.dine_icon-confirmed_new:before {
  content: '\e976';
}

.dine_icon-message:before {
  content: '\e977';
}

.dine_icon-chair:before {
  content: '\e966';
}

.dine_icon-coupon:before {
  content: '\e967';
}

.dine_icon-giving-hand:before {
  content: '\e968';
}

.dine_icon-discount_inverse:before {
  content: '\e969';
}

.dine_icon-cash-register:before {
  content: '\e964';
}

.dine_icon-search-users:before {
  content: '\e965';
}

.dine_icon-email:before {
  content: '\e95d';
}

.dine_icon-cigarette_new:before {
  content: '\e95e';
}

.dine_icon-shape_man:before {
  content: '\e95f';
}

.dine_icon-down-chevron1:before {
  content: '\e960';
}

.dine_icon-chat_bubble:before {
  content: '\e961';
}

.dine_icon-send_message:before {
  content: '\e962';
}

.dine_icon-close_light1:before {
  content: '\e963';
}

.dine_icon-minus_new:before {
  content: '\e959';
}

.dine_icon-chat-bouble:before {
  content: '\e956';
}

.dine_icon-chat-bouble-left:before {
  content: '\e957';
}

.dine_icon-confirmed:before {
  content: '\e958';
}

.dine_icon-plus_new:before {
  content: '\e95a';
}

.dine_icon-send-message-inverse:before {
  content: '\e95b';
}

.dine_icon-table-inverse:before {
  content: '\e95c';
}

.dine_icon-rotate-switch:before {
  content: '\e955';
}

.dine_icon-baby:before {
  content: '\e929';
}

.dine_icon-bench:before {
  content: '\e92a';
}

.dine_icon-birthday-cake:before {
  content: '\e92b';
}

.dine_icon-booth:before {
  content: '\e92c';
}

.dine_icon-business-man:before {
  content: '\e92d';
}

.dine_icon-calendar-inverse:before {
  content: '\e92e';
}

.dine_icon-champagine:before {
  content: '\e92f';
}

.dine_icon-chat-bubble:before {
  content: '\e930';
}

.dine_icon-chef:before {
  content: '\e931';
}

.dine_icon-cigarette:before {
  content: '\e932';
}

.dine_icon-cleared:before {
  content: '\e933';
}

.dine_icon-clock_inverse:before {
  content: '\e934';
}

.dine_icon-close_light:before {
  content: '\e935';
}

.dine_icon-dessert:before {
  content: '\e936';
}

.dine_icon-directions:before {
  content: '\e937';
}

.dine_icon-down-chevron:before {
  content: '\e938';
}

.dine_icon-fish:before {
  content: '\e939';
}

.dine_icon-gluten_free:before {
  content: '\e93a';
}

.dine_icon-graduation:before {
  content: '\e93b';
}

.dine_icon-handicap:before {
  content: '\e93c';
}

.dine_icon-hinduism:before {
  content: '\e93d';
}

.dine_icon-laptop-computer:before {
  content: '\e93e';
}

.dine_icon-laughing-face:before {
  content: '\e93f';
}

.dine_icon-leaf:before {
  content: '\e940';
}

.dine_icon-mail:before {
  content: '\e941';
}

.dine_icon-milk:before {
  content: '\e942';
}

.dine_icon-note1:before {
  content: '\e943';
}

.dine_icon-open-book:before {
  content: '\e944';
}

.dine_icon-path:before {
  content: '\e945';
}

.dine_icon-plus-new:before {
  content: '\e946';
}

.dine_icon-sad-face:before {
  content: '\e947';
}

.dine_icon-salad:before {
  content: '\e948';
}

.dine_icon-send-message:before {
  content: '\e949';
}

.dine_icon-smile-face:before {
  content: '\e94a';
}

.dine_icon-star:before {
  content: '\e94b';
}

.dine_icon-star-inverse:before {
  content: '\e94c';
}

.dine_icon-table-invese:before {
  content: '\e94d';
}

.dine_icon-theater:before {
  content: '\e94e';
}

.dine_icon-us_dolar:before {
  content: '\e94f';
}

.dine_icon-volume-off:before {
  content: '\e950';
}

.dine_icon-warning:before {
  content: '\e951';
}

.dine_icon-window:before {
  content: '\e952';
}

.dine_icon-work-briefcase:before {
  content: '\e953';
}

.dine_icon-writing-pen:before {
  content: '\e954';
}

.dine_icon-print:before {
  content: '\e96a';
}

.dine_icon-sms:before {
  content: '\e96b';
}

.dine_icon-order-price:before {
  content: '\e96c';
}

.dine_icon-item-sold:before {
  content: '\e96d';
}

.dine_icon-custom-del:before {
  content: '\e96e';
}

.dine_icon-cook:before {
  content: '\e96f';
}

.dine_icon-bag:before {
  content: '\e970';
}

.dine_icon-history:before {
  content: '\e971';
}

.dine_icon-credit-card:before {
  content: '\e972';
}

.dine_icon-custom-new:before {
  content: '\e973';
}

.dine_icon-bike:before {
  content: '\e974';
}

.dine_icon-home:before {
  content: '\e975';
}

.dine_icon-unseat:before {
  content: '\e91d';
}

.dine_icon-move:before {
  content: '\e91e';
}

.dine_icon-finish:before {
  content: '\e91f';
}

.dine_icon-check:before {
  content: '\e920';
}

.dine_icon-dish:before {
  content: '\e921';
}

.dine_icon-people-inverse:before {
  content: '\e922';
}

.dine_icon-people:before {
  content: '\e923';
}

.dine_icon-list:before {
  content: '\e924';
}

.dine_icon-calendar:before {
  content: '\e925';
}

.dine_icon-table:before {
  content: '\e926';
}

.dine_icon-walk-in:before {
  content: '\e927';
}

.dine_icon-peoples:before {
  content: '\e928';
}

.dine_icon-waiters:before {
  content: '\e916';
}

.dine_icon-sprayer:before {
  content: '\e917';
}

.dine_icon-men:before {
  content: '\e918';
}

.dine_icon-marker:before {
  content: '\e919';
}

.dine_icon-note:before {
  content: '\e91a';
}

.dine_icon-house:before {
  content: '\e91b';
}

.dine_icon-phone:before {
  content: '\e91c';
}

.dine_icon-clipboard:before {
  content: '\e910';
}

.dine_icon-dismiss:before {
  content: '\e911';
}

.dine_icon-emoji:before {
  content: '\e912';
}

.dine_icon-mic:before {
  content: '\e913';
}

.dine_icon-redo:before {
  content: '\e914';
}

.dine_icon-undo:before {
  content: '\e915';
}

.dine_icon-cash:before {
  content: '\e900';
}

.dine_icon-checkmark:before {
  content: '\e901';
}

.dine_icon-clock:before {
  content: '\e902';
}

.dine_icon-discount:before {
  content: '\e903';
}

.dine_icon-expand-fullscreen:before {
  content: '\e904';
}

.dine_icon-left-chevron:before {
  content: '\e905';
}

.dine_icon-minus:before {
  content: '\e906';
}

.dine_icon-pause:before {
  content: '\e907';
}

.dine_icon-plus:before {
  content: '\e908';
}

.dine_icon-repeat-once-2:before {
  content: '\e909';
}

.dine_icon-search:before {
  content: '\e90a';
}

.dine_icon-silverware:before {
  content: '\e90b';
}

.dine_icon-stacked-circles:before {
  content: '\e90c';
}

.dine_icon-trash:before {
  content: '\e90d';
}

.dine_icon-wallet-cash:before {
  content: '\e90e';
}

.dine_icon-close:before {
  content: '\e90f';
}
