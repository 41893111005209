.bem-select-item {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 26px 1fr 60px;
  gap: 0 15px;
  min-height: 85px;
  padding: 10px 20px 10px 15px;
  text-align: left;
  background-color: var(--mainBG);
  cursor: pointer;
  &__info-box {
    display: grid;
    width: 100%;
    gap: 5px 0;
    align-items: center;
    justify-content: flex-start;
  }
  &__title {
    word-break: break-word;
    display: block;
    font-size: 16.5px;
    line-height: var(--lineHeightTitle);
    font-weight: 400;
    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  &__instruction {
    display: block;
    font-size: 14.5px;
    line-height: var(--lineHeightTitle);
    font-weight: 400;
    letter-spacing: -0.05rem;
    color: #868686;
  }
  &__price {
    padding: 3px;
    color: var(--textColor);
    font-size: 14px;
    line-height: var(--lineHeightTitle);
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
  &__input-box {
    display: block;
    width: 26px;
    height: 26px;
  }
  &__input {
    overflow: hidden;
    width: 26px;
    height: 26px;
    border-radius: 50rem;
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    appearance: none;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-bottom: none;
    &:checked {
      background-color: var(--successColor);
      background-image: var(--checkboxIcon);
      box-shadow: inset 0 0 4px 0 rgba(99, 99, 99, 0.5);
    }
  }
}
