.bem-support-button {
  display: grid;
  height: 56px;
  padding: 10px 20px 10px;
  width: 100%;
  grid-template-columns: 32px 1fr;
  gap: 0 16px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  background-color: var(--mainBG);
  &__box {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    font-size: 15px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
    text-transform: uppercase;
    object-fit: cover;
    object-position: center;
    border-radius: 50rem;
    color: var(--whiteColor);
    background-color: var(--whiteColor);
    img {
      width: 100%;
      height: 100%;
      font-size: 12px;
    }
  }
  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 17px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
    color: var(--textColor);
  }
}
