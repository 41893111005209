.paid-payment-status {
  position: relative;
  z-index: 5;
  box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);

  &__item {
    display: grid;
    width: 100%;
    text-align: left;
    min-height: 75px;
    grid-template-columns: 30px 1fr 30px;
    gap: 0 20px;
    align-items: center;
    padding: 5px 35px;
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: 1px solid #e8e8e8;
    }

    .dark-mode & {
      border-bottom-color: #313131;
      background-color: #000000;
    }

    &:nth-of-type(even) {
      background-color: #f7f7f7;

      .dark-mode & {
        background-color: #1f1e23;
      }
    }
  }

  &__text {
    display: block;
    font-size: rem(18.5);
    color: $color-main;
    line-height: 1.25;

    b,
    strong {
      font-weight: $medium;
    }

    .dark-mode & {
      color: #ffffff;
    }
  }
}
