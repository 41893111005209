@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: $line-height-heading-2;
  font-weight: $medium;
  letter-spacing: -0.32px;
  white-space: nowrap;
  text-align: center;
  border-width: 1px;
  border-style: solid;

  &__text {
    display: block;
  }

  svg,
  img {
    min-width: 10px;
    flex-grow: 0;
    max-width: 60px;
  }

  @include UIStyleTypeModifiers();
  @include UISizeTypeModifiers('bem-badge');
  @include UIInnerSpacingModifiers();
  @include UIFontSizeModifiers();
  @include UIFontWeightModifiers();
  @include UIRadiusModifiers();
  @include UIOtherModifiers();
}
