.payment-modal {
  .generalModal-innerWrap {
    background-color: #f7f7f7;
  }

  a.generalModal-close-btn,
  .generalModal-close-btn {
    &.inside {
      .inner-btn-box {
        background-color: transparent;
      }
    }
  }

  &.cash-amount-selected {
    a.generalModal-close-btn,
    .generalModal-close-btn {
      &.inside {
        top: 12px;
        left: 12px;
      }
    }
  }
}

.payment-box {
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .cash-amount-selected & {
    display: block;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
  }

  .payment-box-header {
    position: relative;
    z-index: 5;
    padding: 29px 60px 20px;

    .cash-amount-selected & {
      padding-top: 34px;
      margin-bottom: 17px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .lottie-box {
    margin-bottom: 21px;
  }

  .payment-box-title {
    display: block;
    margin-bottom: 21px;
    font-size: 35px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #363636;

    .cash-amount-selected & {
      margin-bottom: 23px;
    }
  }

  .payment-box-select-payment-title {
    display: block;
    margin-bottom: 21px;
    font-size: 23px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #363636;
    .cash-amount-selected & {
      margin-bottom: 23px;
    }
  }

  .payment-box-desc {
    margin-bottom: 21px;
    font-size: 22px;
    line-height: 1.25;
    color: #777777;
  }

  .payment-box-body {
    position: relative;
    z-index: 1;
  }

  .payment-box-methods {
    display: flex;
    flex-wrap: wrap;
    padding: 0 42px;
    margin: 0 auto 0;

    .cash-amount-selected & {
      justify-content: center;
    }
  }

  .payment-box-method-wrap {
    flex: 1;
    padding: 16px 16px;

    .cash-amount-selected & {
      padding: 16px 19px;
      max-width: 220px;
    }

    > a,
    > button,
    > .payment-box-card {
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 26px;
      box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);

      .cash-amount-selected & {
        border-radius: 10px;
      }

      &:before {
        border-radius: 26px;

        .cash-amount-selected & {
          border-radius: 10px;
        }
      }

      > .inner-btn-box {
        height: 100%;
        min-height: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 16px 10px;
        border: 1px solid #e8e8e8;
        background-color: #ffffff;
        border-radius: 26px;

        .cash-amount-selected & {
          border-radius: 10px;
          min-height: 82px;
          padding: 5px;
        }
      }

      .payment-method-name {
        display: block;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -0.02em;

        &.green {
          color: #5eb602;
        }

        &.blue {
          color: #0084ff;
        }

        .cash-amount-selected & {
          margin: auto;
          font-size: 20px;
        }
      }

      .payment-method-img {
        display: block;
        max-width: 100px;

        img {
          display: block;
          max-width: 100%;
          height: auto;
          width: auto;
        }
      }

      &.pay-now {
        > .inner-btn-box {
          padding-bottom: 0;
        }

        .payment-method-img {
          max-width: 119px;
        }
      }

      &.pay-on {
        > .inner-btn-box {
          padding-bottom: 27px;
        }

        .payment-method-img {
          max-width: 157px;

          img {
            margin-left: -4px;
          }
        }
      }

      &.pay-on-delivery {
        > .inner-btn-box {
          padding-bottom: 27px;
        }
      }

      &.lottie {
        .inner-btn-box {
          padding: 10px;
          justify-content: center;
          background-color: transparent;
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: 18px;
      line-height: 1.25;
      letter-spacing: -0.005em;
      font-weight: 400;
      color: #363636;
      text-align: left;

      &:before {
        border-radius: 13px;
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        position: absolute;
        top: 50%;
        right: 32px;
        width: 20px;
        height: 21px;
        transform-origin: center;
        transform: translate(50%, -50%);
      }

      .inner-btn-box {
        position: relative;
        padding: 20px 55px 20px 13px;
        border-radius: 13px;
        box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5);
      }

      .pos_icon-credit-card-reader {
        margin-top: 1px;
        height: 32px;
        width: 27px;
      }

      .pos_icon-cash {
        margin-top: -1px;
        height: 31px;
        width: 32px;
      }
    }
  }

  .payment-box-footer {
    position: relative;
    z-index: 3;
    background-color: #ffffff;
    box-shadow: 0 3.6px 9px -1px rgba(0, 0, 0, 0.5);
  }

  .payment-btns-wrap {
    display: flex;
    flex-wrap: nowrap;

    a,
    button {
      flex: 1;
      display: block;
      font-size: 18px;
      letter-spacing: -0.02em;
      line-height: 1.25;
      color: #3a3a3a;

      .inner-btn-box {
        display: block;
        padding: 19px 10px;
        background-color: #ffffff;
        border-right: 1px solid #e3e3e3;
      }

      &:last-child {
        .inner-btn-box {
          border: none;
        }
      }
    }
  }
}

.dark-mode {
  .payment-modal {
    .generalModal-innerWrap {
      background-color: #000;
    }

    .payment-box {
      .payment-box-title {
        color: #ffffff;
      }

      .payment-box-method-wrap {
        > a,
        > button,
        > .payment-box-card {
          > .inner-btn-box {
            background-color: #2b2c2e;
            border-color: #2b2c2e;
          }
        }

        a.btn,
        .btn {
          color: #ffffff;

          .inner-btn-box {
            background-color: #111213;
          }
        }
      }

      .payment-box-footer {
        background-color: #2b2c2e;

        .payment-btns-wrap {
          a,
          button {
            color: #ffffff;

            .inner-btn-box {
              border-color: #3b3a3a;
              background-color: #2b2c2e;
            }
          }
        }
      }
    }
  }
}

.delivery-pay-on-delivery-container {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.select-payment-btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: rem(92);
  font-size: rem(14);
  font-family: $font-main;
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -$letter-spacing-20;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $color-white;
  appearance: none;

  &:before {
    border-radius: 3px;
  }

  .inner-btn-box {
    align-items: center;
    display: flex;
    height: 85px;
    margin: 0;
    width: 100%;
    padding: 6px 12px;
    border: rem(2) solid #067aff;
    background-color: #067aff;
    border-radius: 13px;
    transition: background-color 0s;

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: relative;
      top: rem(1);
      padding-right: 10px;
      font-size: 16px;
    }
    > div {
      flex-grow: 1;
      align-self: center;
      text-align: initial;
      display: block;
    }
    .dine_icon-plus {
      position: relative;
      top: rem(-1);
      padding-right: 5px;
      font-size: rem(10);
      font-weight: $bold;
    }

    .dine_icon-cash {
      font-size: 20px;
      top: 4px;
      line-height: 10px;
      padding-right: 6px;
      font-weight: $normal;
    }
  }

  &.red {
    .inner-btn-box {
      border-color: $pos-error;
      background-color: $pos-error;
    }
  }

  &.big {
    font-size: rem(17.84);
    letter-spacing: 0.02em;

    &:before {
      border-radius: rem(7.667);
    }

    .inner-btn-box {
      padding: rem(12) rem(20);
      border-radius: rem(7.667);
    }
  }

  &.inverse {
    color: #067aff;

    .inner-btn-box {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.grey {
    color: $color-text;

    .inner-btn-box {
      border-color: #dedede;
      background-color: #dedede;
    }

    &.inverse {
      .inner-btn-box {
        background-color: transparent;
      }

      &.active {
        .inner-btn-box {
          background-color: #dedede;
        }
      }
    }
  }

  &.green {
    color: #ffffff;

    .inner-btn-box {
      border-color: #5eb602;
      background-color: #5eb602;
    }
  }

  &.red {
    color: #ffffff;

    .inner-btn-box {
      border-color: $pos-error;
      background-color: $pos-error;
    }
  }

  &.round {
    font-size: rem(14.5);
    font-weight: $medium;

    &:before {
      border-radius: 20rem;
    }

    .inner-btn-box {
      padding: rem(5.5) rem(10);
      border-radius: 20rem;
      border-width: rem(1);
    }
  }

  &.white {
    color: #363636;
    font-weight: $normal;

    .inner-btn-box {
      border-color: #ffffff;
      background-color: #ffffff;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    }
  }

  &--more-round {
    .inner-btn-box {
      border-radius: 13px;
    }
  }

  &--small {
    min-width: rem(69);

    .inner-btn-box {
      display: flex;
      align-items: center;
      padding: 2px 8px;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      max-width: 17px;
      max-height: 17px;
      margin-right: 4px;
    }
  }

  &--with-icon {
    .inner-btn-box {
      display: flex;
      align-items: center;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      max-width: 17px;
      max-height: 17px;
      margin-right: 8px;
    }
  }

  &:disabled {
    .inner-btn-box {
      background-color: #d6d6d6;
      border-color: #d6d6d6;
    }

    cursor: default;
  }
}

.btn-title-inStore {
  color: #5eb602;
  font-weight: 500;
  font-size: 20px;
}

.btn-title-online {
  color: #0084ff;
  font-weight: 500;
  font-size: 20px;
}

.btn-subtitle {
  font-size: 18px;
  font-weight: 400;
}
.btn-subtitle-container {
  padding-top: 10px;
}
.padding-Top {
  padding-top: 18px;
}
