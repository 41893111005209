@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

$modalWidth: 439px;

.bem-item-view-modal {
  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    padding: 0;
    border-radius: 24px;
    background-color: #f7f7f7;
    @include ModalBodyRightGradient();
  }

  &__header {
    position: relative;
    z-index: 3;
    padding: 14px 17px 13px;
    background-color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-black, 0.12);

    .dark-mode & {
      background-color: #1f1e23;
    }
  }

  &__header-info {
    position: relative;
    z-index: 1;
    padding-right: 110px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.65px;
    color: var(--textColor);
  }

  &__desc {
    margin-bottom: 11px;
    font-size: 16px;
    line-height: $line-height-heading-2;
    letter-spacing: -0.34px;
    color: #494b4c;
    opacity: 0.7;
  }

  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
    .dark-mode & {
      background-color: #000000;
    }
  }

  &__body-inner {
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 16px;
  }

  &__title-row {
    padding: 14px 14px 12px;
  }

  &__title-row,
  &__group {
    border-bottom: solid 1px rgba(151, 151, 151, 0.14);
  }

  &__group-title {
    font-size: 18px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.51px;
    color: var(--textColor);
  }

  &__row {
    padding: 8px 12px;
    background-color: var(--secondaryBG);
  }

  &__row--border-bottom,
  &__ingredients-row--bottom-border {
    border-bottom: 1px solid rgba(#e9e9e9, 0.6);
  }

  &__ingredients-row {
    overflow: hidden;
    position: relative;
    display: grid;
    background: var(--secondaryBG);
    align-items: center;
    grid-template-columns: 48px 1fr;
    gap: 0 13px;
    padding: 8px 12px;
    cursor: pointer;

    &:after {
      position: absolute;
      z-index: 3;
      content: '';
      top: 0;
      right: 0;
      height: 100%;
      width: 80px;
      background: var(--gradientItemIngradients);
    }
  }

  &__ingredients {
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: block;
    padding-right: 0;
    font-size: 16px;
    font-weight: $normal;
    line-height: $line-height-heading-2;
    letter-spacing: -0.34px;
    color: var(--textColor);
    white-space: nowrap;
    text-transform: capitalize;

    &--without-ingredients {
      color: #a7a7a7;
    }
  }

  &__dot-button {
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
  }

  &__textarea {
    display: block;
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 3px 2px 0;
    color: var(--textColor);
    resize: none;
    border: none;
    @include font($normal, rem(16), 1.6, $font-main);
    letter-spacing: -0.34px;
    box-shadow: none;
    appearance: none;
    background-color: var(--secondaryBG);
    @include placeholder {
      padding: 0;
      @include font($normal, rem(16), 1.6, $font-main);
      color: #494b4c;
      transition: 0.35s;
      opacity: 0.38;

      &:focus {
        opacity: 0;
      }
    }
  }

  &__course {
    display: flex;
    flex-wrap: wrap;
    @include gapFlexSupportAlternative(14, 14);
    padding: 4px 0;
  }
  &__course-button {
    overflow: hidden;
    position: relative;
    display: block;
    z-index: 3;
    height: 28px;
    min-width: 46px;
    padding: 0 6px;
    text-align: center;
    color: #494b4c;
    font-size: 16.5px;
    font-weight: $medium;
    line-height: 28px;
    letter-spacing: -0.35px;
    background-color: #f7f7f7;
    border-radius: 9px;

    &--active {
      color: $color-white;
      background-color: $pos-blue;
    }
  }

  &__course-button {
    position: relative;
    cursor: pointer;
  }

  &__course-radio {
    position: absolute;
    z-index: 1;
    top: 3px;
    left: 3px;

    &:checked + .bem-item-view-modal__course-button-inner {
      color: $color-white;
      background-color: $pos-blue;
    }
  }

  &__course-button-inner {
    overflow: hidden;
    position: relative;
    display: block;
    z-index: 3;
    height: 28px;
    width: 46px;
    padding: 0 2px;
    text-align: center;
    color: #494b4c;
    font-size: 16.5px;
    font-weight: $medium;
    line-height: 28px;
    letter-spacing: -0.35px;
    background-color: #f7f7f7;
    border-radius: 9px;
  }

  &__footer {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    // grid-template-columns: 125px 1fr 125px; // will be later with adding more controls to the footer
    z-index: 2;
    padding: 10px 17px 14px;
    background-color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-black, 0.12);

    .dark-mode & {
      background-color: #1f1e23;
    }
  }
}
