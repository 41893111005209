.bem-input {
  display: block;
  width: 100%;
  color: $color-main;
  min-height: 56px;
  padding: 0 14px;
  @include font($normal, rem(18), 28px, $font-main);
  letter-spacing: -0.4px;
  border-radius: 9px;
  background-color: #ffffff;
  border: 1.7px solid #e7e6e6;
  box-shadow: none;
  appearance: none;
  transition: 0.35s, background-color 0s;
  &--right-offset {
    padding-right: 60px;
  }
  .dark-mode & {
    color: #ffffff;
    background-color: #1c1c1e;
    border-color: #3a3a3a;
  }
  &::-webkit-input-placeholder {
    @include font($normal, rem(18), 28px, $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
    .dark-mode & {
      color: #747474;
    }
  }
  &::-moz-placeholder {
    @include font($normal, rem(18), 28px, $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
    .dark-mode & {
      color: #747474;
    }
  }
  &:-ms-input-placeholder {
    @include font($normal, rem(18), 28px, $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
    .dark-mode & {
      color: #747474;
    }
  }
  &:-moz-placeholder {
    @include font($normal, rem(18), 28px, $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
    .dark-mode & {
      color: #747474;
    }
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 0;
    }
    &::-moz-placeholder {
      opacity: 0;
    }
    &:-ms-input-placeholder {
      opacity: 0;
    }
    &:-moz-placeholder {
      opacity: 0;
    }
  }
}
