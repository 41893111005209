@import '../../global/variables.module';
@import '../../global/mixins';

.clock-in-out-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;

  &__header {
    z-index: 20;
    background-color: #ffffff;
    box-shadow: 0 -4px 9px rgba(0, 0, 0, 0.5);

    .dark-mode & {
      background-color: #1b1c1e;
    }

    display: flex;
    justify-content: flex-start;
  }

  &__body {
    overflow: hidden;
    position: relative;
    z-index: 10;
    height: 100%;
    color: $color-white;
    background-color: $color-white;
    @include bg();
    background-image: url(../../../img/clock-in-out-bg.jpg);
  }

  &__inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  &__body-content {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.clock-in-out {
  overflow: hidden;
  position: relative;
  margin: 36px 20px;
  max-width: 405px;
  width: 100%;
  text-align: center;
  border-radius: 32px;

  &:after {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-color: rgba(#ffffff, 0.2);
    backdrop-filter: blur(50px);
    border-radius: 32px;
  }

  &--wide-ex-large {
    max-width: 944px;
  }

  &__inner {
    position: relative;
    z-index: 3;
    display: grid;
    gap: 42px 0;
    padding: 50px 40px 55px;

    &--space-between-big {
      gap: 52px 0;
    }
  }

  &__title {
    font-size: rem(35);
    font-weight: $medium;
    letter-spacing: -0.7px;
    line-height: $line-height-heading-2;
    color: #ffffff;
  }

  &__desc {
    font-size: rem(20);
    color: #ffffff;

    &--size-big {
      font-size: rem(23);
    }

    &--move-up {
      margin-top: -18px;
    }
  }

  &__header-group {
    display: grid;
    gap: 28px 0;
  }

  &__status-group {
    padding: 0 5px;
  }

  &__btn-group {
    display: grid;
    gap: 20px 0;
    padding: 0 5px;

    &--space-between-big {
      gap: 35px 0;
    }
  }
}

.clock-in-out-button {
  overflow: hidden;
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 70px;
  height: 75px;
  text-align: left;
  border-radius: 20px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.81);
  appearance: none;
  border: none;

  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #5eb602;

    &--color-theme-orange {
      background-color: #fd6c00;
    }

    &--color-theme-purple {
      background-color: #6236ff;
    }

    &--color-theme-pink {
      background-color: #b620e0;
    }

    &--color-theme-blue {
      background-color: #067aff;
    }

    &--color-theme-red {
      background-color: #ff3008;
    }
  }

  &__text {
    width: 100%;
    height: 75px;
    padding: 0 20px;
    color: $color-text;
    font-size: rem(22);
    font-weight: $normal;
    line-height: 75px;
    letter-spacing: -0.62px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;

    .dark-mode & {
      color: $color-white;
      background-color: #505050;
    }
  }
}
