.driver-main {
  position: relative;
  z-index: 5;
  height: 100%;
  background-color: #ffffff;

  .driver-inner-box {
    overflow: hidden;
    position: relative;
    z-index: 3;
    height: 100%;
  }

  &:before {
    position: absolute;
    z-index: 2;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    top: 0;
    left: 0;
    width: 100px;
    height: calc(100% - 58px);
    background-color: #ffffff;
    transform: rotate(180deg);
    box-shadow: -4px 5px 12px -2px rgba(0, 0, 0, 0.5);
  }
}

.driver-sidebar,
.driver-main {
  .icon-box {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 33px;
    font-size: 22px;
    line-height: 1;
    color: #494b4c;
    vertical-align: middle;

    [class^='pos_icon-'],
    [class*=' pos_icon-'],
    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: absolute;
      left: 50%;
      top: 50%;
      line-height: 1;
      transform: translate(-50%, -50%);
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      width: 20px;
      height: 20px;
    }

    &.red {
      color: #e02020;
    }
  }
}

.driver-accessibility-tabs {
  .accessibility-tab-title {
    display: inline-block;
    width: calc(100% - 137px);
    padding: 6px 22px;
    font-size: 17px;
    letter-spacing: -0.03em;
    font-weight: 500;
    line-height: 1.25;
    color: #ffffff;
    vertical-align: middle;
  }

  &.assign-driver-mode {
    .accessibility-tab-title {
      width: calc(100% - 91px);
    }
  }

  .accessibility-tab-count {
    display: inline-block;
    vertical-align: middle;

    .count {
      display: inline-block;
      vertical-align: middle;
      font-size: 17.6px;
      font-weight: 500;
      line-height: 1.25;
      color: #ffffff;
    }

    .icon-box {
      .pos_icon-bag {
        width: 19px;
        height: 22px;
        transform: translate(-50%, calc(-50% - 2px));
      }
    }
  }

  .accessibility-header-box {
    text-align: left;
    background-color: #5eb602;

    .icon-box {
      margin-right: 5px;
      color: #ffffff;
      background-color: transparent;

      .dine_icon-bag {
        margin-top: -3px;
      }
    }
  }

  .accessibility-body {
  }

  .driver-accessibility-list {
    .driver-single-box {
      cursor: pointer;
    }

    > li {
      &:not(:last-child) {
        border-bottom: solid 0.5px #e9e9e9;
        border-bottom-color: rgba(233, 233, 233, 0.71);
      }
    }

    > li.active {
      background-color: #067aff;

      .driver-read-more {
        display: none;
      }

      * {
        color: #fff;
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        svg {
          path {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
    }
  }

  .driver-accessibility-tab {
    &.green {
      .accessibility-header-box {
        background-color: #5eb602;
      }
    }

    &.orange {
      .accessibility-header-box {
        background-color: #fd6c00;
      }
    }

    &.red {
      .accessibility-header-box {
        background-color: #e02020;
      }
    }

    &.grey {
      .accessibility-header-box {
        background-color: #363636;
        background-color: rgba(54, 54, 54, 0.61);
      }
    }

    &.assign-driver-mode {
      .accessibility-tab-title {
        width: 84.6%;
      }
    }
  }
}

a.driver-read-more,
.driver-read-more {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  color: #b2b2b2;
  vertical-align: middle;

  .pos_icon-left-chevron {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 26px;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.driver-single-box {
  padding: 12px 15px;
  display: block;
  text-align: left;
  width: 100%;

  > div {
    display: inline-block;
    vertical-align: middle;
  }

  .driver-initials-box {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    margin-left: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #f7b500;
    border-radius: 50%;
    vertical-align: middle;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      letter-spacing: 0;
    }

    &.blue {
      background-color: #0686f6;
    }

    &.green {
      background-color: #5eb602;
    }

    &.yellow {
      background-color: #f7b500;
    }

    &.pink {
      background-color: #eb4469;
    }

    &.orange {
      background-color: #fd6c00;
    }

    &.red {
      background-color: #cd3f34;
    }

    &.grey {
      background-color: #6d7278;
    }
  }

  .driver-name {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #363636;
    text-transform: capitalize;
  }

  .initials-coll {
    width: 53px;
  }

  .name-coll {
    width: 266px;
    width: calc(100% - 299px);
  }

  .time-coll {
    width: 123px;

    .icon-box {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      background-color: transparent;

      .pos_icon-clock {
        width: 23px;
        height: 23px;
      }

      .pos_icon-store {
        width: 22px;
        height: 25px;
      }

      .dine_icon-store {
        font-size: 24px;
        margin-left: -1px;
        margin-top: -1px;
      }
    }

    .drivers-time {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      line-height: 1.25;
      color: #363636;
    }
  }

  .progress-coll {
    width: 79px;

    .icon-box {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      background-color: transparent;

      .pos_icon-bag {
        width: 19px;
        height: 22px;
      }

      .pos_icon-checkmark-circle {
        width: 22px;
        height: 22px;
      }

      .dine_icon-bag {
        margin-top: -1px;
      }

      &.finish {
        margin-right: 3px;
      }
    }

    .count {
      display: inline-block;
      vertical-align: middle;
      font-size: 17px;
      line-height: 1.25;
      color: #363636;
    }
  }

  .assign-coll {
    padding-left: 8px;

    a.btn,
    .btn {
      font-size: 16.6px;
      line-height: 1.2;

      .inner-btn-box {
        min-width: 102px;
        padding: 5px 12px;
        border-radius: 11px;
      }

      &:before {
        border-radius: 11px;
      }
    }
  }

  .read-more-coll {
  }

  .assign-driver-mode & {
    padding: 13px 18px 13px 20px;

    .initials-coll {
      width: 48px;
    }

    .name-coll {
      width: calc(100% - 244px);
    }

    .time-coll {
      width: 123px;
    }

    .progress-coll {
      width: 72px;
    }
  }
}

.drivers-page-map {
  position: relative;
  width: 100%;
  height: 100%;
  top: -50px;
  left: -2px;
  background: #ffffff;
}

a.map-full-btn,
.map-full-btn {
  position: absolute !important;
  z-index: 20;
  right: 15px;
  top: 13px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #494b4c;
  border-radius: 10px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;

  &:before {
    border-radius: 10px !important;
  }

  &:after {
    position: absolute;
    content: '\e904';
    top: 50%;
    left: 50%;
    @include icomoon();
    font-size: 19px;
    font-weight: 700;
    line-height: 1;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
}

.single-driver-style {
  .single-driver-header {
    padding: 10px 12px 10px;
  }

  a.back-modal-btn,
  .back-modal-btn {
    display: block;
    margin-bottom: 10px;
    font-size: 26px;
    background-image: url(../../../../assets/img/icon/left-chevron_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    &:after {
      content: none;
    }
  }

  .modal-title {
    font-size: 25px;
    letter-spacing: -0.02em;
  }

  .booking-inner-table {
    margin-bottom: 4px;
    padding: 0 5px 0 12px;

    .inner-cell {
      &:nth-child(2):last-child {
        width: 50%;
      }
    }
  }

  .order-status-row {
    z-index: 3;

    .order-status-box {
      height: 50px;

      .desc {
        line-height: 1.4;
      }

      &:first-child:nth-last-child(2) {
        width: 50%;
      }

      &:last-child:nth-child(2) {
        width: 50%;
      }
    }
  }

  a.modal-delivery-action-btn,
  .modal-delivery-action-btn {
    position: absolute;
    top: 14px;
    right: 26px;

    &:before {
      border-radius: 20rem;
    }
  }
}

.delivery-driver-map-box {
  overflow: hidden;
  position: relative;
  z-index: 2;
  height: 272px;
  width: 100%;
  background-color: #717374;

  &:after {
    position: absolute;
    content: '';
    bottom: -40px;
    left: 0;
    width: 657px;
    height: 31px;
    transform-origin: center;
    transform: rotate(180deg);
    box-shadow: -4px 5px 12px -2px rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &.shadow {
    &:after {
      opacity: 0;
    }
  }

  iframe {
    position: relative;
    left: -2px;
    top: -80px;
  }

  a.map-full-btn,
  .map-full-btn {
    position: absolute;

    &:before {
      border-radius: 10px;
    }
  }
}

.driver-orders-wrap {
  position: relative;
  z-index: 1;
  height: calc(100% - 440px);

  .booking-count-box {
    .pay-img-wrap {
      position: relative;
      margin-right: 12px;
      display: inline-block;
      width: 32px;
      height: 22px;
      border-radius: 3.6px;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      background-color: #ffffff;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }

      &.apple {
        img {
          max-width: 24px;
        }
      }

      &.visa {
        img {
          max-width: 23px;
        }
      }

      &.mastercard {
        img {
          max-width: 22px;
        }
      }
    }
  }

  .deliver-mark-completed {
    position: absolute;
    top: 50%;
    right: 65px;
    transform-origin: center;
    transform: translate(0, -50%);
  }

  .unassigned {
    .single-detail-box-completed {
      padding-right: 62px !important;
    }
    .single-detail-box {
      padding-right: 226px;
      padding-left: 24px;

      a.driver-read-more,
      .driver-read-more {
        position: absolute;
        top: 50%;
        right: 21px;
        transform-origin: center;
        transform: translate(0, -50%);
      }

      .booking-count-box {
        display: inline-block;
        margin-right: 0;
        margin-left: 17px;

        .count {
          min-width: 75px;
          letter-spacing: -0.02em;
        }
      }

      .client-name-box {
        text-align: left;

        .dine_icon-shape_man {
          margin-right: 18px;
          margin-left: 0;
        }

        .client-name {
          min-width: 72px;
        }
      }
    }
  }
}

.dummy-inner-table {
  display: inline-block;
  width: calc(100% - 99px);

  .dummy-inner-row {
    display: table;
    width: 100%;

    &.row-1 {
      margin-bottom: 9px;
    }

    > :nth-child(1):not(:only-child) {
      width: 55.2%;
    }

    > :nth-child(2) {
    }
  }

  .dummy-inner-cell {
    display: table-cell;
    width: 50%;
  }
}

.current-driver-map-box {
  overflow: hidden;
  position: relative;
  height: 305px;
  background-color: grey;

  iframe {
    position: relative;
    left: -2px;
    top: -80px;
  }
}

.current-driver-details-wrap {
  height: calc(100% - 305px);

  .current-driver-actions {
    margin-top: 13px;
    padding: 0 14px;

    > li {
      margin-bottom: 18px;
    }
  }

  .single-sidebar-action {
    .icon-box {
      position: absolute;
      right: 14px;
      width: 42px;
      height: 42px;

      .dine_icon-chat-bubbles-inverse {
        font-size: 28px;
        margin-top: 3px;
      }

      .dine_icon-bag {
        font-size: 27px;
      }

      .dine_icon-clock {
        margin-top: 1px;
        font-size: 26px;
      }

      .dine_icon-widthdraw {
        font-size: 26px;
        margin-left: 1px;
        margin-top: -2px;
      }

      .dine_icon-deposit {
        font-size: 26px;
        margin-left: 1px;
      }

      .pos_icon-clock {
        margin-top: 1px;
        margin-left: 1px;
        width: 26px;
        height: 26px;
      }

      .pos_icon-bag {
        width: 27px;
        height: 27px;
      }

      .pos_icon-chat-bubbles {
        margin-top: 2px;
        width: 27px;
        height: 27px;
      }

      .pos_icon-receive-money {
        margin-top: -2px;
        margin-left: 2px;
        width: 25px;
        height: 27px;
      }

      .pos_icon-send-money {
        margin-top: -2px;
        margin-left: 2px;
        width: 25px;
        height: 27px;
      }
    }
  }
}

.current-driver-details-box {
  height: 100%;
}

.current-driver-details-header {
  position: relative;
  z-index: 5;
  display: table;
  width: 100%;
  padding: 0 16px 0 10px;
  color: #ffffff;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.02em;

  .cell {
    display: table-cell;
    vertical-align: middle;
    padding: 6px 5px;

    &:first-child {
      width: 60%;
    }

    &:nth-child(2) {
      text-align: right;
    }

    .dine_icon-clock {
      font-size: 19px;
    }

    .time {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .dine_icon-clock {
    position: relative;
    font-size: 18px;
    margin-right: 12px;
    top: 1px;
  }

  .dine_icon-finish {
    position: relative;
    font-size: 21px;
    margin-right: 13px;
    line-height: 16px;
    top: 2px;
  }

  .pos_icon-clock {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 12px;
    vertical-align: middle;
  }

  .pos_icon-checkmark-circle {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 13px;
    vertical-align: middle;
  }

  .title {
    font-size: 16.6px;
    font-weight: 500;
  }

  &.green {
    background-color: #5eb602;
  }

  &.orange {
    background-color: #fd6c00;
  }

  &.red {
    background-color: #e02020;
  }

  &.grey {
    background-color: #363636;
    background-color: rgba(54, 54, 54, 0.61);
  }
}

.all-driver-style {
  a.scheduled-dropdown,
  .scheduled-dropdown {
    right: 17px;
    padding-right: 44px;
  }

  .nav-bar-header {
    padding-bottom: 9px;

    .nav-bar-title {
      margin-bottom: 0;
    }
  }

  .driver-accessibility-tabs {
    .accessibility-tab-title {
      padding: 6px 20px;
    }
  }

  .driver-single-box {
    padding: 12px 9px 12px 16px;

    .name-coll {
      width: calc(100% - 317px);
    }

    .time-coll {
      width: 136px;

      .drivers-time {
        font-size: 17px;
        letter-spacing: -0.02em;
      }
    }

    .progress-coll {
      width: 85px;
    }
  }

  a.map-full-btn,
  .map-full-btn {
    top: auto;
    bottom: 17px;
  }
}

.dark-mode {
  .driver-single-box {
    .time-coll {
      .drivers-time {
        color: #ffffff;
      }
    }

    .progress-coll {
      .count {
        color: #ffffff;
      }
    }
  }
}

.div-view-driver-deliveries {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  vertical-align: middle;
  display: inline-block;
  min-width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
}

.drivers-table-wrapper-div {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-color: #edf2f7;
  border-radius: 0.125rem;
  overflow: hidden;
}

.drivers-table {
  min-width: 100%;
  border-top-width: 1px;
  border-color: rgba(229, 231, 235, 1);
}

.drivers-table-head {
  background-color: rgba(249, 250, 251, 1);
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: rgba(107, 114, 128, 1);
  border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.drivers-table-head-th {
  padding: 12px 24px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: rgba(107, 114, 128, 1);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.drivers-table-body {
  border-top-width: 1px;
  border-color: rgba(229, 231, 235, 1);
}

.drivers-table-body-td {
  padding: 16px 24px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.25rem;

  &.centered {
    text-align: center;
  }
}

.drivers-table-footer {
  border-top: 1px solid rgba(229, 231, 235, 1);
}

.drivers-table-body-tr-odd {
  background-color: rgba(255, 255, 255, 1);
}

.drivers-table-body-tr-even {
  background-color: rgba(249, 250, 251, 1);
}

.text-gray-500 {
  color: rgba(107, 114, 128, 1);
}

.text-gray-900 {
  color: rgba(17, 24, 39, 1);
}

.font-weight-500 {
  font-weight: 500;
}

.order-payment-type-btn {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;

  :focus {
    outline: none;
  }

  &.selected {
    color: rgba(67, 56, 202, 1);
    background-color: rgba(224, 231, 255, 1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.confirm-orders-button {
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 4px;
  color: white;
  background-color: rgba(79, 70, 229, 1);
  width: 120px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.skip-orders-button {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid rgba(209, 213, 219, 1);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.confirm-orders-button {
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 4px;
  color: white;
  background-color: rgba(79, 70, 229, 1);
  width: 120px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.skip-orders-button {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid rgba(209, 213, 219, 1);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
