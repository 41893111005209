@import 'src/assets/scss/global/mixins';

.bem-button-group {
  display: flex;
  flex-wrap: wrap;
  @include UIGapModifiers();
  @include UIJustifyContentModifiers();
  @include UIAlignItemsModifiers();
  &--left-offset {
    margin-left: -5px;
  }
}
