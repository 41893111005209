.settings-section {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #101010;

  .title {
    font-size: 21px;
    font-weight: bold;
    padding: 0 0 20px 22px;
    display: inline-block;
  }

  &:last-child {
    border: none;
  }
}

.settings-orders {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 170px;
  z-index: 5;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  li {
    padding: 10px 0;
    width: 100%;
    text-align: center;

    &.selected {
      background: #d9d5d5;
    }
  }
}

.bem-settings {
  &__color-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px 50px;
    li {
      display: grid;
      grid-template-columns: 320px 100px 100px;
      align-items: center;
      gap: 20px;
    }
  }
  &__color-code {
    font-size: 18px;
    font-weight: $medium;
    text-transform: uppercase;
  }
  &__color-value {
    font-size: 18px;
  }
  &__color-box {
    display: block;
    height: 40px;
    width: 100px;
  }
}
