.view-order-sidebar {
  overflow: hidden;
  position: relative;
  z-index: 5;
  height: 100%;
  width: 100%;
  min-width: 400px;
  flex-basis: 39%;
  max-width: 39%;
  background-color: #ffffff;

  .view-order-sidebar-inner {
    position: relative;
    z-index: 1;
    height: 100%;
    background-color: #ffffff;
  }

  .nav-bar-details {
    height: 476px;
    height: calc(100% - 212px);
    overflow: auto;
  }

  .nav-bar-header {
    padding: 11px 20px 10px 15px;

    .top-box {
      display: flex;
      align-items: center;
      margin: 0 0 9px;
      justify-content: space-between;
    }

    .nav-bar-title {
      margin-bottom: 0;
    }
  }
}

.switch-btn-box,
.paid-unpaid-switch {
  display: inline-flex;

  .switch-btn-bg-box {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    border-radius: rem(10);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.04);
  }

  > li {
    position: relative;
  }

  button {
    position: relative;
    z-index: 5;
    min-width: 67px;
    padding: 0 10px;
    min-height: 28px;
    border-radius: rem(10);
    font-weight: 500;
    font-size: 1.7rem;
    color: #686868;
    letter-spacing: -0.02em;
    transition: color 0.5s ease-out;

    &.selected {
      color: #363636;
    }
  }

  .dark-mode & {
    border-radius: 10px;
    background-color: #1f2129;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);

    .switch-btn-bg-box {
      background-color: #646464;
    }

    button {
      color: #878787;

      &.selected {
        color: #ffffff;
      }
    }
  }
}

.details-tab-wrap {
  .details-tab-header {
    position: relative;
    color: #ffffff;
    padding: 5px 50px 5px 17px;
    background-color: #fd6c00;

    &:after {
      position: absolute;
      content: '\e960';
      top: 50%;
      right: 19px;
      margin-top: 1px;
      @include icomoon();
      font-weight: 700;
      font-size: 9px;
      transform-origin: center;
      transform: translate(0, -50%);
    }

    &.orange {
      background-color: #fd6c00;
    }

    &.green {
      background-color: #5eb602;
    }

    .details-tab-title {
      display: block;
      font-size: 16px;
      font-weight: $medium;
      line-height: 1.25;
    }
  }

  .details-tab-body {
    .single-detail-box {
      padding-right: 17px;

      .status-btn {
        margin-left: 9px;
      }
    }

    .booking-count-box {
      margin-right: 19px;

      .count {
        letter-spacing: -0.02em;
      }

      .dine_icon-credit-card {
        font-size: 18.5px;
        margin-right: 8px;
        margin-top: 4.5px;
      }

      .dine_icon-cash {
        font-size: 25px;
        margin-right: 8px;
        margin-top: 0;
      }

      .dine_icon-peoples {
        margin-right: 8px;
      }
    }

    .single-detail-box .quantity-box > :last-child {
      margin-right: 0;
    }
  }

  &.history {
    .details-tab-body {
      .details-list {
        > li {
          &:first-child {
            border-top: none;
          }
        }
      }
    }

    &:not(:last-child) {
      .details-tab-body {
        .details-list {
          > li {
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.order-sidebar-bottom-nav {
  position: absolute;
  z-index: 5;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;

  &:after {
    position: absolute;
    content: '';
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    transform-origin: center;
    transform: rotate(180deg);
    box-shadow: 0 -4.6px 9px 0 rgba(0, 0, 0, 0.5);
  }

  .booking-nav-box {
    position: relative;
    z-index: 3;
    left: auto;
    bottom: auto;
    box-shadow: none;
    border-top: solid 2px #f0f0f0;
    height: 58px;

    [class^='custom_icon-'],
    [class*=' custom_icon-'] {
      position: relative;
      display: inline-block;
      width: 28px;
      height: 28px;

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 28px;
        height: 28px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);
      }
    }

    .custom_icon-unseat {
      &:after {
        margin-top: -3px;
        margin-left: 0;
        width: 25px;
        height: 25px;
        background-image: url(../../../../assets/img/icon/upcoming_grey.svg);

        .dark-mode & {
          background-image: url(../../../../assets/img/icon/upcoming_white.svg);
        }
      }
    }

    .custom_icon-cook {
      &:after {
        margin-top: -2px;
        margin-left: 1px;
        width: 25px;
        height: 30px;
        background-image: url(../../../../assets/img/icon/cook_grey.svg);

        .dark-mode & {
          background-image: url(../../../../assets/img/icon/cook_white.svg);
        }
      }
    }

    .custom_icon-bag {
      &:after {
        margin-top: -3px;
        margin-left: 0;
        width: 26px;
        height: 28px;
        background-image: url(../../../../assets/img/icon/bag_grey.svg);

        .dark-mode & {
          background-image: url(../../../../assets/img/icon/bag_white.svg);
        }
      }
    }

    .custom_icon-history {
      &:after {
        margin-top: -3px;
        margin-left: -2px;
        width: 26px;
        height: 28px;
        background-image: url(../../../../assets/img/icon/history_grey.svg);

        .dark-mode & {
          background-image: url(../../../../assets/img/icon/history_white.svg);
        }
      }
    }

    a,
    button {
      padding: 6.41px 10px 6px;
      width: 33.33333%;

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        width: 28px;
        height: 28px;

        svg {
          path {
            transition: fill 0.2s;
          }
        }
      }

      .pos_icon-cook {
        width: 25px;
        height: 28px;
        margin-bottom: 1px;
      }

      .pos_icon-up-square {
        width: 25px;
        height: 25px;
        margin-bottom: 4px;
      }

      .pos_icon-history {
        width: 27px;
        height: 25px;
        margin-bottom: 4px;
      }

      &.active {
        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          svg {
            path {
              fill: rgb(0, 122, 255);
              stroke: rgb(0, 122, 255);
            }
          }
        }

        .custom_icon-unseat {
          &:after {
            background-image: url(../../../../assets/img/icon/upcoming_blue.svg);
          }
        }

        .custom_icon-cook {
          &:after {
            background-image: url(../../../../assets/img/icon/cook_blue.svg);
          }
        }

        .custom_icon-bag {
          &:after {
            background-image: url(../../../../assets/img/icon/bag_blue.svg);
          }
        }

        .custom_icon-history {
          &:after {
            background-image: url(../../../../assets/img/icon/history_blue.svg);
          }
        }
      }
    }
  }
}

.order-time-btn-box {
  position: relative;
  z-index: 2;

  > :not(:last-child) {
    border-right: solid 2px #f0f0f0;
  }

  a,
  button {
    position: relative;
    display: inline-block;
    width: 50%;
    font-size: 0;
    text-align: left;
    color: #363636;
    padding: 9px 18px;

    .text-wrap {
      font-size: 16.6px;

      span {
        display: block;
        line-height: 1.25;
      }

      .sub-text {
        margin-bottom: 4px;
        font-size: 13.5px;
        font-weight: $medium;
        color: #979797;
      }
    }

    .icon-box {
      position: absolute;
      top: 50%;
      right: 15px;
      width: 26px;
      height: 26px;
      color: #757575;
      background-color: #ffffff;
      transform: translate(0, -50%);
      border-radius: 50rem;
      background-image: url(../../../../assets/img/icon/down-chevron_grey.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 11px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    }

    &:first-child {
      .icon-box {
        right: 18px;
      }
    }
  }
}

.dark-mode {
  .view-order-sidebar {
    .view-order-sidebar-inner {
      background-color: #000000;
    }
  }
}
