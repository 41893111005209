.select-time-modal .innerModal-box {
  @media (min-height: 900px) {
    overflow: hidden;
    border-radius: 0 0 28px 28px;
  }
}

.select-time-modal-box {
  .select-time-btn-box {
    a.btn,
    .btn {
      &.full-width {
        display: block;
        width: 100%;
        font-size: rem(18);
        font-weight: $medium;
        letter-spacing: -0.025em;

        &:before {
          border-radius: 0;
        }

        .inner-btn-box {
          padding: 18px 20px;
          border-radius: 0;
        }
      }
    }
  }
}

.time-modal-title-container{
  padding-left: 30px;
  padding-top: 20px;
}

.time-modal-title {
  font-size: 20px;
  font-weight: 500;
}

.time-modal-title-time {
  font-size: 35px;
  font-weight: 100;
}

.time-modal-divider{
  box-shadow: 0 9px 9px rgba(0, 0, 0, 0.2);
border: 1px solid transparent;
  
}
