.split-bill-footer {
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #0386f6;

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: rotate(180deg);
    box-shadow: 0 -4px 9px -1px rgba(0, 0, 0, 0.5);
  }
}

.split-bill-footer-nav {
  position: relative;
  z-index: 5;
  text-align: left;
  width: 100%;

  > :not(:last-child) {
    border-right: 1px solid #0377db;
  }

  > a,
  > button,
  > .btn-box {
    display: inline-block;
    width: 25%;
    padding: 9px 10px;
    font-size: 0;
    text-align: left;
    color: #ffffff;
    vertical-align: middle;
    transition: 0s;

    .inner-box {
      display: block;
      margin: 0 auto;

      > span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .name {
      font-size: 18px;
      line-height: 1.25;
      font-weight: $medium;
      letter-spacing: -0.02em;
    }

    .icon-box {
      position: relative;
      width: 42px;
      height: 42px;
      font-size: 22px;

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%);
      }

      .dine_icon-close {
        font-size: 16px;
      }

      .dine_icon-giving-hand {
        margin-top: 2px;
        font-size: 15px;
      }

      .dine_icon-move {
        font-weight: 700;
      }

      .dine_icon-left-chevron {
        font-size: 16px;
        margin-left: -1px;
      }

      & + .name {
        margin-left: 10px;
      }
    }

    &.items-selected {
      .inner-box {
        max-width: 200px;

        & + .name {
          margin-left: 9px;
        }
      }

      .icon-box {
        color: #0377db;

        &:after {
          position: absolute;
          z-index: 1;
          content: '';
          top: 50%;
          left: 50%;
          width: 28px;
          height: 28px;
          border-radius: 50rem;
          background-color: #ffffff;
          transform: translate(-50%, -50%);
        }

        & + .name {
          font-size: 19px;
          letter-spacing: 0.02em;
        }
      }
    }

    &.select-discount-items {
      width: 40%;
      padding: 9px 14px;
      border-right: transparent;

      .name {
        word-spacing: -0.1em;
      }

      .inner-box {
        max-width: 100%;

        & + .name {
          margin-left: 10px;
        }
      }

      .icon-box {
        color: #0377db;

        &:after {
          position: absolute;
          z-index: 1;
          content: '';
          top: 50%;
          left: 50%;
          width: 28px;
          height: 28px;
          border-radius: 50rem;
          background-color: #ffffff;
          transform: translate(-50%, -50%);
        }

        & + .name {
          font-size: 19px;
          letter-spacing: 0.02em;
        }
      }
    }

    &.add-seat {
      .inner-box {
        max-width: 160px;
      }
    }

    &.share-items {
      .inner-box {
        max-width: 186px;

        .icon-box + .name {
          margin-left: 16px;
        }
      }
    }

    &.discount-items {
      .inner-box {
        max-width: 192px;

        .icon-box + .name {
          margin-left: 6px;
        }
      }
    }

    &.move-items {
      .inner-box {
        max-width: 189px;
      }
    }
  }

  > .btn-box {
    padding: 9px 20px;
    width: 60%;
    height: 56px;
    overflow: hidden;
    text-align: right;

    a.btn,
    .btn {
      min-width: 146px;
      font-size: 20px;
      font-weight: $medium;
      color: #0386f6;
      border-radius: 8px;
      margin-bottom: 20px;

      &.share-style {
        min-width: 104px;
      }

      &.round {
        border-radius: 50rem;
      }

      &.text-blue {
        color: #0386f6;
      }

      &.text-green {
        color: #5eb602;
      }

      &.text-yellow {
        color: #fd6c00;
      }

      &.text-red {
        color: #eb4469;
      }

      &:disabled {
        opacity: 0.8;
      }
    }

    > :not(:last-child) {
      margin-right: 21px;
    }
  }

  &.has-selected {
    > a,
    > button,
    > .btn-box {
      width: 20.86%;

      &.items-selected {
        width: 37.4%;

        .inner-box {
          max-width: 354px;

          .icon-box + .name {
            margin-left: 13px;
          }
        }
      }

      &.add-seat {
        .inner-box {
          .icon-box + .name {
          }
        }
      }

      &.share-items {
        .inner-box {
          max-width: 170px;

          .icon-box + .name {
          }
        }
      }

      &.discount-items {
        .inner-box {
          .icon-box + .name {
          }
        }
      }

      &.move-items {
        .inner-box {
          max-width: 183px;

          .icon-box + .name {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
