.pos-icon {
  display: inline-flex;
  align-items: center;
  justify-items: center;

  > svg {
    width: 100%;
    height: 100%;
  }

  &__check-circle-solid {
    position: relative;

    svg {
      position: relative;
      z-index: 1;
    }

    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      width: 90%;
      height: 90%;
      left: 50%;
      top: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      border-radius: 100rem;
    }
  }

  &--input-icon {
    position: absolute;
    z-index: 3;
    display: flex;
    right: 2px;
    top: 2px;
    bottom: 2px;
    width: 60px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0 9px 9px 0;
    .dark-mode & {
      background-color: #1c1c1e;
    }
  }
}
