.bem-add-driver-modal {
  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.025rem;
    text-align: center;
  }
  .bem-base-part {
    &__header {
      padding: 32px 22px 21px;
      box-shadow: none;
    }
    &__body-inner {
      display: block;
      width: 100%;
      padding: 10px 22px 20px;
    }
  }
}
