@import '../../assets/scss/global/variables.module';
@import '../../assets/scss/global/mixins';

@mixin dragBg() {
  $borderColor: rgba(151, 151, 151, 0.08);
  background-color: #2b2b2b;
  background-size: 23px 23px;
  background-image: linear-gradient(to right, $borderColor 2px, transparent 2px),
    linear-gradient(to bottom, $borderColor 2px, transparent 2px);
  background-position: 0 0;
}

.table-arrangement {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 100;
  background: white;

  .header {
    z-index: 0;
  }

  .add-floor-icon {
    height: 15px !important;
    width: 15px !important;
    padding-right: 10px;
  }

  &__header {
    &-btn-box {
      display: flex;
      align-items: center;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 25;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    justify-content: space-between;
    height: 100%;
    height: calc(100% - 56px);
    background-color: #fff;
    overflow: hidden;
  }

  &__side-panel {
    width: 326px;
    height: 100%;
    background: white;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 10px;
      top: -10px;
      z-index: 102;
      box-shadow: 0 -4px 9px rgba(0, 0, 0, 0.5);
    }
  }

  &__layout-area {
    position: relative;
    width: calc(100% - 326px);
    height: 100%;
    background-color: #2b2b2b;
    overflow: auto;
    z-index: 100;

    .loader {
      background-color: #2b2b2b;
    }
  }

  .btn.round {
    font-size: 16.5px;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

.drag-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &--edit-mode {
    @include dragBg();
  }
}

.shape {
  position: absolute;
  opacity: 1;
  transition: opacity 0.35s ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-color: rgba(247, 181, 0, 0.05);
    border: 2.5px solid #fffc49;
    transform: translate(-50%, -50%);
    border-radius: 18px;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  &--is-loading {
    opacity: 0;
  }

  &--is-available {
    &:before {
      border-color: #5eb602;
      visibility: visible;
      opacity: 0.91;
    }
  }

  &--is-selected {
    &:before {
      visibility: visible;
      opacity: 0.91;
    }
  }

  .svg-status-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 29px);
    border-radius: 10px;
    border: 2px solid;
    box-sizing: content-box;
    top: 9px;
    left: 9px;
    right: 9px;
  }

  &.RoundTable {
    .svg-status-box {
      border-radius: 50%;
      height: calc(100% - 23px);
      top: 5px;
      left: 6px;
      right: 6px;
    }
  }
  &.SquareTable {
    .svg-status-box {
      height: calc(100% - 27px);
      top: 8px;
      left: 8px;
      right: 8px;
    }
  }
  &.RhombusTable {
    .svg-status-box {
      transform: rotate(-45deg);
      height: calc(100% - 32px);
      top: 10px;
      left: 10px;
    }

    // TODO: provide better workaround for position color overlay
    // for the RhombusTable
    &--num-seats-5,
    &--num-seats-6,
    &--num-seats-7,
    &--num-seats-8 {
      .svg-status-box {
        height: calc(100% - 49px);
        top: 19px;
        left: 17px;
        right: 17px;
      }
    }
    &--num-seats-9,
    &--num-seats-10,
    &--num-seats-11,
    &--num-seats-12 {
      .svg-status-box {
        height: calc(100% - 51px);
        top: 20px;
        left: 18px;
        right: 18px;
      }
    }
    &--num-seats-13,
    &--num-seats-14,
    &--num-seats-15,
    &--num-seats-16 {
      .svg-status-box {
        height: calc(100% - 58px);
        top: 23px;
        left: 23px;
        right: 23px;
      }
    }

    .dine-in-object__inner-wrapper {
      transform: rotate(45deg);
    }
  }
}

.loader {
  position: absolute;
  top: 56px; // header height
  width: 100%;
  height: calc(100% - 56px); // header height
  z-index: 9999;

  &__inner {
    position: absolute;
    top: 0;
    left: 326px;
    width: calc(100% - 326px);
    height: 100%;
    background-color: #2b2b2b;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__refresh {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 326px;
    width: calc(100% - 326px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
}

.objects-loader {
  position: absolute;
  top: 56px; // header height
  width: 100%;
  height: calc(100% - 56px); // header height
  z-index: 9999;

  &__inner {
    position: absolute;
    top: 0;
    left: 326px;
    width: calc(100% - 326px);
    height: 100%;
    background-color: #2b2b2b;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.notifications-loader {
  position: absolute;
  bottom: 17px;
  right: 5px;
  z-index: 9999;
  opacity: 0;
}

.side-panel {
  position: relative;

  &__wrapper {
    position: relative;
    z-index: 2;
    overflow: auto;
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
  }

  &__empty {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform-origin: center;
    transform: translate(-50%, calc(-50% - 36px));

    &-text {
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: -0.03em;
      color: #363636;
      opacity: 0.39;
    }
  }

  &__header {
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 18px 15px 0;

    &-wrapper {
      position: relative;
    }

    .pos_icon-close {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  &__remove-btn {
    position: absolute;
    top: 12px;
    right: 20px;
    z-index: 2;
  }
  &__footer {
    padding: 10px 15px;
    height: 70px;
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    color: #363636;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: 0;
  }
  &__details {
    position: relative;
    z-index: 1;
    height: 100%;
    // height: calc(
    //   100% - (95px + 70px)
    // ); // side-panel__header + side-panel__footer heights
    overflow: auto;
    background-color: #fff;

    &--no-footer {
      height: calc(100% - (95px)); // side-panel__header height only
    }
  }
  &__nav {
    padding: 6px 17px 15px;

    li {
      margin-bottom: 21.5px;
      text-decoration: none;
      font: 400 1.8rem/1.25 'HelveticaNowText', sans-serif;
      color: #363636;
    }
  }

  &__section {
    height: 100%;
  }

  &__icon {
    height: 30px !important;
    width: 30px !important;
  }
}

.single-nav-item-disabled {
  opacity: 0.4;
}

.single-nav-item {
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: center;
  min-height: 70px;
  padding: 10px 52px 10px 16px;
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
  will-change: transform;
  cursor: pointer;

  &__customTags {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 15px;
  }

  &__name {
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
    flex: 1;
  }
  &__sync {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
    margin-right: 5px;
    color: #067aff;
    font-weight: 500;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 26px;
    width: 30px;
    height: 30px;
    color: #494b4c;
    transform: translate(0, -50%);

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      @include centered-pos-icon();
    }
  }
}

.shapes-list {
  list-style: none;
  padding: 10px;

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-name {
      @include font($normal, rem(18), $line-height-heading-2, $font-main);
      color: #363636;
      padding-bottom: 10px;
    }
    &-shape-box {
      width: 117px;
      height: 117px;
      margin-right: 16.5px;
      border-radius: 13px;
      background-color: $pos-dark-grey;
      position: relative;

      img,
      svg {
        @include centered-pos-icon();
      }

      &--LineDivider {
        img,
        svg {
          max-width: 90%;

          rect {
            width: 100%;
          }
        }
      }
      &--RectangularTable {
        img,
        svg {
          transform: translate(-50%, -50%) scale(0.8);
        }
      }
      &--RoundTable {
        img,
        svg {
          transform: translate(-50%, -50%) scale(0.7);
        }
      }
      &--RhombusTable {
        img,
        svg {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
      &--PottedPlant {
        img,
        svg {
          margin: -5px 0 0 -4px;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
  }
}

.floors {
  position: absolute;
  width: 100%;
  bottom: 5%;
  right: 0;

  &__item {
    background-color: white;

    &--is-selected {
      color: orange;
    }
  }
}

.dine-in-object {
  position: relative;
  color: #ffffff;
  height: 100%;

  &--text-light {
    color: #ffffff;
  }
  &--text-dark {
    color: #646464;
  }

  &__box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  &__inner {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-top: 7px;
  }
  &__inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  &__number {
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    color: #646464;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;
    user-select: none;

    &--occupied {
      color: white;
    }
  }
  &__progress-bar {
    overflow: hidden;
    position: relative;
    display: block;
    width: 38px;
    margin: 0 auto;
    height: 3px;
    font-size: 0;
    background-color: #c8c8c8;
    border-radius: 3px;
  }
  &__progress-line {
    position: absolute;
    z-index: 5;
    display: block;
    top: 0;
    left: 0;
    height: 3px;
    background-color: #ffffff;
  }
  &__icon {
    display: block;
    margin: 0 auto;

    &.pos_icon-people {
      width: 18px;
      height: 17px;
      margin-bottom: 10px;
    }
  }
  &__svg {
    position: relative;
    display: block;
    z-index: 1;
    transform-origin: center;
  }
}

.table-configuration {
  &__control-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 20px;
    gap: 13px 20px;
    padding: 12px 20px;

    .slider {
      display: block;
    }

    &:nth-child(odd) {
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      background-color: #f7f7f7;
    }
  }
  &__btn {
    margin: 12px 20px;
  }
}

.table-config-control {
  display: grid;
  gap: 17px 0;
  line-height: 1.25;
  justify-content: center;

  &__title {
    font-weight: 500;
    font-size: 19px;
  }
  &__counter {
    display: grid;
    grid-template-columns: 33px 1fr 33px;
    gap: 0 10px;
    max-width: 130px;
    width: 100%;
    align-items: center;
  }
  &__count {
    font-weight: 500;
    font-size: 19px;
    text-align: center;
    line-height: 1;
    user-select: none;

    &--disabled {
      opacity: 0.4;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    font-size: 20px;
    background-color: #ffffff;
    border-radius: 11px;
    box-shadow: 0 0 9px -3px rgba(0, 0, 0, 0.5);

    &--disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
  &__input {
    height: 38px;
    border: 0;
    padding: 0 10px;
    width: calc(100% - 20px);
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.5);
  }
  &__info {
    display: flex;
    align-items: center;
    //gap: 0 10px;
    @include gapFlexSupportAlternative(0, 10);
    font-size: 13px;
    color: rgba(54, 54, 54, 0.5);
  }
  &__info-icon {
    color: #969696;
    width: 15px;
  }
}

.floor-layout-tabs {
  z-index: 99;
  padding-bottom: 46px;
  max-width: 90%;
  max-width: calc(100% - 40px);
  &__delete-icon {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
