.bem-select-share-item {
  background-color: #ffffff;

  &__sharing-box {
    overflow: hidden;
    position: relative;
    top: -12px;
    max-height: 0;
    transition: max-height 0.15s ease, opacity 0.15s ease 0.15s;
  }
  &__sharing-box-inner {
    display: grid;
    padding: 10px 15px 20px;
    gap: 16px 0;
  }
  &__text {
    display: block;
    font-size: 16.5px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  .bem-select-item {
    background-color: transparent;
    &__title {
    }
  }
  .bem-counter {
    max-width: 175px;
  }
  .bem-counter__quantity {
    font-size: 19.5px;
    font-weight: 400;
  }

  .bem-counter,
  .bem-select-share-item__text {
    color: var(--whiteColor);
  }

  &--checked {
    height: 172px;
    background-color: var(--primaryColor);
    .bem-select-share-item__sharing-box {
      max-height: 200px;
    }
    .bem-counter,
    .bem-select-item__title,
    .bem-select-share-item__text,
    .bem-select-item__instruction {
      color: var(--whiteColor);
    }
    .bem-select-item__instruction {
      opacity: 0.75;
    }
  }
}
