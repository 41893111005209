.denominations-short-modal-box {
  padding: 37px 0 46px;

  .denominations-short-title {
    margin-bottom: 31px;
    padding: 0 20px;
    text-align: center;
    color: #e02020;
  }

  .denominations-short-form {
    .single-input-box {
      label {
        display: block;
        padding: 0 17px;
        margin-bottom: 10px;
        font-size: rem(18);
        font-weight: $normal;
        line-height: 1.25;
        letter-spacing: -0.02em;
        color: #777575;
        text-transform: capitalize;
      }

      .input-style {
        height: 185px;
        padding: 6px 20px;
        letter-spacing: -0.02em;
        border-radius: 0;
        border: none;
        border-top: 2px solid #eaeaea;
      }
    }
  }

  .denominations-short-btn-box {
    width: 100%;
  }

  a.btn,
  .btn {
    display: block;
    width: 100%;

    &:before {
      border-radius: 0;
    }

    .inner-btn-box {
      padding: 10px 12px;
      font-size: rem(18);
      border-radius: 0;
    }
  }
}

.dark-mode {
  .denominations-short-modal-box {
    .denominations-short-form {
      .single-input-box {
        .input-style {
          border-top-color: #979797;
        }
      }
    }
  }
}
