.generalModal {
  &.error {
    .generalModal-innerWrap {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      transform-origin: center;
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  .generalModal-overlay {
    z-index: 1;
    transition: opacity 0.25s ease-in-out 0.35s;
  }

  .generalModal-box {
    height: 100%;
  }

  .generalModal-wrap {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    height: 683px;
    width: 700px;
    border-radius: 2.8rem;
    transform: translate(-50%, -50%);
  }

  .generalModal-innerWrap {
    overflow: hidden;
    position: relative;
    height: 100%;
    background-color: #ffffff;
    border-radius: 2.8rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  }

  a.generalModal-close-btn,
  .generalModal-close-btn {
    overflow: hidden;
    position: absolute;
    z-index: 1000000;
    opacity: 0;
    top: 23px;
    left: 23px;
    display: inline-block;
    border-radius: 50rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.25);

    &:before {
      border-radius: 50rem;
    }

    &:hover {
      &:before {
        width: 56px;
        height: 56px;
      }
    }

    .inner-btn-box {
      overflow: hidden;
      position: relative;
      display: block;
      width: 56px;
      height: 56px;
      background-color: #ffffff;
      border-radius: 50rem;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 26px;
      width: 26px;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    .pos_icon-left-chevron {
      height: 30px;
      width: 18px;
      transform: translate(calc(-50% - 1px), -50%);
    }

    &.inside {
      visibility: visible;
      opacity: 1;
      top: 10px;
      left: 8px;
      box-shadow: none;
      border-radius: 7px;

      &:before {
        border-radius: 7px;
      }

      .inner-btn-box {
        box-shadow: none;
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.default-overlay {
  .generalModal-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.generalModal,
.generalModal-overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 99990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.show.generalModal,
.show .generalModal-overlay {
  visibility: visible;
  opacity: 1;
}

a.generalModal-close-btn.outside,
.generalModal-close-btn.outside,
.generalModal-wrap {
  opacity: 0;
  transition: opacity 0.35s ease-in 0.15s;

  .show & {
    opacity: 1;
  }
}

.top {
  .generalModal-wrap {
    top: 0;
    border-radius: 0 0 2.8rem 2.8rem;
    transform: translate(-50%, 0);

    .generalModal-innerWrap {
      border-radius: 0 0 2.8rem 2.8rem;
    }
  }
}

.right {
  .generalModal-wrap {
    left: 100%;
    border-radius: 2.8rem 0 0 2.8rem;
    transform: translate(-100%, -50%);
  }

  .generalModal-innerWrap {
    border-radius: 2.8rem 0 0 2.8rem;
  }
}

.right-bottom {
  .generalModal-wrap {
    top: 100%;
    left: 100%;
    border-radius: 2.8rem 0 0 0;
    transform: translate(-100%, -100%);

    .generalModal-innerWrap {
      border-radius: 2.8rem 0 0 0;
    }
  }
}

.bottom {
  .generalModal-wrap {
    top: 100%;
    border-radius: 2.8rem 2.8rem 0 0;
    transform: translate(-50%, -100%);

    .generalModal-innerWrap {
      border-radius: 2.8rem 2.8rem 0 0;
    }
  }
}

.left {
  .generalModal-wrap {
    left: 0;
    border-radius: 0 2.8rem 2.8rem 0;
    transform: translate(0, -50%);

    .generalModal-innerWrap {
      border-radius: 0 2.8rem 2.8rem 0;
    }
  }
}

.left-bottom {
  .generalModal-wrap {
    top: 100%;
    left: 0;
    border-radius: 0 0 0 2.8rem;
    transform: translate(0, -100%);

    .generalModal-innerWrap {
      border-radius: 0 0 0 2.8rem;
    }
  }
}

.move-effect {
  &.show.hide-anim {
    a.generalModal-close-btn.outside,
    .generalModal-close-btn.outside {
      opacity: 0;
      transition: opacity 0.35s linear 0s;
    }
  }

  &.show {
    a.generalModal-close-btn.outside,
    .generalModal-close-btn.outside {
      transition: opacity 0.35s ease-in 0.35s;
    }
  }

  &.show.hide-anim .generalModal-wrap,
  .generalModal-wrap {
    opacity: 1;
    transform: translate(-50%, 150%);
    transition: transform 0.35s ease-in 0s;
  }

  &.show {
    .generalModal-wrap {
      transform: translate(-50%, -50%);
      transition: transform 0.35s ease-in 0s;
    }
  }

  &.top {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-50%, 0);
      }
    }
  }

  &.right {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(50%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-100%, -50%);
      }
    }
  }

  &.bottom {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(-50%, 50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-50%, -100%);

        .show-keyboard & {
          transform: translate(-50%, calc(-100% - 358px));
        }
      }
    }
  }

  &.left {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(-150%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(0, -50%);
      }
    }
  }

  &.right-bottom {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      transform: translate(50%, -100%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-100%, -100%);
      }
    }
  }

  &.left-bottom {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      transform: translate(-150%, -100%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(0, -100%);
      }
    }
  }
}

.generalModal.radius-small {
  .generalModal-wrap {
    border-radius: rem(21);

    .generalModal-innerWrap {
      border-radius: rem(21);
    }
  }

  &.top {
    .generalModal-wrap {
      border-radius: 0 0 rem(21) rem(21);

      .generalModal-innerWrap {
        border-radius: 0 0 rem(21) rem(21);
      }
    }
  }

  &.right {
    .generalModal-wrap {
      border-radius: rem(21) 0 0 rem(21);

      .generalModal-innerWrap {
        border-radius: rem(21) 0 0 rem(21);
      }
    }
  }

  &.right-bottom {
    .generalModal-wrap {
      border-radius: rem(21) 0 0 0;

      .generalModal-innerWrap {
        border-radius: rem(21) 0 0 0;
      }
    }
  }

  &.bottom {
    .generalModal-wrap {
      border-radius: rem(21) rem(21) 0 0;

      .generalModal-innerWrap {
        border-radius: rem(21) rem(21) 0 0;
      }
    }
  }

  &.left {
    .generalModal-wrap {
      border-radius: rem(21) rem(21) 0 0;

      .generalModal-innerWrap {
        border-radius: rem(21) rem(21) 0 0;
      }
    }
  }

  &.left-bottom {
    .generalModal-wrap {
      border-radius: 0 rem(21) 0 0;

      .generalModal-innerWrap {
        border-radius: 0 rem(21) 0 0;
      }
    }
  }
}

.generalModal.radius-none {
  .generalModal-wrap {
    border-radius: 0;

    .generalModal-innerWrap {
      border-radius: 0;
    }
  }
}

.show-keyboard {
  .show {
    .generalModal-wrap {
      max-height: calc(100% - 378px);
    }

    .generalModal-overlay {
      transform: translateY(-358px);
    }
  }
}

.dark-mode {
  .generalModal {
    a.generalModal-close-btn,
    .generalModal-close-btn {
      &.outside {
        .inner-btn-box {
          background-color: #1f1e23;
        }
      }

      &.inside {
        .inner-btn-box {
          background-color: transparent;
        }
      }
    }
  }

  .generalModal-innerWrap {
    background-color: #1f1e23;
  }

  .default-overlay {
    .generalModal-overlay {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.general-modal {
  &--rounded-big {
    border-radius: 42px;

    .generalModal-2-box {
      border-radius: 42px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
    padding: 0;
    text-align: center;

    &--space-between-normal {
      //gap: 18px 0;
      @include gapFlexSupportAlternative(18, 0);
    }

    &--space-between-big {
      //gap: 24px 0;
      @include gapFlexSupportAlternative(24, 0);
    }

    &--space-between-biggest {
      //gap: 32px 0;
      @include gapFlexSupportAlternative(32, 0);
    }

    &--grey-bg {
      background-color: #f7f7f7;
    }
  }

  &__header {
    position: relative;
    z-index: 3;
    display: grid;
    gap: 12px 0;
    padding: 20px 30px 16px;
    background-color: #ffffff;

    .dark-mode & {
      background-color: #1f1e23;
    }

    &--space-between-normal {
      gap: 18px 0;
    }

    &--space-between-big {
      gap: 24px 0;
    }

    &--space-between-biggest {
      gap: 32px 0;
    }

    &--top-offset {
      padding-top: 32px;
    }

    &--bottom-small {
      padding-bottom: 24px;
    }

    &--grey-bg {
      background-color: #f7f7f7;
    }

    &--shadow {
      box-shadow: 6px -4.5px 9px 0 rgba(0, 0, 0, 0.5);

      .dark-mode & {
        box-shadow: 6px -4.5px 9px 0 rgba(255, 255, 255, 0.5);
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;

    .dark-mode & {
      background-color: #000000;
    }

    &--grey-bg {
      .dark-mode & {
        background-color: #1f1e23;
      }
    }
  }

  &__body-inner {
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__footer {
    position: relative;
    z-index: 2;
    background-color: #ffffff;

    .dark-mode & {
      background-color: #1f1e23;
    }

    &--offset {
      padding: 20px 20px 20px;
    }

    &--grey-bg {
      background-color: #f7f7f7;
    }

    &--shadow {
      box-shadow: 0 -4.5px 9px -8px rgba(0, 0, 0, 0.5);

      .dark-mode & {
        box-shadow: 0 -4.5px 9px -8px rgba(255, 255, 255, 0.5);
      }
    }
  }

  &__title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    //gap: 0 28px;
    @include gapFlexSupportAlternative(0, 28);
    &--space-between {
      justify-content: space-between;
    }
  }

  &__title {
    font-size: rem(32);
    font-weight: $medium;
    color: $color-main;
    line-height: 1.5;
    letter-spacing: -0.64px;

    &--spacing-large {
      letter-spacing: 0.7px;
    }

    &--size-big {
      font-size: rem(35);
      letter-spacing: -0.7px;
    }

    &--size-normal {
      font-size: rem(30);
      letter-spacing: -0.6px;
    }

    &--size-small {
      font-size: rem(27);
      letter-spacing: 0;
    }

    .dark-mode & {
      color: $color-white;
    }
  }

  &__desc {
    display: grid;
    gap: 14px 0;
    font-size: rem(20);
    font-weight: $normal;
    color: #777777;
    line-height: 1.85;

    &--small-width {
      max-width: 370px;
      margin: 0 auto;
    }

    &--align-left {
      text-align: left;
    }

    &--text-error {
      color: $pos-error;
    }

    &--leading-small {
      line-height: 1.5;
    }

    &--size-big {
      font-size: rem(22);
      line-height: 1.45;
    }

    &--size-small {
      font-size: rem(17);
      line-height: 1.5;
      letter-spacing: -0.4px;
    }

    b,
    strong {
      font-weight: 500;
    }

    .dark-mode & {
      color: #cccccc;
    }
  }

  &__label {
    display: block;
    padding: 0 21px;
    margin-bottom: 12px;
    font-size: rem(17.5);
    line-height: $line-height-heading-2;
    letter-spacing: -0.39px;
    text-align: left;
    color: #777575;

    .dark-mode & {
      color: #cccccc;
    }
  }

  &__message-box {
    text-align: left;
    width: 100%;
    padding: 12px 14px;
    height: 158px;
    font-size: rem(18);
    color: #747474;
    line-height: 1.45;
    letter-spacing: -0.4px;
    border: 1.7px solid #e7e6e6;
    background-color: #ffffff;
    border-radius: 9px;

    .dark-mode & {
      color: #ffffff;
      background-color: #1c1c1e;
      border-color: #3a3a3a;
    }

    textarea:disabled {
      background-color: white;
    }

    b,
    textarea,
    strong {
      font-weight: 500;
      color: $color-main;

      .dark-mode & {
        color: #adadad;
      }
    }
  }

  &__input-box {
    position: relative;
  }

  &__input-icon {
    position: relative;
    z-index: 3;
  }

  &__bem-input {
    position: relative;
    z-index: 1;
    padding-right: 60px;
  }

  &__text-area {
    width: 100%;
    height: 100%;
    padding: 13px 21px;
    @include font($normal, rem(17.5), 1.5, $font-main);
    letter-spacing: -0.39px;
    border: none;
    box-shadow: none;
    border-top: 1px solid #eaeaea;
    background-color: #ffffff;
    resize: none;

    .dark-mode & {
      border-top: 1px solid #676767;
      color: $color-white;
      background-color: #1f1e23;
    }

    &::-webkit-input-placeholder {
      color: $color-text;
      opacity: 0.26;
      transition: 0.35s;

      .dark-mode & {
        color: $color-white;
        opacity: 0.6;
      }
    }

    &::-moz-placeholder {
      color: $color-text;
      opacity: 0.26;
      transition: 0.35s;

      .dark-mode & {
        color: $color-white;
        opacity: 0.6;
      }
    }

    &:-ms-input-placeholder {
      color: $color-text;
      opacity: 0.26;
      transition: 0.35s;

      .dark-mode & {
        color: $color-white;
        opacity: 0.6;
      }
    }

    &:-moz-placeholder {
      color: $color-text;
      opacity: 0.26;
      transition: 0.35s;

      .dark-mode & {
        color: $color-white;
        opacity: 0.6;
      }
    }
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    //gap: 16px 0;
    @include gapFlexSupportAlternative(16, 0);
  }

  &__row-title {
    font-size: rem(22);
    font-weight: $medium;
    color: $color-main;
    line-height: $line-height-heading-2;
    letter-spacing: -0.49px;

    .dark-mode & {
      color: $color-white;
    }
  }

  &__form-box {
    display: grid;
    padding: 26px 30px;
    gap: 24px 0;
  }

  &__actions-group {
    display: flex;
    align-items: center;
    //gap: 0 35px;
    @include gapFlexSupportAlternative(0, 35);
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 74px;
    padding: 10px 25px;
    font-size: rem(20);
    font-weight: $medium;
    color: $pos-green;
    text-align: center;
    letter-spacing: -0.4px;
    appearance: none;
    border-radius: 7px;
    background-color: $color-white;
    border: 1px solid #e8e8e8;
    border-color: rgba(#e8e8e8, 0.62);
    box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);

    &--theme-blue {
      color: $color_blue;
    }

    &--theme-red {
      color: $pos-error;
    }

    &--theme-grey {
      color: $color-grey;
    }

    .dark-mode & {
      border-color: #444444;
      background-color: #444444;
    }
  }

  &__pay-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    min-height: 68px;
    gap: 16px 0;
    // @include gapFlexSupportAlternative(16, 0);
  }

  &__pay-img-box {
    display: flex;
    //gap: 12px;
    @include gapFlexSupportAlternative(12, 12);
  }

  &__pay-img-wrap {
    padding: 10px 6px;
    background-color: #ffffff;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    border-radius: 3.5px;

    img {
      display: block;
    }
  }

  &__price-box {
    display: flex;
    flex-direction: column;
    //gap: 8px 0;
    @include gapFlexSupportAlternative(8, 0);
    text-align: right;
  }

  &__price {
    font-size: rem(17);
    font-weight: $medium;
    line-height: 1;
    letter-spacing: -0.48px;
  }

  &__price-title {
    font-size: rem(14);
    color: $color-main;
    line-height: $line-height-heading-2;
    letter-spacing: -0.31px;
    opacity: 0.67;

    .dark-mode & {
      opacity: 0.9;
      color: $color-white;
    }
  }

  &__divide-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(2);
    background-color: #e9e9e9;

    .dark-mode & {
      background-color: $color-main;
    }

    &--two-col {
      grid-template-columns: repeat(2, 1fr);
    }

    &--three-col {
      grid-template-columns: repeat(3, 1fr);
    }

    &--four-col {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__divide-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 58px;
    //gap: 0 22px;
    @include gapFlexSupportAlternative(0, 22);
    background-color: #ffffff;

    .dark-mode & {
      background-color: #1f1e23;
    }
  }

  &__divide-text {
    font-size: rem(18);
    letter-spacing: -0.36px;
    color: $color-main;
    text-transform: capitalize;

    .dark-mode & {
      color: $color-white;
    }

    b,
    strong {
      font-weight: $medium;
    }

    &--theme-blue {
      color: $pos-blue;

      .dark-mode & {
        color: $pos-blue;
      }
    }
  }

  &__total-box {
    display: flex;
    height: 100%;
    padding-bottom: 26px;
    align-items: center;
    justify-content: center;

    .dark-mode & {
      background-color: #1f1e23;
    }
  }

  &__total {
    display: block;
    font-size: rem(37);
    line-height: 1;
    letter-spacing: -0.76px;
    color: #777777;

    .dark-mode & {
      color: #ffffff;
    }
  }

  &__keypad {
    display: grid;
    gap: 2px;
    padding-top: 2px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 87px);
    background-color: #e9e9e9;

    .dark-mode & {
      background-color: #444444;
    }
  }

  .pos_icon-dot {
    width: 12px;
    height: 12px;
  }

  .pos_icon-left-chevron-second {
    width: 24px;
    height: 24px;
  }

  &__key {
    display: flex;
    align-items: center;
    justify-content: center;
    @include font($normal, rem(30), 1, $font-main);
    color: #515151;
    background-color: #fbfbfb;

    .dark-mode & {
      color: #ffffff;
      background-color: #2b2c2e;
    }

    &:active,
    &.active {
      background-color: #ffffff;

      .dark-mode & {
        background-color: #000000;
      }
    }
  }
}

.general-modal--theme-action {
  .general-modal__inner {
    width: 100%;
    height: auto;
    justify-content: center;
    padding: 35px 45px 35px;
  }

  .general-modal__header {
    padding: 0;
    background-color: transparent;

    .dark-mode & {
      background-color: transparent;
    }
  }

  .general-modal__footer {
    background-color: transparent;

    .dark-mode & {
      background-color: transparent;
    }
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
}
