#sidebar-modal-print-bill {
  .sidebar-modal-box {
    height: 100%;

    .hide-status-bar & {
      height: calc(100% - 21px);
    }

    .hide-status-bar & {
      height: 100%;
    }

    a.scheduled-dropdown,
    .scheduled-dropdown {
      top: 9px;
      right: 13px;

      &:after {
        margin-top: 2px;
      }
    }
  }

  .sidebar-modal-title {
    font-size: 22px;
    font-weight: $medium;
  }

  .sidebar-modal-header {
    padding: 11px 14px 7px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-guest-box {
    position: relative;
    z-index: 20;

    .input-style {
      position: relative;
      z-index: 5;
      border-radius: 0;
      border: none;
    }

    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      background-color: #ffffff;
      box-shadow: 0 -1px 7px -2px rgba(0, 0, 0, 0.35);
    }

    &:after {
      position: absolute;
      content: '\e965';
      z-index: 7;
      right: 16px;
      top: 50%;
      @include icomoon();
      font-size: 28px;
      margin-top: 2px;
      color: #363636;
      transform: translate(0, -50%);
    }
  }

  .print-bill-box {
    height: 100%;

    .print-bill-inner {
      position: relative;
      min-height: 100%;
      padding-bottom: 100px;
    }
  }

  .print-single-item {
    display: table;
    padding: 10px 13px 10px;
    width: 100%;
    color: #363636;

    .cell {
      display: table-cell;
      vertical-align: top;

      &:nth-child(2) {
        width: 90px;
        text-align: right;
      }
    }

    .name {
      display: block;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: -0.02em;
      margin-bottom: 3px;

      .quantity {
        margin-left: 6px;

        .dine_icon-close_light1 {
          margin-right: 6px;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    .single-actions {
      opacity: 0.67;

      a,
      button,
      span {
        display: block;
        font-size: 14px;
        text-align: left;
        line-height: 1.2;
      }
    }

    .price {
      font-size: 17px;
      font-weight: $normal;
    }
  }

  .print-bill-price-box {
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
  }

  .print-bill-price-single {
    display: table;
    width: 100%;
    color: #363636;
    font-weight: $normal;
    padding: 2px 16px 2px 13px;

    .cell {
      display: table-cell;
      vertical-align: middle;

      &:nth-child(2) {
        width: 90px;
        text-align: right;
      }
    }

    .name {
      display: block;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: -0.02em;
      margin-bottom: 3px;
    }

    .price {
      display: block;
      font-size: 17px;
      font-weight: 400;
    }

    &.total {
      padding-left: 15px;

      .price {
        font-size: 18px;
        font-weight: $medium;
      }

      .name {
        font-size: 18px;
        font-weight: $medium;
        letter-spacing: -0.06em;
      }
    }
  }

  .sidebar-modal-body {
    height: calc(100% - 202px);
    padding-top: 3px;
  }

  .sidebar-modal-footer {
  }

  .sidebar-modal-overlay {
    opacity: 0;
    transition: 0s;
    background-color: rgba(0, 0, 0, 0.26);
  }

  &.show .sidebar-modal-overlay {
    opacity: 1;
    transition: opacity 0.35s linear 0.35s;
  }

  .view-order-style & {
    left: -100%;
    transition: opacity 0s linear 0.35s, right 0.35s linear 0.15s;

    &.show {
      opacity: 1;
      left: 0;
      transition: opacity 0s linear 0s, left 0.35s;
    }

    &.show .sidebar-modal-overlay {
      opacity: 1;
      transition: opacity 0.35s linear 0.35s;
    }

    .sidebar-modal-overlay {
      left: 0;
      right: auto;
      transition: opacity 0s linear 0s, left 0.35s;
    }

    &.show .sidebar-modal-overlay {
      transition: opacity 0.35s linear 0.35s;
    }

    .sidebar-modal-box {
      width: 367px !important;
      left: 0;
      box-shadow: 4px 5px 12px -2px rgba(0, 0, 0, 0.5);

      &.split-bill.show {
        + .sidebar-modal-box {
          left: auto;
          right: 198px;

          .sidebar-modal-header {
            padding-bottom: 11px;
          }
        }
      }
    }
  }

  .demo-app & {
    &.show,
    &.show .sidebar-modal-overlay {
      opacity: 1;
      left: 0;
      transition: opacity 0s linear 0s, left 0.35s;
    }

    .sidebar-modal-overlay {
      left: 0;
      right: auto;
      transition: opacity 0s linear 0s, left 0.35s;
    }

    &.show .sidebar-modal-overlay {
      transition: opacity 0s linear 0s, left 0.35s;
    }

    .sidebar-modal-box {
      width: 367px !important;
      left: 0;
      box-shadow: 4px 5px 12px -2px rgba(0, 0, 0, 0.5);

      &.split-bill.show {
        + .sidebar-modal-box {
          left: auto;
          right: 198px;

          .sidebar-modal-header {
            padding-bottom: 11px;
          }
        }
      }
    }
  }

  .save & {
    .demo-app & {
      &.show,
      &.show .sidebar-modal-overlay {
        opacity: 1;
        left: auto;
        right: 0;
        transition: opacity 0s linear 0s, right 0.35s;
      }

      .sidebar-modal-overlay {
        left: auto;
        right: 0;
        transition: opacity 0.15s linear 0s, right 0s;
      }

      &.show .sidebar-modal-overlay {
        transition: opacity 0.35s linear 0.35s, right 0s;
      }

      .sidebar-modal-box {
        left: auto;
        right: 0;
        box-shadow: 4px 5px 12px -2px rgba(0, 0, 0, 0.5);

        &.split-bill.show {
          + .sidebar-modal-box {
            left: auto;
            right: 198px;

            .sidebar-modal-header {
              padding-bottom: 11px;
            }
          }
        }
      }
    }
  }

  .split-price-list-wrap {
    overflow: hidden;
    height: 100%;
  }

  .split-price-list {
    border-top: solid 0.5px #e9e9e9;

    > li {
      display: block;
      border-bottom: solid 0.5px #e9e9e9;

      &:first-child {
        .split-price-box {
          padding: 5px 18px;
        }
      }
    }
  }

  .split-price-box {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    padding: 9px 18px;
    background-color: #ffffff;
    line-height: 1.25;

    &:after {
      display: none;
      position: absolute;
      top: 50%;
      right: 17px;
      content: '\e958';
      @include icomoon();
      font-size: 22px;
      color: #48a903;
      transform: translate(0, -50%);
    }

    .name {
      display: block;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: $medium;
      color: #363636;
    }

    .price {
      display: block;
      margin-bottom: 3px;
      font-size: 16px;
      color: #777575;
    }

    &.paid {
      &:after {
        display: block;
      }
    }

    &.active {
      .price {
        color: #363636;
      }

      background-color: #c1c1c1;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .split-bill {
    visibility: hidden;
    z-index: 30;
    display: none;
    width: 198px;
    background-color: #f7f7f7;
    box-shadow: -4px 5px 12px -2px rgba(0, 0, 0, 0.5);

    &.show {
      display: block;
      visibility: visible;

      & + .sidebar-modal-box {
        left: 198px;

        .sidebar-modal-header {
          padding-bottom: 10px;
        }
      }
    }

    .sidebar-modal-header {
      padding: 0;
      background-color: #f7f7f7;
    }

    a.back-btn,
    .back-btn {
      padding-bottom: 16px;

      .back-icon {
        background-color: #3a3a3a;

        &:before {
          position: absolute;
          top: 50%;
          height: auto;
          width: auto;
          border: none;
          content: '\e963';
          @include icomoon();
          color: #ffffff;
          font-size: 11px;
          font-weight: 700;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .sidebar-modal-body {
      padding-top: 0;
    }
  }

  &.view-orders {
    &.sidebar-modal,
    .sidebar-modal-overlay {
      z-index: 15;
    }

    .sidebar-modal-box {
      width: 367px;

      &.split-bill {
        width: 198px;

        & + .sidebar-modal-box {
          width: 327px;
        }
      }
    }

    .order-actions-btn-box {
      .btn-box {
        flex-wrap: wrap;

        .width-50 {
          width: 50%;
        }

        .width-100 {
          width: 100%;
        }
      }

      a.btn-action.width-100,
      .btn-action.width-100 {
        .inner-btn-box {
          padding: 18px 20px;
        }
      }
    }
  }

  .split-bill .sidebar-modal-body {
    height: calc(100% - 108px);
  }
}
