#guest-search-modal {
  .modal-header {
    padding-top: 63px;
  }

  .modal-body {
    .modal-body-inner {
      padding: 13px 27px;
    }
  }

  .show-keyboard & {
    .modal-box {
      top: 185px;
      border-radius: 2.8rem 2.8rem 0 0;
    }
  }
}
