.bem-navbar {
  overflow: hidden;
  position: relative;
  z-index: 20;
  background-color: var(--screenBG);
  box-shadow: var(--shadowNavBar);
  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
  }
  &__header {
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    border-bottom: 1px solid var(--greyColor-150);

    background-color: var(--mainBG);
    //padding: 23px 22px 0;
    //box-shadow: var(--shadowModalInner);
    //background-color: var(--modaldBg);
  }
  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
  }

  &__footer {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    box-shadow: -6px 4.5px 9px 0 rgba(0, 0, 0, 0.5);
  }
}
