@import 'src/assets/scss/global/mixins';
@import 'src/assets/scss/global/variables.module';

.bem-print-order-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bem-print-order-modal {
  @media all and (max-height: 640px) {
    &.generalModal-2-wrap--responsive-height {
      .generalModal-2-box {
        margin: 0 auto;
        border-radius: 0;
      }
    }
  }

  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
    padding: 0;
    background-color: var(--modalSecondaryBg);
  }

  &__header {
    position: relative;
    z-index: 3;
    padding: 23px 22px 0;
    box-shadow: var(--shadowModalInner);
    background-color: var(--modaldBg);
  }

  &__header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__select {
    display: block;
    text-align: left;
    height: 44px;
    padding: 0 50px 0 54px;
    font-size: 18px;
    font-weight: $normal;
    line-height: 40px;
    letter-spacing: 0.05rem;
    color: var(--textColor);
    background-color: var(--secondaryBG);
    border-color: transparent;
    box-shadow: var(--shadowSecondary);
    border-radius: 50rem;
    background-image: var(--selectPrinter), var(--selectArrow);
    background-repeat: no-repeat;
    background-size: 23px, 21px;
    background-position: 14px 50%, right 13px center;
    appearance: none;

    option {
      display: block;
      padding: 10px;
      font-size: 18px;
      line-height: 1.25;
      text-align: left;
    }
  }

  &__title {
    margin-bottom: 13px;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.05rem;
    font-weight: $medium;
  }

  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
  }

  &__body-inner {
    padding: 28px;
  }

  &__order-box {
    max-width: 356px;
    margin: 0 auto;
    color: var(--textColor);
    border-radius: 15px;
    background-color: var(--mainBG);
    box-shadow: var(--shadowSecondary);
  }

  &__windcave-order-box {
    max-width: 356px;
    margin: 10px 10px;
    color: var(--textColor);
    border-radius: 15px;
    background-color: var(--mainBG);
    box-shadow: var(--shadowSecondary);
    overflow-y: auto;
    max-height: 400px;
  }

  &__order-header-row {
    display: flex;
    min-height: 53px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px 18px 10px;
    border-bottom: 1px dashed var(--greyColor-150);

    &--hide-border {
      border: none;
    }

    > :last-child {
      text-align: right;
      margin-left: 20px;
    }
  }

  &__order-header-single-item {
    justify-content: center;
    > :last-child {
      text-align: center;
      margin-left: 0px;
    }
  }

  &__order-footer-row {
    display: flex;
    min-height: 53px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px 18px 10px;
    border-top: 1px dashed var(--greyColor-150);

    &--hide-border {
      border: none;
    }

    > :last-child {
      text-align: right;
      margin-left: 20px;
    }
  }

  &__order-row {
    display: grid;
    gap: 0 20px;
    grid-template-columns: 1fr 80px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 18px 13px;
  }

  &__order-title {
    display: block;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: -0.025rem;
    font-weight: $medium;
  }

  &__order-header-text {
    display: block;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }

  &__order-footer-text {
    display: block;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }

  &__order-total-text {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }

  &__order-text {
    display: block;
    font-size: 17px;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }

  &__order-ingredient {
    display: block;
    font-size: 14px;
    line-height: 1.25;
    color: var(--greyColor-600);
    letter-spacing: -0.025rem;
  }

  &__order-item {
    display: block;
  }

  &__order-price-box {
    display: block;
    text-align: right;
  }

  &__order-row-inner {
    display: grid;
    gap: 6px 0;
  }

  &__footer {
    position: relative;
    z-index: 2;
    padding: 14px 16px 14px;
    background-color: var(--modaldBg);
    box-shadow: var(--shadowModalInner);
  }
}
