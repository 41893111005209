a.half-area-btn-close,
.half-area-btn-close {
  display: block;
  position: absolute !important;
  z-index: 10;
  top: 18px;
  left: 18px;
  width: 42px;
  height: 42px;

  &:before {
    border-radius: 0;
  }

  .pos_icon-close {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
}

.half-area-notes {
  position: absolute !important;
  z-index: 10;
  top: 18px;
  right: 24px;
  width: 145px;
  height: 42px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  padding: 5px;
  font-size: 1.7rem;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  .notes-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .pos_icon-write-message {
    margin-top: 2px;
    width: 26px;
    height: 28px;
  }
}

.main-half-area-header {
  position: relative;
  max-height: 200px;
  overflow: auto;
  padding: 74px 25px 12px;

  .main-half-date {
    padding-left: 20px;
  }

  .category-title {
    margin-bottom: 0;
  }

  .half-header-btn-box {
    width: 100%;
    text-align: right;
    padding: 0 0 0 10px;
  }
}

.half-header-btn-box {
  margin-bottom: -10px;

  a.btn,
  .btn {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 10px;

    .inner-btn-box {
      padding: 4px 14px;
    }

    .pos_icon-plus {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 16px;
      height: 16px;
      transform-origin: center;
      transform: translateY(-50%);
    }

    &.with-icon {
      .inner-btn-box {
        padding-left: 30px;
      }
    }
  }
}

.main-half-body-wrap {

  z-index: 1;
  display: flex;
  flex-flow: column;
}

.main-half-body-wrap-four-quarter {
  position: relative;
  z-index: 1;

  &.padding-80 {
    padding-bottom: 60px;
  }
}

.main-half-area-body {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 18px 16px 5px;
  background-color: #ffffff;

  > :last-child {
    margin-bottom: 0;
  }
}

a.half-single-card,
.half-single-card {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  border-radius: 12px;

  &:after {
    position: absolute;
    z-index: 3;
    content: '';
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
  }

  &.selected {
    &:after {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.35) 35%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.35) 65%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .half-item-img-wrap {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding-bottom: calc(200% + 10px);
    background-color: #b8b8b8;
    transition: all 0.2s ease-in-out;

    &:before {
      position: absolute;
      content: none;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 50%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.3)
      );
      transform-origin: center;
      transform: translateY(-50%);
    }

    img {
      position: absolute;
      z-index: 1;
      display: block;
      top: 50%;
      left: 50%;
      max-height: 100%;
      height: 100%;
      width: auto;
      transform-origin: center;
      transform: translate(-50%, -50%);
      object-fit: contain;

      .demo-app & {
        width: auto;
        height: auto;
        max-width: calc(100% - 20px);
      }
    }
  }

  .pos_icon-plus {
    position: absolute;
    z-index: 6;
    top: 10px;
    left: 7px;
    width: 26px;
    height: 26px;
  }

  .half-item-title {
    position: absolute;
    display: block;
    z-index: 8;
    left: 10px;
    top: 50%;
    text-align: center;
    width: 90%;
    width: calc(100% - 14px);
    font-weight: 500;
    line-height: 1.25;
    color: #ffffff;
    font-size: 21px;
    transform-origin: center;
    transform: translate(0, -50%);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      height: calc(100% + 200px);
      min-height: 75px;
      width: 100%;
      width: calc(100% + 14px);
      transform-origin: center;
      transform: translate(-50%, -50%);
      background: linear-gradient(
        180deg,
        transparent 0,
        rgba(0, 0, 0, 0.55) 45%,
        rgba(0, 0, 0, 0.6) 49%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.6) 51%,
        rgba(0, 0, 0, 0.6) 52%,
        rgba(0, 0, 0, 0.6) 57%,
        transparent
      );
    }

    .inner-box {
      position: relative;
    }

    &.with-icon {
      font-size: 36px;
      text-transform: uppercase;
      text-shadow: none;

      &:before {
        content: none;
      }

      .inner-box {
        display: inline-block;
        padding-left: 40px;
      }
     

      @media screen and (max-width: 820px) {
        font-size: 26px;
      }
      
      @media screen and (max-width: 735px) {
        font-size: 16px;
      }
     
    }
  }

  .four-quarters & {
    .half-item-img-wrap {
      padding-bottom: calc(100% + 2px);
    }
  }

  .customize-half-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 72px;
    -webkit-box-orient: vertical;
    overflow: hidden;

    & + .customize-half-btn {
      margin-top: 13px;
    }
  }
}

.half-half-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  flex: 1 50%;
  max-width: 340px;
  // min-width: 340px;
  margin: 0 auto;

  .half-card-wrap {
    width: 50%;
    padding: 5px;
    margin: 0 0 22px;

    &:nth-child(odd) {
      a.half-single-card,
      .half-single-card {
        .half-item-title {
          left: auto;
          right: 10px;
        }

        &:after {
          right: 0;
        }

        border-radius: 250rem 10rem 10rem 250rem/270rem 10rem 10rem 270rem;
      }
    }

    &:nth-child(even) {
      a.half-single-card,
      .half-single-card {
        &:after {
          left: 0;
        }

        border-radius: 10rem 250rem 250rem 10rem/10rem 270rem 270rem 10rem;
      }
    }
  }

  &.four-quarters {
    .half-card-wrap {
      margin: 0;
      padding: 5px;

      .half-single-card,
      a.half-single-card {
        &:after {
          content: none;
        }

        .half-item-img-wrap {
          img {
            .demo-app & {
              max-width: calc(100% - 80px);
            }
          }
        }

        .half-item-title {
          font-size: 26px;
          width: calc(100% - 20px);
        }

        .pos_icon-plus {
          top: 7px;
          left: 16px;
          width: 18px;
          height: 18px;
        }
      }

      &:nth-child(4n + 1) {
        .half-single-card,
        a.half-single-card {
          border-radius: 250rem 10rem 10rem 10rem/250rem 10rem 10rem 10rem;

          .half-item-title {
            top: calc(50% + 15px);
          }
        }
      }

      &:nth-child(4n + 2) {
        .half-single-card,
        a.half-single-card {
          border-radius: 10rem 250rem 10rem 10rem/10rem 250rem 10rem 10rem;

          .half-item-title {
            top: calc(50% + 15px);

            &.with-icon {
              .inner-box {
                padding-left: 0;
                padding-right: 40px;
              }
            }
          }

          .pos_icon-plus {
            left: auto;
            right: 16px;
          }
        }
      }

      &:nth-child(4n + 3) {
        .half-single-card,
        a.half-single-card {
          border-radius: 10rem 10rem 10rem 250rem/10rem 10rem 10rem 250rem;

          .half-item-title {
            top: calc(50% - 15px);
          }
        }
      }

      &:nth-child(4n + 4) {
        .half-single-card,
        a.half-single-card {
          border-radius: 10rem 10rem 250rem 10rem/10rem 10rem 250rem 10rem;

          .half-item-title {
            top: calc(50% - 15px);

            &.with-icon {
              .inner-box {
                padding-left: 0;
                padding-right: 40px;
              }
            }
          }

          .pos_icon-plus {
            left: auto;
            right: 16px;
          }
        }
      }
    }
  }
}

.half-half-sizes-box {
  padding: 0 7px 10px;

  .half-half-sizes-title {
    display: block;
    margin-bottom: 22px;
    font-size: 22px;
    line-height: 1.25;
    font-weight: 500;
    color: #363636;
  }

  .half-half-sizes-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 0 -10px -20px;
  }

  .half-half-sizes-btn-wrap {
    padding: 0 10px;
    margin-bottom: 22px;
  }

  a.btn,
  .btn {
    font-size: 18px;
    font-weight: 400;
    color: #363636;

    .inner-btn-box {
      padding: 5px 18px;
      border-color: transparent;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      text-transform: capitalize;
    }
  }
}

.main-half-area-btn-box {
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 25px 20px;
  background-color: #ffffff;

  a.btn,
  .btn {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 500;

    &:before {
      border-radius: 9px;
    }

    .inner-btn-box {
      padding: 12px 20px;
      border-radius: 9px;
    }
  }
}

.dark-mode {
  .main-half-area-body {
    background-color: #000000;
  }

  .main-half-area-btn-box {
    background-color: #000000;
  }

  a.half-single-card,
  .half-single-card {
    .half-item-img-wrap {
      border-color: #383838;
      background-color: #383838;
    }
  }

  .half-half-sizes-title {
    color: #ffffff;
  }
}

.plus-icon{
  width: 26px;
  height: 26px;
  background-image: none;
  @media screen and (max-width: 820px ) {
    width: 20px;
    height: 20px;
  }
 
  @media screen and (max-width: 735px ) {
    
    width: 15px;
    height: 15px;
  }
}
.add-container{
  display: flex;
  align-items: center;
}
