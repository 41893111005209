.postcode-container-height {
  height: 200px;
}

.delivery-details-modal-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: 26px 0 0;

  .delivery-details-modal-box-inner {
    padding: 0 26px;
  }

  .delivery-details-header {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3px;

    .inner-col {
      &:nth-child(1):not(:last-child) {
        padding-right: 20px;
        width: 100%;
      }

      &:nth-child(2) {
        flex-basis: 171px;
        min-width: 171px;
      }
    }
  }

  .delivery-details-body {
    margin-bottom: 24px;
  }

  .delivery-details-customer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 31px;
    font-size: rem(18);
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #3a3a3a;
    min-height: 26px;

    .icon-box {
      display: block;
      line-height: 1.25;
      flex-basis: 26px;
      min-width: 26px;
    }

    .text {
      display: block;
      padding-left: 14px;
      line-height: 1.25;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      display: block;
      margin: 0 auto;
      top: 50%;
      left: 0;
      width: 26px;
      height: 26px;
    }

    .pos_icon-phone-inverse {
      width: 22px;
      height: 22px;
    }
  }

  .delivery-details-map-box {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 20px;
    &__empty-map-box {
      background-image: url(../../../assets/img/details_map.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 100%;
    }
    .delivery-details-map-pin {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: auto;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    .expand-map {
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      left: 13px;
      bottom: 14px;
      background-color: #ffffff;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      z-index: 1000;

      .pos_icon-expand-fullscreen {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }
    }
  }

  .delivery-details-title {
    margin-bottom: rem(28);
    font-size: 22px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.02em;
    color: #363636;
    text-transform: capitalize;
    flex: 1;
  }

  .delivery-details-title-contianer {
    display: flex;
  }

  .delivery-details-form {
    .single-input-box {
      margin-bottom: 24px;

      label {
        display: block;
        margin-bottom: 14px;
        font-weight: $medium;
        font-size: 18px;
        color: #3a3a3a;
        letter-spacing: -0.03em;
        line-height: 1.25;
      }

      .inner-box {
        display: block;
        position: relative;
        margin-bottom: 10px;

        .icon-box {
          position: absolute;
          z-index: 5;
          top: 8px;
          left: 25px;
          width: 42px;
          height: 42px;
          transform-origin: center;
          transform: translate(-50%, 0);

          &.from-right {
            left: auto;
            right: 0;
          }

          [class^='pos_icon-'],
          [class*=' pos_icon-'] {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            width: 18px;
            transform-origin: center;
            transform: translate(-50%, -50%);
          }

          .pos_icon-location {
            width: 20px;
          }

          .pos_icon-modern-house {
            width: 21px;
            margin-left: 2px;
          }

          .pos_icon-write-message {
            width: 24px;
            height: 24px;
            margin-top: -3px;
            margin-left: 2px;
          }
        }
      }

      .input-style {
        position: relative;
        z-index: 1;
        padding-left: 50px;
        border-width: 2px;
        border-color: #e7e6e6;
        border-radius: 9px;
        &:disabled {
          background-color: #e7e6e6;
        }
      }

      textarea.input-style {
        height: 106px;
      }

      .geosuggest-delivery-box {
        border-width: 2px;
        border-color: #e7e6e6;
        border-radius: 9px;
        box-shadow: none;

        .clear-input-btn {
          height: 52px;

          &:before {
            border-radius: 9px;
          }
        }

        input {
          min-height: 52px;
          padding: 10px 56px 10px 50px;
        }
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .delivery-details-time-box {
    padding: 17px 20px;
    text-align: center;
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #3a3a3a;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.5);

    .inner-box {
      position: relative;
      display: inline-block;
      padding-left: 42px;
    }

    .pos_icon-clock {
      position: absolute;
      top: 50%;
      left: 0;
      height: 26px;
      width: 26px;
      margin-top: -2px;
      transform-origin: center;
      transform: translate(0, -50%);
    }

    a.btn,
    .btn {
      min-width: 79px;
      margin-left: 18px;
      font-size: 16px;
      font-weight: $medium;

      .inner-btn-box {
        padding: 3px 10px;
      }
    }
  }

  .delivery-details-btn-box {
    position: relative;
    z-index: 10;
    background-color: #ffffff;

    a.btn,
    .btn {
      &.full-width {
        display: block;
        width: 100%;
        font-size: rem(18);
        font-weight: $medium;
        letter-spacing: -0.025em;

        &:before {
          border-radius: 0;
        }

        .inner-btn-box {
          padding: 18px 20px;
          border-radius: 0;
        }
      }
    }
  }
}

.show-keyboard {
  .delivery-details-modal.move-effect.bottom {
    &.show {
      .generalModal-wrap {
        max-height: 600px;
      }
    }
  }
}

.dark-mode {
  .delivery-details-modal-box {
    .delivery-details-title {
      color: #ffffff;
    }

    .delivery-details-customer {
      color: #ffffff;
    }

    .delivery-details-form {
      .single-input-box {
        label {
          color: #ffffff;
        }

        .input-style {
          border-color: #3a3a3a;
        }

        .geosuggest-delivery-box {
          border-color: #3a3a3a;
        }
      }
    }

    .delivery-details-btn-box {
      background-color: #111213;
    }

    .delivery-details-time-box {
      color: #ffffff;
    }

    .delivery-details-map-box {
      .expand-map {
        background-color: #3a3a3a;
      }
    }
  }
}

.delivery-details-modal {
  .generalModal-2-box {
    min-height: 728px;
    height: auto !important;
  }
}

.delivery-address__error {
  display: block;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 20px;
  color: red;
  margin-top: 5px;
}
