.split-bill-seats {
  text-align: left;
  margin: 15px 8px 0;
}

.split-bill-seat-card {
  position: relative;
  display: inline-block;
  font-size: 0;
  width: 33.33333%;
  padding: 0 11px;
  margin-bottom: 18px;
  vertical-align: top;

  .inner-box {
    overflow: hidden;
    position: relative;
    display: block;
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
  }

  .seat-header {
    position: relative;
    display: block;
    padding: 6px 40px 6px 10px;
    border-radius: 12px 12px 0 0;
    background-color: #5eb602;

    &:after {
      position: absolute;
      content: '\e90c';
      top: 50%;
      right: 13px;
      @include icomoon();
      font-size: 28px;
      color: #ffffff;
      font-weight: $normal;
      transform-origin: 50% 50%;
      transform: translate(0, -50%) rotate(90deg);
    }
  }

  .seat-title {
    font-size: 18px;
    font-weight: $medium;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .seat-body {
    overflow: hidden;
    position: relative;
    height: 229px;
    border-radius: 0 0 12px 12px;

    .seat-inner-box {
      height: 229px;
      border-radius: 0 0 12px 12px;
    }
  }

  &.selected {
    .seat-header {
      padding: 4px 38px 6px 8px;
      border: 2px solid #0386f6;
      border-bottom: none;

      &:after {
        right: 11px;
        margin-top: -1px;
      }
    }

    .seat-body {
      border: 2px solid #0386f6;
      border-top: none;

      .seat-inner-box {
        & > div {
          border-radius: 0 0 12px 12px;
        }
      }

      .single-seat-item {
        padding: 10px;
      }
    }
  }

  .seat-order-items-list {
  }

  &.red {
    .seat-header {
      background-color: #de2d29;
    }
  }

  &.green {
    .seat-header {
      background-color: #5eb602;
    }
  }

  &.green-light {
    .seat-header {
      background-color: #5eb602;
    }
  }

  &.yellow {
    .seat-header {
      background-color: #fd6c00;
    }
  }

  &.blue {
    .seat-header {
      background-color: #0386f6;
    }
  }

  &.purple {
    .seat-header {
      background-color: #b620e0;
    }
  }

  &.blue-light {
    .seat-header {
      background-color: #0686f6;
    }
  }

  &.pink {
    .seat-header {
      background-color: #eb4469;
    }
  }

  &.orange {
    .seat-header {
      background-color: #fd6c00;
    }
  }

  &.golden {
    .seat-header {
      background-color: #f7b500;
    }
  }

  &.grey {
    .seat-header {
      background-color: #6d7278;
    }
  }
}

.single-seat-item {
  overflow: hidden;
  position: relative;
  display: table;
  width: 100%;
  padding: 10px 12px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  border-bottom-color: rgba(233, 233, 233, 0.71);

  &.has-opacity {
    opacity: 0.4;
  }

  &.has-start-drug {
    opacity: 1;

    &:before {
      position: absolute;
      z-index: 15;
      display: block;
      content: attr(data-count);
      top: 50%;
      left: 24px;
      width: rem(26);
      height: rem(26);
      color: $color-white;
      font-size: rem(14.106);
      font-weight: $medium;
      text-align: center;
      line-height: rem(26);
      border-radius: 20rem;
      background-color: $color-bg-blue;
      transform: translate(-50%, -50%);
    }

    &:after {
      position: absolute;
      content: 'Items';
      z-index: 10;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-left: 52px;
      text-align: center;
      font-size: 20px;
      color: #363636;
      background-color: #ffffff;
    }
  }

  > span {
    display: table-cell;
    vertical-align: middle;
  }

  .seat-item-input-wrap {
    width: 26px;
  }

  .seat-item-input-box {
    display: block;
    width: 26px;
    height: 26px;

    .checkstyle {
      position: absolute;
      z-index: 3;
      display: inline-block;
      width: 26px;
      height: 26px;
      font-size: rem(18);
      border: none;
      background-color: #fafafa;
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
      border-radius: 20rem;
      pointer-events: none;
      cursor: pointer;

      &:after {
        position: absolute;
        z-index: 5;
        content: '';
        top: 50%;
        left: 50%;
        @include icomoon();
        transform: translate(-50%, -50%);
        color: $color-white;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:checked + .checkstyle {
        border-color: #7aac4a;
        background-color: #7aac4a;
        box-shadow: inset 0 rem(0.333) rem(3) 0 rgba(0, 0, 0, 0.19);

        &:after {
          content: '\e901';
        }
      }
    }
  }

  .seat-item-title,
  .seat-item-instruction {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: $normal;
    color: #363636;
  }

  .seat-item-instruction {
    font-size: 14px;
    opacity: 0.6;
    letter-spacing: 0.02em;
  }

  .seat-item-info-wrap {
    &:first-child {
      .seat-item-info-box {
        padding-left: 0;
      }
    }
  }

  .seat-item-info-box {
    display: block;
    padding: 0 15px;

    span {
      display: block;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .seat-price {
    display: block;
    float: right;
    min-width: 51px;
    text-align: center;
    height: 21px;
    padding: 3px 2px;
    font-size: 13px;
    font-weight: $medium;
    color: #363636;
    line-height: 1.2;
    letter-spacing: 0.02em;
    border-radius: 7px;
    background-color: #f0f0f0;
  }
}
