@import './src/assets/scss/global/variables.module';
@import './src/assets/scss/global/mixins';

.bem-navigation-button {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  //gap: 0 9px;
  @include gapFlexSupportAlternative_forButtonSquare(
    15,
    'bem-navigation-button'
  );
  min-width: 140px;
  height: 56px;
  text-align: left;
  border: none;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--logoColor);

  &__icon-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: var(--radiusFull);
    color: var(--logoIconColor);
    background-color: var(--logoIconBg);

    svg {
      position: relative;
      z-index: 2;
    }
  }
  &__logo {
    display: block;
    max-width: 102px;
    width: 100%;

    svg,
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    display: block;
    font-size: 19px;
    font-weight: $medium;
  }
  &--danger {
    color: var(--dangerColor);
    .bem-navigation-button__icon-box {
      background-color: var(--dangerColor);
    }
  }
  .nav-bar & {
    width: 100%;
    padding: 0 10px;
    background-color: #ffffff;
  }
}
