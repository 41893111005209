.category-title {
  margin-bottom: rem(16);
  font-size: rem(28.75);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.main-title {
  margin-bottom: rem(14);
  font-size: rem(25);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;

  &:first-letter {
    text-transform: capitalize;
  }

  .modifiers-style & {
    margin-bottom: 28px;
  }
}

.food-title {
  margin-bottom: rem(4);
  font-size: rem(15);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.cards-row-title {
  margin-bottom: rem(4);
  font-size: rem(22.5);
  font-weight: $medium;
  line-height: $line-height-heading;
  color: #919191;
}

.nav-bar-title {
  margin-bottom: 9px;
  font-size: 22px;
  font-weight: $medium;
  color: #363636;
}

.staff-pin-title {
  margin-bottom: rem(17);
  font-size: rem(30);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  color: #363636;
  text-transform: capitalize;
}

.customer-phone-title {
  margin-bottom: rem(17);
  font-size: rem(30);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  color: #363636;
  text-transform: capitalize;
}

.denominations-short-title {
  margin-bottom: rem(17);
  font-size: rem(40);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  color: #e02020;
  text-transform: capitalize;
}

.manual-amount-title {
  margin-bottom: rem(17);
  font-size: rem(25);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: 0;
  color: #363636;
  text-transform: capitalize;
}

.denominations-title {
  margin-bottom: rem(17);
  font-size: rem(25);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: 0;
  color: #363636;
  text-transform: capitalize;
}

.customer-name-title {
  margin-bottom: rem(17);
  font-size: rem(30);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  color: #363636;
  text-transform: capitalize;
}

.add-drawer-title {
  margin-bottom: rem(17);
  font-size: rem(25);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.03em;
  color: #363636;
  text-transform: capitalize;
}

.item-notes-title {
  margin-bottom: rem(30);
  font-size: rem(35);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  text-align: center;
  text-transform: capitalize;
}

.manual-address-modal-title {
  margin-bottom: rem(30);
  font-size: rem(30);
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  text-align: center;
  text-transform: capitalize;
}
