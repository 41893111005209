.search-address-modal-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: 37px 0 0;

  .search-address-modal-box-inner {
    padding: 0 27px 20px;
  }

  .search-address-title {
    margin-bottom: 48px;
    font-size: rem(30);
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.02em;
    color: #363636;
    text-transform: capitalize;
    text-align: center;
  }

  .search-address-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(18);

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 18px 20px;
        border-radius: 0;
      }
    }
  }
}

.dark-mode {
  .search-address-modal-box {
    .search-address-title {
      color: #ffffff;
    }

    .geosuggest-delivery-box {
      border-color: #3a3a3a;
    }
  }
}

.iPad-mode-wrapper {
  .search-address-btn-box {
    a.btn,
    .btn {
      font-size: rem(18);

      .inner-btn-box {
        padding: 10px 20px;
      }
    }
  }
}

.delivery-container {
  margin-top: 10px;
}

.postCodeContainer {
  flex: 1;
}

.search-address-container {
  display: flex;
  gap: 10px;
  position: relative;
}

.search-address-select-container {
  height: 100%;
  width: 250px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  border-radius: 15px;
}
.search-address-select {
  height: 100%;
  display: flex;
  flex: 1;
  .radix-select__trigger {
    width: 100%;
  }
  .radix-select__trigger-inner {
    width: 100%;
    height: 36px;
  }
}
