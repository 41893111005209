a.special-area-btn-close,
.special-area-btn-close {
  display: block;
  position: absolute !important;
  z-index: 10;
  top: 18px;
  left: 18px;
  width: 42px;
  height: 42px;

  &:before {
    border-radius: 0;
  }

  .inner-btn-box {
  }

  .pos_icon-close {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
}

.main-special-date {
  position: relative;
  margin-bottom: 21px;
  text-align: right;
  font-size: 17px;
  color: #3a3a3a;
  letter-spacing: -0.04em;

  .inner-box {
    position: relative;
    display: inline-block;
    padding-left: 34px;
  }

  .pos_icon-clock {
    position: absolute;
    left: 0;
    top: 1px;
    width: 24px;
    height: 24px;
  }
}

.main-special-area-header {
  padding: 25px 29px 12px 25px;

  .main-special-date {
    padding-left: 20px;
  }

  .category-title {
    margin-bottom: 0;
  }
}

.main-special-body-wrap {
  position: relative;
  z-index: 1;
  height: calc(100% - 120px);

  &.all-selected {
    padding-bottom: 80px;
  }
}

.main-special-area-body {
  position: relative;
  z-index: 2;
  padding: 12px 16px;
  background-color: #ffffff;

  > :last-child {
    margin-bottom: 0;
  }
}

a.specials-single-card,
.specials-single-card {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 18px;

  [class^='pos_icon-'],
  [class*=' pos_icon-'] {
    position: absolute;
    z-index: 5;
    top: 5px;
    left: 5px;
  }

  &.selected {
    .specials-item-img-wrap {
      &:before {
        content: '';
      }
    }
  }

  .specials-item-img-wrap {
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding-bottom: 63%;
    border: 1px solid #dedede;
    background-color: #b8b8b8;
    transition: all 0.2s ease-in-out;
    border-radius: 12px;

    &:before {
      position: absolute;
      z-index: 3;
      content: none;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.3)
      );
      border-radius: 0 0 12px 12px;
    }

    img {
      position: absolute;
      z-index: 1;
      display: block;
      top: 50%;
      left: 50%;
      max-width: 100%;
      width: 100%;
      height: auto;
      transform-origin: center;
      transform: translate(-50%, -50%);
      object-fit: contain;
      border-radius: 12px;

      .demo-app & {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  .pos_icon-plus {
    position: absolute;
    z-index: 6;
    top: 16px;
    left: 11px;
    width: 26px;
    height: 26px;
  }

  .specials-item-title {
    position: absolute;
    display: block;
    z-index: 5;
    left: 6px;
    bottom: 13px;
    width: 90%;
    width: calc(100% - 14px);
    font-weight: 500;
    line-height: 1.25;
    color: #ffffff;
    font-size: 21px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 64px;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.big {
      left: 10px;
      bottom: 15px;
      width: 90%;
      width: calc(100% - 32px);
      font-size: 25px;
      letter-spacing: -0.02em;
      max-height: 64px;
    }

    @media (min-width: 1260px) and (min-height: 860px) {
      left: 10px;
    }
  }

  .customize-half-btn {
    position: absolute;
    z-index: 5;
    top: 5px;
    left: 5px;
    box-shadow: 0 2px 7px -4px rgba(0, 0, 0, 0.5);

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      position: relative;
      top: auto;
      left: auto;
    }

    @media (min-width: 1260px) and (min-height: 860px) {
      top: 10px;
      left: 10px;
    }
  }
}

.specials-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  flex: 1 33.3333%;

  .specials-card-wrap {
    width: 50%;
    padding: 0 10px;
    margin: 0;
  }
}

.main-special-area-btn-box {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 25px 20px;
  background-color: #ffffff;

  a.btn,
  .btn {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 500;

    &:before {
      border-radius: 9px;
    }

    .inner-btn-box {
      padding: 12px 20px;
      border-radius: 9px;
    }
  }
}

.dark-mode {
  .main-special-area-btn-box {
    background-color: #000000;
  }
}
