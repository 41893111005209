.bem-scrollable-box {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  &--invisible-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &--scroll-shadow {
    &:after {
      position: absolute;
      content: '';
      top: -10px;
      left: 0;
      right: 0;
      width: 100%;
      height: 10px;
      box-shadow: var(--shadowModalInner);
      transition: all 0.35s ease;
    }
  }
}
