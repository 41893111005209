@import './src/assets/scss/global/mixins';
@import './src/assets/scss/global/variables.module';

.bem-input-box {
  position: relative;
  padding-bottom: 16px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &__label {
    display: block;
    margin-bottom: 17px;
    font-size: 17.5px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  &__input {
    display: block;
    width: 100%;
    height: 55px;
    padding: 12px 15px;
    @include font($normal, rem(18), 1.6, $font-main);
    letter-spacing: -0.025rem;
    color: var(--textColor);
    border: 1.5px solid #d1d5db;
    background-color: var(--mainBG);
    border-radius: 9px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    transition: 0.35s, background-color 0s;
    &--error {
      border-color: var(--dangerColor);
    }
    @include placeholder {
      padding: 0;
      @include font($normal, rem(18), 1.6, $font-main);
      color: var(--textColor);
      transition: 0.35s;
      opacity: 0.38;
      &:focus {
        opacity: 0;
      }
    }
  }
  &__error-text {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    font-size: 14px;
    color: var(--dangerColor);
    line-height: var(--lineHeightTitle);
  }
}
