@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

$disountModalInnerHeight: 328px;

@mixin modalHeight {
  max-height: $disountModalInnerHeight;
  min-height: $disountModalInnerHeight;
  height: 100%;
}

.bem-item-discount-modal {
  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
    padding: 0;
    border-radius: 24px;
    background-color: var(--modalSecondaryBg);
    @include ModalBodyRightGradient();
  }

  &__header {
    position: relative;
    z-index: 5;
    padding: 16px 16px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--modaldBg);
    box-shadow: var(--shadowModalInner);
  }

  &__title {
    display: block;
    font-size: 23px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.65px;
    color: var(--textColor);
  }

  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include modalHeight;
    display: flex;
    justify-content: center;
  }

  &__body-inner {
    @include modalHeight;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include modalHeight;
  }

  &__voucher {
    @include modalHeight;
    min-width: 100%;

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 145px;
    }
  }

  &__input-box {
    display: flex;
    align-items: center;
    justify-content: center;

    &-input {
      height: 30px;
      margin-right: 6px;
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid var(--gray-200);
    }
  }

  &__footer {
    position: relative;
    z-index: 3;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--shadowModalInner);
    background-color: var(--modaldBg);
  }

  &__warning {
    font-size: rem(14);
    color: var(--warningColor);
    padding: 1rem;
    text-align: center;
    width: 100%;
  }

  &__error {
    font-size: rem(14);
    color: var(--dangerColor);
    padding: 1rem;
    text-align: center;
    width: 100%;
  }
}
