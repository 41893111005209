.table-view {
  &.nav-bar {
    flex-basis: 327px;
    max-width: 327px;
    min-width: 327px;
    background-color: #ffffff;
  }

  &.main-area {
    position: relative;
    z-index: 1;
    background-color: #2b2b2b;
  }

  .table-view-wrap {
    max-width: 100%;
    width: 100%;
    height: 100%;

    .table-view-inner-wrap {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      min-height: 620px;
      align-content: baseline;
    }
  }
}

.booking-nav-box {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  text-align: left;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.5);

  a,
  button {
    display: inline-block;
    padding: 8px 10px 6px;
    width: 25%;
    font-size: 0;
    text-align: center;
    color: #717374;
    text-decoration: none;
    border: none;
    box-shadow: none;
    appearance: none;
    background-color: #ffffff;

    span {
      display: block;
      margin: 0 auto;
    }

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      font-size: 25px;
      margin-bottom: 3px;
    }

    .dine_icon-cook {
      font-size: 28px;
      margin-bottom: 0;
    }

    .dine_icon-bag {
      font-size: 26px;
      margin-bottom: 2px;
    }

    .dine_icon-history {
      font-size: 25px;
    }

    .name {
      @include font($medium, rem(13), $line-height-heading-2, $font-main);
    }

    &:nth-child(1) {
      width: 29%;
    }

    &:nth-child(2) {
      width: 43%;
    }

    &:nth-child(3) {
      width: 28%;
    }

    &.active {
      color: #007aff;
    }
  }
}
