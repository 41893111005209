#tag-modal,
.tag-modal-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0s;

  .show-personal-booking & {
    z-index: 9999999;
  }
}

#tag-modal.show,
.show .tag-modal-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.35s;
}

.tag-modal-overlay {
  z-index: 10;
  top: 0;
  background-color: transparent;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);

    .show-personal-booking & {
      width: 100%;
      height: 100%;
    }
  }
}

.view-orders-style {
  #tag-modal {
    .tag-modal-wrap {
      left: 50%;
      transform: translate(-50%, 0);
    }

    .tag-modal-overlay {
      background-color: rgba(0, 0, 0, 0.2);

      &:after {
        content: none;
      }
    }
  }
}

.tag-modal-box {
  height: 100%;
}

.tag-modal-wrap {
  overflow: hidden;
  position: absolute;
  z-index: 20;
  left: 395px;
  bottom: 0;
  width: 564px;
  padding: 24px 20px;
  height: 635px;
  background-color: #ffffff;
  border-radius: 26px 26px 0 0;

  .show-personal-booking & {
    z-index: 999999999;
    left: 50%;
    transform: translate(-50%, 0);
  }

  a.close-modal-btn,
  .close-modal-btn {
    position: absolute;
    top: 15px;
    right: 17px;
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    color: $color-text;
    font-size: 23px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    appearance: none;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  a.add-tag-btn,
  .add-tag-btn {
    position: absolute;
    display: inline-block;
    top: 23px;
    right: 27px;
    padding: 4px 16px;
    @include font($medium, rem(18), $line-height-heading-2, $font-main);
    color: #ffffff;
    border: none;
    background-color: #067aff;
    border-radius: 40rem;
    box-shadow: none;
    appearance: none;

    &:before {
      border-radius: 40rem;
    }
  }

  .tag-modal-title {
    display: block;
    margin-left: 6px;
    margin-bottom: 23px;
    @include font($medium, rem(25), $line-height-heading-2, $font-main);
  }

  .tag-modal-nav-wrap {
    padding: 0 6px 0 3px;
    margin-bottom: 14px;
  }

  .tag-modal-nav {
    display: flex;
    width: 100%;
    padding: 4px;
    background-color: #e9e9e9;
    border-radius: 12px;

    a,
    button {
      position: relative;
      width: 100%;

      @include font($medium, rem(18), $line-height-heading-2, $font-main);
      letter-spacing: -0.02em;
      text-align: center;
      color: #3a3a3a;
      appearance: none;
      box-shadow: none;

      &:before {
        border-radius: 8px;
      }

      .inner-btn-box {
        display: block;
        padding: 2px 20px;
        background-color: transparent;
        border: 0.3px solid transparent;
        border-radius: 8px;
      }

      &.active {
        color: #363636;

        .inner-btn-box {
          border-color: rgba(0, 0, 0, 0.04);
          background-color: #ffffff;
          box-shadow: 0 1.5px 0.5px 0 rgba(0, 0, 0, 0.02),
            0 1.5px 4px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  .tag-options-list {
    height: 508px;
    height: calc(100% - 100px);

    ul {
      > li {
        label {
          display: block;
        }
      }
    }
  }

  .single-tag-box {
    position: relative;
    display: block;
    background-color: #ffffff;
    color: #363636;
    pointer-events: none;

    .icon-box {
      position: absolute;
      display: block;
      top: 50%;
      left: -3px;
      margin-bottom: -2px;
      width: 42px;
      height: 42px;
      font-size: 28px;
      transform: translate(0, -50%);

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .dine_icon-theater {
        font-size: 32px;
      }

      .dine_icon-work-briefcase {
        font-size: 24px;
      }
    }

    .tag-name {
      display: block;
      padding: 24px 40px 25px 0;
      margin-left: 52px;
      font-size: 18px;
      line-height: 1.25;
      border-bottom: 1px solid rgba(233, 233, 233, 0.48);
    }

    input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      &:checked + .radio-style {
        border-color: #5eb602;
        background-color: #5eb602;
        box-shadow: inset 0 0 2px 0 rgba(79, 78, 78, 0.5);

        &:after {
          content: '\e901';
        }
      }
    }

    .radio-style {
      position: absolute;
      display: block;
      z-index: 3;
      top: 50%;
      right: 8px;
      width: 28px;
      height: 28px;
      font-size: 16px;
      border: 0.12rem solid #979797;
      background-color: #ffffff;
      border-radius: 20rem;
      pointer-events: none;
      cursor: pointer;
      transform: translate(0, -50%);

      &:after {
        position: absolute;
        z-index: 5;
        content: '';
        top: 50%;
        left: 50%;
        @include icomoon();
        transform: translate(-50%, -50%);
        color: #ffffff;
      }
    }
  }
}
