.money-count-main-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  justify-content: space-between;
  height: 100%;
  height: calc(100% - 58px);

  .money-count-overlay {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;

    &.show {
      visibility: visible;
      z-index: 3;
      opacity: 1;
      background-color: #000000;
      background-color: rgba(#000000, 0.26);
    }
  }
}
