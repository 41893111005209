.mbsc-select-input,
.input-style {
  display: block;
  width: 100%;
  min-height: 30px;
  margin: 0;
  padding: 0 10px;
  @include font($normal, rem(15), 28px, $font-main);
  letter-spacing: -0.01em;
  color: #000000;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  appearance: none;
  border-radius: rem(8.667);
  transition: 0.35s, background-color 0s;

  &.white {
    color: $color-text;
    background-color: $color-white;
  }

  &.big {
    min-height: 56px;
    font-size: 18px;
    padding: 12px 56px 12px 15px;
    border-radius: 15px;
    word-spacing: -0.1em;
  }
}

.add-drawer-modal-box {
  .mbsc-select-input {
    min-height: 56px;
    font-size: 18px;
    padding: 12px 56px 12px 15px;
    border-radius: 15px;
    word-spacing: -0.1em;
    color: $color-text;
    background-color: $color-white;
  }
}

textarea.input-style {
  overflow-y: auto;
  resize: none;
  padding: 11px 15px;
  height: 285px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.65;
  letter-spacing: -0.035em;
  border-radius: 15px;

  .delivery & {
    height: 243px;
  }
}

.mbsc-select-input::-webkit-input-placeholder,
.input-style::-webkit-input-placeholder {
  padding: 0;
  @include font($normal, rem(15), rem(30), $font-main);
  color: #919191;
  transition: 0.35s;
  opacity: 1;
}

.mbsc-select-input::-moz-placeholder,
.input-style::-moz-placeholder {
  padding: 0;
  @include font($normal, rem(15), rem(30), $font-main);
  color: #919191;
  transition: 0.35s;
  opacity: 1;
}

.mbsc-select-input:-ms-input-placeholder,
.input-style:-ms-input-placeholder {
  padding: 0 8px;
  @include font($normal, rem(15), rem(30), $font-main);
  color: #919191;
  transition: 0.35s;
  opacity: 1;
}

.mbsc-select-input:-moz-placeholder,
.input-style:-moz-placeholder {
  padding: 0;
  @include font($normal, rem(15), rem(30), $font-main);
  color: #919191;
  transition: 0.35s;
  opacity: 1;
}

textarea.input-style::-webkit-input-placeholder {
  @include font($normal, rem(18), rem(30), $font-main);
  transition: 0.35s;
  opacity: 1;
}

textarea.input-style::-moz-placeholder {
  @include font($normal, rem(18), rem(30), $font-main);
  transition: 0.35s;
  opacity: 1;
}

textarea.input-style:-ms-input-placeholder {
  @include font($normal, rem(18), rem(30), $font-main);
  transition: 0.35s;
  opacity: 1;
}

textarea.input-style:-moz-placeholder {
  @include font($normal, rem(18), rem(30), $font-main);
  transition: 0.35s;
  opacity: 1;
}

.input-style.big::-webkit-input-placeholder {
  font-size: 18px;
}

.input-style.big::-moz-placeholder {
  font-size: 18px;
}

.input-style.big:-ms-input-placeholder {
  font-size: 18px;
}

.input-style.big:-moz-placeholder {
  font-size: 18px;
}

.mbsc-select-input:focus::-webkit-input-placeholder,
.input-style:focus::-webkit-input-placeholder {
  opacity: 0;
}

.mbsc-select-input:focus::-moz-placeholder,
.input-style:focus::-moz-placeholder {
  opacity: 0;
}

.mbsc-select-input:focus:-ms-input-placeholder,
.input-style:focus:-ms-input-placeholder {
  opacity: 0;
}

.mbsc-select-input:focus:-moz-placeholder,
.input-style:focus:-moz-placeholder {
  opacity: 0;
}

.select-custom-wrap {
  position: relative;
  display: inline-block;

  [class^='pos_icon-'],
  [class*=' pos_icon-'] {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translate(0, -50%);
    transform-origin: center;
    width: 25px;
    height: 25px;
  }

  .pos_icon-cash-register {
    left: 0;
  }

  .pos_icon-down-chevron {
    margin-top: 1px;
    right: 2px;
    width: 34px;
    height: 10px;
  }

  select {
    position: relative;
    z-index: 1;
    display: block;
    min-width: 155px;
    padding: 3px 34px 3px 42px;
    font-family: $font-main;
    font-weight: 400;
    font-size: 16px;
    color: #363636;
    line-height: $line-height-heading-2;
    letter-spacing: -$letter-spacing-20;
    border: none;

    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;

    .dark-mode {
      color: #ffffff;
    }
  }
}
