.select-delivery-time-wrap {
  overflow: hidden;
  position: relative;
  height: 280px;

  &:before,
  &:after {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
    background-color: rgba(#e9e9e9, 0.73);
    transform-origin: center;
    pointer-events: none;
  }

  &:before {
    transform: translateY(-30px);
  }

  &:after {
    transform: translateY(30px);
  }

  .mbsc-mobiscroll {
    overflow: hidden;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    transform: translateY(-10px);

    .is-Safari & {
      transform: translateY(0);
    }

    .mbsc-sc-whl-gr {
      padding: 0;
      width: 100%;
      max-width: 100%;

      .mbsc-sc-whl-w:first-child {
        text-align: left;
      }
    }

    .mbsc-fr-w {
      background-color: #ffffff;
    }

    .mbsc-sc-whl-l {
      border-color: transparent;
    }

    .mbsc-sc-whl-w {
      margin: 0;
    }

    .mbsc-sc-itm {
      opacity: 0.7;
      font-family: $font-main;
      font-size: 21px;
      font-weight: 300;
    }

    .mbsc-sc-itm-sel {
      color: #363636;
      opacity: 1;
      font-size: 22px;
      font-weight: 400;
    }
  }
}

.dark-mode {
  .select-delivery-time-wrap {
    .mbsc-mobiscroll {
      .mbsc-fr-w {
        background-color: #000000;
      }

      .mbsc-sc-itm {
        color: #ffffff;
      }

      .mbsc-sc-itm-sel {
        color: #ffffff;
      }
    }
  }
}
