.kiosk-screen {
  &__main-area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &__order-bar-overlay {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2)
  }
  &__panel-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
  &__bottom-panel {
    visibility: hidden;
    //opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 29px 18px 29px;
    z-index: 3;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0 8px 9px 4px rgba(0, 0, 0, 0.5);
    transition: 0s ease-out;
    transform: translateY(110%);
    &--show {
      display: flex;
      visibility: visible;
      transform: translateY(0%);
    }
  }
  &__bottom-panel-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__empty.nav-bar {
    box-shadow: none;
    background: transparent;
  }
  &__total {
    display: inline-flex;
    align-items: center;
    justify-content: start;
    flex-grow: 0;
    padding-right: 24px;
    > *:not(:first-child) {
      margin-left: 16px;
    }
  }
  &__total-text {
    display: block;
    line-height: normal;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
  }

  .order-bar.kiosk-screen__order-bar {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 10;
    max-width: 30%;
    width: 100%;
    min-width: 420px;
    transform: translateX(100%);
    transition: 0.35s ease-out;
    @media all and (min-width: 1024px){
      min-width: 560px;
    }
    &--show {

      visibility: visible;
      transform: translateX(0%);
    }
  }


  &__order-bar-overlay {
    opacity: 0;
    visibility: hidden;
    transition: 0.35s ease-out;
    cursor: pointer;
    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
}
