.cashier-main {
  width: 100%;
}

.cashier-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 314px;
  padding: 16px 8px;
  align-items: flex-start;
  justify-content: flex-start;

  .cashier-card-wrap {
    width: 33.333%;
    padding: 0 10px;
    margin: 0 0 20px;
  }

  .cashier-card {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

    &.green {
      .cashier-card-header {
        background-color: #5eb602;
      }
    }

    &.orange {
      .cashier-card-header {
        background-color: #fd6c00;
      }
    }

    &.blue {
      .cashier-card-header {
        background-color: #0386f6;
      }
    }

    &.yellow {
      .cashier-card-header {
        background-color: #fd6c00;
      }
    }

    &.green-light {
      .cashier-card-header {
        background-color: #5eb602;
      }
    }

    &.pink {
      .cashier-card-header {
        background-color: #eb4469;
      }
    }

    &.golden {
      .cashier-card-header {
        background-color: #f7b500;
      }
    }

    &.blue-light {
      .cashier-card-header {
        background-color: #0686f6;
      }
    }

    &.red {
      .cashier-card-header {
        background-color: #de2d29;
      }
    }

    &.purple {
      .cashier-card-header {
        background-color: #b620e0;
      }
    }
  }

  .cashier-card-header {
    position: relative;
    padding: 11px 50px;
    background-color: #5eb602;

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      position: absolute;
      top: 50%;
      left: 27px;
      width: 20px;
      height: 21px;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    a.cashier-card-header-btn,
    .cashier-card-header-btn {
      position: absolute;
      top: 50%;
      right: 0;
      transform-origin: center;
      transform: translate(0, -50%);
    }
  }

  a.cashier-card-header-btn,
  .cashier-card-header-btn {
    position: relative;
    display: inline-block;

    .inner-btn-box {
      display: block;
      width: 70px;
      height: 42px;
    }

    .pos_icon-apper-circle {
      position: absolute;
      left: auto;
      top: 50%;
      right: 50%;
      width: 25px;
      height: 29px;
      transform: translate(50%, -50%) rotate(90deg);
    }
  }

  .cashier-card-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .cashier-card-body {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: 17px;
      font-weight: 400;
      text-align: left;
      letter-spacing: -0.02em;
      color: #363636;

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 21px 62px 24px 18px;
        border: none;
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid #efefef;
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        position: absolute;
        top: 50%;
        right: 36px;
        width: 26px;
        height: 26px;
        transform-origin: center;
        transform: translate(50%, -50%);
      }

      .pos_icon-up-square {
        transform: translate(50%, calc(-50% - 2px));
      }

      .pos_icon-money {
        right: 34px;
        width: 32px;
        height: 26px;
      }

      .pos_icon-receive-money {
        width: 27px;
        height: 27px;
        transform: translate(50%, calc(-50% - 2px));
      }

      &:last-child {
        .inner-btn-box {
          padding-bottom: 28px;
          border-bottom: none !important;
        }
      }
    }
  }
}

.dark-mode {
  .cashier-main-wrap {
    background-color: #000000;
  }

  .cashier-cards-wrap {
    .cashier-card-body {
      a.btn,
      .btn {
        color: #ffffff;

        .inner-btn-box {
          border-color: #3b3a3a;
        }

        &:last-child {
          .inner-btn-box {
            border-bottom: none !important;
          }
        }
      }
    }

    .cashier-card {
      background-color: #1c1c1e;
    }
  }
}
