.home-screen-box {
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $color-white;
  background-image: url(../../../../assets/img/home/shutterstock_1317@2x.jpg);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  .main-nav-box {
    display: block;
    margin: 0 auto;

    .main-inner-box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      max-width: 94.336%;
      margin: 0 auto 6%;
      padding: 1% 0;
      background-color: #393939;
      background-color: rgba(57, 57, 57, 0.68);
      border-radius: 25px;
    }

    a.nav-btn,
    .nav-btn {
      width: 20%;
      padding: 0 2.371%;
      margin: 0;
      margin-top: 3.6%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 0;
      }
    }

    max-width: 97.24%;
    @media (min-aspect-ratio: 4/3) {
      max-width: 140vh;
    }
    @media all and (max-width: 1024px) {
      min-width: auto;
    }
    @media all and (max-width: 960px) {
      a.nav-btn,
      .nav-btn {
        width: 25%;

        &:nth-child(5) {
          margin-top: 3.6%;
        }
      }
    }
    @media all and (max-width: 768px) {
      a.nav-btn,
      .nav-btn {
        width: 33.333%;

        &:nth-child(4) {
          margin-top: 3.6%;
        }
      }
    }
    @media all and (max-width: 580px) {
      a.nav-btn,
      .nav-btn {
        width: 50%;

        &:nth-child(3) {
          margin-top: 3.6%;
        }
      }
    }
  }

  .other-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-width: 1024px;
    width: 100%;
    padding: 0 0 0 0.6%;
    margin: 0 auto 26px;

    a.nav-btn,
    .nav-btn {
      width: 14.285%;
      padding: 0 2.539%;
      margin: 0;
      margin-top: 5.664%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        margin-top: 0;
      }
    }

    @media (min-aspect-ratio: 4/3) {
      max-width: 140vh;
    }
    @media all and (max-width: 1024px) {
      min-width: auto;
    }
    @media all and (max-width: 960px) {
      a.nav-btn,
      .nav-btn {
        width: 16.666%;

        &:nth-child(7) {
          margin-top: 5.664%;
        }
      }
    }
    @media all and (max-width: 768px) {
      a.nav-btn,
      .nav-btn {
        width: 20%;

        &:nth-child(6) {
          margin-top: 5.664%;
        }
      }
    }
    @media all and (max-width: 580px) {
      a.nav-btn,
      .nav-btn {
        width: 25%;

        &:nth-child(5) {
          margin-top: 5.664%;
        }
      }
    }
    @media all and (max-width: 480px) {
      a.nav-btn,
      .nav-btn {
        width: 33.333%;

        &:nth-child(4) {
          margin-top: 5.664%;
        }
      }
    }
  }

  a.nav-btn,
  .nav-btn {
    display: block;
    box-shadow: none;
    border-radius: 0;
    line-height: $line-height-heading-2;
    margin-bottom: 20px;
    font-family: $font-main;
    font-weight: $medium;
    font-size: rem(19);
    color: $color-white;
    text-decoration: none;
    appearance: none;

    > :last-child {
      margin-bottom: 0;
    }

    .icon-box {
      overflow: hidden;
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 70.95%;
      margin: 0 auto 7px;
      border-radius: 27px;
      box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
      background-color: #ffffff;

      span,
      img {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        font-size: 40px;
        transform: translate(-50%, -50%);
        transform-origin: center;
      }

      img {
        max-width: 100%;
        height: auto;
        width: auto;
        font-size: 14px;
      }

      &.walk-in {
        img {
          width: 34.46%;
          margin-top: 2px;
          margin-left: -3px;
        }
      }

      &.pick {
        img {
          width: 45.27%;
          margin-left: -6px;
          margin-top: 1px;
        }
      }

      &.delivery {
        img {
          width: 72.96%;
          margin-top: 2px;
          margin-left: -3px;
        }
      }

      &.dine {
        img {
          width: 69.59%;
          transform: translate(-50%, -48%);
        }
      }

      &.settle {
        img {
          width: 47.29%;
        }
      }

      &.orders {
        img {
          width: 102%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.customers {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.drivers {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.menu {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.reviews {
        img {
          width: 64.52%;
        }
      }

      &.shifts {
        img {
          width: 75.26%;
        }
      }

      &.promotions {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.about {
        img {
          width: 73.11%;
        }
      }

      &.music {
        img {
          width: 55.92%;
        }
      }

      &.gift {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.cashier {
        img {
          width: 100%;
          max-width: initial;
          object-fit: cover;
        }
      }

      &.settings {
        img {
          width: 100%;
        }
      }
    }

    &.small {
      .icon-box {
        display: block;
        margin: 0 auto 7px;
        width: 100%;
        height: auto;
        padding-bottom: 100%;
        border-radius: 30%;
      }
    }

    &.blue-light .icon-box {
      background-image: linear-gradient(180deg, #74c5e7 0%, #357bf0 100%);
    }

    &.blue .icon-box {
      background-image: linear-gradient(180deg, #74c5e7 0%, #357bf0 58%);
    }

    &.orange .icon-box {
      background-image: linear-gradient(180deg, #e58b26 0%, #e77133 100%);
    }

    &.white .icon-box {
      background-color: #ffffff;
    }

    &.brown .icon-box {
      background-color: #c06918;
      background-image: linear-gradient(180deg, #c06918 0%, #c06918 100%);
    }

    &.green .icon-box {
      background-image: linear-gradient(180deg, #66fe80 0%, #02b520 100%);
    }

    &.red .icon-box {
      background-image: linear-gradient(to top, #ea3324 0%, #ec605a 100%);
    }

    &.yellow .icon-box {
      background-image: linear-gradient(180deg, #fceb04 0%, #e77133 100%);
    }

    &.purple .icon-box {
      background-image: linear-gradient(180deg, #da59b8 0%, #bc4dec 100%);
    }

    &.grey .icon-box {
      background-image: linear-gradient(180deg, #e6e0ee 0%, #8c9094 100%);
    }

    &.black .icon-box {
      background-image: linear-gradient(180deg, #333333 0%, #121212 100%);
    }

    .name {
      display: block;
    }
  }

  @media all and (max-height: 640px) {
    zoom: 0.85;
  }
}

.home-top-box {
  margin-bottom: 37px;
  padding: 16px 28px;
  overflow: hidden;

  .date-time-box {
    float: left;
  }

  .weather-box {
    float: right;
  }
}

.date-time-box {
  padding: 12px 6px 10px;
  font-size: 36px;
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -0.02em;
  color: $color-white;
  text-align: left;

  span {
    display: block;
  }

  .time {
    margin-bottom: 4px;
  }

  .date {
    font-size: 24px;
    font-weight: $normal;
    letter-spacing: -0.02em;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.weather-box {
  overflow: hidden;
  min-width: 291px;
  height: 103px;
  color: $color-white;
  padding: 10px 18px 10px 19px;
  border-radius: 25px;
  background-color: #d8d8d8;
  background-image: linear-gradient(180deg, #4289b5 0%, #71afcf 100%);
  @media all and (max-width: 640px) {
    display: none;
  }

  span {
    display: block;
  }

  .left-box,
  .right-box {
    float: left;
    width: 50%;
    text-align: left;
  }

  .right-box {
    text-align: right;

    .temperature {
      font-size: 20px;
      margin-right: -4px;
    }
  }

  .location {
    font-size: 19px;
    font-weight: $medium;
    letter-spacing: -0.03em;

    & + .temperature {
      margin-top: -10px;
      margin-left: -3px;
    }
  }

  .temperature {
    font-size: 45px;
    font-weight: $light;

    span {
      display: inline-block;
      font-weight: $normal;
      margin-right: 8px;
    }
  }

  .sun-box {
    display: inline-block;
    vertical-align: top;
    max-width: 27px;
    margin-right: 9px;
    margin-top: 3px;

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .text {
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 21px;
    font-size: 15px;
    letter-spacing: -0.03em;
    vertical-align: top;
  }
}
