.header {
  overflow: hidden;
  position: relative;
  z-index: 5;
  height: 53px;
  background-color: #ffffff;
  box-shadow: 0 -4px 9px rgba(0, 0, 0, 0.5);

  .header-inner {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }

  .header-single-box {
    display: block;
    padding: 0;

    &.logo {
      width: 253px;
    }

    &.date-selection {
      width: calc(100% - 630px);
    }

    &.btn-group {
      padding-right: 26px;
      text-align: right;
    }

    &.table-view-group {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  a.back-btn,
  .back-btn {
    max-width: 198px;

    .back-icon {
      margin-right: 11px;
    }
  }

  .header-btn-box {
    display: inline-block;
    text-align: right;

    &--centered {
      display: flex;
      align-items: center;
    }

    a.btn,
    .btn {
      margin-left: 18px;
      font-size: 16.5px;
      line-height: 1.25;
      letter-spacing: -0.02em;
      border: none;

      &:before {
        border-radius: 20rem;
      }

      .inner-btn-box {
        padding: 6px 12px;
      }

      .dine_icon-walk-in {
        margin-left: -4px;
        padding-right: 9px;
        font-size: 18px;
        line-height: 14px;
      }

      .dine_icon-calendar {
        font-size: 17px;
        line-height: 14px;
      }

      &.calendar {
        .inner-btn-box {
          padding-right: 16px;
        }
      }
    }
  }
}

.visitors-count {
  display: inline-block;
  width: 80px;
  color: #363636;
  font-weight: $normal;
  font-size: 16px;

  > span {
    display: inline-block;
    vertical-align: middle;
  }

  .dine_icon-peoples {
    margin-right: 9px;
    font-size: 20px;
  }
}

.quick-date-selection {
  position: relative;
  display: block;
  width: 350px;
  margin: 0 auto;

  .iPad-mode-wrapper & {
    margin: 0;
  }

  .quick-date-slider {
    padding: 0 35px;

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next,
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      top: 0;
      margin-top: 0;
      width: 40px;
      height: 53px;
      background-color: #ffffff;

      &:after {
        font-size: 24px;
        color: #b5b5b6;
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0;
    }
  }

  .swiper-wrapper {
    margin-left: 3px;
  }

  .swiper-slide {
    height: 53px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    align-content: center;
    background-color: #ffffff;
  }

  a.select-day-btn,
  .select-day-btn {
    overflow: hidden;
    display: block;
    width: 128px;
    text-align: center;
    padding: 7px 10px;
    margin: 0;
    @include font($normal, rem(17.2), 1.2, $font-main);
    letter-spacing: -0.04em;
    color: #3a3a3a;
    border-radius: 7px;
    appearance: none;
    border: none;
    text-decoration: none;
    background-color: #ffffff;
    text-overflow: ellipsis;
    word-wrap: normal;
    transition: 0s;
    box-shadow: 0 0 7.5px -3px rgba(0, 0, 0, 0.5);
  }

  a.static-dinner-now,
  .static-dinner-now {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 45px;
  }
}
