.guestDropDownContainer {
  border-radius: 20px;
  border: solid 1px #d1d5db;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.guestDropDownText {
  padding-left: 5px;
}

.guestDropDownIconContainer {
  padding-left: 5px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
