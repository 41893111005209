@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-divider {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  padding: 5px 10px;
  color: var(--whiteColor);
  background-color: #666666;
  @include UIStyleTypeModifiers();
  &__title {
    line-height: var(--lineHeightTitle);
    font-size: 15.5px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
