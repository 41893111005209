@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-split-per-item {
  display: flex;
  flex-direction: column;
  max-width: 644px;
  height: 100%;
  padding-top: 26px;
  margin: 0 auto;
  &__row {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 0 20px;
  }
  &__title {
    font-size: 25px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  &__share-items-count {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    font-size: 18px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    color: var(--primaryColor);
    border-radius: 50rem;
    background-color: var(--whiteColor);
  }
  &__select-items {
    border-radius: 15px 15px 0 0;
    background-color: transparent;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      height: 100%;
      width: 1px;
      background: var(--greyColor-150);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__select-items-grid {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    //border-bottom: 1px solid var(--greyColor-150);
    background-color: var(--greyColor-150);
  }
}

.bem-split-equally {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 354px 1fr;
  gap: 0 60px;
  //max-width: 354px;
  height: 100%;
  padding-top: 30px;
  margin: 0 auto;
  @media all and (max-width: 1024px) {
    gap: 0 20px;
    .bem-btn--split-edit .bem-btn__text {
      text-orientation: mixed;
      writing-mode: tb;
    }
    //gap: 0 20px;
  }
}

.bem-select-items-share-modal {
  &__title {
    //padding: 20px;
    font-size: 25px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);

    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  .bem-base-part__header {
    padding: 22px;
    box-shadow: none;
    border-bottom: 1px solid var(--greyColor-150);
  }
  .bem-base-part {
    background-color: var(--greyColor-150);
  }
  &__select-items-grid {
    overflow: hidden;
    //display: grid;
    //grid-template-columns: 1fr 1fr;
    //gap: 1px;
    border-bottom: 1px solid var(--greyColor-150);
    background-color: var(--greyColor-150);
  }
  &__select-items-col {
    display: grid;
    gap: 1px;
    float: left;
    width: 50%;
    &:first-child {
      border-right: 1px solid var(--greyColor-150);
    }
  }

  @media all and (max-height: 600px) {
    &.generalModal-2-wrap--responsive-height {
      .generalModal-2-box {
        margin: 0 auto;
        border-radius: 0;
      }
    }
  }
}

.bem-add-custom-amount-modal {
  &.generalModal-2-wrap {
    .generalModal-2-box {
      min-height: 560px;
      @media all and (max-height: 600px) {
        //display: inline-block;
        margin: 0 auto;
        border-radius: 0;
      }
    }
    .bem-manual-amount {
      height: 100%;

      //@media all and (max-height: 560px) {
      //  height: 590px;
      //}
    }
    .generalModal-2-container {
      @media all and (max-height: 600px) {
        display: inline-block;
        &:before {
          content: '';
        }
      }
    }
    .bem-manual-amount__inner,
    .bem-manual-amount {
      @media all and (max-height: 600px) {
        border-radius: 0;
      }
    }
  }
}

.bem-split-page {
  overflow: hidden;
  display: grid;
  grid-template-columns: 226px 1fr;
  width: 100%;
  height: 100%;
  background-color: var(--screenBG);
  &__main {
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
  }
  &__header {
    position: relative;
    z-index: 10;
    background-color: var(--mainBG);
    box-shadow: var(--shadowHeader);
    .radix-select {
      &__trigger-inner {
        height: 36px;
      }
      &__trigger-chevron svg {
        stroke-width: 3;
      }
      svg circle {
        stroke-width: 1;
      }
    }
  }
  &__header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px;
  }

  &__header-bottom {
    padding: 0 18px 0;
  }
  &__header-title {
    margin-right: 18px;
    font-size: 25px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    color: var(--textColor);
  }

  &__body {
    overflow: hidden;
    position: relative;
    z-index: 5;
    background-color: var(--screenBG);
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

.item-container {
  display: flex;
  padding-left: 15px;
}

.delete-item-container {
  border: 1px solid #f40000;
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 15px;
  padding: 2px;
  cursor: pointer;
}
