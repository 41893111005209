.iPad-mode-wrapper {
  position: relative;

  &.default-screen-mode {
    height: auto !important;
  }

  @media all and (max-width: 1740px) {
    padding-bottom: 150px;
  }
  @media all and (max-height: 980px) {
    padding-bottom: 150px;
  }
}

.iPad-wrap {
  padding: 20px;
  text-align: center;

  .test {
    display: flex;
    font-size: 32px;
    flex-wrap: wrap;

    span {
      margin: 10px;
    }
  }

  .ipad-style-2 & {
    padding: 72px 42px 35px;
  }
}

.iPad-box {
  position: relative;
  display: block;
  width: 1114px;
  margin: 0 auto;
  padding: 5px;
  background-color: #e9e9ea;
  border: 1px solid #e3e2e2;
  border-radius: 60px;
  -webkit-box-shadow: 5px 15px 32px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 15px 32px 7px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 15px 32px 7px rgba(0, 0, 0, 0.5);

  .iPad-btn-off {
    position: absolute;
    top: 62px;
    left: -6px;
    width: 5px;
    height: 60px;
    border-radius: 4px 0 0 4px;
    background: rgb(215, 214, 216);
    background: -moz-linear-gradient(
      0deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d7d6d8", endColorstr="#d7d6d8", GradientType=1);
  }

  .iPad-btn-volume {
    position: absolute;
    top: -4px;
    left: 100px;
    width: 52px;
    height: 3px;
    border-radius: 5px 5px 0 0;
    background: rgb(215, 214, 216);
    background: -moz-linear-gradient(
      90deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(215, 214, 216, 1) 0%,
      rgba(233, 233, 234, 1) 10%,
      rgba(233, 233, 234, 1) 90%,
      rgba(215, 214, 216, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d7d6d8", endColorstr="#d7d6d8", GradientType=1);
  }

  .iPad-btn-volume.plus {
    left: 95px;
  }

  .iPad-btn-volume.minus {
    left: 155px;
  }

  .iPad-inner-wrap {
    position: relative;
    z-index: 5;
    padding: 38px;
    border-radius: 58px;
    border: 1px solid #dcdbdd;
  }

  .iPad-inner-box {
    overflow: hidden;
    position: relative;
    z-index: 10;
    width: 1024px;
    height: 768px;
    background-color: #ffffff;
    border-radius: 20px;
  }

  .iPad-body {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 21px;
    background-color: #ffffff;
    border-radius: 20px;

    .hide-status-bar & {
      padding-top: 0;
    }
  }

  .ipad-style-2 & {
    width: 1098px;
    padding: 4px;
    border-radius: 52px;
    background-color: #e5e5e7;
    border-color: #e2e3e5;
    box-shadow: none;

    .iPad-inner-wrap {
      padding: 31px;
      border-radius: 48px;
      border-color: #d4d5d8;
      background-color: #e7e7e9;
    }

    .iPad-inner-box {
      border-radius: 16px;
    }

    .iPad-body {
      border-radius: 16px;
    }

    .iPad-btn-off {
      top: 49px;
      left: -4px;
      width: 3px;
      height: 48px;
      background: linear-gradient(
        0deg,
        #b4b3b5 0%,
        #e9e9ea 10%,
        #e9e9ea 90%,
        #b4b3b5 100%
      );
    }

    .iPad-btn-volume {
      top: -3px;
      width: 40px;
      background: linear-gradient(
        90deg,
        #b4b3b5 0%,
        #e1e1e6 10%,
        #e1e1e6 90%,
        #b4b3b5 100%
      );

      &.plus {
        left: 77px;
      }

      &.minus {
        left: 122px;
      }
    }
  }
}

.iPad-body {
  text-align: left;
}
