.bem-switch-group {
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  background-color: var(--switchGroupBg);
  max-width: fit-content; // expirimental, need to check browsers support
  &__inner {
    position: relative;
    z-index: 3;
    display: flex;
    padding: 3px 4px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .floor-layout-tabs & {
      padding: 2px 3px;
    }
  }
  .floor-layout-tabs & {
    border-radius: 50rem;
  }
  &__button {
    position: relative;
    z-index: 5;
    display: block;
    min-width: 95px;
    padding: 3px 18px;
    border-radius: 8px;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: var(--textColor);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;

    &--dine-in-buttons {
      padding: 3px 14px;
      white-space: nowrap;
    }
    .floor-layout-tabs & {
      padding: 5px 14px;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.2;
      min-width: auto;
    }
  }

  &__active-overlay {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 0;
    width: 0;
    height: calc(100% - 8px);
    border-radius: 8px;
    background-color: var(--switchGroupActiveBtnBG);
    transition: width 0.25s ease-in, transform 0.25s ease-in;
    transform-origin: center;
    box-shadow: var(--switchGroupActiveBtnshadow);
    .floor-layout-tabs & {
      top: 2px;
      height: calc(100% - 4px);
      border-radius: 50rem;
    }
  }
}
