@import 'src/assets/scss/global/variables.module';

.button {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0 14px;
  padding: 5px 15px;
  min-height: 38px;
  font-size: rem(16);
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.28px;
  color: #ffffff;
  line-height: 1.2;
  background-color: #067aff;
  border-radius: 20rem;
  appearance: none;
  text-decoration: none;
  cursor: pointer;

  &--icon-position-left {
    padding-right: 20px;
  }
  &--icon-position-right {
    padding-left: 20px;
  }

  &--size-big {
    min-height: 48px;
    font-size: rem(18);
  }
  &--size-biggest {
    min-height: 66px;
    font-size: rem(20);
  }
  &--size-ex-small {
    min-height: 18px;
    padding: 1px 8px;
    font-size: rem(12);
  }
  &--rounded-none {
    border-radius: 0;
  }
  &--fullwidth {
    width: 100%;
  }
  &--align-center {
    justify-content: center;
  }
  &--theme-green {
    background-color: $pos-green;
  }
  &--customize {
    padding: 2px 15px;
    gap: 0 8px;
    min-height: 32px;
    font-size: rem(17);
    letter-spacing: -0.34px;
  }
  &--height-29 {
    height: 29px;
    min-height: 29px;
  }
  &:disabled {
    background-color: #d6d6d6;
    cursor: default;
    .dark-mode & {
      color: #3a3a3a;
      background-color: #6c6c6c;
    }
  }
}
