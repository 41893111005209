.money-count-sidebar {
  position: relative;
  z-index: 5;
  flex-basis: 379px;
  max-width: 379px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 4px 0 6px 2px rgba(0, 0, 0, 0.5);
}

.dark-mode {
  .money-count-sidebar {
    background-color: #000000;
  }
}
