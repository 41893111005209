.main-area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main-area-inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  background-color: #ffffff;
}

.main-area-header {
  position: relative;
  z-index: 3;
  padding: 18px 22px 16px;
  background-color: #ffffff;
  box-shadow: 0.195px 0.382px 5.88px 0.12px rgba(0, 0, 0, 0.09);

  &.hide-shadow {
    box-shadow: none;
  }

  &.specials {
    padding: 19px 25px 12px;

    .search-btn {
      top: 17px;
      right: 25px;
      width: 32px;
      height: 42px;
    }

    .category-title {
      margin-bottom: 0;
    }
  }

  &.specials-items {
    padding: 73px 25px 12px;

    .search-btn {
      top: 17px;
      right: 25px;
      width: 32px;
      height: 42px;
    }

    .category-title {
      margin-bottom: 0;
      text-transform: initial;
    }
  }

  .category-title {
    padding-right: rem(48);
  }

  .header-btn-box {
    padding: 0 rem(3);
  }

  a.btn,
  .btn {
    text-transform: uppercase;

    .inner-btn-box {
      position: relative;
      padding-right: rem(13);
    }

    .pos_icon-plus {
      @include centered-pos-icon(50%, 15px);
      width: 12px;
      height: 12px;
    }
  }

  .food-style & {
    padding: 8px 15px 7px 27px;

    .inner-btn-box {
      padding: 9px 20px 5px 27px;
    }
  }

  .modifiers-style & {
    padding: 20px 22px 22px 28px;

    .inner-btn-box {
      padding: 22px 26px 22px 26px;
    }
  }

  .search-btn {
    position: absolute;
    top: 13px;
    right: 17px;
  }
}

.current-food-box {
  .current-img-box {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: rem(41);
    height: rem(41);
    text-align: center;
    background-color: #f7f7f7;
    line-height: rem(41);
    margin-right: rem(13);
    padding: 2px;
    box-shadow: $default-box-shadow;
    border-radius: rem(10);
    vertical-align: top;
    border: none;

    img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      transform: translate(-50%, -50%);
      font-size: 10px;
      border-radius: rem(10);

      .demo-app & {
        max-width: 36px;
        max-height: 36px;
        border-radius: 0;
      }
    }
  }

  .current-img-box {
    &:after {
      position: absolute;
      z-index: 5;
      content: '';
      left: 3px;
      bottom: -2px;
      width: 30px;
      height: 30px;
      background-color: $color-white;
      background-image: url(../../../../assets/img/icon/expand-fullscreen--thick_grey.svg);
      background-size: 15px 22px;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 rem(2.32) rem(0.347) rgba(0, 0, 0, 0.26);
      border-radius: rem(12);

      .food-style & {
        content: none;
      }
    }
  }

  .current-food-body {
    display: inline-block;
    width: calc(100% - 61px);
    vertical-align: top;
  }

  .food-ingredients {
    font-size: rem(13);
    line-height: $line-height-heading-2;
    letter-spacing: -0.03em;
    word-spacing: -0.06em;
    color: $color-bg-grey;
  }

  .modifiers-style & {
    .current-img-box {
      overflow: visible;
      width: 133px;
      height: 133px;
      margin-right: 0;
      background-color: #f7f7f7;
      border-radius: rem(25);
      vertical-align: middle;
      box-shadow: none;

      &:before {
        border-radius: rem(25);
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 2.5rem;

        .demo-app & {
          max-width: 115px;
          max-height: 115px;
          border-radius: 0;
        }
      }
    }

    .current-food-body {
      width: calc(100% - 140px);
      padding-right: 20px;
      vertical-align: middle;
    }

    .food-title {
      margin-bottom: 11px;
      font-size: rem(28.75);
    }

    .food-ingredients {
      font-size: rem(16.25);
      line-height: 1.6;
      letter-spacing: -0.02em;
    }
  }
}

.search-btn {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  font-size: rem(25);
  color: $color-text;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;

  .pos_icon-search {
    @include centered-pos-icon();
    width: 26px;
    height: 38px;
  }
}

.specials-items-back-btn,
a.specials-items-back-btn {
  overflow: hidden;
  position: absolute !important;
  top: 18px;
  left: 16px;
  width: 42px;
  height: 42px;

  .pos_icon-left-chevron {
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    width: 16px;
  }
}

.main-area-body-wrap {
  position: relative;
  z-index: 1;
  height: calc(100% - 112px);

  &.has-bottom-btn {
    padding-bottom: 90px;
  }

  .modifiers-style & {
    height: calc(100% - 175px);
  }

  .food-style & {
    overflow: auto;
    height: calc(100% - 157px);

    .show-keyboard & {
      height: calc(100% - 537px);
    }
  }

  .delivery.dl-confirm-address & {
    height: 729px;
  }

  .wi-phone-number &,
  .wi-customer-name & {
    height: 724px;
  }

  &.specials-items {
    height: calc(100% - 120px);

    .main-area-body {
      padding-top: 0;
    }
  }

  &.specials {
    height: calc(100% - 66px);

    .main-area-body {
      padding-top: 0;
    }

    .food-cards {
      margin: 12px -7px;

      .card-item {
        width: 50%;
        margin-bottom: 25px;

        .iPad-mode-wrapper & {
          width: 50%;
        }

        .card-name {
          width: 100%;
          min-height: 40px;
          font-size: 14px;
          font-weight: $normal;
          text-align: left;
          line-height: 1.45;
          letter-spacing: -0.02em;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .card-img-wrap {
          padding-bottom: 64%;
          border: none;
          text-align: left;
          margin-bottom: 10px;
          border-radius: 10px;

          &:before {
            position: absolute;
            z-index: 3;
            content: '';
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 50px;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.6)
            );
          }

          img {
            z-index: 1;
            max-width: 100%;
            max-height: initial;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.main-area-body {
  position: relative;
  z-index: 2;
  padding: 10px 22px;
  background-color: #ffffff;

  &--no-padding {
    padding: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .delivery.dl-confirm-address & {
    position: unset;
  }

  .show-keyboard.delivery-note-type & {
    margin-top: -230px;
  }

  .modifiers-style & {
    height: auto;
    padding: 27px 27px;
  }

  .food-style & {
    height: auto;
    padding: 16px 27px;
    padding-bottom: 10px;

    .btn-list {
      margin-bottom: 2px;

      a.btn,
      .btn {
        font-size: 15px;
        line-height: 1.2;

        .inner-btn-box {
          padding: 6px 18px 6px 34px;
        }

        .name {
          position: relative;
          top: -1px;
          letter-spacing: -0.03em;
        }

        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          @include centered-pos-icon(50%, 19px);
          width: 16px;
          height: 24px;
        }

        .pos_icon-cash {
          width: 21px;
          height: 28px;
          left: 21px;
        }

        &.type-voucher {
          .inner-btn-box {
            position: relative;
            padding: 6px 16px 6px 36px;
          }
        }
      }

      > li {
        &:not(:first-child) {
          margin-left: 14px;
        }
      }
    }
  }

  .food-style.discount-type-list & {
    height: calc(100% - 56px);
  }

  .discount-type-list & {
    .btn-list {
      margin-bottom: 5px;
    }
  }
}

.food-cards {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 12px 0;

  &--no-margin {
    margin: 0;
  }

  .card-item {
    display: block;
    width: 25%;
    padding: 0 11px;
    margin-bottom: 18px;

    .iPad-mode-wrapper & {
      width: 25%;
    }

    button {
      display: block;
      width: 100%;
      font-family: $font-main;
      box-shadow: none;
      border: none;
      font-weight: $normal;
      background-color: transparent;
      color: #3a3a3a;

      &:before {
        content: none;
      }
    }

    a {
      display: block;
      text-decoration: none;
    }

    .card-inner-box {
      display: block;
      text-align: center;
      cursor: pointer;

      > :last-child {
        margin-bottom: 0;
      }
    }

    .soldout-tag {
      width: 50%;
      height: 20%;
      font-size: 10px;
      color: #fff;
      background-color: rgba(254, 0, 0, 1);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .overlay {
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    .card-img-wrap {
      overflow: hidden;
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 97.95%;
      text-align: center;
      margin-bottom: 7px;
      background-color: #e9e9e9;
      border-radius: rem(12);
      will-change: scale;

      .demo-app & {
        background-color: #fafafa;
        border: rem(0.75) solid #e9e9e9;
        border-radius: rem(12.333);
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        max-width: 102%;
        max-height: 102%;
        width: 102%;
        height: auto;
        transform-origin: center;
        transform: translate(-50%, -50%);

        .demo-app & {
          max-width: 76%;
          max-height: 76%;
          width: auto;
        }
      }
    }

    .card-upsale-price {
      position: absolute;
      font-size: 10.77px;
      background-color: #e02020;
      display: inline-block;
      padding: 1px 3px 0;
      border-radius: 4px;
      letter-spacing: 0.013em;
      border: 1px solid #e02020;
      color: #fff;
      bottom: 5%;
      left: 32.5%;
    }

    &.card-item-half,
    &.card-item-four {
      .card-img-wrap {
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .card-name {
      display: block;
      width: 96px;
      margin: 0 auto;
      font-size: rem(15.5);
      font-weight: $medium;
      line-height: $line-height-heading-2;
      text-transform: capitalize;
    }

    .card-extra-price,
    .card-special-date,
    .card-special-price,
    .card-special-name {
      overflow: hidden;
      position: absolute;
      z-index: 5;
      left: 6px;
      font-size: 12px;
      line-height: 1.25;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 20px);
    }

    .card-special-date {
      top: 11px;
      padding: 1px 8px 1px 20px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 4px;
      color: #ffffff;
      background-color: #363636;
      background-color: rgba(#363636, 0.63);

      .pos_icon-clock {
        position: absolute;
        top: 2px;
        left: 4px;
        height: 12px;
        width: 12px;
      }
    }

    .card-special-price {
      bottom: 34px;
      padding: 1px 4px;
      font-weight: 500;
      color: #363636;
      border-radius: 4px;
      background-color: #ffffff;
    }

    .card-special-name {
      bottom: 8px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }

    .card-extra-price {
      left: 50%;
      bottom: 6px;
      max-width: 90%;
      padding: 2px 4px;
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      background-color: #cd3f34;
      border-radius: 5px;
      transform-origin: center;
      transform: translateX(-50%);
    }
  }
}

.search-box {
  margin-bottom: rem(21);

  + .detail-form {
    margin-top: -15px;
  }
}

.search-form {
  position: relative;

  .input-style {
    position: relative;
    z-index: 1;
    padding: 0 10px 0 37px;

    &.has-text {
      width: calc(100% - 62px);
    }
  }

  .input-style::-webkit-input-placeholder {
    padding: 0;
  }

  .input-style::-moz-placeholder {
    padding: 0;
  }

  .input-style:-moz-placeholder {
    padding: 0;
  }

  .input-style:-ms-input-placeholder {
    padding: 0;
  }

  button[type='submit'] {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 38px;
    height: 30px;
    padding: 0;
    color: #919191;
    font-family: $font-main;
    font-size: 15px;
    border-radius: rem(8.667);
    background-color: #e9e9e9;
    transition: 0.35s;
    box-shadow: none;
    appearance: none;
    border: none;

    &:after {
      @include centered-pos-icon();
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(../../../../assets/img/icon/search--thick_grey.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
    }
  }

  button[type='reset'] {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    width: 62px;
    text-align: right;
    height: 30px;
    font-family: $font-main;
    font-size: 15px;
    font-weight: $medium;
    letter-spacing: -0.02em;
    color: #0a84ff;
    padding: 0;
    background-color: $color-white;
    box-shadow: none;
    appearance: none;
    border: none;
  }
}

.main-bottom-btn-box {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 29px;
  display: flex;

  & > :first-child {
    margin-right: 15px;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8015581232492998) 0%,
      rgba(255, 255, 255, 0.7987570028011204) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8015581232492998) 0%,
      rgba(255, 255, 255, 0.7987570028011204) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8015581232492998) 0%,
      rgba(255, 255, 255, 0.7987570028011204) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
  }

  > * {
    position: relative;
    z-index: 3;
  }

  a.btn,
  .btn {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .form-remove-item & {
      letter-spacing: -0.01em;
    }
  }
}

.counter-box {
  display: inline-block;
  width: 126px;
  margin-top: 2px;
  padding-right: 15px;
  vertical-align: middle;

  .counter-value {
    display: inline-block;
    width: 43px;
    height: 32px;
    padding: 0;
    margin-top: -2px;
    text-align: center;
    vertical-align: top;
    font-family: $font-main;
    font-weight: $normal;
    font-size: rem(22.5);
    color: $color-text;
    line-height: 32px;
    border-color: transparent;
  }

  .counter-btn {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    color: #3a3a3a;
    border: rem(2) solid #3a3a3a;
    border-radius: 20rem;
    vertical-align: top;
    background-color: $color-white;
    box-shadow: none;
    appearance: none;
    cursor: pointer;
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;

    .inner-btn-box {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      transform-origin: center;
      transform: translate(-50%, -50%);

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }

      svg {
        transition: 0.25s;
      }
    }

    &:before {
      border-radius: 20rem;
    }

    &.plus {
      background-image: url(../../../../assets/img/icon/plus.svg);
    }

    &.minus {
      background-image: url(../../../../assets/img/icon/minus.svg);
    }

    &:disabled {
      color: #c4c4c4;
      border-color: #c4c4c4;
      cursor: default;

      &:before {
        content: none;
      }

      svg {
        path {
          fill: rgb(196, 196, 196);
          stroke: rgb(196, 196, 196);

          .dark-mode & {
            fill: rgb(128, 128, 128);
            stroke: rgb(128, 128, 128);
          }
        }
      }
    }
  }

  & + a.btn,
  & + .btn {
    width: 312px;
    width: calc(100% - 128px);
    letter-spacing: -0.02em;
    vertical-align: middle;

    &:before {
      border-radius: rem(7.667);
    }

    .inner-btn-box {
      border-radius: rem(7.667);
    }
  }
}

.item-cards-row {
  width: 100%;
}

.item-cards-row-header {
  width: 100%;
  margin-bottom: 10px;

  .cards-row-title {
    margin-left: 2px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.item-cards-wrap {
  display: grid;
  width: 100%;
  gap: 25px 0;
}

.item-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 25px;

  @media all and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (max-width: 860px) {
    grid-template-columns: 1fr;
  }

  .add-form & {
    margin-right: 30px;
  }

  .item-card {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      right: -14px;
      top: 50%;
      width: rem(1);
      height: 66%;
      background-color: #e7e7e7;
      transform: translateY(-50%);
    }

    &:nth-child(3n) {
      &:after {
        content: none;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    @media all and (max-width: 1024px) {
      &:nth-child(3n) {
        &:after {
          content: '';
        }
      }
      &:nth-child(2n) {
        &:after {
          content: none;
        }
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    @media all and (max-width: 860px) {
      &:after {
        content: none;
      }
    }

    label {
      display: flex;
      min-height: 64px;
      height: 100%;
      padding: 10px 10px 14px;
      margin: auto;
      justify-content: left;
      align-items: center;
      background-color: #fafafa;
      border: rem(2) solid #e9e9e9;
      border-radius: rem(6.333);
      cursor: pointer;
      transition: all 0.2s ease-in-out, border-color 0s;
    }

    input {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:checked + label {
        border-color: #7aac4a;

        .form-remove-item & {
          border-color: red;
        }
      }
    }

    .inner-box {
      display: grid;
      grid-template-columns: 1fr 45px;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: 100%;

      > span {
        display: block;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .card-title {
      display: block;
      margin-bottom: 4px;
      font-size: rem(14.937);
      font-weight: $medium;
      line-height: $line-height-heading-2;
      letter-spacing: -0.02em;
      text-transform: capitalize;

      .discount-type-list & {
      }
    }

    .card-price {
      text-align: right;
    }

    .card-price,
    .card-discount {
      font-size: rem(14.38);
      color: #757575;
      letter-spacing: -0.07em;
    }

    &--remove {
      .inner-box {
        grid-template-columns: 1fr;
      }
    }
  }

  .discount-type-list & {
    .item-card {
      margin: -4px 22px 23px 0;
      flex-basis: calc(33.333% - 16px);

      &:after {
        right: -11px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      label {
        padding: 10px 6px 10px 14px;
      }
    }

    .card-title {
      font-size: rem(16.5);
    }
  }
}

.soldout-size-tag {
  display: flex;

  flex: 1;
  color: #fe0000;
  font-weight: 500;
  padding-left: 10px;
  font-style: italic;
}

.item-detail-cards {
  .item-detail-card {
    position: relative;
    display: flex;
    padding: 15px 0;
    border-bottom: rem(1.333) solid #f3f3f3;
    border-bottom-color: rgba(110, 110, 110, 0.102);
    align-items: center;
    cursor: pointer;
  }

  .detail-card-price,
  .detail-card-title {
    font-size: rem(20);
    font-weight: $normal;
    letter-spacing: -0.02em;
    color: #484848;
  }

  .detail-card-title {
    display: flex;
    flex: 0.5;
    flex-basis: 320px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .detail-card-price {
    padding: 0 10px;
    color: #8a8a8a;
  }

  .detail-card-input-box {
    position: absolute;
    top: 50%;
    right: -1px;
    width: 31px;
    height: 31px;
    transform: translateY(-50%);

    .checkstyle {
      position: absolute;
      z-index: 3;
      display: inline-block;
      width: 31px;
      height: 31px;
      font-size: rem(18);
      border: rem(1) solid #989898;
      background-color: $color-white;
      box-shadow: inset 0 0.8px 8px 0 rgba(0, 0, 0, 0.19);
      border-radius: 20rem;
      pointer-events: none;
      cursor: pointer;

      &:after {
        position: absolute;
        z-index: 5;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        font-family: 'dine-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: inline-block;
      width: 29px;
      height: 29px;
      cursor: pointer;

      &:checked + .checkstyle {
        border-color: #7aac4a;
        background-color: #7aac4a;
        box-shadow: inset 0 rem(0.333) rem(3) 0 rgba(0, 0, 0, 0.19);

        &:after {
          content: '\e901';
        }
      }
    }
  }
}

.alphabet-nav {
  position: absolute;
  z-index: 7;
  top: 35px;
  right: 24px;
  background-color: #fafafa;
  width: 19px;
  padding: 3px 0;
  border-radius: 20rem;

  a,
  button {
    position: relative;
    z-index: 3;
    display: block;
    width: 19px;
    height: 19px;
    font-family: $font-main;
    font-size: rem(12.5);
    font-weight: $medium;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 20rem;
    background-color: transparent;
    color: #919191;
    border: none;
    appearance: none;
    box-shadow: none;

    &.active {
      background-color: #0a84ff;
      color: $color-white;
    }

    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.customization-form {
  &.detail-form {
    padding-bottom: 100px;
  }
}

.add-form {
  margin-top: -6px;

  .item-cards {
    .item-card {
      label {
        min-height: 65px;
        padding: 8px 11px 8px;
      }
    }
  }
}

.plus-icon {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../img/plus_icon.png);
}

.bem-ingredient-counter {
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr 25px;
  width: 80px;
  margin: auto;
  text-align: center;
  grid-area: 2/1/2/3;
}

.kiosk-screen {
  .main-area-body {
    .food-cards {
      margin: 12px -11px;
      padding-bottom: 100px;
    }
  }
  .food-cards {
    .card-item {
      width: 25%;
    }
  }

  .specials-cards .specials-card-wrap,
  .main-area-body-wrap.specials .food-cards .card-item {
    width: 50%;
  }
}

.extraHeaderSection {
  display: flex;
  margin-bottom: 1.4rem;
}

.extraHeaderLabel {
  flex: 1;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.02em;

  &:first-letter {
    text-transform: capitalize;
  }

  .modifiers-style & {
    margin-bottom: 28px;
  }
}

.extraHeaderButton {
  border: 0.2rem solid #e9e9e9;
  border-radius: 0.6333rem;
  background-color: #fafafa;
  font-weight: 500;
  padding: 10px;
  color: #3a3a3a;
  margin-left: 20px;
  font-size: 15px;
}

.selectedButton {
  border: 0.2rem solid #067aff;
  color: white;
  background-color: #067aff;
}

@media (min-width: 1024px) and (min-height: 1000px) {
  .kiosk-screen {
    .food-cards {
      .card-item {
        width: 33.333%;
      }
    }

    .specials-cards .specials-card-wrap,
    .main-area-body-wrap.specials .food-cards .card-item {
      width: 50%;
    }
  }
}
@media (min-width: 1280px) and (min-height: 1000px) {
  .kiosk-screen {
    .food-cards {
      .card-item {
        width: 25%;
      }
    }

    .specials-cards .specials-card-wrap,
    .main-area-body-wrap.specials .food-cards .card-item {
      width: 50%;
    }
  }
}

@media all and (min-width: 1280px) {
  .food-cards {
    .card-item {
      width: 20%;
    }
  }
}

@media all and (min-width: 1440px) {
  .food-cards {
    .card-item {
      width: 16.666%;
    }
  }
  .specials-cards .specials-card-wrap,
  .main-area-body-wrap.specials .food-cards .card-item {
    width: 33.33333%;
  }
}

.dark-mode {
  .main-area-inner {
    background-color: #000000;
  }
}

@media all and (min-width: 1920px) {
  .food-cards {
    .card-item {
      width: 14.28%;
    }
  }
  .specials-cards .specials-card-wrap,
  .main-area-body-wrap.specials .food-cards .card-item {
    width: 25%;
  }
}

@media all and (min-width: 2048px) and (min-height: 1000px) {
  .kiosk-screen {
    .food-cards {
      .card-item {
        width: 20%;
      }
    }

    .specials-cards .specials-card-wrap,
    .main-area-body-wrap.specials .food-cards .card-item {
      width: 33.333%;
    }
  }
}

@media all and (min-width: 2560px) {
  .food-cards {
    .card-item {
      width: 12.5%;
    }
  }
  .specials-cards .specials-card-wrap,
  .main-area-body-wrap.specials .food-cards .card-item {
    width: 20%;
  }
}

@media all and (min-width: 3840px) and (min-height: 1000px) {
  .kiosk-screen {
    .food-cards {
      .card-item {
        width: 16.666%;
      }
    }

    .specials-cards .specials-card-wrap,
    .main-area-body-wrap.specials .food-cards .card-item {
      width: 25%;
    }
  }
}

@media all and (max-width: 1023px) {
  .kiosk-screen .food-cards,
  .food-cards {
    .card-item {
      width: 33.333%;
    }
  }

  .item-detail-cards {
    .detail-card-title {
      flex-basis: 150px;
    }
  }
}

@media all and (max-width: 860px) {
  .kiosk-screen .food-cards,
  .food-cards {
    .card-item {
      width: 50%;
    }
  }
  .kiosk-screen .specials-cards .specials-card-wrap,
  .kiosk-screen .main-area-body-wrap.specials .food-cards .card-item,
  .specials-cards .specials-card-wrap,
  .main-area-body-wrap.specials .food-cards .card-item {
    width: 100%;
  }
}

@media all and (max-width: 680px) {
  .kiosk-screen .food-cards,
  .food-cards {
    .card-item {
      width: 100%;
    }
  }
}

.main-area.modifiers-style {
  .main-area-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-area-body-wrap {
      overflow: hidden;
      height: 100% !important;
    }
  }
  .current-food-box .food-ingredients {
    overflow: hidden auto;
    max-height: 160px;
  }
}

.searchAreaContainer {
  display: flex;
  justify-content: flex-end;
}
