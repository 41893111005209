.bem-counter {
  display: grid;
  grid-template-columns: 34px 1fr 34px;
  gap: 0 7px;
  align-items: center;
  justify-content: space-between;
  color: var(--textColor);
  &__quantity {
    display: block;
    min-width: 74px;
    text-align: center;
    font-size: 16px;
    line-height: var(--lineHeightTitle);
    font-weight: 500;
    letter-spacing: -0.05rem;
  }
  &__button {
    display: block;
    width: 34px;
    height: 34px;
    cursor: pointer;
    color: currentColor;
    &:disabled {
      color: var(--counterDisabledColor);
      cursor: default;
    }
  }
  &__icon {
    line {
      stroke-width: 1.5;
    }
  }
}
