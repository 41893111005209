.selectDeliveryContainer {
  padding: 21.5px !important;
}

.modalHeading {
  display: flex;
  justify-content: center;
  font-family: HelveticaNowText;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.48px;
  text-align: center;
  color: #363636;
  height: 42px;
}

.deliveryMethodItemContainer {
  padding-top: 19.5px;
}

.deliveryMethodItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 295px;
  height: 91px;
  padding: 15px 17px 15.9px 15.5px;
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
}

.deliveryMethodItem:last-child {
  margin-top: 25.5px;
}

.inStore {
  display: flex;
  width: 173px;
  height: 26px;
  margin: 0 46.5px 7px 1px;
  font-family: HelveticaNowText;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5eb602;
}

.subText {
  width: 214px;
  height: 25px;
  margin: 7px 6.5px 2.1px 0;
  font-family: HelveticaNowText;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
}

.uberDriver {
  color: #0386f6;
}
