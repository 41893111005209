.staff-pin-modal-box {
  padding: 37px 20px 20px;
  text-align: center;

  > :last-child {
    margin-bottom: 0;
  }
}

.staff-pin-desc {
  max-width: 315px;
  margin: 0 auto 30px;
  font-size: 18px;
  color: #515151;
}

.staff-pin-line {
  display: flex;
  max-width: 204px;
  margin: 0 auto 29px;
  flex-flow: row nowrap;
  flex: 1;
  align-items: center;
  justify-content: space-around;

  .staff-pin-circle {
    position: relative;
    display: block;
    margin: 0;
    width: 21px;
    height: 21px;
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
    background-color: #ffffff;
    border: 2px solid #515151;
    border-radius: 20rem;
    transition: border-color 0.25s ease-in-out;

    .number {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      font-size: 32px;
      line-height: 21px;
      font-weight: 500;
      color: transparent;
      transform-origin: center;
      transform: translate(-50%, -50%);
      transition: 0.25s ease-in-out;
    }

    &.selected {
      background-color: #515151;
      border-color: #515151;

      .number {
        color: #363636;
      }
    }
  }
}

.numeric-keypad-box {
  display: flex;
  max-width: 300px;
  margin: 0 auto 20px;
  flex-flow: row wrap;
  flex: 1 100px;
  align-items: center;
  justify-content: flex-end;

  .numeric-key {
    padding: 12px 14px;

    .inner-btn-box {
      overflow: hidden;
      position: relative;
      display: block;
      margin: 0;
      width: rem(72);
      height: rem(72);
      font-size: rem(30);
      line-height: 1.2;
      color: #515151;
      border-radius: 50rem;
      background-color: #e7e7e7;
      background-color: rgba(#e7e7e7, 0.54);

      .btn-text {
        position: absolute;
        z-index: 2;
        display: block;
        left: 50%;
        top: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%);
        user-select: none;
      }
    }

    &:active,
    &.active {
      &:before {
        opacity: 0 !important;
      }

      .inner-btn-box {
        background-color: #d7ffb2;
      }
    }

    &:disabled {
      opacity: 0;
    }
  }

  .key-delete,
  .key-cancel {
    .inner-btn-box {
      font-size: rem(18);
      background-color: transparent;
    }
  }
}

.dark-mode {
  .staff-pin-title,
  .staff-pin-desc {
    color: #ffffff;
  }

  .staff-pin-line {
    .staff-pin-circle {
      border-color: #d1d1d1;
      background-color: transparent;

      &.selected {
        border-color: #d1d1d1;
        background-color: #d1d1d1;

        .number {
          color: #ffffff;
        }
      }
    }
  }

  .numeric-keypad-box {
    .numeric-key {
      .inner-btn-box {
        color: #ffffff;
        background-color: rgba(#e7e7e7, 0.42);
      }

      &.active,
      &:active {
        .inner-btn-box {
          background-color: #000000;
        }
      }
    }

    .key-delete,
    .key-cancel {
      .inner-btn-box {
        font-size: rem(18);
        background-color: transparent;
      }
    }
  }
}


@media all and (max-height: 735px) {
  .staff-pin-modal {
    zoom: 0.9;
  }
}

@media all and (max-height: 640px) {
  .staff-pin-modal {
    zoom: 0.8;
  }
}
