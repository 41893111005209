@import '../../global/variables.module';

.table-main {
  position: relative;
  width: 100%;

  .table-main__head-cell {
    padding: 5px 5px 32px;
    font-size: rem(20);
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #e7e7e7;
    opacity: 0.8;
  }

  .table-main__body {
    color: $color-main;
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.81);

    .dark-mode & {
      color: $color-white;
    }
  }

  .table-main__body-row {
    background-color: #f7f7f7;

    .dark-mode & {
      background-color: #505050;
    }
  }

  .table-main__body-row:nth-child(even) {
    background-color: #ffffff;

    .dark-mode & {
      background-color: $color-dark-bg;
    }
  }

  .table-main__body-cell {
    padding: 5px 5px;
    height: 52px;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}
