.bem-move-table {
  &__top-box {
    margin-bottom: 16px;
  }
  &__title {
    display: block;
    margin-left: 6px;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.025rem;
    color: var(--textColor);
    line-height: var(--lineHeightTitle);
  }
  .bem-base-part__header {
    padding: 21px 17px 10px;
    & > :last-child {
      margin-bottom: 0;
    }
  }
  .bem-base-part__body-inner {
    padding: 15px 17px 40px;
  }
}
