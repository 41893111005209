.status-bar {
  overflow: hidden;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 21px;
  color: $color-white;
  background-color: #000000;

  .hide-status-bar & {
    display: none;
  }

  .cellular-connection {
    float: right;
    margin-right: 12px;
    width: 88px;
    margin-top: 5px;
    height: auto;
  }

  .status-time-box {
    float: left;
    margin-left: 14px;
    font-size: 11px;
    margin-top: 2px;
    word-spacing: -0.05em;

    .date {
      margin-left: 4px;
    }
  }
}

.call-notificaiton-status-bar {
  overflow: hidden;
  position: absolute;
  display: flex;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 35px;
  color: $color-white;
  background-color: #6bb81a;
  // .hide-status-bar & {
  //   display: none;
  // }

  .cellular-connection {
    margin-right: 12px;
    width: 88px;

    height: auto;
  }

  .status-time-box {
    float: left;
    margin-left: 14px;
    font-size: 11px;
    margin-top: 2px;
    word-spacing: -0.05em;

    .date {
      margin-left: 4px;
    }
  }
}

.batter-section {
  display: flex;
  align-items: center;
}

.time-section {
  display: flex;
  align-items: center;
}

.call-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.on-call-label {
  font-weight: 500;
  padding-right: 5px;
}

.end-call-button {
  background-color: #e01f21;
  width: 70px;
  color: white;
  border-radius: 15px;
  min-height: 25px;
  font-weight: 500;
}

.phone-number-section {
  min-width: 165px;
}
