@import 'src/assets/scss/global/mixins';
@import 'src/assets/scss/global/variables.module';

.bem-tabs-navigation {
  position: relative;

  &:after,
  &:before {
    position: absolute;
    z-index: 5;
    top: 0;
    content: '';
    width: 22px;
    height: 100%;
    background-color: var(--mainBG);
  }

  &:before {
    left: 0;
    background: var(--gradientLeftRightLinear);
  }

  &:after {
    right: 0;
    background: var(--gradientRightLeftLinear);
  }

  &__inner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 13px;
    overflow: auto hidden;

    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 22px;
    scroll-padding-right: 22px;
    padding-left: 22px;
    padding-right: 22px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    margin-right: 28px;
    font-weight: $medium;
    font-size: 18px;
    line-height: $line-height-heading-2;
    letter-spacing: -0.025rem;
    color: var(--textColor);
    opacity: 0.67;
    white-space: nowrap;
    scroll-snap-align: center;
    text-decoration: none;

    &--active {
      color: var(--primaryColor);
      opacity: 1;
    }

    &--success {
      color: var(--successColor);
      opacity: 1;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__active-line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--primaryColor);
    transition: transform 0.35s ease, width 0.35s ease;
  }
}
