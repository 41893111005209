.driver-main-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  justify-content: space-between;
  height: 100%;
  height: calc(100% - 58px);

  .driver-sidebar {
    min-width: 429px;
    flex-basis: 41.89%;
    max-width: 41.89%;
    width: 100%;
  }

  .driver-main {
    width: 58.11%;
  }

  &.all-driver-style {
    .driver-sidebar {
      min-width: 661px;
      flex-basis: 64.55%;
      max-width: 64.55%;
      width: 100%;
    }

    .driver-main {
      width: 35.45%;

      &:after {
        height: 100%;
      }
    }
  }
}
