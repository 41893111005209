.nav-bar-header {
  position: relative;
  z-index: 2;
  background-color: #ffffff;

  .close-btn {
    position: absolute;
    top: 8px;
    left: 6px;
    display: inline-block;
    width: 42px;
    height: 42px;
    font-size: 22px;
    font-weight: $normal;
    color: #494b4c;
    border: none;
    background-color: transparent;
    appearance: none;
    box-shadow: none;

    .dine_icon-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .nav-bar-title {
    margin-bottom: 9px;
    letter-spacing: 0;
  }

  .table-box-style & {
    padding-top: 55px;
    padding-bottom: 15px;

    .nav-bar-title {
      margin-bottom: 10px;
      margin-left: 5px;
    }
  }

  .tv-action-scroll & {
    padding-bottom: 22px;
  }
}

.timeline-box {
  overflow: hidden;
  position: relative;
  margin: 0 auto 10px;
  width: 100%;
  height: 206px;
  border-radius: 14px;
  background-color: #eeeeee;

  .action-line {
    position: absolute;
    z-index: 5;
    top: 47px;
    left: 48px;
    width: 237px;
    height: 2px;
    background-color: #ff0000;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: -3px;
      left: -1px;
      width: 8px;
      height: 8px;
      border-radius: 20rem;
      background-color: #ff0000;
    }
  }

  .time-line-list {
    pointer-events: none;
    user-select: none;
    margin-top: -12px;

    li {
      position: relative;
      display: block;
      height: 31px;

      span {
        display: inline-block;
        margin-left: 11px;
        font-size: 15px;
        vertical-align: bottom;
        color: #363636;
      }

      &:after {
        position: absolute;
        content: '';
        bottom: 11px;
        right: 9px;
        width: 225.53px;
        border-bottom: 1.25px solid #979797;
        opacity: 0.3;
      }
    }
  }

  .timeline-single-event {
    position: absolute;
    display: block;
    top: 5px;
    left: 68px;
    width: 218px;
    padding: 5px 10px 6px 8px;
    font-size: 14px;
    color: #ffffff;
    line-height: $line-height-heading-2;
    border-radius: 10px;
    background-color: #5eb602;

    .detail-row {
      display: table;
      width: 100%;

      &:first-child:not(:last-child) {
        margin-bottom: 5px;
      }

      .detail-cell {
        display: table-cell;
        vertical-align: middle;

        &:last-child:not(:first-child) {
          text-align: right;
        }
      }
    }

    .name {
      font-weight: $medium;
    }

    .time {
    }

    .booking-count-box {
      display: inline-block;
      margin-top: 1px;
      font-size: 14px;

      &:only-child {
        margin-right: 0;
      }

      .dine_icon-men {
        margin-right: 5px;
        margin-top: 2px;
        font-size: 16px;
      }
    }

    &.green {
      background-color: #5eb602;
    }

    &.blue {
      background-color: #0386f6;
    }

    &.pink {
      background-color: #eb4469;
    }

    &.event-1 {
      top: 5px;
    }

    &.event-2 {
      top: 68px;
    }

    &.event-3 {
      top: 175px;
    }
  }

  .tv-action-scroll & {
    height: 174px;
  }

  .tv-action-seated & {
    height: 174px;

    .action-line {
      top: 32px;
    }
  }
}

.table-select-nav {
  padding: 6px 17px;

  .tv-action-scroll & {
    padding-bottom: 15px;
  }

  ul {
    > li {
      display: block;

      &:not(:last-child) {
        margin-bottom: 21.5px;
      }

      a,
      button {
        display: block;
        width: 100%;
        text-decoration: none;
        @include font($normal, rem(18), $line-height-heading-2, $font-main);
        color: #363636;

        &:before {
          border-radius: 13px;
        }
      }

      button {
        padding: 0;
        appearance: none;
        box-shadow: none;
        border: none;
        background-color: transparent;
      }
    }
  }

  .single-detail-box {
    padding: 11px 15px 9px 14px;
    background-color: #ffffff;
    border-radius: 13px;
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
    will-change: transform;

    .detail-row {
      &.row-1 {
        margin-bottom: 9px;
      }
    }
  }
}

.table-single-item {
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: center;
  min-height: 70px;
  padding: 10px 52px 10px 16px;
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
  will-change: transform;

  .name {
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 7px;
    font-size: 26px;
    width: 42px;
    height: 42px;
    color: #494b4c;
    transform: translate(0, -50%);

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      @include centered-pos-icon();
    }

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: relative;
    }

    .pos_icon-peoples {
      width: 20px;
    }

    .pos_icon-silverware {
      width: 27px;
    }

    .pos_icon-wallet-cash {
      width: 26px;
    }

    .pos_icon-check {
      width: 24px;
    }

    .pos_icon-move {
      width: 22px;
    }

    .pos_icon-upcoming {
      width: 25px;
    }

    .pos_icon-calendar {
      height: 26px;
    }

    .pos_icon-walk-in {
      height: 27px;
      margin-left: 2px;
    }

    .pos_icon-people {
      width: 25px;
    }

    .pos_icon-close {
      width: 21px;
    }

    .dine_icon-walk-in {
    }

    .dine_icon-people {
      top: -2px;
      font-size: 24px;
    }

    .dine_icon-calendar {
    }

    .dine_icon-close_light1 {
      font-size: 21px;
      font-weight: 700;
    }

    .dine_icon-close {
      font-size: 24px;
    }

    .dine_icon-silverware {
      top: 2px;
      left: 4px;
      font-size: 26px;
    }

    .dine_icon-wallet-cash {
      top: 3px;
      left: 2px;
    }

    .dine_icon-close {
      left: 1px;
    }
  }
}

.table-nav-status-box {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 327px;

  a,
  button {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 18px 40px 18px 60px;
    text-align: left;
    @include font($medium, rem(18), 1.25, $font-main);
    color: $color-white;
    background-color: #5eb602;
    appearance: none;
    box-shadow: none;
    text-decoration: none;
    border: none;

    &.orange {
      background-color: #fd6c00;
    }

    &.purple {
      background-color: #b620e0;
    }

    &.blue {
      background-color: #0686f6;
    }

    &.pink {
      background-color: #eb4469;
    }

    &.yellow {
      background-color: #f7b500;
    }

    &.green {
      background-color: #5eb602;
    }

    &.grey {
      background-color: #6d7278;
    }

    .icon-box {
      position: absolute;
      display: inline-block;
      left: 10px;
      top: 50%;
      font-size: 28px;
      width: 42px;
      height: 42px;
      transform: translate(0, -50%);

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:after {
      position: absolute;
      content: '\e960';
      top: 50%;
      right: 18px;
      @include icomoon();
      font-size: 12px;
      margin-top: 1px;
      font-weight: 700;
      transform: translate(0, -50%);
    }

    .name {
      display: inline-block;
    }
  }
}
