.alert-modal {
  .generalModal-2-box {
    border-radius: 1rem;
  }
  .order-back-modal {
    &__text-color {
      color: rgb(107, 114, 128);
    }
    &-button {
      padding: 1rem 4rem;
      font-size: 14px;
      margin: 4px 8px;
      border-radius: 6px;
      font-weight: 500;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &-button-cancel {
      background-color: #dc2626;
      color: white;

      &:hover {
        background-color: #c53030;
      }
    }

    &-button-stay {
      color:rgb(55, 65, 81);
      border: 1px solid;
      border-color: rgb(209, 213, 219);
      background-color: white;
      &:hover {
        background-color: #f9fafb;
      }
    }

    &-text-div {
      padding: 2rem;
    }

    &__header {
      font-weight: 500;
      margin-bottom: 5px;
    }

    &-buttons-div {
      padding: 1.5rem;
      display: flex;
      justify-content: flex-end;
      background-color: rgba(249,250,251,1);
    }
  }
}