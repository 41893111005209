@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.radix-select {
  &__trigger {
    @include UIStyleTypeModifiers();
    border-radius: 50rem;
  }
  &__trigger-inner {
    display: inline-grid;
    align-items: center;
    text-align: left;
    padding: 0 6px;
    line-height: 1;
    height: 25px;
    font-size: 16px;
    font-weight: 500;
    gap: 0 8px;
    grid-template-columns: 1fr 30px;
    &--with-icon {
      grid-template-columns: 30px 1fr 30px;
    }
  }
  &__icon-box,
  &__trigger-chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    max-height: none !important;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35),
      0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  }
  &__viewport {
    padding: 5px;
  
  }
  &__item {
    line-height: 1;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
  &__item-indicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    cursor: default;
  }
  &__loader{
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #9E9E9E;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
