#message-modal,
.message-modal-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0s;
}

.show-keyboard .message-modal-overlay,
.show-keyboard #message-modal {
  height: 410px;
  bottom: 358px;
}

#message-modal.show,
.show .message-modal-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.35s;
}

.message-modal-overlay {
  z-index: 10;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.message-modal-wrap {
  overflow: hidden;
  position: absolute;
  z-index: 20;
  left: 50%;
  width: 672px;
  height: 385px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  top: auto;
  bottom: 50%;
  transform: translate(-50%, 50%);
  transition: bottom 0.35s, transform 0.35s;

  .iPad-mode-wrapper & {
    top: 188px;
    transform: translate(-50%, 0);
    transition: top 0.35s;
  }

  .show-keyboard & {
    top: 25px;
    border-radius: 16px 16px 0 0;
    box-shadow: none;
  }

  a.close-modal-btn,
  .close-modal-btn {
    position: absolute;
    top: 4px;
    right: 9px;
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    color: $color-text;
    font-size: 18px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    appearance: none;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .message-modal-header {
    position: relative;
    z-index: 5;
    padding: 10px 5px 9px;
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.5);

    > :last-child {
      margin-bottom: 0;
    }
  }

  .message-modal-body {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 232px;
    padding: 6px 0 6px;
  }

  .message-modal-fast-action {
    position: relative;
    padding: 5px 0 0 16px;
    width: 800px;

    a,
    button {
      position: relative;
      display: inline-block;
      padding: 6px 40px 6px 15px;
      margin: 0 12px 6px 0;
      @include font($normal, rem(16), 1.25, $font-main);
      letter-spacing: -0.02em;
      appearance: none;
      border: none;
      border-radius: 30rem;
      background-color: #ffffff;
      box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.5);

      &:before {
        border-radius: 30rem;
      }

      .icon-box {
        position: absolute;
        top: 50%;
        right: 9px;
        margin-top: -1px;
        width: 26px;
        height: 26px;
        font-size: 18px;
        color: #067cfe;
        text-transform: uppercase;
        transform: translate(0, -50%);

        [class^='dine_icon-'],
        [class*=' dine_icon-'] {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .message-modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: #ffffff;
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.5);
  }

  .send-message-box {
    position: relative;
    padding: 9px 56px 9px 16px;

    .input-style {
      max-width: 600px;
      min-height: 35px;
      padding: 0 12px;
      font-size: 16px;
      line-height: 33px;
      border-radius: 16px;
    }

    textarea.input-style {
      padding: 5px 12px;
      min-height: auto;
      line-height: 1.45;
    }

    .transparent-text {
      color: transparent;
    }

    .input-style::-webkit-input-placeholder {
      font-size: 16px;
    }

    .input-style::-moz-placeholder {
      font-size: 16px;
    }

    .input-style:-ms-input-placeholder {
      font-size: 16px;
    }

    .input-style:-moz-placeholder {
      font-size: 16px;
    }

    textarea.input-style::-webkit-input-placeholder {
      font-size: 16px;
      line-height: 25px;
    }

    textarea.input-style::-moz-placeholder {
      font-size: 16px;
      line-height: 25px;
    }

    textarea.input-style:-ms-input-placeholder {
      font-size: 16px;
      line-height: 25px;
    }

    textarea.input-style:-moz-placeholder {
      font-size: 16px;
      line-height: 25px;
    }

    a.send-message,
    .send-message {
      position: absolute;
      display: inline-block;
      width: 29px;
      height: 29px;
      top: 50%;
      right: 15px;
      color: #007aff;
      font-size: 32px;
      background-color: #ffffff;
      box-shadow: none;
      border: none;
      appearance: none;
      transform: translate(0, -50%);
      border-radius: 50rem;

      &:before {
        border-radius: 50rem;
      }

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .message-modal-title {
    display: block;
    margin-left: 6px;
    margin-bottom: 23px;
    @include font($normal, rem(18), $line-height-heading-2, $font-main);

    .initials-box {
      position: relative;
      display: inline-block;
      width: 31px;
      height: 31px;
      margin-right: 13px;
      font-size: 16px;
      color: #ffffff;
      text-transform: uppercase;
      background-color: #fd6c00;
      border-radius: 50%;
      vertical-align: middle;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        letter-spacing: 0;
      }
    }

    .name {
      display: inline-block;
      letter-spacing: -0.04em;
      vertical-align: middle;
    }
  }

  .message-list-wrap {
    padding: 0 22px 0 18px;
  }

  .message-list {
    > li {
      opacity: 1;
      transition: opacity 0.35s;
      margin-bottom: 22px;
      text-align: left;

      &.right {
        text-align: right;
      }
    }

    &.delay-last {
      > li {
        &:last-child {
          opacity: 0;
          transition: opacity 0s;
        }
      }
    }

    .message-date {
      display: block;
      margin-bottom: 6px;
      font-size: 13px;
      font-weight: $normal;
      text-align: center;
      color: #717374;
      letter-spacing: -0.06em;
      opacity: 0.78;

      span {
        font-weight: $medium;
      }
    }
  }

  .message-single-box {
    position: relative;
    display: inline-block;
    padding: 5px 6px 5px 12px;
    max-width: 302px;
    min-width: 46px;
    text-align: left;
    font-size: 14px;
    letter-spacing: -0.015em;
    background-color: #e5e5ea;
    color: #000000;
    border-radius: 16px;

    &:after {
      position: absolute;
      content: '\e957';
      @include icomoon();
      z-index: 1;
      right: auto;
      left: -5px;
      bottom: 0;
      font-size: 10px;
      color: #e5e5ea;
    }

    &.blue {
      padding: 7px 6px 6px 12px;
      color: #ffffff;
      background-color: #047cfe;

      &:after {
        content: '\e956';
        color: #047cfe;
        right: -5px;
        left: auto;
        bottom: 0;
      }

      .message-bubble-box {
        background-color: #047cfe;
      }
    }

    &.map {
      overflow: hidden;
      margin-top: 0;
      max-width: 205px;
      padding: 0;

      img {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        border-radius: 10px;
      }

      &:after {
        content: none;
      }
    }

    .message-bubble-box {
      position: relative;
      z-index: 3;
      word-break: break-word;
      background-color: #e5e5ea;
    }
  }

  .free-message-bubble {
    position: absolute;
    z-index: 2;
    bottom: 9px;
    left: 22px;
    transition: left 0.65s ease-in-out, bottom 0.65s ease-in-out,
      opacity 0.35s ease-in-out, z-index 0s;
    opacity: 0;
    transform: scale(1);
  }
}
