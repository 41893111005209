@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-bill-check-info {
  display: flex;
  min-height: 53px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px 18px 10px;
  @include UIJustifyContentModifiers();
  @include UIBorderSideModifiers(1px, dashed, var(--greyColor-150));
  > :not(:only-child):last-child {
    text-align: right;
    margin-left: 20px;
  }
  &__title {
    display: block;
    font-size: 20px;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.025rem;
    font-weight: 500;
  }
  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }
  &__counter-text {
    display: block;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }
  &__total-text {
    display: block;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }
  &__counter-box {
    //max-width: 100px;
    .bem-counter__quantity {
      min-width: 28px;
    }
  }
}
