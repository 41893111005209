.notificationContainer {
  background-color: #eb3428;
  border-radius: 35px;
  min-width: 200px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 60px;
  color: white;
  display: flex;
  margin-left: 10px;
}
.onCallNotification {
  background-color: #6bb81a;
}
.callLoading {
  background-color: #f39c12;
}
.notificationInfoSection {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.phoneIcon {
  width: 20px;
  height: 20px;
}
.iconContainer {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #6bb81a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.declineCallButton {
  background-color: #e01f21;
}
.actionButtonContainer {
  padding-left: 20px;
  align-self: center;
  cursor: pointer;
}
.incommingCallLabel {
  font-size: 14px;
  color: #f9c8c4;
}
.onCallLabel {
  color: #cee8b2;
}
.lineName {
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
}
.phoneNumber {
  font-size: 15px;
}
