[class^='pos_icon-'],
[class*=' pos_icon-'] {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  svg {
    @include centered-pos-icon();
    width: 100%;
    height: 100%;
  }
}

.pos-icon {
  &__reload {
    height: 26px;

    svg {
      width: 22px;
      height: 26px;
    }

    &--smaller {
      height: 18px;

      svg {
        width: 15px;
        height: 18px;
      }
    }
  }
}
