.headerContainer {
  padding-left: 16.5px;
  padding-top: 21.2px;
  padding-bottom: 19.5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.customCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 40.7px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  cursor: pointer;
}

.mainDriverHeadingText {
  display: flex;
  height: 37px;
  font-family: HelveticaNowText;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
  margin-bottom: 6.5px;
  margin-top: 11.1px;
}

.subTextDriverText {
  height: 50px;
  opacity: 0.83;
  font-family: HelveticaNowText;
  font-size: 17.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #363636;
}

.driverListContainer {
  height: 326px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.driverItemContainer {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  cursor: pointer;
}

.driverItemContainer:first-child {
  margin-top: 1.5px;
}

.driverAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: blue;
  color: #fff;
  font-family: HelveticaNowText;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.72px;
}

.driverNameText {
  padding-left: 17px;
  height: 26px;
  font-family: HelveticaNowText;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
}

.active {
  background-color: #fd6c00;
  color: #ffffff;
}

.activeAvatar {
  color: #fd6c00;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
}

.assignButton {
  width: 428px;
  height: 47px;
  background-color: #fd6c00;
  font-family: HelveticaNowText;
  font-size: 18.2px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.49px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assignButton:disabled {
  background-color: #f9c299;
}
