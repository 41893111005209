@import '../../assets/scss/global/variables.module';

.favourites {
  position: relative;
  z-index: 2;
  display: grid;
  padding: 17px 27px 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -16px 20px 5px rgba(0, 0, 0, 0.5);
  .dark-mode & {
    background-color: #111213;
  }
  &__title {
    display: block;
    font-size: rem(22.5);
    font-weight: $medium;
    letter-spacing: -0.45px;
    // color: $color-main;
    .dark-mode & {
      color: #ffffff;
    }
  }
  &__wrap {
    position: relative;
    z-index: 1;
    width: calc(100% + 54px);
    margin-left: -27px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 27px 10px;
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  &__small-group {
    display: grid;
    grid-template-columns: 232px;
    grid-template-rows: 1fr 1fr;
    gap: 1px 0;
    background-color: #979797;
    background-color: rgba(#979797, 0.15);
    .dark-mode & {
      background-color: rgba(210, 210, 210, 0.4);
    }
  }
}

.favourite-item {
  display: grid;
  height: 100%;
  grid-template-columns: 48px 1fr 38px;
  align-items: center;
  padding: 15px 20px 15px 0;
  background-color: #ffffff;
  .dark-mode & {
    background-color: #111213;
  }
  &__img-wrap {
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    text-align: center;
    border-radius: 8px;
    background-color: #e9e9e9;
  }
  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
  &__info-block {
    display: grid;
    gap: 6px 0;
    padding: 0 10px;
  }
  &__name {
    font-size: rem(15);
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.34px;
    color: #363636;
    .dark-mode & {
      color: #ffffff;
    }
  }
  &__ingredients {
    color: #838383;
    font-size: rem(11);
    line-height: 1.22;
    letter-spacing: -0.25px;
    .dark-mode & {
      color: rgba(255, 255, 255, 0.77);
    }
  }
}

.favourite-empty-item {
  height: 100%;
  background-color: #ffffff;
  .dark-mode & {
    background-color: #111213;
  }
}
