.switch-btn-group-box {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 4px;
  border-radius: 12px;
  background-color: #e9e9e9;

  a,
  button {
    position: relative;
    z-index: 5;
    display: block;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #3a3a3a;
    text-decoration: none !important;

    &:before {
      border-radius: 9px;
    }

    .inner-btn-box {
      min-width: 95px;
      display: block;
      padding: 3px 18px;
    }

    &.active {
      color: #363636;
    }
  }

  .switch-btn-active {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 0;
    width: 113px;
    height: calc(100% - 8px);
    border-radius: 8px;
    background-color: #ffffff;
    transition: width 0.25s ease-in, transform 0.25s ease-in;
    transform-origin: center;
    box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.04), 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  }
}

.dark-mode {
  .switch-btn-group-box {
    background-color: #292a2e;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);

    a,
    button {
      color: #ffffff;
    }

    .switch-btn-active {
      background-color: #64646b;
    }
  }
}
