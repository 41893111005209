#item-detail-modal,
.dine-modal-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.35s;
}

#item-detail-modal.show,
.show .dine-modal-overlay {
  visibility: visible;
  opacity: 1;
}

.dine-modal-overlay {
  z-index: 10;
  padding: 43px 22px 22px;
  background-color: rgba(0, 0, 0, 0.2);
}

.dine-modal-wrap {
  position: absolute;
  z-index: 20;
  top: 43px;
  left: 22px;
  right: 22px;
  bottom: 22px;
  max-height: 100%;
  .hide-status-bar & {
    top: 22px;
  }

  .dine-modal-box {
    position: relative;
    display: flex;
    z-index: 999;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: rem(25);
    background-image: url(../../img/modal_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .dine-modal-body {
    overflow: hidden;
    max-height: 100%;
    display: flex;
  }
  .dine-img-box {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      max-width: 600px;
      max-height: 600px;
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    position: absolute;
    top: 25px;
    left: 27px;
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    color: $color-text;
    font-size: 38px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    appearance: none;
  }

  .dine-modal-text-box {
    position: absolute;
    left: 34px;
    bottom: 30px;
    max-width: 300px;
    letter-spacing: -0.02em;

    > :last-child {
      margin-bottom: 0;
    }
  }

  .dine-modal-title {
    margin-bottom: rem(4);
    font-size: rem(25);
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.02em;
    text-transform: capitalize;
  }

  .dine-modal-desc {
    font-size: rem(16.25);
    color: #a1a1a1;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
