.nav-bar {
  flex-basis: 198px;
  max-width: 198px;
  min-width: 198px;
  width: 100%;
  position: relative;
  z-index: 7;
  height: 100%;
  background-color: #f7f7f7;
  box-shadow: 0 0 4px 0.6px rgba(0, 0, 0, 0.19);

  .bottom-nav-box {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-white;
    box-shadow: 0 0 0 0.5px #d2d5db,
      rem(0.195) rem(0.638) rem(5.88) rem(0.12) rgba(0, 0, 0, 0.2);

    .category-nav {
      .nav-link {
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .count-box {
    display: inline-block;
    width: rem(26);
    height: rem(26);
    color: $color-white;
    font-size: rem(14.106);
    font-weight: $medium;
    text-align: center;
    line-height: rem(26);
    border-radius: 20rem;
    background-color: $color-bg-blue;
  }
}

.cursor-pointer {
  cursor: pointer;
}

a.back-btn,
.back-btn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 10px 0 15px;
  text-align: left;
  font-size: rem(19);
  font-family: $font-main;
  font-weight: $medium;
  line-height: $line-height-heading-2;
  border: none;
  text-decoration: none;
  box-shadow: none;

  &:before {
    content: none;
  }

  .back-icon {
    position: relative;
    display: block;
    z-index: 2;
    width: 24px;
    height: 24px;
    margin-right: 14px;
    background-color: $color-bg;
    border-radius: 50%;

    .pos_icon-back-icon {
      @include centered-pos-icon();
      width: 14px;
      height: 12px;
    }

    .pos_icon-close {
      @include centered-pos-icon();
      width: 14px;
      height: 12px;
    }
  }

  .text {
    position: relative;
    display: block;
    margin-left: -4px;
    line-height: 1;
  }

  .dine_icon-left-chevron {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 11px;
    font-size: 18px;
    line-height: 23px;
    color: $color-white;
    background-color: $color-bg;
    border-radius: 100rem;
    text-align: center;
    vertical-align: middle;

    &:before {
      position: relative;
      left: -1px;
    }
  }

  &.red {
    color: #e03934;

    .back-icon {
      background-color: #e03934;
    }

    svg {
      path {
        fill: rgb(255, 255, 255);
      }
    }
  }

  .back-logo {
    width: 28px;
    margin-left: 2px;
    margin-right: 10px;
  }
}

.logo-box {
  display: block;
  max-width: 100px;
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.category-nav-wrap {
  height: calc(100% - 154px);
}

.category-nav {
  .nav-link {
    display: table;
    width: 100%;
    height: 60px;
    padding: 5px 5px 5px 0;
    font-size: rem(17);
    line-height: $line-height-heading-2;
    letter-spacing: -0.02em;
    text-decoration: none;
    background-color: $color-white;
    transition: $transition-other;
    border: 1px solid #e9e9e9;
    border-right: none;
    border-left: none;
    border-bottom-color: $color-white;
    text-transform: capitalize;

    &:last-child {
      border-bottom-color: #e9e9e9;
    }

    > span {
      display: table-cell;
      vertical-align: middle;

      &:not(.img-box) {
        padding-bottom: 2px;

        &:only-child {
          padding-left: 5px;
        }
      }
    }

    .img-box {
      width: 62px;
      padding: 0 5px;
      text-align: center;

      img {
        display: inline-block;
        max-width: 35px;
        max-height: 32px;
        width: auto;
        height: auto;
        font-size: 14px;
      }

      &.text-style {
        width: auto;

        .count-box {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          padding: 3.5px 7px;
          width: auto;
          height: 22px;
          max-width: 90px;
          font-size: 1.25rem;
          letter-spacing: -0.02em;
          white-space: nowrap;
          border-radius: rem(8.666);
          line-height: $line-height-heading-2;
        }
      }
    }

    &.with-icon {
      > span:not(.img-box) {
        padding-left: 18px;
      }

      .img-box {
        width: 42px;
      }

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        font-size: 26px;
        line-height: 48px;
      }

      .pos_icon-silverware,
      .pos_icon-write-message {
        width: 26px;
        height: 28px;
      }

      .pos_icon-silverware {
        margin-top: 2px;
      }

      .pos_icon-write-message {
        margin-top: -2px;
      }

      .pos_icon-discount {
        width: 24px;
      }
    }

    &.sub-text-style {
      padding-left: 18px;

      &.small {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: $normal;

        .sub-text {
          display: table-footer-group;
          font-weight: $medium;
          color: #777575;
          white-space: nowrap;

          [class^='dine_icon-'],
          [class*=' dine_icon-'] {
            display: inline-block;
            font-size: 13px;
            margin-left: 3px;
            margin-right: 9px;
            line-height: 1;
          }

          &.address {
            font-weight: 400;
            letter-spacing: 0;
          }
        }
      }
    }

    &.green {
      background-color: #48a903;
      color: $color-white;

      .sub-text {
        color: $color-white;
      }

      border-color: #48a903;

      &.small {
        .sub-text {
          color: $color-white;
        }
      }

      & + .nav-link {
        border-top-color: transparent;

        &.active {
          border-color: #b5b5b5;
        }
      }
    }

    &.active {
      background-color: rgba($color-bg-grey, 0.5);
      border-color: #b5b5b5;

      &.green {
        background-color: #48a903;
        border-color: #48a903;
      }

      & + .nav-link {
        border-top-color: transparent;
      }
    }

    &.small {
      font-size: 16px;
    }

    &.smaller {
      font-size: 15px;
    }

    &.smallest {
      font-size: 14px;
    }
  }

  &.flex-style {
    .nav-link {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      > span {
        display: block;
        line-height: 1.25;
        padding-bottom: 0;
      }

      .img-box {
        line-height: 1;

        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          line-height: 1;
        }
      }
    }
  }

  &.support {
    .nav-link {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-top: 2px;
      padding-bottom: 3px;
      height: 49px;
      font-size: rem(16);
      font-family: $font-heading;
      font-weight: $medium;
      letter-spacing: 0;
      transition: 0s;

      > span:not(.img-box) {
        padding-bottom: 0;
      }

      .icon-box {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        background-color: #363636;
        border-radius: 50rem;

        &.orange {
          background-color: #fd6c00;
        }

        &.green {
          background-color: #5eb602;
        }

        .initials,
        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 15px;
          line-height: 1;
          letter-spacing: 0.01em;
          color: #ffffff;
          font-weight: 400;
          text-transform: uppercase;
          transform-origin: center;
          transform: translate(-50%, -50%);
        }

        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          width: 12px;
          height: 16px;
        }
      }

      .img-box {
        width: 62px;

        img {
          position: relative;
          max-width: 34px;
          max-height: 34px;
          left: 2px;
          top: 2px;
        }
      }

      &:first-child {
        border-top: none;
      }

      &:first-child:nth-last-child(even) {
        height: 46px;
      }

      > span {
        display: block;
        line-height: 1.25;
      }
    }
  }

  & + .support {
    padding-top: 9px;
    background-color: #f7f7f7;

    .nav-link {
      height: 57px;
      background-color: #f7f7f7;
      border: none;

      .img-box {
        img {
          max-width: 33px;
          max-height: 33px;
        }
      }

      &:first-child:nth-last-child(2) {
        height: 47px;
      }

      &:last-child:nth-last-child(1) {
        height: 58px;

        .img-box {
          img {
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }
}

.instructions-textarea {
  margin-left: 5px;
  resize: none;
  font-family: $font-main;
}

.nav-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-notes {
  text-transform: none !important;
}

@media all and (min-width: 1920px) {
  .nav-bar {
    flex-basis: 20%;
    max-width: 500px;
  }
}
