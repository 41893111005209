.half-half-item-container {
  display: flex;
  align-items: center;
  font-size: 17px;

  .menu-opener-cta {
    position: absolute;
    right: 0;
    transform: scale(0.6);
  }

  .half-half-price-container {
    position: absolute;
    right: 50px;
  }

  &.voided {
    opacity: 0.3;
    text-decoration: line-through;
  }
}

.split-image-container {
  height: 50px;
  width: 50px;
  position: relative;
  box-shadow: 0 0 4.6px -1px rgba(70, 70, 70, 0.5);
  border-radius: 7px;
}

.split-image-wrapper {
  height: inherit;
  width: inherit;

  .split-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px;
  }

  .img-0 {
    clip-path: polygon(100% 2%, 2% 100%, 100% 100%);
  }

  .img-1 {
    clip-path: polygon(98% 0, 0 98%, 0 0);
  }
}

.orders-screen-title-container-spacing {
  width: 100%;
  margin: 10px 0;
}

// beware hackish code.
.order-flow {
  border-bottom: solid 2px #e9e9e9;
  border-bottom-color: rgba(233, 233, 233, 0.43);

  .half-and-half-titles-container {
    .name {
      font-size: 16px !important;
    }
  }

  &.half-half-item-container {
    padding-right: 32px;
  }

  .half-half-price-container {
    position: relative;
    right: auto;
    width: 140px;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .split-image-container {
    width: 46px !important;
    height: 46px !important;
    margin: 4px 25px 6px 17px !important;
  }

  .split-image-wrapper {
    position: relative;
    right: -0.5px;
  }

  .half-half-item-container {
    padding: 12px 0;
  }

  .menu-opener-cta {
    opacity: 0.57;
  }

  .single-modify-btn {
    width: 31px;
    height: 42px;

    .pos_icon-apper-circle {
      height: 21px;
      width: 14px;
    }
  }
}

.additionals {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  padding: 0;
  text-align: left;
  font-family: 'HelveticaNowText', sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
  letter-spacing: -0.01em;
  color: #838383;
  background-color: transparent;
  box-shadow: none;
  appearance: none;
  border: none;
}
