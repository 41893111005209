.actions-modal {
  a.generalModal-close-btn.inside,
  .generalModal-close-btn.inside {
    top: 8px;
    left: 7px;

    .inner-btn-box {
      width: 42px;
      height: 42px;
    }

    .pos_icon-close {
      width: 18px;
      height: 18px;
    }
  }

  .hide-status-bar & {
    .generalModal-wrap {
      height: 100% !important;
    }
  }
}

.actions-modal-box {
  width: 100%;
  height: 100%;

  .action-modal-header {
    position: relative;
    z-index: 15;
    padding: 60px 18px 6px;
    background-color: #ffffff;
  }

  .actions-modal-title {
    display: block;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #363636;
  }

  .actions-modal-body {
    width: 100%;
    height: calc(100% - 92px);
  }

  .sidebar-action-list {
    display: block;
    width: 100%;
  }
}

.dark-mode {
  .actions-modal-box {
    background-color: #000000;

    .action-modal-header {
      background-color: #000000;
    }

    .actions-modal-title {
      color: #ffffff;
    }

    .single-sidebar-action {
      background-color: #2b2c2e;
    }
  }
}
