.bem-numeric-keyboard {
  overflow: hidden;
  display: grid;
  height: 100%;
  padding: 1px 0 1px;
  gap: 1px;
  background-color: var(--numericKeyboardBg);
  grid-template-columns: 1fr 1fr 1fr;
  &__key {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    height: 100%;
    font-size: 30px;
    color: var(--numericKeyboardBtnColor);
    background-color: var(--numericKeyboardBtnBg);

    &:active {
      background-color: var(--mainBG);
    }
  }
}
