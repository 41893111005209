.innerModal {
  .innerModal-overlay {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.25s ease-in-out 0.35s;
  }

  .innerModal-wrap {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    height: 683px;
    width: 700px;
    border-radius: 2.8rem;
    transform: translate(-50%, -50%);
  }

  .innerModal-innerWrap {
    overflow: hidden;
    position: relative;
    height: 100%;
    background-color: #ffffff;
    border-radius: 2.8rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  }

  a.innerModal-close-btn,
  .innerModal-close-btn {
    overflow: hidden;
    position: absolute;
    z-index: 1000000;
    opacity: 0;
    top: 23px;
    left: 23px;
    display: inline-block;
    border-radius: 50rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.25);

    &:before {
      border-radius: 50rem;
    }

    &:hover {
      &:before {
        width: 56px;
        height: 56px;
      }
    }

    .inner-btn-box {
      overflow: hidden;
      position: relative;
      display: block;
      width: 56px;
      height: 56px;
      background-color: #ffffff;
      border-radius: 50rem;
    }

    .pos_icon-close {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 26px;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }
  }
}

.innerModal,
.innerModal-overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.show-inner.innerModal,
.show-inner .innerModal-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s ease-in 0s;
}

a.innerModal-close-btn,
.innerModal-close-btn,
.innerModal-wrap {
  opacity: 0;
  transition: opacity 0.35s ease-in 0.15s;

  .show-inner & {
    opacity: 1;
  }
}

.top {
  .innerModal-wrap {
    top: 0;
    border-radius: 0 0 2.8rem 2.8rem;
    transform: translate(-50%, 0);

    .innerModal-innerWrap {
      border-radius: 0 0 2.8rem 2.8rem;
    }
  }
}

.right {
  .innerModal-wrap {
    left: 100%;
    border-radius: 2.8rem 0 0 2.8rem;
    transform: translate(-100%, -50%);

    .innerModal-innerWrap {
      border-radius: 2.8rem 0 0 2.8rem;
    }
  }
}

.right-bottom {
  .innerModal-wrap {
    top: 100%;
    left: 100%;
    border-radius: 2.8rem 0 0 0;
    transform: translate(-100%, -100%);

    .innerModal-innerWrap {
      border-radius: 2.8rem 0 0 0;
    }
  }
}

.bottom {
  .innerModal-wrap {
    top: 100%;
    border-radius: 2.8rem 2.8rem 0 0;
    transform: translate(-50%, -100%);

    .innerModal-innerWrap {
      border-radius: 2.8rem 2.8rem 0 0;
    }
  }
}

.left {
  .innerModal-wrap {
    left: 0;
    border-radius: 0 2.8rem 2.8rem 0;
    transform: translate(0, -50%);

    .innerModal-innerWrap {
      border-radius: 0 2.8rem 2.8rem 0;
    }
  }
}

.left-bottom {
  .innerModal-wrap {
    top: 100%;
    left: 0;
    border-radius: 0 0 0 2.8rem;
    transform: translate(0, -100%);

    .innerModal-innerWrap {
      border-radius: 0 0 0 2.8rem;
    }
  }
}

.move-effect {
  &.show-inner.hide-anim-inner {
    a.innerModal-close-btn,
    .innerModal-close-btn {
      opacity: 0;
      transition: opacity 0.35s linear 0s;
    }
  }

  &.show-inner {
    a.innerModal-close-btn,
    .innerModal-close-btn {
      transition: opacity 0.35s ease-in 0.35s;
    }
  }

  &.show-inner.hide-anim-inner .innerModal-wrap,
  .innerModal-wrap {
    opacity: 1;
    transform: translate(-50%, 150%);
    transition: transform 0.35s ease-in 0s;
  }

  &.show-inner {
    .innerModal-wrap {
      transform: translate(-50%, -50%);
      transition: transform 0.35s ease-in 0s;
    }
  }

  &.top {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(-50%, 0);
      }
    }
  }

  &.right {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      opacity: 1;
      transform: translate(50%, -50%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(-100%, -50%);
      }
    }
  }

  &.bottom {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      opacity: 1;
      transform: translate(-50%, 50%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(-50%, -100%);
      }
    }
  }

  &.left {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      opacity: 1;
      transform: translate(-150%, -50%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(0, -50%);
      }
    }
  }

  &.right-bottom {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      transform: translate(50%, -100%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(-100%, -100%);
      }
    }
  }

  &.left-bottom {
    &.show-inner.hide-anim-inner .innerModal-wrap,
    .innerModal-wrap {
      transform: translate(-150%, -100%);
    }

    &.show-inner {
      .innerModal-wrap {
        transform: translate(0, -100%);
      }
    }
  }
}

.innerModal.radius-small {
  .innerModal-wrap {
    border-radius: rem(21);

    .innerModal-innerWrap {
      border-radius: rem(21);
    }
  }

  &.top {
    .innerModal-wrap {
      border-radius: 0 0 rem(21) rem(21);

      .innerModal-innerWrap {
        border-radius: 0 0 rem(21) rem(21);
      }
    }
  }

  &.right {
    .innerModal-wrap {
      border-radius: rem(21) 0 0 rem(21);

      .innerModal-innerWrap {
        border-radius: rem(21) 0 0 rem(21);
      }
    }
  }

  &.right-bottom {
    .innerModal-wrap {
      border-radius: rem(21) 0 0 0;

      .innerModal-innerWrap {
        border-radius: rem(21) 0 0 0;
      }
    }
  }

  &.bottom {
    .innerModal-wrap {
      border-radius: rem(21) rem(21) 0 0;

      .innerModal-innerWrap {
        border-radius: rem(21) rem(21) 0 0;
      }
    }
  }

  &.left {
    .innerModal-wrap {
      border-radius: rem(21) rem(21) 0 0;

      .innerModal-innerWrap {
        border-radius: rem(21) rem(21) 0 0;
      }
    }
  }

  &.left-bottom {
    .innerModal-wrap {
      border-radius: 0 rem(21) 0 0;

      .innerModal-innerWrap {
        border-radius: 0 rem(21) 0 0;
      }
    }
  }
}

.innerModal.radius-none {
  .innerModal-wrap {
    border-radius: 0;

    .innerModal-innerWrap {
      border-radius: 0;
    }
  }
}

.dark-mode {
  .innerModal {
    .innerModal-innerWrap {
      background-color: #1f1e23;
    }
  }
}
