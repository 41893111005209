.denominations-box {
  .denominations-header {
    padding: 42px 20px 40px;
    text-align: center;

    a.btn,
    .btn {
      font-size: rem(16);
      font-weight: $normal;
      color: #363636;
      letter-spacing: -0.02em;

      &:before {
        border-radius: 12px;
      }

      .inner-btn-box {
        padding: 6px 15px;
        border-radius: 12px;
        border-color: transparent;
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .denominations-title {
    margin-bottom: rem(52);
  }

  .denominations-price {
    overflow: hidden;
    display: block;
    margin-bottom: 60px;
    font-size: rem(40);
    min-height: 50px;
    line-height: 1.25;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .denominations-keypad-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    display: flex;
    width: calc(100% + 2px);
    margin: 0 auto;
    flex-flow: row wrap;
    align-items: center;
    background-color: #fbfbfb;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    .denominations-key-wrap {
      width: (100% / 3);
      padding: 0;
      border: 1px solid #e9e9e9;

      &:nth-child(3n + 1) {
        border-left: none;
      }

      &:nth-child(3n) {
        border-right: none;
      }
    }

    .denominations-key {
      overflow: hidden;
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      height: rem(82);
      font-size: rem(30);
      line-height: 1.2;
      color: #515151;
      border-radius: 0;

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 25px;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }

      .pos_icon-plus {
        height: 22px;
      }

      .pos_icon-back-icon {
        height: 25px;
      }

      .pos_icon-dot {
        width: 5px;
        height: 5px;
      }

      .pos_icon-arrow {
        width: 26px;
        height: 22px;
      }

      .rotate {
        &-90 {
          transform: translate(-50%, -50%) rotate(90deg);
        }

        &-270 {
          transform: translate(-50%, -50%) rotate(270deg);
        }
      }

      &:active {
        &:before {
          opacity: 0 !important;
        }

        background-color: #ffffff;
      }
    }
  }

  .denominations-btn-box {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(18);

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 13px 20px;
        border-radius: 0;
      }
    }
  }
}

.dark-mode {
  .denominations-title {
    color: #ffffff;
  }

  .denominations-box {
    .denominations-key-wrap {
      border-color: #444444;
    }

    .denominations-keypad-box {
      border-color: #444444;
      background-color: #2b2c2e;

      .denominations-key {
        color: #ffffff;

        &:active {
          background-color: #000000;
        }
      }
    }
  }
}
