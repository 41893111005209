.generalModal.simple-select-time-modal {
  .generalModal-wrap {
    overflow: hidden;
    border-radius: 2.8rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  }

  .generalModal-innerWrap {
    box-shadow: none;
  }
}

@media all and (min-height: 900px) {
  .tag-modal-wrap,
  #driver-map-modal .modal-box,
  #payment-view-modal .modal-box,
  #guest-search-modal .modal-box,
  #deliver-order-modal .modal-box,
  #person-booking-modal .modal-box {
    max-height: 90%;
  }
  #new-booking-modal .modal-box {
    max-height: 98%;
  }

  #payment-view-modal {
    .modal-box {
      top: auto;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .tag-modal-wrap,
  .view-orders-style #tag-modal .tag-modal-wrap,
  .show-personal-booking .tag-modal-wrap,
  .modal-box {
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: rem(28);
  }

  .app-wrapper {
    .top .generalModal-wrap,
    .bottom .generalModal-wrap {
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2.8rem;

      .generalModal-innerWrap {
        border-radius: 2.8rem;
      }
    }

    .bottom .generalModal-wrap {
      .bottom .innerModal-wrap {
        border-radius: 0 0 rem(28) rem(28);

        .innerModal-innerWrap {
          border-radius: 0 0 rem(28) rem(28);
        }
      }

      .select-time-modal {
        .select-time-btn-box {
          overflow: hidden;
          border-radius: 0 0 rem(28) rem(28);
        }
      }
    }

    .move-effect.bottom.show .generalModal-wrap {
      transform: translate(-50%, -50%);
    }

    .move-effect.bottom.show.hide-anim .generalModal-wrap,
    .move-effect.bottom .generalModal-wrap {
      transform: translate(-50%, 150%);
    }

    .move-effect.top.show .generalModal-wrap {
      transform: translate(-50%, -50%);
    }

    .move-effect.top.show.hide-anim .generalModal-wrap,
    .move-effect.top .generalModal-wrap {
      transform: translate(-50%, -150%);
    }
  }
}

.full-screen-mode {
  .main-area-body-wrap.specials {
    height: calc(100% - 66px);
  }

  .show-keyboard .booking-modal,
  .show-keyboard .modal-overlay {
    height: calc(100% - 357px);
  }

  .open-mobile-keyboard {
    .message-modal-wrap {
      bottom: 0;
      border-radius: 16px 16px 0 0;
      transition: bottom 0s, transform 0s;
      transform: translate(-50%, 0);
    }

    &.is-chrome-ios {
      .message-modal-wrap {
        bottom: 56px;
        border-radius: 16px 16px 0 0;
        transform: translate(-50%, 0);
      }
    }
  }

  .view-order-style #sidebar-modal-print-bill {
    .sidebar-modal-box {
      width: 31.9%;

      &.split-bill {
        width: 198px;

        & + .sidebar-modal-box {
          width: 31.9%;
        }
      }
    }
  }

  #iPad-keyboard,
  .iPad-keyboard-overlay {
    top: calc(100vh - 357px);
  }

  #iPad-keyboard {
    max-width: 100%;

    .iPad-keyboard-inner-wrap {
      max-width: 1026px;
      margin: 0 auto;
      border: 1px solid #9e9e9e;
      border-top: none;
      border-bottom: none;
    }
  }
}
