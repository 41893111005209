.modal-table {
  width: 100%;
  text-align: left;
  color: var(--tableColor);

  &__head {
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: var(--shadowModalInner);
  }

  &__head-cell {
    padding: 8px 17px 12px;
    font-size: 18px;
    font-weight: $medium;
    line-height: normal;
    letter-spacing: -0.38px;
    color: var(--tableHeaderColor);
    background-color: var(--modaldBg);

    &:first-child {
      padding-left: 22px;
    }
    &:last-child {
      padding-right: 22px;
    }

    &--size {
      width: 70px;
      padding-left: 19px;
    }

    &--btn {
      width: 70px;
    }
  }

  &__body {
    position: relative;
    z-index: 1;
  }

  &__row {
    border-bottom: solid 1px var(--tableBorderColor);

    &:nth-child(odd) {
      background-color: var(--tableRowOddBg);
    }
    &:nth-child(even) {
      background-color: var(--tableRowEvenBg);
    }

    &:last-child {
      border-bottom: none;
    }

    &--disabled {
      * {
        color: var(--greyColor-200);
        background: none;
        border-color: transparent;
      }
    }
  }

  &__cell {
    padding: 15px 10px;

    &--size {
      width: 70px;
    }
    &--btn {
      width: 70px;
      text-align: center;
    }
    &:first-child {
      padding-left: 22px;
    }
    &:last-child {
      padding-right: 22px;
    }
  }
  &__cell-name {
    font-size: 18px;
    font-weight: $medium;
    line-height: normal;
    letter-spacing: -0.51px;
  }
}
