.guestCounterContainer {
  padding: 12px;
}

.guestCounterModalCloseIcon {
  display: flex;
  justify-content: flex-end;
}

.guestCounterHeader {
  display: flex;
}

.guestCounterHeaderText {
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 22px;
  flex: 1;
}

.counterContainer {
  padding-top: 15px;
}
