.markDeliveryOrderCompleteModalfooterContainer {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
}
.confirmButtonContainer {
  display: flex;
  justify-content: center;
}
.warningTextContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
