.bem-accordion {
  display: grid;
  gap: 18px 0;
  &__section {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    border-radius: 14px;
    background-color: var(--mainBG);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  }
  &__header {
    svg {
      transition: 0.15s;
      color: #888888;
    }
  }
  &__header-label {
    display: grid;
    gap: 0 10px;
    align-items: center;
    padding: 11px 14px 11px 11px;
    grid-template-columns: 24px 1fr 56px 29px;
    cursor: pointer;
  }
  &__title {
    font-size: 16.5px;
    font-weight: 400;
    letter-spacing: -0.05rem;
    line-height: var(--lineHeightTitle);
    color: var(--textColor);
    .bem-accordion__section--open & {
      font-weight: 500;
    }
  }
  &__content {
    height: auto;
    max-height: 0;
    transition: max-height 0.15s ease;
    .bem-accordion__section--open & {
      max-height: 500px;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    min-height: 48px;
    font-weight: 400;
    font-size: 14.5px;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.025rem;
    padding: 10px 12px;
    border-top: 1px solid var(--greyColorLight);
    color: var(--textColor);

    .bem-accordion__section--disabled & {
      color: #979797;
    }
  }
  &__checkbox {
    position: relative;
    appearance: none;
    width: 24px;
    height: 24px;
    background-color: var(--checkboxBg);
    border-radius: 50rem;
    border: none;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);

    &--checked,
    &:checked {
      background-image: var(--checkboxIcon);
      box-shadow: inset 0 0 4px 0 rgba(99, 99, 99, 0.5);
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 50% 6px;
      background-color: var(--greenColor);
    }

    &--disabled,
    &:disabled {
      background-color: var(--checkboxCheckedBg);
    }
  }
}
