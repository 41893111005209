.table-view-main {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  justify-content: space-between;
  height: 100%;
  height: calc(100% - 53px);
  background-color: #ffffff;
}
