#new-booking-modal {
  .modal-box {
    width: 836px;
    height: 639px;
  }

  .modal-title {
    font-size: 25px;
    letter-spacing: -0.02em;
  }

  .modal-header {
    padding: 18px 23px 18px;
    box-shadow: 1px 4px 9px -7px rgba(0, 0, 0, 0.5);
  }

  .modal-body {
    height: 495px;

    .modal-body-inner {
      padding: 0;
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    left: auto;
    right: 18px;
  }

  .modal-btn-box {
    a.btn,
    .btn {
      letter-spacing: 0;
    }
  }
}

.modal-sliders-wrap {
  position: relative;
  height: 505px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 26%,
    rgba(240, 240, 240, 1) 26%,
    rgba(240, 240, 240, 1) 50%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 76%,
    rgba(240, 240, 240, 1) 76%,
    rgba(240, 240, 240, 1) 100%
  );

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 86px;
    margin-top: -6px;
    background-color: #0684ff;
    transform: translate(0, -50%);
  }

  .swiper-grid-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 78px;
      border: 1px solid rgba(86, 86, 86, 0.3);
      border-left: none;
      border-right: none;
      transform: translate(0, -50%);
    }

    &:before {
      top: 87px;
    }

    &:after {
      top: 410px;
    }
  }

  .swiper-grid-shadow {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    pointer-events: none;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      left: 0;
      width: 100%;
      height: 48px;
      border-left: none;
      border-right: none;
      background-color: #ffffff;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .swiper-container-vertical > .swiper-scrollbar {
    display: none;
  }

  .swiper-container {
    position: relative;
    z-index: 5;
    top: -30px;
    float: left;
    width: 25%;
    height: 546px;
    background-color: transparent;

    &.slider-new-booking {
      &--day {
        width: 26%;

        .swiper-slide {
          padding: 5px 26px;
        }
      }

      &--guest {
        width: 24%;

        .swiper-slide {
          padding: 5px 20px;
        }
      }

      &--time {
        width: 26%;

        .swiper-slide {
          padding: 5px 23px;
        }
      }

      &--place {
        width: 24%;

        .swiper-slide {
          padding: 5px 18px;
        }
      }
    }
  }

  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 5px 20px;
    font-size: 18px;
    color: #363636;
    transition: 0s;
    opacity: 0.6;

    span {
      font-weight: $medium;
    }

    .red {
      color: #e02020;
    }

    .green {
      color: #5eb602;
    }

    &-active {
      color: #ffffff;
      height: 86px !important;
      font-weight: $medium;
      opacity: 1;

      .red,
      .green {
        color: #ffffff;
      }
    }

    &-prev,
    &-next {
      opacity: 0.8;
    }
  }
}
