.drivers-map-max-box {
  .drivers-map-max-header {
    position: relative;
    z-index: 3;
    padding: 27px 75px 23px;
    box-shadow: 6px -4.6px 9px 0 rgba(0, 0, 0, 0.5);
  }

  .drivers-map-max-modal-title {
    margin-bottom: rem(14);
    font-size: rem(19);
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    word-spacing: 0.02em;

    &.red span {
      font-weight: 500;
      color: #e02020;
    }
  }

  .drivers-map-max-body {
    position: relative;
    z-index: 1;
    padding: 0;
    height: 632px;
    background-color: #f7f7f7;
  }

  .driver-map-max-wrap {
    overflow: hidden;
    position: relative;

    iframe {
      position: relative;
      top: -80px;
      left: -2px;
    }
  }
}
