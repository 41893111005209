.sidebar-modal,
.sidebar-modal-overlay {
  position: absolute;
  z-index: 12;
  top: 0;
  left: -100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0s linear 0.35s, left 0.35s;
}

.sidebar-modal.show,
.show .sidebar-modal-overlay {
  opacity: 1;
  left: 0;
  transition: opacity 0s linear 0s, left 0.35s;
}

.sidebar-modal-overlay {
  z-index: 10;
  top: 0;
  background-color: transparent;
}

.sidebar-modal-box {
  overflow: hidden;
  position: absolute;
  z-index: 20;
  left: 0;
  bottom: 0;
  width: 327px;
  height: calc(100% - 74px);
  background-color: #ffffff;

  .guests-count-modal & {
    .sidebar-modal-body {
      height: calc(100% - 202px);
    }
  }

  &:before {
    position: absolute;
    z-index: 20;
    content: '';
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    box-shadow: 0 -4px 9px rgba(0, 0, 0, 0.5);
  }

  .ps__rail-y {
    z-index: 5;
  }

  .sidebar-modal-header {
    position: relative;
    z-index: 15;
    padding: 66px 24px 24px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(86, 86, 86, 0.06);

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }

    &.shadow {
      border-bottom: none;
      box-shadow: 0 -3.6px 9px 0 rgba(0, 0, 0, 0.5);

      &:before {
        content: none;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .sidebar-modal-body {
    height: 100%;

    #sidebar-modal-waitlist & {
      height: calc(100% - 240px);
    }

    #sidebar-modal-available-table & {
      height: calc(100% - 106px);
    }
  }

  .sidebar-modal-footer {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 12px;
    background-color: #ffffff;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      box-shadow: 1px 4px 9px -7px rgba(0, 0, 0, 0.5);
    }
  }

  .sidebar-modal-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      margin: 0 auto;

      .inner-btm-box {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }

  a.close-modal-btn,
  .close-modal-btn,
  a.back-modal-btn,
  .back-modal-btn {
    position: absolute;
    top: 13px;
    left: 13px;
  }

  a.back-modal-btn,
  .back-modal-btn {
    left: 9px;
  }

  .sidebar-modal-title {
    @include font($medium, rem(25), 1.5, $font-main);
    letter-spacing: 0.01em;
  }

  .scheduled-dropdown {
    position: absolute;
    top: 17px;
    right: 9px;
    padding-right: 40px;
    font-size: 16px;
  }

  .guest-quantity-box {
    height: 100%;
  }

  &.wait-time {
    .guest-quantity-box {
      height: 100%;
    }
  }

  .guest-quantity-list {
  }

  .guest-quantity-single-wrap {
    position: relative;
    display: block;

    input {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:checked + .guest-quantity-single-box {
        color: #ffffff;
        background-color: #0684ff;

        &:before {
          content: none;
        }

        &:after {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .guest-quantity-single-box {
      position: relative;
      z-index: 5;
      display: flex;
      height: 86px;
      padding: 10px 70px 10px 27px;
      align-items: center;
      justify-content: left;
      @include font($normal, rem(18), $line-height-heading-2, $font-main);
      background-color: #ffffff;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.2;
        border: 1px solid rgba(86, 86, 86, 0.3);
      }

      &:after {
        visibility: hidden;
        position: absolute;
        content: '\e922';
        top: 50%;
        color: #ffffff;
        right: 28px;
        @include icomoon();
        font-size: 23px;
        transform: translate(0, -50%);
        opacity: 0;
      }

      .time {
        text-align: right;
      }
    }
  }

  .available-table-box {
    height: 100%;

    .table-select-nav {
      padding-bottom: 26px;
    }
  }

  .table-select-nav {
    padding-top: 18px;
  }

  &.wait-time {
    .guest-quantity-single-wrap {
      .guest-quantity-single-box {
        padding-right: 27px;
        padding-left: 29px;
        height: 79px;
        justify-content: space-between;

        &:after {
          content: none;
        }
      }

      input {
        &:checked + .guest-quantity-single-box {
          height: 86px;
        }
      }
    }
  }

  &.available-table {
    .sidebar-modal-header {
      padding-bottom: 4px;
      border: none;
    }

    .single-detail-box {
      padding-top: 13px;

      .name {
        font-weight: $normal;
      }

      .desc {
        font-weight: $medium;
      }

      .detail-row {
        .detail-cell {
          vertical-align: middle;
        }

        &.row-1 {
          margin-bottom: 10px;
        }
      }

      .quantity-box {
        top: auto;
      }
    }

    .active {
      .single-detail-box {
        border: 1px solid #7aac4a;
      }
    }
  }
}
