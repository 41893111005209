@function rem($pixels) {
  @return #{$pixels/10}rem;
}

/*fonts*/
$font-main: 'HelveticaNowText', sans-serif;
$font-heading: 'HelveticaNowDisplay', sans-serif;

/*fonts-weight*/
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

/*fonts-size*/
$size-html: 10px;
$size-main: rem(16);

$size-btn: rem(18);

/*line-height*/
$line-height: 1.6;
$line-height-heading: 1;
$line-height-heading-2: 1.25;

/*colors*/
$letter-spacing-20: 0.02em;

/*colors*/
//START DEPRECATED colors - please do NOT remove / add / update these colors
//actual colors located in _default-theme.scss please use this file as a reference
$color-white: #ffffff;
$color-black: #000000;
$color-text: #3a3a3a;
$color_blue: #0a84ff;
$color-grey: #9497a1;
$color-main: #363636;
$color-bg: #3a3a3a;
$color-bg-grey: #838383;
$color-bg-blue: #0a84ff;
$color-dark-bg: #1c1c1e;

$pos-white: 'white';
$pos-blue: #067aff;
$pos-green: var(--greenColor);
$pos-dark-grey: #2b2b2b;
$pos-error: red;
//END DEPRECATED colors

/*shadow*/
$default-box-shadow: rem(0.195) rem(0.638) rem(5.88) rem(0.12)
  rgba(0, 0, 0, 0.09);

/*transition*/
$transition-link: 0.2s;
$transition-other: 0.35s;

/*border-radius*/
$radius-main: 6px;

/*breakpoints*/
$start-hover: 'min-width: 769px';
$mobile-small: 'max-width: 380px';
$mobile: 'max-width: 767px';
$tablet-small: 'max-width: 910px';
$tablet: 'max-width: 1024px';
$laptop: 'max-width: 1180px';

:export {
  posPrimaryColor: var(--primaryColor);
  posSecondaryColor: var(--secondaryColor);
  posSuccessColor: var(--successColor);
  posWarningColor: var(--warningColor);
  posDangerColor: var(--dangerColor);
  posBlue: var(--blueColor);
  posRed: var(--redColor);
  posGreen: var(--greenColor);
  posOrange: var(--orangeColor);
  posPurple: var(--purpleColor);
  posBlueLight: var(--blueColorLight);
  posGreenLight: var(--greenColorLight);
  posOrangeLight: var(--orangeColorLight);
  posRedLight: var(--redColorLight);
  posPurpleLight: var(--purpleColorLight);
}
