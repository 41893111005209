#deliver-order-modal {
  transition: opacity 0s;

  .payment-view-modal.show,
  .show .modal-overlay {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.35s;
  }

  &.slideDown {
    opacity: 0;
    transition: opacity 0s linear 0.71s;

    .modal-box {
      bottom: -700px;
      transition: bottom 0.7s;
    }

    .modal-overlay {
      opacity: 0;
      transition: opacity 0.35s linear 0.35s;
    }
  }

  .modal-box {
    width: 638px;
    height: 698px;
    color: #363636;
  }

  .deliver-order-actions-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 698px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.35s ease-in-out, z-index 0s linear 0.35s;

    .deliver-order-actions-overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.09;
      border-radius: 14.5px;
      background-color: #1c1c1c;
    }

    .deliver-order-actions-box {
      overflow: hidden;
      position: absolute;
      z-index: 5;
      top: 22px;
      right: 22px;
      width: 250px;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
      border: solid 0.6px rgba(0, 0, 0, 0.04);
    }

    .deliver-order-actions {
      > li {
        display: block;
        border-bottom: 0.6px solid rgba(60, 60, 67, 0.072);

        &:last-child {
          border: none;
        }

        a,
        button {
          position: relative;
          display: block;
          width: 100%;
          padding: 16.5px 50px 16px 17px;
          font-size: 16.6px;
          font-weight: 400;
          letter-spacing: -0.01em;
          text-align: left;
          line-height: 1.25;
          color: #363636;

          .icon-box {
            position: absolute;
            top: 50%;
            right: 3px;
            display: block;
            width: 42px;
            height: 42px;
            color: #494b4c;
            transform-origin: center;
            transform: translate(0, -50%);

            [class^='dine_icon-'],
            [class*=' dine_icon-'] {
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 20px;
              line-height: 1;
              transform-origin: center;
              transform: translate(-50%, -50%);
            }

            .dine_icon-order-price {
              font-size: 22px;
              margin-left: 1px;
            }

            .dine_icon-item-sold {
              font-size: 24px;
            }

            .dine_icon-close_light1 {
              margin-left: -1px;
              font-weight: 700;
            }
          }

          &.red {
            color: #e12b2b;

            [class^='dine_icon-'],
            [class*=' dine_icon-'] {
              color: #e12b2b;
            }
          }
        }
      }
    }
  }

  &.show-action-modal {
    .deliver-order-actions-wrap {
      z-index: 25;
      opacity: 1;
      transition: opacity 0.35s ease-in-out, z-index 0s linear 0s;
    }

    .modal-header {
      z-index: 10;
      transition: z-index 0s linear 0s;
    }
  }

  .modal-header {
    position: relative;
    z-index: 1;
    padding: 24px 26px 17px;
    transition: z-index 0s linear 0.35s;
  }

  .modal-title {
    font-size: 25px;
  }

  .guest-location-row {
    position: relative;
    z-index: 3;
    margin-bottom: 19px;
    padding: 0 5px;
  }

  .order-status-row {
    position: relative;
    z-index: 7;

    .order-status-box {
      width: 50%;

      .icon-box {
        .dine_icon-shape_man {
          font-size: 26px;
          margin-top: 1px;
        }
      }

      .desc {
        margin-bottom: -1px;
      }
    }

    > :first-child {
      padding-left: 18px;
    }
  }

  .order-actions-btn-box {
    .order-total-paid-box {
      padding: 0;
      width: 100%;

      .paycard-box {
        width: 49px;
        height: 34px;

        &.apple {
          img {
            max-width: 36px;
          }
        }
      }

      .price-wrap {
        .price-desc {
          margin-bottom: 4px;
        }
      }
    }
  }

  .modal-body {
    z-index: 2;
    padding: 0;
    height: calc(100% - 314px);
    background-color: #f7f7f7;

    .seats-list-wrap {
      height: 100%;

      .seat-items-wrap {
        .single-item {
          border-bottom: 2px solid #f2f2f2;

          &:after {
            content: none;
          }

          .single-item-table {
            height: 64px;
            padding: 7px 20px 7px 20px;
          }

          .single-item-cell {
            &.info-cell {
              padding-left: 0;
            }

            &.price-cell {
              padding-right: 0;
            }
          }

          .single-title {
            margin-bottom: 4px;
            font-size: 17px;
            color: #363636;
          }

          .single-actions {
            a,
            button,
            span {
              max-width: 545px;
              font-size: 14px;
            }
          }

          .single-body {
            > :last-child {
              margin-bottom: 0;
            }
          }
        }

        > :nth-child(even) {
          background-color: #ffffff;
          border-bottom: none;
        }
      }
    }
  }

  a.btn-action,
  .btn-action {
    font-size: 20px;
    letter-spacing: -0.02em;

    .inner-btn-box {
      min-height: 59px;
      padding: 10px 20px;
    }

    &.change {
      text-align: left;

      .inner-btn-box {
        padding: 10px 10px 10px 68px;
      }

      .icon-box {
        position: absolute;
        top: 50%;
        left: 18px;
        width: 42px;
        height: 42px;
        transform-origin: center;
        transform: translate(0, -50%);

        [class^='dine_icon-'],
        [class*=' dine_icon-'] {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 31px;
          margin-top: -1px;
          transform-origin: center;
          transform: translate(-50%, -50%);
        }
      }

      .text {
        display: inline-block;
        margin-bottom: -5px;
        font-size: 16.6px;
        font-weight: 400;
        letter-spacing: -0.01em;
        vertical-align: bottom;
      }

      .simulate-btn {
        display: inline-block;
        margin-left: 30px;
        margin-bottom: -6px;
        padding: 4px 10px;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        color: #067aff;
        background-color: #ffffff;
        border-radius: 50rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
        border: solid 0.63px rgba(0, 0, 0, 0.04);
        vertical-align: bottom;
      }
    }

    &.pay {
      .inner-btn-box {
        padding: 10px 21px 10px 17px;
      }
    }

    &.width-100 {
      .inner-btn-box {
        padding: 20px;
      }
    }
  }

  .modal-footer {
    z-index: 6;

    &:before {
      height: 47px;
      box-shadow: 0 -3.6px 9px -1px rgba(0, 0, 0, 0.5);
    }
  }
}

a.modal-delivery-action-btn,
.modal-delivery-action-btn {
  position: absolute !important;
  z-index: 5;
  top: 22px;
  right: 22px;
  display: inline-block;
  font-size: 0;
  width: 32px;
  height: 32px;
  background-image: url(../../../assets/img/icon/circles_white.svg);
  background-size: 22px 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #067aff;
  border-radius: 20rem;

  &:before {
    border-radius: 50rem !important;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ffffff;

    &:after,
    &:before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      top: 0;
      left: 50%;
      border-radius: 50%;
      transform-origin: center;
      transform: translate(-50%, 0);
      background-color: #ffffff;
    }

    &:after {
      margin-left: -8px;
    }

    &:before {
      margin-left: 8px;
    }
  }
}
