.table-view-wrap {
  .floor-switch-box {
    position: relative;
    display: inline-block;
    padding: 2px 1px;
    font-size: 0;
    border-radius: 16px;
    background-color: #eeeeef;

    &:after {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      height: 28px;
      width: 117px;
      left: 3px;
      border-radius: 14px;
      background-color: #ffffff;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
      transition: left 0.35s;
    }

    &.active-2:after {
      left: 114px;
    }

    a,
    button {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 115px;
      padding: 5px 10px;
      margin: 0;
      font-family: $font-main;
      font-weight: $normal;
      font-size: 15px;
      letter-spacing: -0.02em;
      color: #363636;
      line-height: 1.2;
      border-radius: 16px;
      border: none;
      background-color: transparent;
      box-shadow: none;

      &:before {
        border-radius: 16px;
      }
    }

    & + .waiters-btn {
      position: absolute;
      top: 50%;
      right: 7.3%;
      transform: translate(0, -50%);
    }
  }

  a.waiters-btn,
  .waiters-btn {
    font-size: 15px;

    .inner-btn-box {
      padding: 6px 11px 6px 8px;
    }

    .dine_icon-waiters {
      padding-right: 5px;
      font-size: 19px;
      line-height: 14px;
    }
  }
}

a.table-wrap,
.table-wrap {
  position: relative;
  display: inline-block;
  padding: 10px;
  font-size: 0;
  transform-origin: 50% 50%;
  text-decoration: none;
  backface-visibility: hidden;

  & * {
    will-change: transform;
  }

  &:before {
    content: none;
  }

  &.active {
    .table-overlay {
      visibility: visible;
      opacity: 0.91;
    }
  }

  .table-overlay {
    z-index: 10;
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-color: rgba(247, 181, 0, 0.05);
    border: 2.5px solid #fffc49;
    transform: translate(-50%, -50%);
    border-radius: 18px;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  &.available {
    .table-overlay {
      visibility: visible;
      background-color: rgba(94, 182, 2, 0.1);
      border-color: #5eb602;
      opacity: 0.91;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    &:checked + .table-overlay {
      visibility: visible;
      opacity: 0.91;
    }
  }

  .table-box {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #646464;
    font-size: 17px;
    font-weight: $medium;
    line-height: 1;
    box-shadow: 0 0 2.5px 0.5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background-color: #ffffff;

    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      transform-origin: 50% 50%;
      transform: translate(-50%, -50%);
    }

    > * {
      position: relative;
      z-index: 3;
      transform-origin: 50% 50%;
    }

    .number {
      position: relative;
      display: block;
      width: 20px;
      text-align: center;
      margin-bottom: 3px;
    }

    .progress-bar {
      overflow: hidden;
      position: relative;
      display: block;
      margin-bottom: 2px;
      width: 38px;
      height: 3px;
      font-size: 0;
      background-color: #c8c8c8;
      border-radius: 2.5px;

      span {
        position: absolute;
        z-index: 5;
        display: block;
        top: 0;
        left: 0;
        height: 3px;
        background-color: #ffffff;
      }
    }

    .table-icon-box {
      position: relative;
      display: block;
      width: 30px;
      height: 20px;
      color: #ffffff;

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 17px;
        transform: translate(-50%, -50%);
      }

      .dine_icon-cash {
        font-size: 17px;
      }

      .dine_icon-sprayer {
        font-size: 18px;
      }

      .dine_icon-silverware {
        font-size: 13px;
      }

      .dine_icon-people {
        font-size: 15px;
      }

      .dine_icon-dish {
        font-size: 14px;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .table-single-seat {
    position: absolute;
    z-index: 1;
    width: 28px;
    height: 18px;
    border-radius: 7px;
    background-color: #d8d8d8;
    opacity: 0.76;
    transform-origin: 50% 50%;
  }

  &.round {
    width: 128px;
    height: 128px;

    .table-overlay {
      border-radius: 50%;
    }

    .table-box {
      width: 108px;
      height: 108px;
      border-radius: 50%;

      &:after {
        border-radius: 50%;
      }

      .number {
        margin-bottom: 8px;
      }

      .progress-bar {
        margin-bottom: 5px;
      }
    }

    .table-single-seat {
      transform-origin: 50% 50%;

      &.seat-1 {
        top: 5px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &.seat-2 {
        top: 20px;
        right: 13px;
        transform: translate(0, 0) rotate(45deg);
      }

      &.seat-3 {
        top: 50%;
        right: -1px;
        transform: translate(0, -50%) rotate(90deg);
      }

      &.seat-4 {
        bottom: 19px;
        right: 13px;
        transform: translate(0, 0) rotate(130deg);
      }

      &.seat-5 {
        bottom: 3px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &.seat-6 {
        bottom: 19px;
        left: 12px;
        transform: translate(0, 0) rotate(225deg);
      }

      &.seat-7 {
        top: 50%;
        left: -1px;
        transform: translate(0, -50%) rotate(90deg);
      }

      &.seat-8 {
        top: 20px;
        left: 13px;
        transform: translate(0, 0) rotate(310deg);
      }
    }
  }

  &.square {
    width: 74px;
    height: 74px;

    .table-box {
      width: 54px;
      height: 54px;
    }

    .table-single-seat {
      transform: none;

      &.seat-1 {
        top: 1px;
        left: 23px;
      }

      &.seat-2 {
        right: -3px;
        top: 28px;
        transform: rotate(90deg);
      }

      &.seat-3 {
        bottom: 1px;
        left: 23px;
      }

      &.seat-4 {
        left: -3px;
        top: 28px;
        transform: rotate(90deg);
      }
    }

    &.big-size {
      width: 100px;
      height: 100px;
      margin-left: 19px;
      transform: rotate(45deg);

      .table-box {
        width: 80px;
        height: 80px;

        &:after {
          transform: translate(-50%, -50%);
        }

        .number {
          position: relative;
          left: -3px;
          top: 1px;
          margin-bottom: 3px;
          transform: translate(-50%, 0) rotate(-45deg);
          transition: 0s;

          &:only-child {
            display: block;
            width: 10px;
            height: 10px;
          }
        }

        .table-icon-box {
          transform: translate(50%, 0) rotate(-45deg);

          [class^='dine_icon-'],
          [class*=' dine_icon-'] {
            position: relative;
            top: -4px;
            left: 3px;
          }
        }

        border-radius: 13px;
      }

      .table-single-seat {
        transform: none;

        &.seat-1 {
          top: 0;
          left: 36px;
        }

        &.seat-2 {
          right: -5px;
          top: 40px;
          transform: rotate(90deg);
        }

        &.seat-3 {
          bottom: 0;
          left: 36px;
        }

        &.seat-4 {
          left: -5px;
          top: 41px;
          transform: rotate(90deg);
        }
      }
    }
  }

  &.rectangular {
    .table-box {
      width: 98px;
      height: 54px;
      border-radius: 12px;
      background-color: #ffffff;

      &:after {
        border-radius: 12px;
      }

      .number {
        top: -2px;
      }
    }

    &.seats-2 {
      width: 74px;
      height: 68px;

      .table-overlay {
        height: calc(100% + 10px);
      }

      .table-box {
        width: 54px;
        height: 48px;
        border-radius: 10px;

        &:after {
          border-radius: 10px;
        }
      }

      .table-single-seat {
        &.seat-1 {
          top: 25px;
          right: -3px;
          transform: rotate(90deg);
        }

        &.seat-2 {
          top: 25px;
          left: -3px;
          transform: rotate(90deg);
        }
      }
    }

    &.seats-6 {
      width: 118px;
      height: 74px;

      .table-single-seat {
        &.seat-1 {
          top: 1px;
          left: 22px;
        }

        &.seat-2 {
          top: 1px;
          left: 67px;
        }

        &.seat-3 {
          top: 28px;
          right: -3px;
          transform: rotate(90deg);
        }

        &.seat-4 {
          bottom: 1px;
          left: 67px;
        }

        &.seat-5 {
          bottom: 1px;
          left: 22px;
        }

        &.seat-6 {
          top: 28px;
          left: -3px;
          transform: rotate(90deg);
        }
      }
    }
  }

  &.green {
    .table-box {
      color: #ffffff;
      background-color: #5eb602;
    }
  }

  &.blue {
    .table-box {
      color: #ffffff;
      background-color: #0386f6;
    }
  }

  &.orange {
    .table-box {
      color: #ffffff;
      background-color: #fd6c00;
    }
  }

  &.yellow {
    .table-box {
      color: #ffffff;
      background-color: #f7b500;
    }
  }

  &.purple {
    .table-box {
      color: #ffffff;
      background-color: #b620e0;
    }
  }

  &.grey {
    .table-box {
      color: #ffffff;
      background-color: #6d7278;
    }
  }

  &.pink {
    .table-box {
      color: #ffffff;
      background-color: #eb4469;
    }
  }
}

.plant-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 10px;
  font-size: 0;

  .oval {
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid #979797;
    background-color: #d8d8d8;

    &:after {
      position: absolute;
      z-index: 5;
      content: '';
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #55a501;
      transform: translate(-50%, -50%);
    }
  }

  .leaf {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 18px;
    margin-top: 7px;
    margin-left: -4px;
    border-radius: 4px;
    background-color: rgba(109, 212, 0, 0.62);
    transform-origin: 0px -3px;

    &.darkest {
      z-index: 3;
      background-color: rgba(85, 165, 1, 0.6);
    }
  }

  .leaf-1 {
    transform: translate(4px, -4px) rotate(0);
  }

  .leaf-2 {
    transform: translate(4px, -4px) rotate(20deg);
  }

  .leaf-3 {
    transform: translate(4px, -4px) rotate(40deg);
  }

  .leaf-4 {
    transform: translate(4px, -4px) rotate(60deg);
  }

  .leaf-5 {
    transform: translate(4px, -4px) rotate(80deg);
  }

  .leaf-6 {
    transform: translate(4px, -4px) rotate(100deg);
  }

  .leaf-7 {
    transform: translate(4px, -4px) rotate(120deg);
  }

  .leaf-8 {
    transform: translate(4px, -4px) rotate(140deg);
  }

  .leaf-9 {
    transform: translate(4px, -4px) rotate(160deg);
  }

  .leaf-10 {
    transform: translate(4px, -4px) rotate(180deg);
  }

  .leaf-11 {
    transform: translate(4px, -4px) rotate(200deg);
  }

  .leaf-12 {
    transform: translate(4px, -4px) rotate(220deg);
  }

  .leaf-13 {
    transform: translate(4px, -4px) rotate(240deg);
  }

  .leaf-14 {
    transform: translate(4px, -4px) rotate(260deg);
  }

  .leaf-15 {
    transform: translate(4px, -4px) rotate(280deg);
  }

  .leaf-16 {
    transform: translate(4px, -4px) rotate(300deg);
  }

  .leaf-17 {
    transform: translate(4px, -4px) rotate(320deg);
  }

  .leaf-18 {
    transform: translate(4px, -4px) rotate(340deg);
  }
}

.wall-wrap {
  width: 76.8%;

  .wall-box {
    width: 100%;
    height: 13px;
    border-radius: 5px 0 0 5px;
    background-color: #d8d8d8;
    opacity: 0.27;
  }
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .inner-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &.row-1 {
    height: 21.2%;

    .inner-box {
      width: 89.2%;
      margin-right: 5.3%;
    }
  }

  &.row-2 {
    height: 8%;

    .inner-box {
      width: 72.5%;
      margin-right: 4.9%;
    }
  }

  &.row-3 {
    height: 3%;

    .inner-box {
      justify-content: flex-end;
    }
  }

  &.row-4 {
    height: 11%;

    .inner-box {
      width: 71.2%;
      margin-right: 5.3%;
    }
  }

  &.row-5 {
    height: 27%;

    .inner-box {
      width: 71.5%;
      margin-right: 5.3%;
    }
  }

  &.row-6 {
    height: 19%;

    .inner-box {
      width: 69.5%;
      margin-right: 7.3%;
    }
  }

  &.nav-row {
    height: 9.8%;

    .inner-box {
      position: relative;
      width: 100%;
      justify-content: center;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

a.scheduled-dropdown,
.scheduled-dropdown {
  position: relative;
  padding: 6px 35px 6px 6px;
  @include font($normal, rem(16), $line-height-heading-2, $font-main);
  color: #363636;
  text-decoration: none;
  border: none;
  background-color: transparent;
  appearance: none;
  box-shadow: none;

  .initials {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-top: -3px;
    margin-right: 9px;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #fd6c00;
    border-radius: 50%;
    vertical-align: middle;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      line-height: 1.25;
      transform: translate(-50%, -50%);
    }
  }

  &.orange {
    .initials {
      background-color: #fd6c00;
    }
  }

  &.purple {
    .initials {
      background-color: #b620e0;
    }
  }

  &.blue {
    .initials {
      background-color: #0686f6;
    }
  }

  &.pink {
    .initials {
      background-color: #eb4469;
    }
  }

  &.yellow {
    .initials {
      background-color: #f7b500;
    }
  }

  &.green {
    .initials {
      background-color: #5eb602;
    }
  }

  &.grey {
    .initials {
      background-color: #6d7278;
    }
  }

  .pos_icon-down-chevron {
    position: absolute;
    top: 50%;
    right: 17px;
    width: 17px;
    height: 11px;
    transform-origin: center;
    transform: translate(50%, -50%);
  }

  &.with-initials {
    padding-right: 38px;
    font-size: 15px;
  }

  &.station {
    padding-left: 40px;
    padding-right: 46px;
  }
}

.nav-bar-header {
  position: relative;
  padding: 11px 15px 15px;

  .top-box {
    .options-holder {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 170px;
      min-height: 80px;
      position: absolute;
      z-index: 5;
      top: 50px;
      right: 15px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

      .list-items {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .item {
          min-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          &.selected {
            background: #d9d5d5;
          }
        }
      }
    }
  }

  .nav-bar-title {
    position: relative;
    z-index: 1;
    margin-left: 3px;
  }

  .scheduled-dropdown {
    position: absolute;
    z-index: 3;
    top: 12px;
    right: 10px;

    &.with-initials {
      top: 15px;
      right: 9px;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.nav-bar-details {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
  height: calc(100% - 158px);
  background-color: #ffffff;

  .table-box-style & {
    height: 100%;
    height: calc(100% - 321px);
  }

  .tv-action-scroll & {
    height: 100%;
    height: calc(100% - 353px);
    overflow: auto;
  }
}

.nav-bar-search-box {
  position: relative;
  margin-bottom: 20px;

  &:before {
    position: absolute;
    content: '\e90a';
    top: 50%;
    left: 10px;
    @include icomoon();
    font-size: 16px;
    color: #979797;
    transform: translate(0, -50%);
  }

  .input-style {
    padding-left: 34px;
    font-size: rem(17.2);
    letter-spacing: -0.03em;
  }

  .input-style::-webkit-input-placeholder {
    @include font($normal, rem(17.2), rem(30), $font-main);
    color: #979797;
  }

  .input-style::-moz-placeholder {
    @include font($normal, rem(17.2), rem(30), $font-main);
    color: #979797;
  }

  .input-style:-ms-input-placeholder {
    @include font($normal, rem(17.2), rem(30), $font-main);
    color: #979797;
  }

  .input-style:-moz-placeholder {
    @include font($normal, rem(17.2), rem(30), $font-main);
    color: #979797;
  }
}

.details-list {
  > div {
    display: block;
    cursor: pointer;

    &.even-index-child {
      background-color: #f7f7f7;
      border: 1px solid #e9e9e9;
      border-color: rgba(#e9e9e9, 0.71);
      border-right: none;
      border-left: none;
    }

    a {
      text-decoration: none;
    }

    &.active {
      background-color: #067aff;

      .single-detail-box {
        color: #ffffff;

        .text-status.red,
        .text-status.green,
        .text-status.orange,
        .text-status.blue,
        .due-time.bad,
        .due-time.good,
        .completed-status,
        .date-time .date,
        .name {
          color: #ffffff;
        }
      }
    }
  }
}

.booking-count-box {
  margin-right: 20px;

  [class^='dine_icon-'],
  [class*=' dine_icon-'] {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    margin-right: 10px;
    margin-top: 3px;
  }

  .dine_icon-cash {
    font-size: 24px;
    margin-right: 16px;
    margin-top: 0;
  }

  .dine_icon-peoples {
    font-size: 20px;
    margin-right: 16px;
    margin-top: 2px;
  }

  .dine_icon-credit-card {
    font-size: 18px;
    margin-right: 16px;
    margin-top: 2px;
  }

  .dine_icon-cigarette_new {
    font-size: 16px;
    margin-right: 2px;
    margin-top: 0;
  }

  .dine_icon-table {
    margin-top: 4px;
    margin-right: 16px;
    font-size: 18px;
  }

  .dine_icon-cigarette {
    font-size: 25px;
    margin-top: 0;
    margin-right: 2px;
    line-height: 18px;
  }

  [class^='pos_icon-'],
  [class*=' pos_icon-'] {
    margin-top: 3px;
    margin-right: 0;
    height: 20px;
  }

  .pos_icon-peoples {
    height: 20px;
  }

  .count {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    font-size: 15px;
    margin-right: 3px;
    letter-spacing: 0;

    &.unpaid {
      color: #e02020;
      font-weight: 500;
    }
  }

  &.table {
    .count {
      width: 25px;
    }
  }
}

.onshift-text {
  letter-spacing: -0.01em;

  span {
    font-weight: 500;
  }
}

.onshift-text span,
.text-status {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: $medium;

  &.orange {
    color: #fd6c00;
  }

  &.red {
    color: #e02020;
  }

  &.green {
    color: #5eb602;
  }

  &.blue {
    color: #0386f6;
  }

  &.purple {
    color: #b620e0;
  }

  &.grey {
    font-weight: 400;
    color: #757575;
  }
}

.single-detail-box {
  display: block;
  width: 100%;
  padding: 10px 19px 12px;
  font-size: 16px;
  @include font($normal, rem(16), $line-height, $font-main);
  text-align: left;
  border: none;
  appearance: none;
  box-shadow: none;
  background-color: transparent;
  text-decoration: none;
  color: #363636;

  .detail-row {
    display: table;
    width: 100%;

    .detail-cell {
      display: table-cell;
      vertical-align: top;

      &:nth-child(2) {
        text-align: right;
      }
    }

    &.row-1 {
      margin-bottom: 7px;
    }

    &.row-2 {
    }
  }

  .name {
    font-size: 18px;
    font-weight: $normal;
    color: #363636;
    letter-spacing: -0.04em;
    text-transform: capitalize;

    .status-btn {
      position: relative;
      top: -1px;
      margin-left: 8px;
    }
  }

  .quantity-box {
    position: relative;
    top: -1px;

    & > :last-child {
      margin-right: 0;
    }
  }

  .date-time {
    .time {
      font-weight: 500;
    }

    .date {
      color: #717374;
      letter-spacing: 0.02em;
    }

    &.wait-style {
      .date {
        letter-spacing: 0;
      }
    }

    .red {
      color: #e02020;
    }
  }

  .completed-status {
    font-weight: $medium;
    color: #0386f6;
  }

  .due-time {
    font-weight: $medium;

    &.good {
      color: #fd6c00;
    }

    &.bad {
      color: #e02020;
    }
  }

  .status-box {
    display: block;
    margin-top: 2px;
    margin-bottom: -10px;
  }

  .status-btn {
    display: inline-block;
    color: #ffffff;
    height: 20px;
    font-size: 13px;
    font-weight: $medium;
    line-height: 1.35;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 2px 6px;
    margin: 0 0 10px 11px;
    border-radius: 3px;
    vertical-align: top;

    &:first-child {
      margin-left: 0;
    }

    &.table-id {
      background-color: #fd6c00;
    }

    &.delivery-id {
      background-color: #5eb602;
    }

    &.pickup-id {
      background-color: #0386f6;
    }
    &.drive-thru-id {
      background-color: #0386f6;
    }
    &.refunded {
      background-color: #f40000;
    }

    &.vip {
      position: relative;
      top: 5px;
      margin: 0 0 0 12px;
      width: 30px;
      height: 20px;
      padding: 5px 5px;
      line-height: 1;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      background-color: #e77133;
      font-size: 0;
      background-image: url(../../../../assets/img/vip.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.paid,
    &.confirmed {
      background-color: #5eb602;
    }

    &.custom-new {
      position: relative;
      color: #ffffff;
      width: 43px;
      background-color: #00ccbc;

      &:after {
        position: absolute;
        content: '\e973';
        top: 50%;
        left: 50%;
        @include icomoon();
        font-size: 12px;
        transform: translate(-50%, -50%);
      }
    }

    &.custom-new-2 {
      position: relative;
      color: #ffffff;
      width: 43px;
      background-color: #ff3008;

      &:after {
        position: absolute;
        content: '\e96e';
        top: 50%;
        left: 50%;
        @include icomoon();
        font-size: 12px;
        transform: translate(-50%, -50%);
      }
    }

    &.uber {
      position: relative;
      color: #ffffff;
      width: 43px;
      background-color: #000000;

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 29px;
        height: 10px;
        background-image: url(../../../../assets/img/orders/uber@2x.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }
    }

    &.resy {
      padding: 3px 5px;
      width: 43px;
      height: 20px;
      font-weight: $bold;
      letter-spacing: 0.1em;
      color: #fc5a45;
      text-transform: uppercase;
      font-size: 0;
      background-image: url(../../../../assets/img/resy.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.running-late {
      background-color: #f7b500;
    }

    &.bussing,
    &.left-message {
      background-color: #6d7278;
    }

    &.dessert {
      background-color: #eb4469;
    }

    &.entree {
      background-color: #b620e0;
    }

    &.arrived {
      background-color: #6236ff;

      &.blue {
        background-color: #0086f9;
      }
    }

    &.main {
      background-color: #0086f9;
    }

    &.web {
      min-width: 43px;
      padding: 3px 5px;
      font-size: 11px;
      font-weight: $bold;
      color: #363636;
      background-color: #e9e9e9;
    }

    &.seated {
      background-color: #fd6c00;
    }

    &.booked {
      background-color: #e02020;
    }

    &.google {
      overflow: hidden;
      position: relative;
      height: 20px;
      width: 43px;
      background-color: #e9e9e9;
    }

    &.bot {
      min-width: 43px;
      padding: 2px 5px;
      font-size: 11px;
      color: #363636;
      background-color: #e9e9e9;
    }

    &.doorDash {
      overflow: hidden;
      position: relative;
      height: 20px;
      width: 43px;
      background-color: #e9e9e9;
    }

    &.phone {
      overflow: hidden;
      position: relative;
      height: 20px;
      width: 43px;
      color: #00ad00;
      background-color: #e9e9e9;

      &:after {
        position: absolute;
        content: '\e91c';
        top: 50%;
        left: 50%;
        @include icomoon();
        font-size: 12px;
        transform: translate(-50%, -50%);
      }
    }

    &.cancelled {
      background-color: #fa6400;
    }

    &.circle {
      position: relative;
      width: 43px;
      height: 20px;
      background-color: #e9e9e9;

      &:after,
      &:before {
        position: absolute;
        content: '';
      }

      &:after {
        top: 3px;
        left: 16px;
        width: 4px;
        height: 4px;
        border: 5px solid #da3743;
        border-radius: 50%;
      }

      &:before {
        top: 8px;
        left: 8px;
        width: 4px;
        height: 4px;
        background-color: #da3743;
        border-radius: 50%;
      }
    }
  }
}

.dark-mode {
  .nav-bar-header {
    .top-box {
      .options-holder {
        background-color: #000000;

        .list-items {
          .item {
            &.selected {
              background-color: #64646b;
            }
          }
        }
      }
    }
  }
}
