.payment-services-box {
  .single-payment-service {
    position: relative;
    display: inline-block;
    width: 49px;
    height: 34px;
    margin: 8px;
    line-height: 39px;
    text-align: center;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    background-color: #ffffff;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: auto;
      height: auto;
      max-width: 49px;
      max-height: 39px;
      transform: translate(-50%, -50%);
    }

    &.apple {
      img {
        max-width: 37px;
      }
    }

    &.goo {
      img {
        max-width: 37px;
      }
    }

    &.mastercard {
      img {
        max-width: 28px;
      }
    }

    &.visa {
      img {
        max-width: 29px;
      }
    }

    &.amex {
      background-color: #3498d8;

      img {
        max-width: 36px;
      }
    }

    &.union {
      img {
        max-width: 31px;
      }
    }

    &.jcb {
      img {
        max-width: 29px;
      }
    }
  }

  button {
    box-shadow: 0 0 6px -3px rgb(0, 0, 0 / 50%);
    border-radius: 7px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    padding: 10px;
    font-weight: 500;
    font-size: 20px;
    color: #5eb602;
    width: 220px;
    min-height: 60px;
    margin-bottom: 10px;
  }
}

#payment-view-modal {
  transition: opacity 0s;

  .payment-services-box {
    position: absolute;
    z-index: 10;
    display: block;
    width: 600px;
    text-align: center;
    left: 50%;
    bottom: 5px;
    transform: translate(-50%, 0);
  }

  .payment-view-modal.show,
  .show .modal-overlay {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.35s;
  }

  &.slideDown {
    opacity: 0;
    transition: opacity 0s linear 0.71s;

    .modal-box {
      bottom: -100%;
      transition: bottom 0.7s;
    }

    .modal-overlay {
      opacity: 0;
      transition: opacity 0.35s linear 0.35s;
    }
  }

  a.split-btn,
  .split-btn {
    position: absolute;
    top: 23px;
    right: 27px;
    font-size: 18px;
    min-width: 66px;
    transition: 0s !important;

    .inner-btn-box {
      padding: 3px 10px 4px;
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    top: 18px;
    left: 20px;
    font-size: 32px;

    &:after {
      content: '\e90f';
      color: #494b4c;
      font-weight: 400;
    }
  }

  .modal-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 768px;
    height: auto;
    color: #363636;
    &.center-position {
      top: auto;
      bottom: 50%;
      transform: translate(-50%, 50%);
      border-radius: 2.8rem;
    }
  }

  .terminal-total-price {
    display: block;
    margin-bottom: 12px;
    font-size: 35px;
    line-height: $line-height-heading-2;
    font-weight: $medium;
    text-align: center;
  }

  .terminal-text {
    display: block;
    height: 35px;
    text-align: center;
    font-size: 22px;
    font-weight: $normal;
    color: #777777;
    margin-bottom: 39px;
  }

  .terminal-wrap {
    max-width: 162px;
    margin: 0 auto;
  }

  .terminal-box {
    position: relative;
    height: 277px;

    .terminal-action-box {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 162px;
      height: 156px;
      border-radius: 22px;
      background-color: transparent;
    }

    img {
      position: relative;
      z-index: 3;
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
      transition: opacity 0.35s;

      &.hide {
        opacity: 0;
        transition: opacity 0.35s;
      }
    }
  }

  .cash-cards {
    text-align: center;
    display: flex;
    flex-direction: row;

    a,
    button {
      display: inline-block;
      width: 125px;
      height: 82px;
      margin: 0 12px 12px;
      box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);
      border-radius: 7px;
      border: 1px solid #e8e8e8;
      background-color: #ffffff;

      &:before {
        border-radius: 7px;
      }

      &.active {
        background-color: #5eb602;

        .title,
        .price {
          color: #ffffff;
        }
      }
    }

    .title,
    .price {
      display: block;
      font-weight: $medium;
      font-size: 20px;
      color: #5eb602;
      pointer-events: none;
    }

    .title {
      color: #0084ff;
    }

    .cash-amount-btn {
      margin-left: auto;
    }
  }

  .modal-pre-footer {
    padding: 17px 10px 14px;
    position: relative;
    &.hide {
      display: none;
    }
    .select-custom-wrap,
    a.scheduled-dropdown,
    .scheduled-dropdown {
      position: absolute;
      top: 14px;
      right: 15px;
    }

    .modal-title {
      margin-bottom: 30px;
      margin-left: 15px;
      font-size: 25px;
    }
  }

  .payment-nav-box {
    overflow: hidden;

    a,
    button {
      float: left;
      width: 25%;
      padding: 18px 10px;
      text-align: center;
      font-size: 17px;
      letter-spacing: -0.02em;
      line-height: $line-height-heading-2;
      color: #3a3a3a;
      border-right: 1px solid #e3e3e3;

      &:last-child {
        border-right: none;
      }
    }
  }

  .modal-body {
    padding: 35px 23px 0;
    height: 100%;
    background-color: #f7f7f7;

    &--save-order {
      display: flex;
      align-items: center;
      height: 100%;

      .terminal-wrap {
        max-width: 260px;
        margin-top: -15%;
      }
      .payment-services-box {
        bottom: 40%;
      }
    }
  }

  .modal-footer {
    &:before {
      height: 47px;
      box-shadow: 0 -3.6px 9px -1px rgba(0, 0, 0, 0.5);
    }

    .payment-nav-box {
      position: relative;
      background-color: #ffffff;
    }
  }
}

.payment-types-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.retry-side-div {
  text-align: center;
  box-shadow: 0 0 6px -3px black;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #5eb602;
  width: 220px;
  margin: auto;
  margin-bottom: 20px;

  &.cursor-pointer {
    cursor: pointer;
  }
}

.retry-btn {
  position: relative;
  display: block;
  margin: 0;
  padding: 3px 12px;
  border: 0.2rem solid #067aff;
  background-color: #067aff;
  border-radius: 3px;
  transition: background-color 0s;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.paymentWarningContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f39c12;
  font-weight: 500;
  padding-top: 10px;
}

.windcaveButton {
  margin-left: 10px;
  margin-right: 10px;
  width: 150px !important;
}
.windcaveButtonDisabled {
  background-color: #e8e8e8 !important;
}
.windcaveButtonItem {
  display: flex;
  justify-content: center;
}

.windcaveButtonText {
  padding-left: 25px;
}

.windcaveItemIcon > line {
  stroke: #f40000;
}

.windcaveActionButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px !important;
  box-shadow: 0 0 6px -3px rgb(0, 0, 0 / 50%);
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #5eb602;
  width: 220px;
  min-height: 60px;
  margin-bottom: 10px;
}
