.single-price {
  position: relative;
  font-size: 17px;
  color: #363636;
}

.single-item-cell {
  display: table-cell;
  padding: 0 7px;
  text-align: left;

  // .order-bar & {
  //   width: 100%;
  // }

  &.img-cell {
    width: 17px;
  }

  &.info-cell {
    padding-left: 0;
    width: calc(100% - 54px);
  }

  &.course-cell {
    padding: 0 2px 0 0;
    text-align: right;
    vertical-align: middle;

    .view-order-main-wrap & {
      width: 140px;
    }
  }

  &.price-cell {
    padding: 0 2px 0 0;
    text-align: right;

    .view-order-main-wrap & {
      width: 140px;
    }
  }
}

.item-cart-voucher-code {
  color: #2d3748;
  font-size: 12px;
  padding: 0 5px;
  margin-right: 5px;
}

.item-cart-old-price {
  color: #2d3748;
  border-radius: 0.375rem;
  font-size: 12px;
  padding: 0 5px;
  margin-right: 5px;
  white-space: nowrap;
}

.item-cart-label {
  white-space: nowrap;
  padding: 2px 7px;
  background-color: #5eb602;
  border-radius: 16px;
  color: #f7fafc;
  font-weight: 500;
  font-size: 12px;
  & + .single-actions {
    margin-left: 2px;
  }
}

.single-item-table {
  padding: 4px 32px 6px 11px;

  &.voided {
    opacity: 0.3;
    text-decoration: line-through;
  }
}
.row-cart-item {
  min-height: 56px;
  padding: 4px 32px 6px 0px;
  display: flex;
  flex-direction: column;

  justify-content: center;

  &.voided {
    opacity: 0.3;
    text-decoration: line-through;
  }
  &.row-cart-item-p {
    padding-left: 11px !important;
  }
}

.row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  gap: 5px;
}

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.voided-reasons-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.other-input-container {
  padding-left: 20px;
  padding-right: 20px;
}
.input-label-text {
  display: flex;
  font-weight: bold;
}

.other-label {
  display: flex;
  font-weight: 400;
  font-style: italic;
  color: grey;
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.dineInHeader {
  display: flex;
  padding-bottom: 10px;
  padding-left: 14px;
}
.cart-tag-container {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-section-container {
  flex: 1;
  display: inline;
}

.seat-section-header {
  background-color: #2f7cff;
  color: white;
  font-weight: 500;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
}

.seat-section-header-span {
  padding-left: 10px;
}
.cart-container {
  overflow: auto;
  flex: 1;
}

.seat-circle {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  margin-left: 10px;
}

.is-seats-tag-selected {
  color: #2f7cff;
  font-weight: 500;
  background-color: #f1f8ff;
  margin-left: 5px;
}

.is-courses-tag-selected {
  color: #fd6c00;
  font-weight: 500;
  background-color: #faece1;
  margin-left: 5px;
}

.custom-svg-seats-style > line {
  stroke: #2f7cff;
  stroke-width: 4.2;
}

.custom-svg-courses-style > line {
  stroke: #fd6c00;
  stroke-width: 4.2;
}
.other-item-tag {
  font-size: 12px;
  font-weight: 500;
  background-color: #054f4f;
  color: #e9e9e9;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 10px;
}
