#driver-map-modal {
  transition: opacity 0s;

  .payment-view-modal.show,
  .show .modal-overlay {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.35s;
  }

  .modal-header {
    padding: 27px 75px 23px;
    box-shadow: 6px -4.6px 9px 0 rgba(0, 0, 0, 0.5);
  }

  .modal-title {
    font-size: 19px;
    font-weight: 400;
    text-align: center;

    span {
      font-weight: 500;
    }

    &.red {
      span {
        color: #e02020;
      }
    }

    &.orange {
      span {
        color: #fd6c00;
      }
    }

    &.green {
      span {
        color: #5eb602;
      }
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    top: 16px;
    left: 15px;
    font-size: 22px;
    color: #363636;

    &:after {
      content: '\e963';
      font-weight: 700;
    }
  }

  .modal-box {
    width: 886px;
    height: 705px;
    color: #363636;
  }

  .modal-body {
    padding: 0;
    height: calc(100% - 73px);
    background-color: #f7f7f7;
  }

  .driver-map-wrap {
    overflow: hidden;
    position: relative;

    iframe {
      position: relative;
      top: -80px;
      left: -2px;
    }
  }
}
