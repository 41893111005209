.bem-table-card {
  overflow: hidden;
  display: grid;
  gap: 14px 10px;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 15px 17px 15px 13px;
  background-color: var(--mainBG);
  color: var(--textColor);
  border-radius: 13px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  &__table {
    display: block;
    font-size: 17.5px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
  }
  &__floor {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
  }
  &__seats-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__seats {
    display: block;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
  }
  &__icon-box {
  }
}
