@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-manual-amount {
  position: relative;
  width: 440px;
  height: 673px;
  min-height: 535px;
  box-shadow: var(--shadowModal);
  border-radius: 24px;

  @media all and (max-height: 675px) {
    height: 620px;
  }

  &__inner {
    overflow: hidden;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: inherit;
    height: 100%;
    padding: 0;
    border-radius: 24px;
    background-color: var(--modaldBg);
  }

  &__header {
    position: relative;
    display: flex;
    min-height: 200px;
    max-height: 274px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //gap: 20px 0;
    @include gapFlexSupportAlternative(20, 0);
    padding: 20px 20px 36px;
    height: 100%;
    text-align: center;
  }

  &__title {
    display: block;
    font-size: 23px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    letter-spacing: -0.65px;
    color: var(--textColor);
    &--big {
      font-size: 30px;
    }
  }

  &__value {
    font-size: 40px;
    line-height: $line-height-heading-2;
    letter-spacing: -0.8px;
    color: var(--manualAmountValueColor);
    @include UITextColorModifiers();
  }

  &__phone-box {
    overflow: hidden;
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 40px;
    max-width: 340px;
    width: 100%;
    gap: 0 20px;
    svg {
      flex-grow: 0;
      flex-shrink: 0;
      width: 40px;
      stroke-width: 1.5;
      color: var(--successColor);
    }
  }

  &__phone {
    display: block;
    width: 100%;
    font-size: 34px;
    line-height: $line-height-heading-2;
    letter-spacing: -0.02em;
    text-align: center;
    white-space: nowrap;
  }

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__body {
    position: relative;
    display: contents;
    @include ModalBodyRightGradient();
  }

  &__footer {
    position: relative;
    z-index: 8;
    flex-grow: 0;
    flex-shrink: 0;
  }

  /* START inner modal styles */
  &__inner-modal {
    position: absolute;
    top: 0;
    left: 0;
    padding: 80px 30px 30px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  &__inner-modal-box {
    position: relative;
    width: 100%;
    padding: 20px 20px 30px;
    min-height: 260px;
    border-radius: 15px;
    background-color: #ffffff;
  }
  &__inner-modal-header {
    display: flex;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__inner-modal-body {
  }

  &__inner-modal-title {
    margin-bottom: 16px;
    font-size: 23px;
    line-height: 1.25;
    font-weight: $medium;
    letter-spacing: -0.05rem;
  }
  &__inner-modal-content {
    font-size: 17.5px;
    line-height: 1.7;
    letter-spacing: -0.025rem;
    b,
    strong {
      font-weight: $medium;
    }
    a {
      color: var(--primaryColor);
      text-decoration: none;
    }
    > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  /* END inner modal styles */
}
