#modal-blur-overlay:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  pointer-events: none;
  z-index: 999;
}

.blurEffect {
  .home-screen-box,
  .nav-bar,
  .main-area,
  .order-bar,
  .header,
  .markup-wrap,
  .bem-header,
  .settle-main-wrap,
  .cashier-main-wrap,
  .money-count-main-wrap,
  .settings-page,
  .driver-main-wrap,
  .view-order-main-wrap,
  .dine-in-blur-area {
    transition: filter 0.15s cubic-bezier(0.85, 0, 0.15, 1) 0.15s;
    -webkit-filter: blur(6px);
    filter: blur(6px);
  }
}

.generalModal-2-overlay {
  visibility: hidden;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;

  .default-overlay & {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .show & {
    visibility: visible;
    z-index: 1;
  }
}

.generalModal-2-wrap {
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 1043;
  top: 21px;
  left: 0;
  width: 100%;
  height: calc(100% - 21px);
  transform-origin: center;
  transform: translate(0, 0);

  .hide-status-bar & {
    top: 0;
    height: 100%;
  }

  &.show {
    visibility: visible;
  }

  &.error {
    .generalModal-2-box {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      transform-origin: center;
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  .generalModal-2-container-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  .generalModal-2-container {
    position: relative;
    z-index: 1045;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
  }

  .generalModal-2-box {
    overflow: hidden;
    position: relative;
    z-index: 5;
    text-align: left;
    height: 100%;
    max-width: 100%;
    margin: 40px auto;
    background-color: #ffffff;
    border-radius: 28px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  }

  &.top {
    .generalModal-2-container {
      vertical-align: top;
    }
    &.generalModal-2-wrap--responsive-height .generalModal-2-container {
      justify-content: flex-start;
      .generalModal-2-box {
        margin-top: 0;
        @media all and (min-height: 900px) {
          justify-content: center;
        }
      }
    }
  }

  &.bottom {
    .generalModal-2-container {
      vertical-align: bottom;
    }
    &.generalModal-2-wrap--responsive-height .generalModal-2-container {
      justify-content: flex-end;
      @media all and (min-height: 900px) {
        justify-content: center;
      }
    }

    .generalModal-2-box {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 28px 28px 0 0;
    }
  }

  a.generalModal-2-close-btn,
  .generalModal-2-close-btn {
    overflow: hidden;
    position: fixed;
    z-index: 1000000;
    top: 23px;
    left: 23px;
    display: inline-block;
    border-radius: 50rem;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.25);

    &:before {
      border-radius: 50rem;
    }

    &:hover {
      &:before {
        width: 56px;
        height: 56px;
      }
    }

    .inner-btn-box {
      overflow: hidden;
      position: relative;
      display: block;
      width: 56px;
      height: 56px;
      background-color: #ffffff;
      border-radius: 50rem;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 26px;
      width: 26px;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    .pos_icon-left-chevron {
      height: 30px;
      width: 18px;
      transform: translate(calc(-50% - 1px), -50%);
    }

    &.inside {
      visibility: visible;
      opacity: 1;
      top: 10px;
      left: 8px;
      box-shadow: none;
      border-radius: 7px;

      &:before {
        border-radius: 7px;
      }

      .inner-btn-box {
        box-shadow: none;
      }

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        width: 22px;
        height: 22px;
      }
    }
  }

  &--responsive-height {
    overflow: hidden;
    .generalModal-2-box {
      margin: 21px auto;
    }
    .generalModal-2-container-wrap {
      overflow: hidden;
    }
    .generalModal-2-container-wrap:before {
      content: none;
    }
    .generalModal-2-container {
      overflow: hidden;
      max-height: 100%;

      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      overflow-y: auto;
    }
  }
}

a.generalModal-2-close-btn.outside,
.generalModal-2-close-btn.outside,
.generalModal-wrap {
  visibility: hidden;
  transition: opacity 0s;

  .show & {
    visibility: visible;
  }
}

.top {
  .generalModal-2-box {
    border-radius: 0 0 2.8rem 2.8rem;
  }
}

.right {
  border-radius: 2.8rem 0 0 2.8rem;
}

.right-bottom {
  .generalModal-2-box {
    border-radius: 2.8rem 0 0 0;
  }
}

.bottom {
  .generalModal-2-box {
    border-radius: 2.8rem 2.8rem 0 0;
  }
}

.left {
  .generalModal-2-box {
    border-radius: 0 2.8rem 2.8rem 0;
  }
}

.left-bottom {
  .generalModal-2-box {
    border-radius: 0 0 0 2.8rem;
  }
}

.move-effect {
  &.show.hide-anim {
    a.generalModal-2-close-btn.outside,
    .generalModal-2-close-btn.outside {
      opacity: 0;
      transition: opacity 0s;
    }
  }

  &.show {
    a.generalModal-2-close-btn.outside,
    .generalModal-2-close-btn.outside {
    }
  }

  &.show.hide-anim .generalModal-2-container-wrap,
  .generalModal-2-container-wrap {
    opacity: 1;
    transform: translate(0, -150%);
  }

  &.show {
    .generalModal-2-container-wrap {
      transform: translate(0, 0);
    }
  }

  &.top {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-50%, 0);
      }
    }
  }

  &.right {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(50%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-100%, -50%);
      }
    }
  }

  &.left {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      opacity: 1;
      transform: translate(-150%, -50%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(0, -50%);
      }
    }
  }

  &.right-bottom {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      transform: translate(50%, -100%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(-100%, -100%);
      }
    }
  }

  &.left-bottom {
    &.show.hide-anim .generalModal-wrap,
    .generalModal-wrap {
      transform: translate(-150%, -100%);
    }

    &.show {
      .generalModal-wrap {
        transform: translate(0, -100%);
      }
    }
  }
}

.generalModal-2-wrap.radius-small {
  .generalModal-2-box {
    border-radius: rem(21);
  }

  &.top {
    .generalModal-2-box {
      border-radius: 0 0 rem(21) rem(21);
    }
  }

  &.right {
    .generalModal-2-box {
      border-radius: rem(21) 0 0 rem(21);
    }
  }

  &.right-bottom {
    .generalModal-2-box {
      border-radius: rem(21) 0 0 0;
    }
  }

  &.bottom {
    .generalModal-2-box {
      border-radius: rem(21) rem(21) 0 0;
    }
  }

  &.left {
    .generalModal-2-box {
      border-radius: rem(21) rem(21) 0 0;
    }
  }

  &.left-bottom {
    .generalModal-2-box {
      border-radius: 0 rem(21) 0 0;
    }
  }
}

.generalModal.radius-none {
  .generalModal-2-box {
    border-radius: 0;
  }
}

.show-keyboard {
  .show {
    .generalModal-wrap {
      max-height: calc(100% - 378px);
    }

    .generalModal-overlay {
      transform: translateY(-358px);
    }
  }
}

.dark-mode {
  .generalModal {
    a.generalModal-2-close-btn,
    .generalModal-2-close-btn {
      &.outside {
        .inner-btn-box {
          background-color: #1f1e23;
        }
      }

      &.inside {
        .inner-btn-box {
          background-color: transparent;
        }
      }
    }
  }

  .generalModal-2-box {
    background-color: #1f1e23;
  }

  .default-overlay {
    .generalModal-2-overlay {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.wiggle-error {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 1;
  transform: translate3d(0, 0, 0);
  transform-origin: center;
  backface-visibility: hidden;
  perspective: 1000px;
}

.slide-effect {
  &.show.hide-anim .generalModal-2-container-wrap,
  .generalModal-2-container-wrap {
    opacity: 1;
    transform: translate(150%, 0%);
    transition: transform 0.35s cubic-bezier(1, 0, 0.4, 1) 0s;
  }

  &.show {
    .generalModal-2-container-wrap {
      transform: translate(0, 0);
      transition: transform 0.35s cubic-bezier(1, 0, 0.4, 1) 0s;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.refund-manual-amount {
  &.generalModal-2-wrap {
    @media all and (max-height: 800px) {
      .generalModal-2-box {
        margin: 0 auto;
        height: auto !important;
        min-height: 656px;
      }
    }
    @media all and (max-height: 655px) {
      .generalModal-2-box {
        border-radius: 0;
      }
    }
  }
}

@media all and (max-height: 800px) {
  .sidebar-scroll {
    height: 550px;
  }
}
@media all and (max-height: 700px) {
  .sidebar-scroll {
    height: 450px;
  }
}

@media all and (max-height: 600px) {
  .sidebar-scroll {
    height: 350px;
  }
}
