.bem-nav-button {
  display: grid;
  height: 65px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 36px 1fr;
  gap: 0 12px;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--mainBG);
  &:disabled {
    background-color: #e9e9e9;
    cursor: no-drop;
  }
  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    color: var(--textColor);
    font-size: 17px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
  }
  &--active {
    background-color: var(--primaryColor);
    svg {
      color: var(--whiteColor);
    }
    & .bem-nav-button__title {
      font-size: 18px;
      font-weight: 500;
      color: var(--whiteColor);
    }
  }
  & + .bem-nav-button {
    border-top: 1px solid var(--greyColor-150);
  }
}

.disable {
  color: #bbbbbb;
}
