.digits-input-modal {
  @media all and (max-height: 760px) {
    .generalModal-2-box {
      max-height: 100%;
      height: auto !important;
      min-height: 656px;
    }
  }
  @media all and (max-height: 600px) {
    .generalModal-2-box {
      max-height: 100%;
      height: auto !important;
      min-height: 628px;
    }
  }
}

.digits-input-modal-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;

  .digits-input-header {
    position: relative;
    padding: 39px 20px 20px;
  }

  .digits-input-title {
    margin-bottom: rem(19);
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #363636;
    text-transform: capitalize;
  }

  .digits-input-selected {
    span {
      color: #067aff;
      padding: 2px 19px;
      border-radius: 15px;
      text-align: center;
      background-color: rgba(6, 120, 250, 0.2);
      font-size: 20px;
      display: inline-block;
    }
  }

  .digits-input-desc {
    max-width: 315px;
    margin: 0 auto 12px;
    font-size: 20px;
    font-weight: $medium;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #067aff;

    .inner-btn-box {
      display: block;
      padding: 0 5px;
    }
  }

  .digits-input-line {
    overflow: hidden;
    margin: 50px auto 30px;
    font-size: rem(34);
    font-weight: $normal;
    letter-spacing: -0.02em;
  }

  .digits-input-inner {
    position: relative;
    display: inline-block;
    color: grey;
    min-width: 247px;
    max-width: 320px;
    min-height: 54px;
    margin: 0 auto 18px;
    text-align: center;
    vertical-align: top;

    .pos_icon-phone-inverse {
      position: absolute;
      left: -38px;
      top: 50%;
      width: 35px;
      height: 35px;
      transform-origin: center;
      transform: translate(0, -50%);
    }

    .number {
      white-space: nowrap;
    }
  }

  .digits-input-keypad-box {
    position: relative;
    z-index: 1;
    display: flex;
    width: calc(100% + 2px);
    margin: 0 auto;
    flex-flow: row wrap;
    align-items: center;
    background-color: #fbfbfb;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    .digits-input-key-wrap {
      width: (100% / 3);
      padding: 0;
      border: 1px solid #e9e9e9;

      &:nth-child(3n + 1) {
        border-left: none;
      }

      &:nth-child(3n) {
        border-right: none;
      }
    }

    .digits-input-key {
      overflow: hidden;
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      height: rem(88);
      font-size: rem(30);
      line-height: 1.2;
      color: #515151;
      border-radius: 0;

      @media all and (max-height: 730px) {
        height: rem(70);
      }
      @media all and (max-height: 600px) {
        height: rem(63);
      }

      .pos_icon-dot {
        top: -8px;
        height: 8px;
        width: 8px;
      }

      .pos_icon-plus {
        height: 22px;
      }

      .pos_icon-back-icon {
        height: 25px;
      }

      .pos_icon-left-chevron-second {
        height: 25px;
        width: 16px;
      }

      &:disabled {
        color: transparent;

        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          opacity: 0;
        }
      }

      &:active,
      &.active {
        &:before {
          opacity: 0 !important;
        }

        background-color: #ffffff;
      }
    }
  }

  .digits-input-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(20);
      font-weight: $medium;
      letter-spacing: -0.02em;

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 17px 20px;
        border-radius: 0;
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.dark-mode {
  .digits-input-modal-box {
    .digits-input-title {
      color: #ffffff;
    }

    .digits-input-key-wrap {
      border-color: #444444;
    }

    .digits-input-keypad-box {
      border-color: #444444;
      background-color: #2b2c2e;

      .digits-input-key {
        color: #ffffff;

        &.active,
        &:active {
          background-color: #000000;
        }
      }
    }
  }
}
