.money-count-modal-box {
  padding: 34px 20px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;

  .money-count-desc {
    margin: 0 auto 90px;
    color: #777777;
    font-size: rem(22);
    font-weight: $normal;
  }

  .money-count-box {
    margin: 0 auto 20px;
  }

  .money-count-total {
    display: block;
    min-height: 45px;
    font-size: rem(45);
    font-weight: $medium;
    line-height: 1;
    letter-spacing: -0.02em;
  }

  .money-count-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(18);
      font-weight: $medium;
      letter-spacing: -0.02em;

      &:before {
        border-radius: 10px;
      }

      .inner-btn-box {
        padding: 10px 12px;
        border-radius: 10px;
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.dark-mode {
  .money-count-modal-box {
    .money-count-desc {
      color: #ffffff;
    }
  }
}
