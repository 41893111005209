.uberHeader {
  background-color: #000000;
}

.headerSection {
  display: flex;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 22px;
  padding-bottom: 20px;
}
@media screen and (max-height: 630px) {
  .headerSection {
    display: flex;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 22px;
    padding-bottom: 10px;
  }
}

@media screen and (max-height: 610px) {
  .headerSection {
    display: flex;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 22px;
    padding-bottom: 2px;
  }
}

.closeButtonContainer {
  display: flex;
  flex: 1;
}
.closeButton {
  align-self: center;
  display: flex;
  align-items: center;
  padding: 7px;
  background-color: #222222;
  border-radius: 15px;
  cursor: pointer;
}
.infoTextContainer {
  padding-left: 23px;
  padding-bottom: 30px;
}

.decreasePadding {
  padding-bottom: 7px;
}

@media screen and (max-height: 660px) {
  .infoTextContainer {
    padding-left: 23px;
    padding-bottom: 20px;
  }
}

@media screen and (max-height: 650px) {
  .infoTextContainer {
    padding-left: 23px;
    padding-bottom: 10px;
  }
}
.infoMainText {
  font-size: 25px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.mainHeadingText {
  flex: 1;
}
.infoSubText {
  width: 350px;
  color: #9c9c9c;
}

.deliveredByTimeContainer {
  display: flex;
  justify-content: center;
  height: 47px;
  background-color: #ffffff;
  box-shadow: 0 -4px 9px rgb(0 0 0 / 50%);
}

.deliveredByTimeText {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.mapTilerContainer {
  height: 50%;
  margin-top: 1px;
}
@media screen and (max-height: 720px) {
  .mapTilerContainer {
    height: 47%;
    margin-top: 1px;
  }
}

@media screen and (max-height: 700px) {
  .mapTilerContainer {
    height: 45%;
    margin-top: 1px;
  }
}

.footerInfoItemContainer {
  display: flex;
  justify-content: space-evenly;
  height: 47px;
}
.footerInfoItem {
  display: flex;
  align-items: center;
}

.footerActionButtonContainer {
  display: flex;
  justify-content: center;
}
.footerActionButton {
  background-color: #000000;
  color: #ffffff;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 85%;
}

.footerActionButton:disabled {
  background-color: #9c9c9c;
  cursor: not-allowed;
}

.cancelButton {
  background-color: #f50000;
  color: #ffffff;
  font-size: 15px;
  height: 25px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 18px;
}

.loadingIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
}

.courierInfoContaier {
  display: flex;
  justify-content: space-evenly;
  padding-top: 7px;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.courierInfoItem {
  display: flex;
  align-items: center;
}
.courierInfoItemText {
  padding-left: 10px;
}

.mapTilerContainerDeliveryDetail {
  height: 410px;
  margin-bottom: 7px;
}

.mapTilerFullheigth {
  height: 453px;
}

.skeletonContainer {
  padding-left: 23px;
  padding-bottom: 30px;
  min-height: 146.5;
}
.headingMainTextSkeleton {
  background-color: #9c9c9c;
  height: 20px;
  width: 170px;
  margin-bottom: 20px;
  border-radius: 3px;
  overflow: Hidden;
}

.headingMainTextSkeleton::before {
  display: Block;
  content: '';
  width: 50%;
  height: 100%;
  background: Linear-Gradient(
    90deg,
    Rgba(255, 255, 255, 0),
    #dadae8,
    Rgba(255, 255, 255, 0)
  );
  animation: Skeleton-Animation 1.25s Ease-In-Out Infinite;
}

.headingSubTextSkeleton {
  background-color: #9c9c9c;
  height: 15px;
  width: 350px;
  margin-bottom: 13px;
  border-radius: 3px;
  overflow: Hidden;
}

.headingSubTextSkeleton::before {
  display: Block;
  content: '';
  width: 50%;
  height: 100%;
  background: Linear-Gradient(
    90deg,
    Rgba(255, 255, 255, 0),
    #dadae8,
    Rgba(255, 255, 255, 0)
  );
  animation: Skeleton-Animation 1.25s Ease-In-Out Infinite;
}

.headingSecondSubTextSkeleton {
  background-color: #9c9c9c;
  height: 15px;
  width: 250px;
  border-radius: 3px;
  overflow: Hidden;
}

.headingSecondSubTextSkeleton::before {
  display: Block;
  content: '';
  width: 50%;
  height: 100%;
  background: Linear-Gradient(
    90deg,
    Rgba(255, 255, 255, 0),
    #dadae8,
    Rgba(255, 255, 255, 0)
  );
  animation: Skeleton-Animation 1.25s Ease-In-Out Infinite;
}

@keyframes Skeleton-Animation {
  0% {
    transform: TranslateX(-100%);
  }
  50% {
    transform: TranslateX(200%);
  }
  100% {
    transform: TranslateX(-100%);
  }
}

.errorMessageContianer {
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.errorMessageContianerDeliveryDetailsUI {
  min-height: 410px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uberSupport {
  display: flex;
  align-items: center;
  height: 35px;
  background-color: #3288ff;
  border-radius: 17px;
  color: #ffffff;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}
.trackDriver {
  display: flex;
  align-items: center;
  height: 35px;
  background-color: #e67e22;
  border-radius: 17px;
  color: #ffffff;
  padding-left: 13px;
  padding-right: 13px;
  margin-left: 10px;
  cursor: pointer;
}

.itemText {
  padding-left: 6px;
}

.detailsActionButtonContainer {
  display: flex;
  padding-left: 23px;
  padding-bottom: 10px;
}
