.time-mobiscroll-box {
  position: relative;
  margin-top: -22px;

  .mobiscroll-grid-shadow {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    pointer-events: none;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      left: 0;
      width: 100%;
      height: 48px;
      border-left: none;
      border-right: none;
      background-color: #ffffff;
    }

    &:before {
      top: 32px;
    }

    &:after {
      bottom: 30px;
    }
  }

  .mobiscroll-grid-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    pointer-events: none;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 78px;
      border: 1px solid rgba(86, 86, 86, 0.3);
      border-left: none;
      border-right: none;
      transform: translate(0, -50%);
    }

    &:before {
      top: 120px;
    }

    &:after {
      top: 432px;
    }
  }

  .mbsc-mobiscroll {
    position: relative;

    .mbsc-fr-w {
      background-color: #ffffff;
    }

    .mbsc-sc-whl-gr {
      padding: 0;
    }

    .mbsc-sc-whl-w {
      margin: 0;
    }

    .mbsc-sc-whl-l {
      border-color: #efefef;
    }

    .mbsc-sc-itm {
      font-family: $font-main;
      font-size: 21px;
      color: #363636;
      font-weight: 300;
      letter-spacing: -0.02em;
    }

    .mbsc-sc-itm-sel {
      font-weight: 400;
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      background-color: transparent;
    }
  }

  .booking-modal & {
    margin-top: -27px;

    .mbsc-mobiscroll {
      .mbsc-sc-whl-gr {
        > :nth-child(even) {
          background-color: #f0f0f0;
        }

        > :nth-child(2) {
          .mbsc-sc-itm {
            padding: 0 20px;
          }
        }

        > :nth-child(3) {
          .mbsc-sc-itm {
            padding: 0 23px;
          }
        }

        > :nth-child(4) {
          .mbsc-sc-itm {
            padding: 0 18px;
          }
        }
      }

      .mbsc-sc-whl-l {
        background-color: #0684ff;
        border-color: #0684ff;
      }

      .mbsc-sc-itm {
        padding: 0 29px;
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        color: #808080;
        border-right: none;
        border-left: none;

        span {
          font-weight: 500;
        }

        .red {
          color: #e02020;
        }

        .green {
          color: #5eb602;
        }
      }

      .mbsc-sc-itm-sel {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
