.custom-dropdown-wrap {
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  .custom-dropdown-box {
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      content: '\e90a';
      z-index: 3;
      top: 50%;
      left: 13px;
      color: #979797;
      @include icomoon();
      font-size: 22px;
      background-color: #ffffff;
      transform: translate(0, -50%);
    }

    .input-style {
      min-height: 52px;
      padding: 11px 20px 11px 48px;
      line-height: 30px;
      font-size: 18px;
      border: none;
      box-shadow: none;
    }

    .input-style::-webkit-input-placeholder {
      letter-spacing: -0.02em;
    }

    .input-style::-moz-placeholder {
      letter-spacing: -0.02em;
    }

    .input-style:-ms-input-placeholder {
      letter-spacing: -0.02em;
    }

    .input-style:-moz-placeholder {
      letter-spacing: -0.022em;
    }
  }

  .dropdown-list-box {
    display: none;
    visibility: hidden;
    position: relative;
    z-index: 2;

    > ul {
      li {
        display: block;
        border-top: 1px solid #e9e9e9;

        label {
          position: relative;
          display: block;
          margin-bottom: 0;

          input[type='radio'] {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;

            &:checked + span {
              border-color: #7aac4a;
            }
          }

          a,
          button {
            display: block;
            width: 100%;
            text-align: left;

            &:before {
              content: none;
            }
          }

          .dropdown-item-name {
            display: block;
            padding: 15px 20px 15px 48px;
            font-size: 18px;
            font-weight: $normal;
            color: #949494;
            line-height: $line-height-heading-2;
            background-color: #ffffff;
            border: 1px solid transparent;

            span {
              color: #363636;
            }
          }

          .icon-box {
            position: absolute;
            display: block;
            width: 42px;
            height: 42px;
            top: 50%;
            left: 4px;
            margin-top: rem(-0.5);
            font-size: 23px;
            transform: translate(0, -50%);

            [class^='dine_icon-'],
            [class*=' dine_icon-'] {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .dropdown-single-btn-box {
      display: block;
      padding: 29px 0;

      a.btn,
      .btn {
        display: block;
        min-width: 144px;
        margin: 0 auto;
        font-size: 17px;
        letter-spacing: 0;

        &:before {
          border-radius: 12px;
        }

        .inner-btn-box {
          padding: 10px 15px;
          border-radius: 12px;
        }
      }
    }
  }

  &.open {
    .dropdown-list-box {
      display: block;
      visibility: visible;
    }

    .custom-dropdown-box {
      .icon-box {
        .dine_icon-marker {
          &:before {
            content: '\e90a';
          }
        }
      }
    }
  }
}
