.suburbs-box {
  position: relative;
  z-index: 2;
  height: 100%;
  height: calc(100% - 62px);
}

.suburb-form-title {
  display: block;
  margin-bottom: 18px;
  padding-right: 20px;
  font-size: 19px;
  font-weight: $medium;
  letter-spacing: -0.04em;
}

.suburb-header-box {
  padding: 10px 18px 4px 20px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;

  .suburb-form-title,
  > :last-child {
    margin-bottom: 0;
  }

  .search-btn {
    top: 5px;
    right: 7px;
    height: 40px;

    .pos_icon-search {
      width: 18px;
      height: 28px;
    }
  }
}

.suburbs-form {
  overflow: hidden;
  height: 640px;
  padding-bottom: 51px;

  .show-keyboard & {
    height: 282px;
  }
}

.suburbs-btn-box {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $color-white;

  a.btn,
  .btn {
    display: block;
    width: 100%;

    font-size: 18px;
    letter-spacing: -0.02em;

    .inner-btn-box {
      padding: 13px 15px;
      border-radius: 0;
    }
  }
}

.single-suburb {
  display: table;
  width: 100%;
  padding: 12.5px 18px 12.5px 20px;
  font-size: 17px;
  color: #363636;
  letter-spacing: -0.05em;
  border: 1px solid transparent;
  border-top-color: #f4f4f4;

  > span {
    display: table-cell;
    vertical-align: middle;
  }

  .price {
    width: 50px;
    text-align: right;
  }
}

.suburbs-list {
  > li {
    display: block;

    label {
      position: relative;
      display: block;

      input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        &:checked + .single-suburb {
          border-color: #7aac4a;
        }
      }
    }

    &:first-child {
      .single-suburb {
        border-top-color: transparent;
      }
    }
  }
}
