@import '../global/variables.module';

.customer-name-modal-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: 38px 0 0;

  .customer-name-modal-inner {
    padding: 0 23px 40px;
  }

  .customer-name-title {
    margin-bottom: 40px;
    text-align: center;
  }

  .customer-name-form {
    .single-input-box {
      label {
        display: block;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 1.25;
        letter-spacing: -0.02em;
        color: #3a3a3a;
      }

      .input-style {
        min-height: 58px;
        border-width: 2px;
        border-radius: 9px;
      }
    }
  }

  .customer-name-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(18);

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 18px 20px;
        border-radius: 0;
      }
    }
  }
}

.dark-mode {
  .customer-name-modal-box {
    .customer-name-title {
      color: #ffffff;
    }

    .customer-name-form {
      .single-input-box {
        label {
          color: #ffffff;
        }
      }
    }
  }
}

.iPad-mode-wrapper {
  .customer-name-btn-box {
    a.btn,
    .btn {
      font-size: rem(18);

      .inner-btn-box {
        padding: 12px 20px;
      }
    }
  }
}

.voucher-modal-box {
  &__selected-wrapper {
    margin: 0 30% 30px;
  }

  &__selected-voucher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $pos-error;
    padding: 5px 0;
  }

  &__remove {
    cursor: pointer;
    color: $pos-error;
    height: 24px;
  }
}
