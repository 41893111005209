@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

$modalWidth: 439px;

.bem-modals-carousel {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(#{$modalWidth} + 20px); // to not cut the shadow
  min-height: 100%;
  will-change: contents;
  overflow: hidden;
  z-index: 105;

  @include hidden;

  &--show {
    @include visible;

    & + .bem-modals-carousel__arrow-top,
    & + .bem-modals-carousel__arrow-top + .bem-modals-carousel__arrow-bottom {
      @include visible;
    }
  }

  &__container {
    overflow: hidden;
    position: absolute;
    margin: 0 10px 0 9px;
    width: calc(100% - 19px);
    z-index: 100;
    padding: 0;
    border-radius: 24px;
    background-color: #fff;
    box-shadow: var(--shadowModal);
    will-change: transform;
  }

  &__inner {
    position: relative;
    height: auto;
    will-change: contents;
    transition: height 0.1s ease; // animations
  }

  &__slide {
    position: absolute;
    top: 0;
    width: calc(#{$modalWidth} + 1px);
    transition: 0s ease; // animations
    z-index: 104;

    &:first-child {
      z-index: 105;
    }

    .bem-manual-amount {
      box-shadow: none;
    }
  }

  &__arrow-bottom {
    width: 50px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: $modalWidth;
    z-index: 100;
    overflow: hidden;
    margin-top: -23.5px; // half of the triangle angled height
    margin-right: 5px; // to include shadow gap
    border-radius: 7px;
    background-color: $color-white;
    transform: rotate(45deg);
    transform-origin: center;

    @include hidden;
  }

  &__arrow-top {
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: $modalWidth;
    transform: translateY(-50%);
    margin-right: 2px;
    border-style: solid;
    border-width: 25px 0 25px 21px;
    border-color: transparent transparent transparent $color-white;
    z-index: 105;

    @include hidden;
  }
}
