.bem-search-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  color: var(--buttonColor);
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
