.geosuggest-delivery-wrap {
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  height: 58px;
  // margin-bottom: 23px;

  &.geosuggest-searching {
    .geosuggest-delivery-box {
      .geosuggest__input-wrapper {
        &:before {
          left: 23px;
          background-image: url(../../../assets/img/icon/search_grey.svg);
          background-size: 20px;
        }
      }
    }
  }
}
.delivery-address-search {
  &:disabled {
    background-color: #e7e6e6;
  }
}

.geosuggest-delivery-box {
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 15px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .clear-input-btn {
    position: absolute;
    z-index: 1;
    display: block;
    height: 54px;
    width: 54px;
    top: 0;
    right: 0;
    font-size: 15px;
    color: #494b4c;
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 0 15px 15px 0;

    .pos_icon-close {
      @include centered-pos-icon();
      width: 18px;
      height: 18px;
    }

    &:before {
      border-radius: 15px;
    }
  }

  .geosuggest__input-wrapper {
    position: relative;
    background-color: #ffffff;

    &:before {
      @include centered-pos-icon(50%, 22px);
      content: '';
      width: 41px;
      height: 40px;
      background-image: url(../../../assets/img/icon/location_grey.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 19px;
    }
  }

  input {
    display: block;
    width: 100%;
    min-height: 54px;
    margin: 0;
    padding: 11px 56px 11px 53px;
    @include font($normal, rem(18), rem(32), $font-main);
    letter-spacing: -0.01em;
    color: $color-text;
    background-color: #ffffff;
    border: none;
    border-radius: 0;
    word-spacing: -0.1em;
    box-shadow: none;
    appearance: none;
    transition: 0.35s;
  }

  .geosuggest__input::-webkit-input-placeholder,
  .input-style::-webkit-input-placeholder {
    padding: 0;
    @include font($normal, rem(18), rem(30), $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
  }

  .geosuggest__input::-moz-placeholder,
  .input-style::-moz-placeholder {
    padding: 0;
    @include font($normal, rem(18), rem(30), $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
  }

  .geosuggest__input:-ms-input-placeholder,
  .input-style:-ms-input-placeholder {
    padding: 0 8px;
    @include font($normal, rem(18), rem(30), $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
  }

  .geosuggest__input:-moz-placeholder,
  .input-style:-moz-placeholder {
    padding: 0;
    @include font($normal, rem(18), rem(30), $font-main);
    color: #919191;
    transition: 0.35s;
    opacity: 1;
  }

  .geosuggest__suggests-wrapper {
    height: 300px;
    overflow: auto;
    position: relative;
    z-index: 2;
    padding: 0 10px;
    background-color: #ffffff;

    > ul {
      transition: 0.35s;

      li {
        position: relative;
        display: block;
        border-top: 1px solid #e9e9e9;
      }

      .geosuggest__item {
        z-index: 1;
        display: block;
        padding: 15px 10px 15px 42px;
        font-size: 18px;
        font-weight: $normal;
        color: $color-text;
        line-height: $line-height-heading-2;
        background-color: #ffffff;
        cursor: pointer;

        &:before {
          @include centered-pos-icon(50%, 14px);
          width: 40px;
          height: 40px;
          content: '';
          background-image: url(../../../assets/img/icon/location_grey.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 17px;
        }
      }

      .li_geosuggest_enter_address_manually {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .enter_address_manually {
        margin: 25px 0;
        padding: 10px;
        color: $pos-blue;
        border: 2px solid $pos-blue;
        border-radius: 15px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
