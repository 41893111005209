.settle-main {
  position: relative;
  z-index: 1;
  height: 100%;
  width: calc(100% - 367px);

  .view-order-main {
    width: 100% !important;
  }

  .booking-actions-list > li:first-child {
    margin-right: 27px !important;
  }

  .seats-list-wrap .single-item .single-item-table {
    padding-top: 5px;
  }

  .current-order-status-box {
    padding: 2px 20px;
  }

  .current-order-status-box a.order-status-btn,
  .current-order-status-box .order-status-btn {
    right: 28px;
  }
}
