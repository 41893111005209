#sidebar-modal-view-orders-action {
  &.sidebar-modal,
  .sidebar-modal-overlay {
    z-index: 99;
    left: auto;
    right: -100%;
    transition: opacity 0s linear 0.35s, right 0.35s;
  }

  &.sidebar-modal.show,
  &.show .sidebar-modal-overlay {
    opacity: 1;
    right: 0;
    transition: opacity 0s linear 0s, right 0.35s;
  }

  &.show .sidebar-modal-overlay {
    transition: opacity 0.35s linear 0.35s, right 0s;
  }

  .sidebar-modal-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0s;
  }

  .sidebar-modal-box {
    left: auto;
    right: 0;
    z-index: 100;
    height: 100%;

    .sidebar-modal-header {
      padding: 57px 18px 6px;
      border: none;
    }

    .sidebar-modal-title {
      font-size: 22px;
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    top: 8px;
    left: 6px;
    font-size: 22px;
    color: #494b4c;

    &:before {
      content: '\e90f';
      font-weight: 400;
    }
  }

  .sidebar-modal-body {
    height: calc(100% - 96px);
  }
}

.sidebar-action-list {
  margin-top: 5px;
  padding: 0 18px;

  > li {
    margin-bottom: 21px;
  }
}

.single-sidebar-action {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 13px;
  min-height: 70px;
  align-items: center;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  &:hover {
    transform: scale(1.03);
  }

  &:before {
    border-radius: 13px;
  }

  .inner-box {
    > :last-child {
      margin-bottom: 0;
    }
  }

  .icon-box {
    position: absolute;
    top: 50%;
    right: 6px;
    width: 42px;
    height: 42px;
    transform: translate(0, -50%);

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 32px;
      line-height: 1;
      transform: translate(-50%, -50%);
    }

    .dine_icon-message {
      font-size: 22px;
    }

    .dine_icon-shape_man {
      font-size: 27px;
    }

    .dine_icon-wallet-cash {
      font-size: 25px;
    }

    .dine_icon-print {
      font-size: 26px;
      margin-top: 1px;
    }

    .dine_icon-silverware {
      font-size: 27px;
    }

    .dine_icon-item-sold {
      margin-left: -2px;
      font-size: 27px;
    }

    .dine_icon-repeat-once-2 {
      margin-left: -2px;
      font-size: 27px;
    }

    .dine_icon-order-price {
      font-size: 25px;
    }

    .dine_icon-close_light1 {
      font-size: 22px;
      font-weight: 700;
    }

    .dine_icon-email {
      font-size: 22px;
    }

    .dine_icon-sms {
      font-size: 22px;
    }

    .dine_icon-chat-bubbles-inverse {
      font-size: 27px;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      @include centered-pos-icon();
    }

    .pos_icon-silverware {
      height: 28px;
    }

    .pos_icon-repeat {
      width: 28px;
    }

    .pos_icon-wallet-cash {
      width: 25px;
    }

    .pos_icon-close {
      width: 22px;
    }

    .pos_icon-shape-man {
      width: 26px;
    }

    .pos_icon-print {
      width: 27px;
    }

    .pos_icon-email-inverse {
      width: 26px;
    }

    .pos_icon-chat-bubbles {
      width: 26px;
    }

    .pos_icon-item-sold {
      height: 27px;
    }

    .pos_icon-order-price {
      height: 24px;
    }

    .pos_icon-bike {
      height: 28px;
    }
  }

  .sidebar-action-title,
  .sidebar-action-subtitle {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    color: #363636;
    text-transform: capitalize;
  }

  .sidebar-action-title {
    span {
      font-weight: 500;
      letter-spacing: -0.03em;
    }
  }

  .sidebar-action-subtitle {
    margin-bottom: 6px;
    font-size: 14px;
    opacity: 0.71;
  }
}
