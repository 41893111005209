.booking-modal,
.modal-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0s;

  .show-keyboard & {
    height: 410px;
  }
}

.booking-modal.show,
.show .modal-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.35s;
}

.modal-overlay {
  z-index: 10;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);

  .show-keyboard & {
    height: 410px;
  }
}

.modal-title {
  margin-bottom: 12px;
  @include font($medium, rem(24), $line-height-heading-2, $font-main);
  text-transform: capitalize;
}

.booking-nav-bar {
  overflow: hidden;
  display: block;
  width: 100%;
  margin-bottom: 23px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  border-radius: 12px;

  a,
  button {
    position: relative;
    display: block;
    float: left;
    width: 25%;
    padding: 14px 10px 14px 50px;
    background-color: transparent;
    font-size: 18px;
    font-weight: $normal;
    text-align: left;
    line-height: $line-height-heading-2;
    letter-spacing: -0.04em;
    color: #363636;

    &:before {
      content: none;
    }

    &:nth-child(1) {
      padding-left: 51px;
      width: 33.8%;
    }

    &:nth-child(2) {
      width: 22.2%;
    }

    &:nth-child(3) {
      width: 22.8%;
    }

    &:nth-child(4) {
      width: 21.2%;
    }

    .icon-box {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 30px;
      height: 30px;
      font-size: 23px;
      transform: translate(0, -50%);
      color: #494b4c;

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .dine_icon-calendar {
        font-size: 25px;
        margin-left: -1px;
        margin-top: 1px;
      }

      .dine_icon-rotate-switch {
        margin-left: -1px;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid #f7f7f7;
    }
  }
}

.booking-tag-box {
  display: block;
  margin-bottom: 3px;

  a,
  button {
    position: relative;
    display: inline-block;
    margin-right: 22px;
    margin-bottom: 20px;
    padding: 7px 18px 7px 39px;
    font-size: 18px;
    font-weight: $normal;
    line-height: $line-height-heading-2;
    color: #363636;
    letter-spacing: -0.04em;
    background-color: transparent;
    border-radius: 40rem;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);

    &:before {
      border-radius: 40rem !important;
    }

    .icon-box {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 22px;
      height: 22px;
      font-size: 18px;
      transform: translate(0, -50%);

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .dine_icon-plus {
        font-size: 14px;
      }
    }
  }
}

.booking-note-list {
  margin-bottom: 26px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  border-radius: 12px;

  ul {
    > li {
      display: block;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(216, 216, 216, 0.26);
      }
    }
  }
}

.booking-note-box {
  position: relative;
  display: block;

  .input-style {
    position: relative;
    z-index: 1;
    min-height: 66px;
    line-height: 34px;
    padding: 15px 56px 15px 15px;
    border-color: transparent;
    box-shadow: none;
  }

  .input-style::-webkit-input-placeholder {
    letter-spacing: -0.025em;
  }

  .input-style::-moz-placeholder {
    letter-spacing: -0.025em;
  }

  .input-style:-ms-input-placeholder {
    letter-spacing: -0.025em;
  }

  .input-style:-moz-placeholder {
    letter-spacing: -0.025em;
  }

  .icon-box {
    position: absolute;
    z-index: 3;
    right: 1px;
    top: 1px;
    width: 60px;
    height: 64px;
    font-size: 32px;
    color: #494b4c;

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -2px;
      transform: translate(-50%, -50%);
    }

    .dine_icon-table {
      font-size: 23px;
    }

    .dine_icon-silverware {
      font-size: 28px;
    }

    .dine_icon-note1 {
      font-size: 25px;
      margin-left: -1px;
      margin-top: 1px;
    }

    .dine_icon-star-inverse {
      font-size: 24px;
    }
  }
}

.booking-actions-list {
  > li {
    display: inline-block;
    vertical-align: top;

    a,
    button {
      position: relative;
      padding: 10px 10px 10px 52px;
      font-size: 18px;
      color: #363636;
      font-weight: $normal;
      line-height: $line-height-heading-2;
      letter-spacing: -0.04em;

      .icon-box {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 10px;
        width: 30px;
        height: 30px;
        margin-top: -1px;
        font-size: 16px;
        transform: translate(0, -50%);
        color: #ffffff;
        background-color: #067aff;
        border-radius: 50%;

        [class^='dine_icon-'],
        [class*=' dine_icon-'] {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .dine_icon-phone {
          font-size: 14px;
        }

        .dine_icon-email {
          display: block;
          width: 100%;
          height: 100%;
          background-image: url(../../../assets/img/icon/email_white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px;

          &:before {
            content: none;
          }
        }

        .dine_icon-mail {
        }
      }

      &.green {
        .icon-box {
          background-color: #5eb602;

          [class^='pos_icon-'],
          [class*=' pos_icon-'] {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            transform-origin: center;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 13px;

      a,
      button {
        padding-left: 50px;
      }
    }

    &:first-child {
      margin-right: 15px !important;

      a,
      button {
        padding-left: 54px;
      }
    }
  }
}

.modal-btn-box {
  padding: 14px 23px;

  a.btn,
  .btn {
    display: block;
    width: 100%;
    max-width: 478px;
    margin: 0 auto;
    letter-spacing: -0.02em;

    &:before {
    }
  }
}

.booking-inner-table {
  display: table;
  width: 100%;
  margin-bottom: 12px;

  .inner-cell {
    display: table-cell;
    vertical-align: middle;

    &:nth-child(2):last-child {
      width: 70%;
      text-align: right;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.modal-header {
  position: relative;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 60px 26px 11px;
  background-color: #ffffff;

  a.btn-plus-style,
  .btn-plus-style {
    position: absolute;
    top: 20px;
    right: 24px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.modal-body {
  overflow: hidden;
  position: relative;
  z-index: 5;
  height: 505px;

  .modal-body-inner {
    padding: 10px 27px;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.modal-footer {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 110%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: translate(-50%, 0) rotate(180deg);
    box-shadow: 1px 4px 9px -7px rgba(0, 0, 0, 0.5);
  }

  .show-keyboard & {
    display: none;
    visibility: hidden;
  }

  .inner-box {
    display: flex;
    width: 100%;

    a,
    button {
      position: relative;
      width: 33.333%;
      margin: 0;
      color: #ffffff;
      text-align: left;
      font-size: 18px;
      font-weight: $medium;
      line-height: $line-height-heading-2;
      padding: 17px 18px 19px 57px;
      letter-spacing: -0.02em;
      background-color: #067aff;

      &.arrow {
        position: relative;

        &:after {
          position: absolute;
          content: '\e960';
          top: 50%;
          right: 13px;
          margin-top: 1px;
          @include icomoon();
          font-weight: 700;
          font-size: 12px;
          transform: translate(0, -50%);
        }
      }

      .icon-box {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 13px;
        width: 30px;
        height: 30px;
        color: #ffffff;
        border-radius: 50%;
        font-size: 22px;
        transform: translate(0, -50%);

        [class^='dine_icon-'],
        [class*=' dine_icon-'] {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .dine_icon-confirmed {
          margin-top: -1px;
          font-size: 25px;
        }

        .dine_icon-table-inverse {
          margin-top: -1px;
          margin-left: 1px;
        }
      }

      &.green {
        background-color: #5eb602;
      }

      &.orange {
        background-color: #fd6c00;
      }

      &.blue {
        background-color: #067aff;
      }

      &.grey {
        background-color: #6d7278;
      }
    }
  }

  &.has-two {
    .inner-box {
      a,
      button {
        width: 50%;
      }
    }
  }
}

.modal-box {
  overflow: hidden;
  position: absolute;
  z-index: 20;
  left: 50%;
  bottom: 0;
  height: 683px;
  width: 700px;
  background-color: #ffffff;
  border-radius: rem(28) rem(28) 0 0;
  transform: translate(-50%, 0);
  transition: top 0.35s;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);

  .show-keyboard & {
    top: 74px;
    height: 325px;
    box-shadow: none;
  }

  #person-booking-modal & {
    .modal-body {
      height: calc(100% - 180px);
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    position: absolute;
    top: 14px;
    left: 14px;
  }

  a.back-modal-btn,
  .back-modal-btn {
    position: absolute;
    top: 13px;
    left: 12px;
  }
}
