@import 'src/assets/scss/global/variables.module';

.bem-counter-box {
  display: grid;
  grid-template-columns: 32px 25px 32px;
  gap: 0 10px;
  margin: 0 auto;
  text-align: center;
  align-items: center;

  &__counter-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: var(--textColor);
    border-radius: 50rem;
    border: solid 2px var(--textColor);
    background-color: var(--mainBG);
    &:disabled {
      color: var(--counterDisabledColor);
      border-color: var(--counterDisabledColor);
    }
  }

  &__counter-img {
    display: block;
  }
  &__icon-minus,
  &__icon-plus {
    stroke-width: 2.75;
    width: 20px;
  }
  &__counter {
    display: block;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.55px;
    color: var(--textColor);
    line-height: normal;
  }

  &--secondary {
    grid-template-columns: 28px 30px 28px;
    .bem-counter-box__counter-button {
      width: 28px;
      height: 28px;
      border: solid 0.5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--shadowCounter);
      &:disabled {
        color: var(--counterDisabledColor);
      }
    }
    .bem-counter-box__icon-minus,
    .bem-counter-box__icon-plus {
      width: 16px;
    }
    .bem-counter-box__icon-minus {
      stroke-width: 4.5;
    }
    .bem-counter-box__icon-plus {
      stroke-width: 4;
    }
    .bem-counter-box__counter {
      font-size: 19.5px;
      font-weight: $medium;
    }
  }
}
