@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-courses-popup {
  position: fixed;
  z-index: 55;
  bottom: 0;
  right: 0;
  max-height: 515px;
  height: calc(100% - 40px);
  width: 31.9%;
  border-radius: 25px 25px 0 0;
  background-color: var(--modalSecondaryBg);
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    width: 100%;
  }
  &__overlay {
    position: fixed;
    z-index: 52;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modalOverlayBg);
  }
  &__header {
    position: relative;
    z-index: 3;
    display: flex;
    padding: 12px 21px 16px;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    margin-right: 20px;
    font-weight: $medium;
    font-size: 22.5px;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
    color: var(--textColor);
  }
  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
  }
  &__body-inner {
    padding: 0 20px 40px;
  }
  &__footer {
    position: relative;
    z-index: 2;
  }
}
