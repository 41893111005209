.show-simple-keyboard {
  &.generalModal-2-box {
    margin-bottom: 355px !important;
  }
}

.virtual-keyboard-wrap {
  visibility: hidden;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  color: $color-text;
  z-index: 99999;
  transform-origin: center;
  transform: translateY(100%);
  background-color: #dcdcdc;

  .dark-mode & {
    color: $color-text;
  }

  .react-simple-keyboard {
    position: relative;
    z-index: 3;
  }

  &.show {
    visibility: visible;
    transform: translateY(0);
  }

  &.floating {
    left: 50%;
    bottom: 0;
    right: auto;
    max-width: 1064px;
    padding-bottom: 40px;
    transform: translateX(-50%);
    background-color: transparent;

    .move-keyboard {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      z-index: 1;
      width: 80px;
      height: 120px;
      background-color: #dcdcdc;
      transform: translateX(-50%);
      border-radius: 0 0 50rem 50rem;
      cursor: move;
    }
  }
}

.simple-keyboard.hg-theme-ios {
  max-width: 1064px;
  width: 100%;
  margin: auto;

  .hg-row {
    display: flex;
  }

  .hg-row:not(:last-child) {
    margin-bottom: 15px;
  }

  .hg-row .hg-button:not(:last-child) {
    margin-right: 15px;
  }

  &.hg-theme-default {
    background-color: #dcdcdc;
    padding: 15px;
    border-radius: 5px;

    .hg-row .hg-button {
      flex-grow: 1;
      cursor: pointer;
      max-width: initial;
    }

    &.hg-layout-custom {
      background-color: #e5e5e5;
      padding: 5px;
    }

    .hg-button {
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0;
      background: white;
      border-bottom: 1px solid #b5b5b5;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      font-weight: 400;
      font-size: 24px;
      max-width: 70px;
      min-width: 50px;
      height: 70px;
      min-height: 70px;
    }

    .hg-button:active,
    .hg-button:focus {
      background: #e4e4e4;
    }

    .hg-button.hg-functionBtn {
      background-color: #adb5bb;
    }

    .hg-button {
      &.hg-button-shift,
      &.hg-button-shiftactivated {
        max-width: 152px;
        min-width: 152px;
      }

      &.hg-button-shift[data-skbtnuid='undefined-r2b10'],
      &.hg-button-shiftactivated[data-skbtnuid='undefined-r2b10'],
      &.hg-button-shift[data-skbtnuid='alt-r2b10'],
      &.hg-button-shiftactivated[data-skbtnuid='alt-r2b10'],
      &.hg-button-shift[data-skbtnuid='smileys-r2b10'],
      &.hg-button-shiftactivated[data-skbtnuid='smileys-r2b10'],
      &.hg-button-shift[data-skbtnuid='default-r2b10'],
      &.hg-button-shiftactivated[data-skbtnuid='default-r2b10'],
      &.hg-button-shift[data-skbtnuid='shift-r2b10'],
      &.hg-button-shiftactivated[data-skbtnuid='shift-r2b10'] {
        max-width: 102px;
        min-width: 102px;
      }

      &.hg-button-tab {
        max-width: 88px;
        min-width: 88px;
      }

      &.hg-button-space {
        background-color: #ffffff;
      }

      &.hg-button-capslock {
        max-width: 116px;
        min-width: 116px;
      }

      &.hg-button-enter {
        max-width: 139px;
        min-width: 139px;
      }

      &.hg-button-smileys {
        max-width: 70px;
        min-width: 70px;
      }

      &.hg-button-alt {
        max-width: 70px;
        min-width: 70px;
      }

      &.hg-button-mic {
        max-width: 70px;
        min-width: 70px;
      }

      &.hg-button-altright {
        max-width: 102px;
        min-width: 102px;
      }

      &.hg-button-floating {
        max-width: 70px;
        min-width: 70px;
      }

      &.hg-button-downkeyboard {
        max-width: 70px;
        min-width: 70px;
      }

      &.hg-button-hg-button-bksp {
        max-width: 89px;
        min-width: 89px;
      }
    }
  }
}
