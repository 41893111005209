.timerContainer {
  border-radius: 20px;
  border: solid 1px #d1d5db;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.timerText {
  padding-left: 5px;
  min-width: 50px;
}
