@import 'src/assets/scss/global/mixins';
@import 'src/assets/scss/global/variables.module';

.bem-transfer-order-modal {
  &__box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 37px 28px 37px;
    background-color: var(--modalSecondaryBg);
  }
  &.generalModal-2-wrap .generalModal-2-box {
    border-radius: 40px;
  }
  &__header {
    margin-bottom: 38px;
  }
  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.05rem;
    color: var(--textColor);
    text-align: center;
  }
  &__body {
    height: 100%;
  }
  &__select {
    overflow: hidden;
  }
  &__lottie-box {
    margin-bottom: 36px;
  }
  .radix-select {
    margin-bottom: 34px;
    color: var(--secondaryColor);
    border-color: var(--secondaryBG);
    background-color: var(--secondaryBG);
    box-shadow: var(--shadowMain);
    border-radius: 8px;
  }
  .radix-select__trigger {
    width: 100%;
  }
  .radix-select__trigger-inner {
    height: 52px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    grid-template-columns: 1fr 30px;
  }
  .radix-select__trigger-inner--with-icon {
    grid-template-columns: 30px 1fr 30px;
  }
  .radix-select__trigger-chevron {
    svg {
      top: 1px;
      width: 12px;
      stroke-width: 5px;
    }
  }
  &__desc {
    margin-bottom: 38px;
    padding: 0 15px;
    font-size: 20px;
    color: var(--manualAmountValueColor);
    line-height: 1.85;
    letter-spacing: -0.025rem;
    text-align: center;
  }
  &__footer {
    .bem-btn {
      height: 72px;
    }
    .bem-transfer-order-modal__desc {
      margin-bottom: 0;
    }
  }
  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    @include gapFlexSupportAlternative(0, 28, 100%, 28);
  }
}
