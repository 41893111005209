// FONT
@mixin font(
  $weight: $normal,
  $size: $size-main,
  $line-height: $line-height,
  $family: $font-main
) {
  font: $weight #{$size}/#{$line-height} $family;
}

// BACKGROUND IMAGE
@mixin bg($bg_position: center, $bg_size: cover, $bg_repeat: no-repeat) {
  background: {
    position: $bg_position;
    size: $bg_size;
    repeat: $bg_repeat;
  }
}

// INPUT PLACEHOLDER
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Icomoon --  need remove after migration to our icons package
@mixin icomoon() {
  font-family: 'dine-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin centered-pos-icon($top: 50%, $left: 50%) {
  position: absolute;
  top: $top;
  left: $left;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
}

/* Gap property for Flexbox - alternative for iOS < 14 */
// https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
@mixin gapFlexSupportAlternative($y: 0, $x: 0, $width: 0, $offset: 0) {
  @if $width > 0 {
    @if $offset > 0 {
      width: calc(#{$width} + #{$offset}px);
    }
  }
  @if ($y > 0) {
    margin-bottom: -#{$y}px;
  }
  @if ($x > 0) {
    margin-left: -#{$x/2}px;
    margin-right: -#{$x/2}px;
  }
  & > * {
    @if ($y > 0) {
      margin-bottom: #{$y}px;
    }
    @if ($x > 0) {
      margin-right: #{$x/2}px;
      margin-left: #{$x/2}px;
    }
  }
}

@mixin gapFlexSupportAlternative_forButtonSquare($x: 0, $baseClass: '') {
  @if ($x > 0) {
    &[data-position='left'] > .#{$baseClass}__icon-box:first-of-type {
      margin-right: #{$x}px;
    }
    &[data-position='right'] > .#{$baseClass}__icon-box:first-of-type {
      margin-left: #{$x}px;
    }
  }
}

@mixin UIStyleTypeModifiers() {
  &--st-primary {
    color: var(--whiteColor);
    border-color: var(--primaryColor);
    background-color: var(--primaryColor);
  }

  &--st-secondary {
    color: var(--secondaryColor);
    border-color: var(--secondaryBG);
    background-color: var(--secondaryBG);
    box-shadow: var(--shadowMain);
  }

  &--st-success {
    color: var(--whiteColor);
    border-color: var(--successColor);
    background-color: var(--successColor);
  }

  &--st-warning {
    color: var(--whiteColor);
    border-color: var(--warningColor);
    background-color: var(--warningColor);
  }

  &--st-danger {
    color: var(--whiteColor);
    border-color: var(--dangerColor);
    background-color: var(--dangerColor);
  }

  &--st-primary-light {
    color: var(--primaryColor);
    border-color: var(--blueColorLight);
    background-color: var(--blueColorLight);
  }

  &--st-secondary-light {
    color: var(--secondaryColor);
    border-color: var(--greyColorLight);
    background-color: var(--greyColorLight);
  }

  &--st-success-light {
    color: var(--successColor);
    border-color: var(--greenColorLight);
    background-color: var(--greenColorLight);
  }

  &--st-warning-light {
    color: var(--warningColor);
    border-color: var(--orangeColorLight);
    background-color: var(--orangeColorLight);
  }

  &--st-danger-light {
    color: var(--dangerColor);
    border-color: var(--redColorLight);
    background-color: var(--redColorLight);
  }

  &--st-primary-outline {
    color: var(--primaryColor);
    border-color: var(--primaryColor);
    background-color: var(--transparentColor);
  }

  &--st-secondary-outline {
    color: var(--textColor);
    border-color: var(--greyColor-400);
    background-color: var(--transparentColor);
  }

  &--st-success-outline {
    color: var(--successColor);
    border-color: var(--successColor);
    background-color: var(--transparentColor);
  }

  &--st-warning-outline {
    color: var(--warningColor);
    border-color: var(--warningColor);
    background-color: var(--transparentColor);
  }
  &--st-danger-outline {
    color: var(--dangerColor);
    border-color: var(--dangerColor);
    background-color: var(--transparentColor);
  }
}
@mixin UITextColorModifiers() {
  &--tc-primary {
    color: var(--primaryColor);
  }
  &--tc-secondary {
    color: var(--secondaryColor);
  }
  &--tc-success {
    color: var(--successColor);
  }
  &--tc-warning {
    color: var(--warningColor);
  }
  &--tc-danger {
    color: var(--dangerColor);
  }
}

@mixin gapFlexSupportAlternative_forButton($x: 0, $offset: -2) {
  @if ($x > 0) {
    &[data-position='left'] > svg:first-child,
    &[data-position='left'] > img:first-child {
      margin-right: #{$x}px;
      margin-left: #{$offset}px;
    }
    &[data-position='right'] > svg:first-child,
    &[data-position='right'] > img:first-child {
      margin-left: #{$x}px;
      margin-right: #{$offset}px;
    }
  }
}

@mixin UIInnerSpacingModifiers() {
  &--inner-spacing-xxs {
    padding: 0 6px;
    @include gapFlexSupportAlternative_forButton(4);
  }
  &--inner-spacing-xs {
    padding: 0 8px;
    @include gapFlexSupportAlternative_forButton(6);
  }
  &--inner-spacing-s {
    padding: 0 10px;
    @include gapFlexSupportAlternative_forButton(8);
  }
  &--inner-spacing-m {
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
  }
  &--inner-spacing-l {
    padding: 0 14px;
    @include gapFlexSupportAlternative_forButton(12);
  }
  &--inner-spacing-xl {
    padding: 0 16px;
    @include gapFlexSupportAlternative_forButton(14);
  }
  &--inner-spacing-xxl {
    padding: 0 18px;
    @include gapFlexSupportAlternative_forButton(16);
  }
}

@mixin UISizeTypeModifiers($baseClass: '') {
  &--size-xxs {
    min-height: 19px;
    font-size: 12px;
    padding: 0 6px;
    @include gapFlexSupportAlternative_forButton(4, -1);
    .#{$baseClass}__text {
      padding: 1px 0;
    }
  }
  &--size-xs {
    min-height: 22px;
    font-size: 14px;
    padding: 0 6px;
    @include gapFlexSupportAlternative_forButton(4, -1);
    .#{$baseClass}__text {
      padding: 1px 0;
    }
  }
  &--size-s {
    font-size: 16px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 4px 0;
    }
  }
  &--size-m {
    font-size: 16px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 7px 0;
    }
  }
  &--size-l {
    font-size: 18px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 8px 0;
    }
  }
  &--size-xl {
    font-size: 18px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 13px 0;
    }
  }
  &--size-xxl {
    font-size: 20px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 19px 0;
    }
  }
  &--size-xxxl {
    font-size: 20px;
    padding: 0 12px;
    @include gapFlexSupportAlternative_forButton(10);
    .#{$baseClass}__text {
      padding: 27.5px 0;
    }
  }
}

@mixin UIGapModifiers() {
  &--gap-xs {
    @include gapFlexSupportAlternative(4, 4);
  }

  &--gap-s {
    @include gapFlexSupportAlternative(8, 8);
  }

  &--gap-m {
    @include gapFlexSupportAlternative(12, 12);
  }

  &--gap-l {
    @include gapFlexSupportAlternative(14, 14);
  }

  &--gap-xl {
    @include gapFlexSupportAlternative(18, 18);
  }
}

@mixin UIFontWeightModifiers() {
  &--fw-light {
    font-weight: 300;
  }

  &--fw-regular {
    font-weight: 400;
  }

  &--fw-medium {
    font-weight: 500;
  }

  &--fw-bold {
    font-weight: 700;
  }
}

@mixin UIFontSizeModifiers() {
  &--fs-xs {
    font-size: 12px;
  }

  &--fs-s {
    font-size: 14px;
  }

  &--fs-m {
    font-size: 16px;
  }

  &--fs-l {
    font-size: 18px;
  }

  &--fs-xl {
    font-size: 20px;
  }
}

@mixin UIRadiusModifiers() {
  &--r-none {
    border-radius: 0;
  }
  &--r-xs {
    border-radius: 4px;
  }
  &--r-s {
    border-radius: 8px;
  }
  &--r-m {
    border-radius: 10px;
  }
  &--r-l {
    border-radius: 12px;
  }
  &--r-xl {
    border-radius: 16px;
  }
  &--r-full {
    border-radius: 50rem; //for equal  border radius for any size
  }
}

@mixin UIIconPositionModifiers() {
  &--ip-left {
    flex-direction: row;
  }
  &--ip-right {
    flex-direction: row-reverse;
  }
}

@mixin UIJustifyContentModifiers() {
  &--jc-left {
    justify-content: flex-start;
  }
  &--jc-center {
    justify-content: center;
  }
  &--jc-between {
    justify-content: space-between;
  }
  &--jc-right {
    justify-content: flex-end;
  }
}

@mixin UIAlignItemsModifiers() {
  &--ai-start {
    align-items: flex-start;
  }
  &--ai-center {
    align-items: center;
  }
  &--ai-end {
    align-items: flex-end;
  }
}

@mixin UIBorderSideModifiers(
  $width: 1px,
  $style: solid,
  $color: var(--blackColor)
) {
  &--b-top {
    border-top: $width $style $color;
  }
  &--b-right {
    border-right: $width $style $color;
  }
  &--b-bottom {
    border-bottom: $width $style $color;
  }
  &--b-left {
    border-left: $width $style $color;
  }
}
@mixin UIOtherModifiers() {
  &--full-width {
    width: 100%;
  }
  &--disabled,
  &:disabled {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    cursor: default;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--cursor-default {
    cursor: default;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--loading {
    cursor: default;
    .bem-btn__text {
      opacity: 0.35;
    }
    .profile-main-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 36px;
      height: 36px;
      max-width: 80%;
      max-height: 80%;
      transform: translate(-50%, -50%);
    }
    svg circle {
      stroke: currentColor;
    }
  }
}

/* UIModifiers */
@mixin UIModifiers() {
  @include UIStyleTypeModifiers();
  @include UIGapModifiers();
  @include UIFontSizeModifiers();
  @include UIFontWeightModifiers();
  @include UIRadiusModifiers();
  @include UIOtherModifiers();
}

@mixin ModalBodyRightGradient() {
  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba($color-white, 0) 0%,
      rgba($color-white, 0.9) 90%,
      $color-white 100%
    );
    z-index: 5;
    pointer-events: none;
  }
}

/* Visibility */
@mixin hidden {
  visibility: hidden;
  opacity: 0;
  // transition: opacity 0s;
  will-change: visibility, opacity;
}
@mixin visible {
  visibility: visible;
  opacity: 1;
  // transition: opacity 0.05s;
}
