.bem-step-modal {
  &.generalModal-2-wrap {
    z-index: 600;
    @media all and (max-height: 700px) {
      .generalModal-2-box {
        margin: 0 auto;
        border-radius: 0;
      }
    }
  }
}
