.app-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 21px;
  background-color: #ffffff;

  &.hide-status-bar {
    padding-top: 0;
  }

  &.home-page {
    background-color: #000000;
  }
}

.notification-with-menu-items {
  padding-top: 30px;
}
.app-wrapper-notification {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 36px;
  background-color: #ffffff;

  &.hide-status-bar {
    padding-top: 0;
  }

  &.home-page {
    background-color: #000000;
  }
}

.dark-mode {
  .app-wrapper {
    background-color: #000000;

    &.home-page {
      background-color: #000;
    }
  }
}
