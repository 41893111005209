#split-bill-discount-modal {
  .modal-box {
    width: 536px;
    max-height: 90%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }

  .modal-header {
    padding-top: 30px;

    a.voucher-btn,
    .voucher-btn {
      font-size: 14px;
      font-weight: $normal;

      &:before {
        border-radius: 50rem;
      }

      .inner-btn-box {
        padding: 5px 13px 5px 41px;
        border-color: transparent;
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);

        &:after {
          position: absolute;
          content: '\e967';
          top: 50%;
          left: 12px;
          @include icomoon();
          font-size: 16px;
          transform: translate(0, -50%);
        }
      }
    }
  }

  .modal-body {
    height: calc(100% - 211px);

    .modal-body-inner {
      padding: 13px 27px;
      display: flex;
      justify-content: center;
    }
  }

  .modal-loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .modal-title {
    padding-right: 140px;
    letter-spacing: 0.02em;
  }

  .modal-sub-title {
    text-transform: none;
  }

  .item-cards {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    .item-card {
      .inner-box {
        grid-template-columns: 1fr;
        gap: 8px 0;
      }
      &:after {
        content: none;
      }

      label {
        width: 100%;
        min-height: 91px;
        padding: 12px;
        text-align: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 7px;
        box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.335);
        border: 1px solid #e8e8e8;
        border-color: rgba(232, 232, 232, 0.62);

        &:before {
          border-radius: 7px;
        }
      }

      input {
        &:disabled {
          appearance: none;

          & + label {
            opacity: 0.5;
            cursor: default;
            border: 0;

            .card-title {
              color: #717374;
            }
          }
        }
      }

      .card-title {
        margin-bottom: 0;
        font-size: 20px;
        color: #5eb602;
      }

      .card-discount {
        font-size: 20px;
        color: #717374;
        opacity: 0.94;
      }

      &.custom {
        .card-title {
          color: #0084ff;
        }
      }

      input:checked + label {
        border-color: #7aac4a;
      }
    }
  }

  .modal-footer {
    &:before {
      content: none;
    }
  }

  .modal-btn-box {
    padding: 20px 23px;
  }

  a.back-modal-btn,
  .back-modal-btn {
    &:after {
      content: '\e905';
      font-size: 26px;
      font-weight: 400;
      color: #363636;
      transform: translate(-50%, -50%) rotate(0deg);

      .dark-mode & {
        color: #ffffff;
      }
    }
  }

  .voucher-modal {
    .modal-overlay {
      background-color: rgba(0, 0, 0, 0.3);

      .dark-mode & {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
