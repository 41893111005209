.customer-phone-modal {
  &.generalModal-2-wrap {
    @media all and (max-height: 800px) {
      .generalModal-2-box {
        margin: 0 auto;
      }
    }
  }
}
.customer-phone-modal-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;

  .customer-phone-header {
    position: relative;
    padding: 39px 20px 20px;

    .customer-payment-details & {
      padding: 33px 23px 10px;
      text-align: left;
    }
  }

  .customer-phone-title {
    margin-bottom: rem(59);

    .cash-amount & {
      margin-bottom: rem(64);
    }
  }

  .cash-amount-total {
    overflow: hidden;
    display: block;
    margin-bottom: 40px;
    font-size: 3.8rem;
    min-height: 50px;
    line-height: 1.25;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.grey {
      color: #777777;
    }
  }

  .cash-amount-error-div {
    height: 20px;
  }

  .cash-amount-error {
    font-size: 14px;
    color: $pos-error;
  }

  .customer-phone-desc {
    max-width: 315px;
    margin: 0 auto 12px;
    font-size: 20px;
    font-weight: $medium;
    line-height: 1.25;
    letter-spacing: -0.02em;
    color: #067aff;

    .inner-btn-box {
      display: block;
      padding: 0 5px;
    }
  }

  .customer-phone-line {
    overflow: hidden;
    margin: 0 auto 92px;
    font-size: rem(34);
    font-weight: $normal;
    letter-spacing: -0.02em;
  }

  .customer-phone-inner {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    min-width: 247px;
    max-width: 320px;
    height: 54px;
    margin: 0 auto;
    text-align: center;
    vertical-align: top;

    .pos_icon-phone-inverse {
      position: absolute;
      left: -38px;
      top: 50%;
      width: 35px;
      height: 35px;
      transform-origin: center;
      transform: translate(0, -50%);
    }

    .number {
      white-space: nowrap;
    }
  }

  .customer-phone-keypad-box {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex-flow: row wrap;
    align-items: center;
    background-color: #fbfbfb;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;

    .customer-phone-key-wrap {
      width: (100% / 3);
      height: 25%;
      padding: 0;
      border: 1px solid #e9e9e9;

      &:nth-child(3n + 1) {
        border-left: none;
      }

      &:nth-child(3n) {
        border-right: none;
      }
    }

    .customer-phone-key {
      overflow: hidden;
      position: relative;
      display: block;
      margin: 0;
      height: 100%;
      width: 100%;
      font-size: rem(30);
      line-height: 1.2;
      color: #515151;
      border-radius: 0;
      @media all and (max-height: 730px) {
        height: rem(70);
      }
      @media all and (max-height: 600px) {
        height: rem(63);
      }

      .customer-payment-details & {
        height: rem(87);
        @media all and (max-height: 730px) {
          height: rem(70);
        }
        @media all and (max-height: 600px) {
          height: rem(63);
        }
      }

      .inner-btn-box {
      }

      .pos_icon-dot {
        top: -8px;
        height: 8px;
        width: 8px;
      }

      .pos_icon-plus {
        height: 22px;
      }

      .pos_icon-back-icon {
        height: 25px;
      }

      .pos_icon-left-chevron-second {
        height: 25px;
        width: 16px;
      }

      &:disabled {
        color: transparent;

        [class^='pos_icon-'],
        [class*=' pos_icon-'] {
          opacity: 0;
        }
      }

      &:active,
      &.active {
        &:before {
          opacity: 0 !important;
        }

        background-color: #ffffff;
      }
    }
  }

  .customer-phone-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(20);
      font-weight: $medium;
      letter-spacing: -0.02em;

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 17px 20px;
        border-radius: 0;
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.payment-card-box {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;

  .customer-phone-header & {
    box-shadow: none;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .customer-phone-title {
    margin-bottom: 0;
  }

  .pos_icon-credit-card {
    width: 31px;
    height: 23px;
  }

  .payment-services-box {
    position: relative;
    right: -8px;
    top: -4px;

    .single-payment-service {
      width: 43px;
      height: 30px;

      &.mastercard {
        img {
          max-width: 22px;
        }
      }

      &.visa {
        img {
          max-width: 26px;
        }
      }

      &.amex {
        img {
          max-width: 30px;
        }
      }
    }
  }

  .payment-row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    padding: 0 25px;
    text-align: left;
    align-content: center;
    justify-content: space-between;

    .customer-phone-header & {
      padding: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }

    label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: #cfd7df;
      text-transform: uppercase;
      transition: 0.35s;

      &.active {
        color: #363636;

        .input-style {
          border-bottom-color: grey;
        }
      }

      &.error {
        .input-style {
          border-bottom-color: red;
        }
      }

      &.valid {
        .input-style {
          border-bottom-color: green;
        }
      }
    }

    .input-style {
      margin-top: 7px;
      margin-left: -5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 23px;
      font-weight: 400;
      letter-spacing: -0.05em;
      border-color: transparent;
      cursor: pointer;
      border-radius: 0;
      line-height: 28px;

      &::-webkit-input-placeholder {
        padding: 0;
        @include font($light, 23px, rem(28), $font-main);
        letter-spacing: -0.05em;
        color: #979797;
        transition: 0.35s;
        opacity: 1;
      }

      &::-moz-placeholder {
        padding: 0;
        @include font($light, 23px, rem(28), $font-main);
        letter-spacing: -0.05em;
        color: #979797;
        transition: 0.35s;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        padding: 0 8px;
        @include font($light, 23px, rem(28), $font-main);
        letter-spacing: -0.05em;
        color: #979797;
        transition: 0.35s;
        opacity: 1;
      }

      &:-moz-placeholder {
        padding: 0;
        @include font($light, 23px, rem(28), $font-main);
        letter-spacing: -0.05em;
        color: #979797;
        transition: 0.35s;
        opacity: 1;
      }
    }

    > div {
      display: flex;
      align-content: center;
      padding: 10px 5px 9px;

      [class^='pos_icon-'],
      [class*=' pos_icon-'] {
        margin: auto;
      }

      .customer-phone-header & {
        padding: 5px 5px;

        &:nth-child(1):nth-last-child(2) {
          padding-right: 10px;
        }

        &:nth-child(2):nth-last-child(1) {
          padding-left: 10px;
        }
      }
    }

    &.row-header {
    }

    &.row-number {
      .card-preview {
        .single-payment-service {
          width: 43px;
          height: 30px;
          position: relative;
          display: inline-block;
          margin: auto;
          line-height: 39px;
          text-align: center;
          box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          background-color: #ffffff;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: auto;
            height: auto;
            max-width: 49px;
            max-height: 39px;
            transform: translate(-50%, -50%);
          }

          &.amex {
            background-color: #3498d8;
          }
        }
      }
    }

    &.row-other {
      > div {
        padding-top: 5px;
        padding-bottom: 8px;

        &:first-child {
          flex-grow: 0;
          flex-shrink: 0;
          width: 164px;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
}

.payment-error-message {
  position: absolute;
  z-index: 10;
  color: red;
  bottom: 5px;
  left: 29px;
  font-size: 14px;
}

.dark-mode {
  .customer-phone-modal-box {
    .customer-phone-title {
      color: #ffffff;
    }

    .customer-phone-key-wrap {
      border-color: #444444;
    }

    .customer-phone-keypad-box {
      border-color: #444444;
      background-color: #2b2c2e;

      .customer-phone-key {
        color: #ffffff;

        &.active,
        &:active {
          background-color: #000000;
        }
      }
    }
  }

  .payment-card-box {
    background-color: #1f1e23;

    .payment-row {
      label {
        color: #6c6c6c;

        &.active {
          color: #ffffff;
        }
      }

      &:not(:last-child) {
        border-bottom-color: rgba(210, 210, 210, 0.4);
      }
    }
  }
}

.windcaveSplitPaymentInfoContainer {
  display: flex;
  justify-content: center;
  color: #f39c12;
  font-weight: 500;
}
