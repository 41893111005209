@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

.bem-square-btn {
  display: inline-flex;
  align-items: center;
  @include gapFlexSupportAlternative_forButtonSquare(14, 'bem-square-btn');
  @include UIIconPositionModifiers();
  &__text {
    font-size: 15px;
    font-weight: $medium;
    line-height: $line-height-heading-2;
    color: var(--squareBtnTextColor);
  }
  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    box-shadow: var(--shadowMain);
    border-radius: 8.5px;
    @include UIStyleTypeModifiers();
  }
}
