@import '../../assets/scss/global/variables.module';

.count-box {
  display: inline-block;
  width: rem(26);
  height: rem(26);
  color: $color-white;
  font-size: rem(14.106);
  font-weight: $medium;
  text-align: center;
  line-height: rem(26);
  border-radius: 20rem;
  background-color: $color-bg-blue;
}