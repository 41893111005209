@import '../../assets/scss/global/variables.module';

.previous-orders {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: grid;
    gap: 8px 0;
    padding: 14px 27px 10px;
  }

  &__title {
    font-size: rem(28.75);
    font-weight: $medium;
    letter-spacing: -0.45px;
    line-height: $line-height-heading-2;

    .dark-mode & {
      color: $color-white;
    }
  }

  &__grid {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 2fr 1fr;
    gap: 10px;

    > :nth-child(even) {
      text-align: right;
    }
  }

  &__text {
    font-size: rem(17);
    font-weight: $medium;
    color: #a5a5a5;
    letter-spacing: -0.3px;

    .dark-mode & {
      color: rgba(255, 255, 255, 0.77);
    }
  }

  &__body {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__body-inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}