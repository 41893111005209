.add-payment-type {
  display: grid;
  gap: 24px 0;
  padding: 24px;

  &__item {
    display: grid;
    align-items: center;
    min-height: 82px;
    padding: 10px 20px;
    gap: 0 20px;
    grid-template-columns: 1fr 46px;
    color: $color-main;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);
    text-align: left;
    cursor: pointer;

    &--blue {
      color: #067aff;
    }

    &--green {
      color: #5eb602;
    }

    &--orange {
      color: #fd6c00;
    }

    &--purple {
      color: #b620e0;
    }

    .dark-mode & {
      background-color: #1f1e23;
      border-color: #000000;
    }
  }

  &__cell-icon {
    place-self: center;
  }

  &__text {
    display: block;
    font-size: rem(20.5);
    font-weight: $medium;
    line-height: 1.2;
  }
}
