.windcaveRecoveryContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-items: center;
  overflow-y: auto;
  background-color: #f7f7f7;
}

.windcaveHeaderContainer {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}

.windcaveTerminalbox {
  display: flex;
  justify-content: center;
}
.windcavePaymentServicesBox {
  display: flex;
}
.windcavePaymentServicesBox button {
  box-shadow: 0 0 6px -3px black;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #5eb602;
  width: 220px;
  min-height: 60px;
  margin-bottom: 10px;
}

.windcaveLoadingContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  flex-direction: column;
}

.loadingInfoTextContainer {
  margin-top: 20px;
  padding: 40px;
  color: #787878;
}

.paymentSuccessfullText {
  color: #70cd5e;
  font-weight: 500;
  font-size: 18px;
}

.windCaveWarningContainer {
  color: #f39c12;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  position: absolute;
  top: 78px;
  left: 127px;
}
