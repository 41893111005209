.actionHeader {
  display: flex;
  justify-content: center;
  border: 0.5px solid hsla(0, 0%, 91.4%, 0.71);
  min-height: 40px;
  align-items: center;
  font-weight: 500;
  background-color: #fbfbfb;
  padding: 5px;
}

.actionButton {
  display: flex;
  border: 0.5px solid hsla(0, 0%, 91.4%, 0.71);
  height: 45px;
  align-items: center;
  padding-left: 15px;
  cursor: pointer;
}

.actionButtonText {
  margin-left: 10px;
  color: #5b5b5b;
  font-weight: 400;
}

.disableActionButton {
  opacity: 0.5;
}
