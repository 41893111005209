.bem-bill-check-item {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 80px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 13px 18px 13px;
  flex: 1;
  &__inner {
    display: grid;
    gap: 6px 0;
  }
  &__text {
    word-break: break-word;
    display: block;
    font-size: 17px;
    line-height: 1.25;
    letter-spacing: -0.025rem;
  }
  &__instruction {
    display: block;
    font-size: 14px;
    line-height: 1.25;
    color: var(--greyColor-600);
    letter-spacing: -0.025rem;
  }
  &__price-box {
    display: block;
    text-align: right;
  }
}

.voided {
  text-decoration: line-through;
}
