.cart-tag {
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.25;
  letter-spacing: -0.02em;
  text-decoration: none;
  cursor: pointer;
  border-width: 1px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  border-width: 1px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  padding: 0px 12px 0px 10px;
}

.cart-tag-text {
  margin-left: 5px;
  font-weight: 400;
  color: #5e5e5e;
}

.is-cart-tag-selected {
  color: #2f7cff;
  font-weight: 500;
  background-color: #f1f8ff;
}

.custom-svg-style > line {
  stroke: #2f7cff;
  stroke-width: 4.2;
}
