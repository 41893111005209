.bem-single-cart-item {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
  text-align: left;
  padding: 11px 0 11px 14px;
  align-items: center;
  appearance: none;
  cursor: pointer;
  border-bottom: 1px solid var(--greyColorLight);
  transition: all 0.35s ease-in-out;
  background-color: var(--mainBG);

  &.voided > * {
    opacity: 0.3;
    text-decoration: line-through;
  }

  &.add-item {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0s;
  }
  &.delete-item {
    opacity: 0;
    max-height: 0;
    transform: translateX(100%);
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1 1 auto;
    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__inner-box {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    > :not(:last-child) {
      margin-right: 6px;
    }

    &--top {
      justify-content: space-between;
    }
    &--bottom {
    }
  }
  &__title {
    display: block;
    color: var(--textColor);
    font-size: 15.5px;
    font-weight: 400;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.05rem;
  }
  &__ingredients-wrap {
    overflow: hidden;
    display: grid;
  }
  &__ingredients {
    overflow: hidden;
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.025rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--greyColor-700);
    b,
    strong {
      font-weight: 500;
    }
  }
  &__edit-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 32px;
    width: 32px;
    height: 37px;
    color: var(--greyColor-250);
    background-color: var(--mainBG);
    appearance: none;
  }
}
.order-item-container{
  display:flex;
  width: 100%;
}

.order-item{
 flex-grow: 1;
}
.price-tag{
  align-self: center;
}