@import 'src/assets/scss/global/mixins';

@mixin UIButtonSizeModifiers() {
  &--circle-size-s {
    width: 38px;
    height: 38px;
  }
  &--circle-size-m {
    width: 42px;
    height: 42px;
  }
  &--circle-size-l {
    width: 46px;
    height: 46px;
  }
}

.bem-circle-btn {
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 50rem;

  svg {
    position: relative;
    min-width: 16px;
    max-width: 90%;
    transform-origin: center;
  }

  @include UIStyleTypeModifiers();
  @include UIButtonSizeModifiers();

  &__icon-back {
    left: -1px;
  }
}
