.menuItemListEvenItem {
  display: flex;
  background-color: #fbfbfb;
  border: 0.5px solid hsla(0, 0%, 91.4%, 0.71);
  border-right: none;
  border-left: none;
  padding: 12px 10px 12px 25px;
}
.hold {
  background-color: #e4f3ff !important;
}
.rush {
  background-color: #ffede0 !important;
}
.menuItemListOddItem {
  display: flex;
  background-color: #ffffff;
  border-right: none;
  border-left: none;
}

.menuItemName {
  width: 40%;
  font-weight: 500;
  align-self: center;
  cursor: pointer;
}

.menuItemActionButton {
  display: flex;
  width: 60%;
}

.threeDotsIconContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.coursesListContainer {
  width: 100%;
  margin-right: 10px;
  align-self: center;
}

.coursesList {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #f0f0f0;
  padding: 3px;
  border-radius: 10px;
  color: #656566;
  font-weight: 500;
  font-size: 15px;
}

.coursesList > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #f0f0f0;
  text-align: center;
  border-radius: 7px;
}

.courseTag {
  cursor: pointer;
}

.subInfoIcon {
  width: 17px;
  height: 17px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.subInfoContainer {
  display: flex;
  align-items: center;
}

.subInfoText {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 400;
}

.menuItemSent {
  background-color: #e2f6d5;
}
