@import 'src/assets/scss/global/variables.module';
@import 'src/assets/scss/global/mixins';

@mixin modalStyles {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bem-modal-wrapper {
  @include modalStyles;
  @include hidden;

  &__overlay {
    @include modalStyles;
    @include hidden;

    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &--show {
    @include visible;

    .bem-modal-wrapper__overlay {
      @include visible;
    }
  }
}
