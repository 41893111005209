@import 'src/assets/scss/global/variables.module';

.refund-select-list {
  text-align: left;
  &__subgroup {
    display: block;
    width: 100%;
    max-width: calc(100% - 82px);
    margin-left: 82px;
    border-top: rem(1.5) solid #e9e9e9;
    .dark-mode & {
      border-top-color: $color-main;
    }
  }
  & > div:nth-child(even) {
    background-color: #f7f7f7;
    .dark-mode & {
      background-color: #1f1e23;
    }
  }

  & > div:nth-child(odd) {
    background-color: #ffffff;
    .dark-mode & {
      background-color: #000000;
    }
  }

  &__label {
    display: grid;
    gap: 0 25px;
    padding: 10px 30px;
    min-height: 64px;
    grid-template-columns: 26px 1fr;
    align-items: center;
    cursor: pointer;
    &--with-price {
      grid-template-columns: 26px 3fr 1fr;
    }
    &--small {
      min-height: 46px;
      padding-left: 0;
      gap: 0 16px;
    }
  }

  &__item-cell {
    display: block;
    &--align-right {
      text-align: right;
    }
  }
  &__input-checkbox,
  &__input-radio {
    appearance: none;
    padding: 0;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    width: 26px;
    height: 26px;
    color: #2563eb;
    background-color: #fafafa;
    border: none;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
    .dark-mode & {
      background-color: $color-dark-bg;
      box-shadow: inset 0 0 4px 0 rgba(255, 255, 255, 0.25);
      //box-shadow: inset 0 0.8px 8px 0 rgb(0 0 0 / 19%)
    }
    &--partially-checked {
      background-color: $pos-green;
      position: relative;
      &:before {
        content: '';
        background-color: white;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
    &:checked {
      background-color: $pos-green;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 12'%3E%3Cpath d='M4.926,11 C4.68725347,11.0004531 4.45824407,10.9051674 4.29,10.7353729 L0,6.43520955 L1.266,5.16621028 L4.926,8.83488115 L13.734,0 L15,1.2810277 L5.556,10.7353729 C5.38922281,10.9036917 5.16266593,10.9988547 4.926,11 Z' transform='translate(.5 .5)' fill='%23FFF'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      box-shadow: inset 0 0 4px 0 rgba(99, 99, 99, 0.5);
      .dark-mode & {
        box-shadow: inset 0 0 4px 0 rgba(99, 99, 99, 0.5);
      }
    }
    &--small {
      width: 24px;
      height: 24px;
      &:checked {
        background-size: 14px;
      }
    }
  }
  &__input-radio {
    border-radius: 30rem;
  }
  &__input-checkbox {
    border-radius: 30rem;
  }
  &__text {
    display: block;
    font-size: rem(17);
    letter-spacing: -0.48px;
    color: $color-main;
    line-height: 1.2;
    .dark-mode & {
      color: $color-white;
    }
    &--small {
      font-size: rem(16);
      letter-spacing: -0.45px;
    }
    &--theme-grey {
      color: #747474;
    }
  }
}
