.courses-modal-header {
  position: relative;
  padding: 20px 20px;
  box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.9);
}

.header-buttons-section {
  display: flex;
  padding-top: 10px;
  padding-left: 5px;
}

.course-title {
  font-size: 22px;
  font-weight: 500;
  flex: 1;
}

.courses-acttion-button {
  padding-left: 15px;
}

.course-seat-section-header {
  color: white;
  font-weight: 500;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
}

.course-seat-section-header-span {
  padding-left: 10px;
}

.detail-card-input-box {
  width: 31px;
  height: 31px;
  transform: translateY(-50%);
  margin-right: 15px;
}

.radioInput {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 0;
  display: inline-block;
  width: 29px;
  height: 29px;
  cursor: pointer;
}

.radioInput:checked + .course-checkstyle {
  border-color: #7aac4a;
  background-color: #7aac4a;
}

.radioInput:checked + .course-checkstyle::after {
  content: '\e901';
  font-family: 'dine-icons' !important;
  top: 0;
  left: 19%;
  color: #fff;
  position: absolute;
}

.course-checkstyle {
  position: absolute;
  z-index: 3;
  top: 15px;
  display: inline-block;
  width: 31px;
  height: 31px;
  font-size: 1.8rem;
  border: 0.1rem solid #989898;
  background-color: #fff;
  box-shadow: inset 0 0.8px 8px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20rem;
  pointer-events: none;
  cursor: pointer;
}

.course-modal-footer {
  position: absolute;
  bottom: 0;
  height: 70px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 7px -4px rgba(0, 0, 0, 0.9);
}

.course-modal-footer-button-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.course-footer-button {
  height: 50px;
  width: 60%;
}
