.main-area-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  justify-content: space-between;
  height: 100%;
  background-color: #ffffff;
}

.dark-mode {
  .main-area-wrap {
    background-color: #000000;
  }
}
