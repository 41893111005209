.payoutCard {
  padding: 21px 22px 24px 18px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
  display: flex;
  width: 100%;
}
.payoutCardText {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.02em;
  color: #363636;
  flex: 1;
}

.payoutItemContainer {
  display: flex;
  height: 21px;
}
