.customize-half-btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: rem(92);
  font-size: rem(14);
  font-family: $font-main;
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -$letter-spacing-20;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $color-white;
  appearance: none;
}

a.btn,
.btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: rem(92);
  font-size: rem(14);
  font-family: $font-main;
  font-weight: $medium;
  line-height: $line-height-heading-2;
  letter-spacing: -$letter-spacing-20;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $color-white;
  appearance: none;

  &:before {
    border-radius: 3px;
  }

  .inner-btn-box {
    position: relative;
    display: block;
    margin: 0;
    width: 100%;
    padding: 6px 12px;
    border: rem(2) solid #067aff;
    background-color: #067aff;
    border-radius: 3px;
    transition: background-color 0s;

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: relative;
      top: rem(1);
      padding-right: 10px;
      font-size: 16px;
    }

    .dine_icon-plus {
      position: relative;
      top: rem(-1);
      padding-right: 5px;
      font-size: rem(10);
      font-weight: $bold;
    }

    .dine_icon-cash {
      font-size: 20px;
      top: 4px;
      line-height: 10px;
      padding-right: 6px;
      font-weight: $normal;
    }
  }

  &.red {
    .inner-btn-box {
      border-color: $pos-error;
      background-color: $pos-error;
    }
  }

  &.big {
    font-size: rem(17.84);
    letter-spacing: 0.02em;

    &:before {
      border-radius: rem(7.667);
    }

    .inner-btn-box {
      padding: rem(12) rem(20);
      border-radius: rem(7.667);
    }
  }

  &.inverse {
    color: #067aff;

    .inner-btn-box {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.grey {
    color: $color-text;

    .inner-btn-box {
      border-color: #dedede;
      background-color: #dedede;
    }

    &.inverse {
      .inner-btn-box {
        background-color: transparent;
      }

      &.active {
        .inner-btn-box {
          background-color: #dedede;
        }
      }
    }
  }

  &.green {
    color: #ffffff;

    .inner-btn-box {
      border-color: #5eb602;
      background-color: #5eb602;
    }
  }

  &.red {
    color: #ffffff;

    .inner-btn-box {
      border-color: $pos-error;
      background-color: $pos-error;
    }
  }

  &.round {
    font-size: rem(14.5);
    font-weight: $medium;

    &:before {
      border-radius: 20rem;
    }

    .inner-btn-box {
      padding: rem(5.5) rem(10);
      border-radius: 20rem;
      border-width: rem(1);
    }
  }

  &.white {
    color: #363636;
    font-weight: $normal;

    .inner-btn-box {
      border-color: #ffffff;
      background-color: #ffffff;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    }
  }

  &--more-round {
    .inner-btn-box {
      border-radius: 13px;
    }
  }

  &--small {
    min-width: rem(69);

    .inner-btn-box {
      display: flex;
      align-items: center;
      padding: 2px 8px;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      max-width: 17px;
      max-height: 17px;
      margin-right: 4px;
    }
  }

  &--with-icon {
    .inner-btn-box {
      display: flex;
      align-items: center;
    }

    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      max-width: 17px;
      max-height: 17px;
      margin-right: 8px;
    }
  }

  &:disabled {
    .inner-btn-box {
      background-color: #d6d6d6;
      border-color: #d6d6d6;
    }

    cursor: default;
  }
}

.btn-list {
  margin: 0 auto -18px;

  > li {
    display: inline-block;
    margin-bottom: 18px;

    &:not(:first-child) {
      margin-left: 18px;
    }
  }

  &.custom {
    > li {
      margin-right: 22px;

      &:nth-child(2) {
        margin-right: 22px;
      }

      &:not(:first-child) {
        margin-left: 0;
      }

      a.btn,
      .btn {
        .dine_icon-plus {
          padding-right: 6px;
        }
      }
    }
  }
}

.btn-group {
  .btn + .btn {
    margin-left: 19px;
  }
}

a.btn-plus-style,
.btn-plus-style {
  position: relative;
  display: inline-block;
  padding: 7px 18px 7px 39px;
  font-size: 18px;
  line-height: $line-height-heading-2;
  letter-spacing: -0.04em;
  background-color: transparent;
  border-radius: 40rem;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5);

  &:before {
    border-radius: 40rem !important;
  }

  &:after {
    position: absolute;
    content: '\e946';
    top: 50%;
    left: 13px;
    @include icomoon();
    font-size: 15px;
    transform: translate(0, -50%);
  }
}

a.order-status-btn,
.order-status-btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
  min-width: rem(108);
  margin: 0;
  font-size: rem(17);
  line-height: 1.3;
  font-weight: $medium;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: #067aff;
  appearance: none;

  &:before {
    border-radius: 50rem !important;
  }

  .inner-btn-box {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 34px 5px 15px;
    border: rem(2) solid $color-white;
    background-color: $color-white;
    border-radius: 50rem;
  }

  &:after {
    position: absolute;
    content: '\e960';
    top: 50%;
    right: 9px;
    @include icomoon();
    font-size: 9px;
    font-weight: 700;
    transform-origin: center;
    transform: translate(0, -50%) rotate(-90deg);
  }

  &.circle {
    min-width: auto;

    .inner-btn-box {
      width: 35px;
      height: 35px;
      padding: 0;
      border-radius: 50%;
    }

    &:after {
      left: 50%;
      right: auto;
      margin-left: 1px;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
}

a.btn-toggle,
.btn-toggle {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  max-width: 295px;
  text-align: left;
  padding: 14px 20px 14px 24px;
  margin: 0 0 20px;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.5);

  .btn-title {
    line-height: 1.2;
    font-size: 22px;
    letter-spacing: -0.02em;
    color: #363636;
  }

  .btn-toggle-thumb {
    position: absolute;
    display: block;
    top: 50%;
    right: 25px;
    width: 51px;
    height: 31px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    transition: all 0.2s ease;
    transform: translate(0, -50%);

    span {
      position: absolute;
      top: 1px;
      left: 1px;
      width: 29px;
      height: 29px;
      border: 1px solid #4d4d4d;
      border-radius: 50%;
      background-color: #fafafa;
      box-sizing: border-box;
      transition: all 0.25s ease;
    }

    &.active {
      background-color: #19ab27;

      span {
        left: 20px;
        border-color: #19ab27;
      }
    }
  }

  .btn-toggle-thumb {
    &:hover {
      &.active {
        background-color: #128d15;
      }

      background-color: #000000;
    }
  }
}

.customize-half-btn {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  min-height: 36px;
  max-width: 100%;
  min-width: rem(109);
  font-size: rem(13);
  font-family: $font-main;
  font-weight: $normal;
  line-height: 1;
  letter-spacing: -$letter-spacing-20;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: #363636;
  appearance: none;
  background-color: #ffffff;
  border-radius: 30rem;

  [class^='pos_icon-'],
  [class*=' pos_icon-'] {
    width: 18px;
    height: 18px;
  }

  .customize-half-btn-title {
    display: block;
    margin-right: 7px;
    line-height: 1.25;
  }

  .dark-mode & {
    color: #ffffff;
    background-color: #656565;
  }

  @media (min-width: 1260px) and (min-height: 860px) {
    padding: 10px 20px;
    font-size: 18px;
    [class^='pos_icon-'],
    [class*=' pos_icon-'] {
      width: 28px;
      height: 28px;
    }
  }
}
