.dropdownItem {
  display: flex;
  background-color: #ffffff;
  border: 0.5px solid hsla(0, 0%, 91.4%, 0.71);
  border-right: none;
  border-left: none;
  padding: 12px 10px;
  font-weight: 500;
  color: #5d5d5d;
  cursor: pointer;
}
