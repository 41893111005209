@import '../global/variables.module';

.add-drawer-modal-box {
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 67px 0 0;

  .add-drawer-modal-box-inner {
    padding: 0 23px;
  }

  .add-drawer-title {
    margin-bottom: 21px;
  }

  .add-drawer-note {
    display: block;
    padding: 0 14px;
    color: #777575;
    font-size: 13px;
    line-height: 1.25;
    letter-spacing: -0.02em;
    word-spacing: -0.01em;

    b,
    strong {
      font-weight: $medium;
    }
  }
  .add-drawer-error-note {
    display: block;
    padding: 0 14px;
    color: red;
    font-size: 13px;
    line-height: 1.25;
    letter-spacing: -0.02em;
    word-spacing: -0.01em;

    b,
    strong {
      font-weight: $medium;
    }
  }

  .add-drawer-form {
    .single-input-box {
      position: relative;
      margin-bottom: 20px;

      .pos_icon-down-chevron {
        position: absolute;
        top: 21px;
        right: 18px;
        width: 22px;
        height: 16px;
        transform-origin: center;
        z-index: 5;
      }

      .mbsc-select-input,
      .input-style {
        margin-bottom: 17px;
        font-size: 16px;
        border-radius: 9px;
      }

      > :last-child {
        margin-bottom: 0;
      }

      .mbsc-select-input::-webkit-input-placeholder,
      .input-style::-webkit-input-placeholder {
        font-size: 16px;
      }

      .mbsc-select-input::-moz-placeholder,
      .input-style::-moz-placeholder {
        font-size: 16px;
      }

      .mbsc-select-input:-ms-input-placeholder,
      .input-style:-ms-input-placeholder {
        font-size: 16px;
      }

      .mbsc-select-input:-moz-placeholder,
      .input-style:-moz-placeholder {
        font-size: 16px;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .add-drawer-btn-box {
    a.btn,
    .btn {
      display: block;
      width: 100%;
      font-size: rem(18);

      &:before {
        border-radius: 0;
      }

      .inner-btn-box {
        padding: 10px 20px;
        border-radius: 0;
      }
    }
  }
}

.mbsc-mobiscroll .mbsc-fr-w {
  background-color: #ffffff;
}

.mbsc-mobiscroll.mbsc-fr-pointer
  .mbsc-sel-one.mbsc-sc-whl-multi
  .mbsc-sc-itm-sel {
  background-color: #f7f7f7;
}

.mbsc-mobiscroll.mbsc-input .mbsc-control,
.mbsc-mobiscroll.mbsc-input .mbsc-control:focus,
.mbsc-mobiscroll.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
  border-bottom-color: rgba(233, 233, 233, 0.71);
}

.dark-mode {
  .add-drawer-title {
    color: #ffffff;
  }

  .add-drawer-modal-box {
    .add-drawer-note {
      color: #ffffff;
    }

    .mbsc-select-input {
      color: #ffffff;
      border-color: #3a3a3a;
      background-color: #1c1c1e;
    }
  }

  .mbsc-mobiscroll.mbsc-input .mbsc-control {
    color: #ffffff;
  }

  .mbsc-sel-empty,
  .mbsc-sel-filter-clear {
    color: #ffffff;
  }

  .mbsc-mobiscroll.mbsc-fr-pointer
    .mbsc-sel-one.mbsc-sc-whl-multi
    .mbsc-sc-itm-sel {
    color: #ffffff;
  }

  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm {
    color: #e5d8d8;
  }

  .mbsc-mobiscroll .mbsc-fr-w {
    background-color: #1c1c1e;
  }

  .mbsc-mobiscroll.mbsc-fr-pointer
    .mbsc-sel-one.mbsc-sc-whl-multi
    .mbsc-sc-itm-sel {
    background-color: #1c1c1e;
  }

  .mbsc-mobiscroll.mbsc-input .mbsc-control,
  .mbsc-mobiscroll.mbsc-input .mbsc-control:focus,
  .mbsc-mobiscroll.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
    border-bottom-color: rgba(233, 233, 233, 0.71);
  }

  .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-mobiscroll .mbsc-sc-itm:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
