@import 'src/assets/scss/global/mixins';
.bem-bill-check {
  border-radius: 15px;
  .bem-base-part__header {
    box-shadow: none;
  }
  &__action-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    padding: 0 18px 12px;
    .bem-btn {
      height: 47px;
    }
  }
}
