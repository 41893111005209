.sidebar-modal-view-orders-time {
  &.sidebar-modal,
  .sidebar-modal-overlay {
    z-index: 99;
    top: auto;
    left: 0;
    bottom: -100%;
  }

  &.sidebar-modal {
    transition: opacity 0s linear 0.5s, bottom 0.35s linear 0.15s;
  }

  .sidebar-modal-overlay {
    transition: opacity 0.15s linear 0s, bottom 0s linear 0.35s;
  }

  .sidebar-modal-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.sidebar-modal.show,
  &.show .sidebar-modal-overlay {
    bottom: 0;
    transition: opacity 0s linear 0s, bottom 0.35s linear;
  }

  &.show .sidebar-modal-overlay {
    transition: opacity 0.35s linear 0.35s, bottom 0s;
  }

  .sidebar-modal-box {
    z-index: 100;
    height: 238px;
    min-width: 400px;
    width: 39%;
    box-shadow: -14px 0 15px -2px rgba(0, 0, 0, 0.5);
    border-radius: 19px 19px 0 0;

    .drivers-style &,
    &.drivers-style {
      width: 429px;
    }

    .sidebar-modal-header {
      padding: 55px 18px 26px;
      border: none;
    }

    .sidebar-modal-title {
      font-size: 22px;
      text-align: center;
    }
  }

  a.close-modal-btn,
  .close-modal-btn {
    top: 8px;
    left: 6px;
    font-size: 22px;
    color: #494b4c;

    &:after {
      content: '\e90f';
      font-weight: 400;
    }
  }

  .counter-box {
    display: block;
    margin-bottom: 28px;
    padding: 0;
    width: 100%;
    text-align: center;

    .counter-value {
      margin-top: 0;
      width: 111px;
      color: #363636;
      font-size: 18px;
    }

    .counter-btn {
      color: #067aff;
      border-color: #067aff;

      &.plus {
        background-image: url(../../../assets/img/icon/plus_blue.svg);
      }

      &.minus {
        background-image: url(../../../assets/img/icon/minus_blue.svg);
      }

      &:disabled {
        color: #c4c4c4;
        border-color: #c4c4c4;

        svg {
          path {
            fill: rgb(196, 196, 196);
            stroke: rgb(196, 196, 196);

            .dark-mode & {
              fill: rgb(128, 128, 128);
              stroke: rgb(128, 128, 128);
            }
          }
        }
      }
    }
  }

  .counter-desc {
    display: block;
    text-align: center;
    color: #363636;
    font-size: 15px;
    font-weight: 400;

    span {
      margin-left: 10px;
      font-weight: $medium;
    }
  }
}
