#select-modal,
#select-modal-status,
.select-modal-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  bottom: 0;
  width: 327px;
  height: 100%;
  opacity: 0;
  transition: opacity 0s;
}

#select-modal-status.show,
#select-modal.show,
.show .select-modal-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.35s;
}

.select-modal-overlay {
  z-index: 10;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.select-modal-box {
  overflow: hidden;
  position: absolute;
  z-index: 20;
  left: 0;
  bottom: 0;
  width: 327px;
  max-height: 618px;
  background-color: #ffffff;
  border-radius: 19px 19px 0 0;
}

.select-options-list {
  > li {
    display: block;
    color: #0686f6;

    a,
    button {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: left;
      text-decoration: none;
      border: none;
      appearance: none;
      box-shadow: none;
      text-transform: capitalize;
    }

    &.active {
      .single-select-box {
        color: #ffffff;
        background-color: #dbdbdb;
        box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.5);
      }

      .people-count-box {
        color: #ffffff;
      }
    }

    &.orange {
      .single-select-box {
        color: #fd6c00;

        .initials {
          color: #ffffff;
          background-color: #fd6c00;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #fd6c00;

          .initials {
            color: #fd6c00;
            background-color: #ffffff;
          }
        }
      }
    }

    &.purple {
      .single-select-box {
        color: #b620e0;

        .initials {
          color: #ffffff;
          background-color: #b620e0;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #b620e0;

          .initials {
            color: #b620e0;
            background-color: #ffffff;
          }
        }
      }
    }

    &.blue {
      .single-select-box {
        color: #0686f6;

        .initials {
          color: #ffffff;
          background-color: #0686f6;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #0686f6;

          .initials {
            color: #0686f6;
            background-color: #ffffff;
          }
        }
      }
    }

    &.pink {
      .single-select-box {
        color: #eb4469;

        .initials {
          color: #ffffff;
          background-color: #eb4469;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #eb4469;

          .initials {
            color: #eb4469;
            background-color: #ffffff;
          }
        }
      }
    }

    &.yellow {
      .single-select-box {
        color: #f7b500;

        .initials {
          color: #ffffff;
          background-color: #f7b500;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #f7b500;

          .initials {
            color: #f7b500;
            background-color: #ffffff;
          }
        }
      }
    }

    &.green {
      .single-select-box {
        color: #5eb602;

        .initials {
          color: #ffffff;
          background-color: #5eb602;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #5eb602;

          .initials {
            color: #5eb602;
            background-color: #ffffff;
          }
        }
      }
    }

    &.grey {
      .single-select-box {
        color: #6d7278;

        .initials {
          color: #ffffff;
          background-color: #6d7278;
        }
      }

      &.active {
        .single-select-box {
          color: #ffffff;
          background-color: #6d7278;

          .initials {
            color: #6d7278;
            background-color: #ffffff;
          }
        }
      }
    }

    &:last-child {
      .single-select-box {
        padding-bottom: 15px;
      }
    }
  }
}

.single-select-box {
  position: relative;
  z-index: 5;
  display: block;
  padding: 10px 13px;
  background-color: #ffffff;
  pointer-events: none;

  .icon-box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 37px;
    height: 37px;
    margin-right: 17px;
    font-size: 20px;
    text-transform: uppercase;

    &.initials {
      color: #ffffff;
      background-color: #0686f6;
      border-radius: 50%;

      span:after {
        position: absolute;
        content: attr(data-title);
        top: 50%;
        left: 50%;
        @include font($normal, rem(15), 1, $font-main);
        letter-spacing: -0.01em;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
      }
    }

    [class^='dine_icon-'],
    [class*=' dine_icon-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 30px;
      transform: translate(-50%, -50%);
    }

    .dine_icon-people {
      font-size: 27px;
    }

    .dine_icon-people-inverse {
      font-size: 27px;
    }

    .dine_icon-silverware {
      font-size: 30px;
    }

    .dine_icon-dish {
      font-size: 23px;
    }

    .ine_icon-waiters {
      font-size: 30px;
    }

    .dine_icon-check {
      font-size: 30px;
    }

    .dine_icon-cash {
      font-size: 30px;
    }

    .dine_icon-sprayer {
      font-size: 32px;
    }

    .dine_icon-finish {
      font-size: 27px;
    }
  }

  .name {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    line-height: 1.25;
    letter-spacing: 0.04em;

    #select-modal-status & {
      letter-spacing: 0;
    }
  }

  .people-count-box {
    position: absolute;
    top: 50%;
    right: 16px;
    display: inline-block;
    width: 56px;
    color: #363636;
    text-align: left;
    vertical-align: middle;
    font-size: 18px;
    margin-left: 10px;
    transform: translate(0, -50%);

    .dine_icon-people {
      display: inline-block;
      font-size: 18px;
      margin-right: 10px;

      .active & {
        &:before {
          content: '\e922';
        }
      }
    }

    .count {
      display: inline-block;
    }
  }

  #select-modal-status & {
    font-weight: $normal;

    .name {
      color: #363636 !important;
    }
  }

  #select-modal-status .active & {
    .name {
      color: #ffffff !important;
    }
  }
}
