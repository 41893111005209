/*----------------------------------------------------------------------------------*/
/*------------------------------RESET DEFAULT STYLES--------------------------------*/
/*----------------------------------------------------------------------------------*/
html {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  min-height: 100%;
  overscroll-behavior: none;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: $size-html;
}

/* avoid 3rd party overlay */
body > iframe {
  visibility: hidden !important;
}

body {
  padding: 0;
  margin: 0;
  @include font();
  background: #fafafa;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

#root {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

div,
p,
a,
span,
em,
strong,
img,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
td,
tr,
form,
fieldset,
label,
select,
button,
input,
textarea {
  padding: 0;
  margin: 0;
  list-style: none;
}

select,
input,
textarea {
  font-size: $size-main;
  vertical-align: middle;
  color: $color-text;
}

textarea {
  vertical-align: top;
}

input[type='submit'],
button {
  cursor: pointer;
}

img,
fieldset {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  font-family: $font-main;
  color: $color-text;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:focus,
.btn:focus,
:focus {
  outline: none;
}

button,
label,
a {
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// removes the blue highlights on the android chrome browser
*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: $bold;
}

a,
.btn {
  transition: $transition-link;
}

button {
  padding: 0;
  font-family: $font-main;
  font-weight: $normal;
  background-color: transparent;
  border: none;
  box-shadow: none;
  appearance: none;
}

.iPad-box {
  .iPad-body {
  }

  &.disabled-hover {
    .item-cards .item-card label:hover,
    .food-cards .card-item:hover .card-img-wrap,
    #payment-view-modal .cash-cards a:hover,
    #payment-view-modal .cash-cards button:hover,
    a.table-wrap:hover,
    .table-wrap:hover,
    .home-screen-box a.nav-btn:hover .icon-box,
    .home-screen-box .nav-btn:hover .icon-box {
      transform: none;
    }
  }
}

img {
  user-select: none;
}

a.close-modal-btn,
.close-modal-btn,
a.back-modal-btn,
.back-modal-btn {
  position: relative;
  width: 42px;
  height: 42px;
  font-size: 25px;
  background-color: transparent;
  appearance: none;
  box-shadow: none;
  border: none;

  &:after {
    position: absolute;
    content: '\e963';
    left: 50%;
    top: 50%;
    @include icomoon();
    font-weight: 700;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
  }

  .guests-count-modal & {
    background-image: url(../../../assets/img/icon/close_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;

    &:after {
      content: none;
    }
  }
}

a.back-modal-btn,
.back-modal-btn {
  font-size: 12px;

  &:after {
    content: '\e960';
    transform: translate(-50%, -50%) rotate(90deg);
  }

  #sidebar-modal-available-table &,
  #sidebar-modal-waitlist & {
    background-image: url(../../../assets/img/icon/left-chevron_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;

    &:after {
      content: none;
    }
  }
}

.container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.vertical-align {
  .container {
    display: table;
    width: 100%;
    height: 494px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container-box {
    display: table-cell;
    width: 100%;
    min-height: 50px;
    vertical-align: middle;
  }
}

.content {
  * {
    margin-bottom: 12px;
    line-height: $line-height;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-text;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }

  ul {
    & > li {
      list-style-type: disc;
    }
  }

  ol {
    & > li {
      list-style-type: decimal;
    }
  }

  li {
    margin-bottom: 12px;
    text-align: left;
  }

  blockquote {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  img {
    max-width: 100%;
    height: auto;

    &.alignleft {
      float: left;
      margin: 4px 12px 12px 0;
    }

    &.alignright {
      float: right;
      margin: 4px 0 12px 12px;
    }

    &.aligncenter {
      display: block;
      margin: 0 auto 12px;
      clear: both;
    }
  }
}

.shadow {
  box-shadow: 0 2px 7px -4px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
}

.app-logo {
  position: fixed;
  z-index: 1;
  left: 96px;
  bottom: 50px;
  width: auto;
  height: auto;
  max-width: 144px;
  @media all and (max-width: 1740px) {
    position: absolute;
  }
  @media all and (max-height: 980px) {
    position: absolute;
  }
}

.close-intercom-btn {
  position: fixed;
  bottom: 70px;
  left: 90px;
  z-index: 1000;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.35s;
  border-radius: 50rem;
  color: var(--logoIconColor);
  background-color: var(--mainBG);
  box-shadow: var(--shadowModal);
}

@import 'bem-pos-icon';
