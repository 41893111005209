.menu-search-box {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  transition: transform 0.35s ease-in-out;
  transform: translateY(-100%);

  &.show {
    transform: translateY(0);
    box-shadow: 0.195px 0.382px 5.88px 0.12px rgba(0, 0, 0, 0.09);
  }

  .search-box {
    overflow: hidden;
    margin: 0;
    padding: 13px 20px;

    .hide-search-box {
      width: 72px;
      min-width: 72px;
      float: left;
      line-height: 1.3;

      .inner-btn-box {
        padding: 4px 12px;
        border-radius: 0 rem(8.667) rem(8.667) 0;
      }
    }
  }

  .search-form {
    float: left;
    width: 100%;

    &:not(:last-child) {
      width: calc(100% - 72px);

      .input-style {
        border-radius: rem(8.667) 0 0 rem(8.667);
      }
    }

    .input-style {
      padding-right: 32px;
    }

    button[type='reset'] {
      width: 30px;
      border-radius: rem(8.667);
      background-color: #e9e9e9;

      .pos_icon-close {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.dark-mode {
  .menu-search-box {
    background-color: #000;

    .search-form button[type='reset'] {
      top: 1px;
      right: 1px;
      height: 28px;
      background-color: #1c1c1e;
    }
  }
}
