@import 'src/assets/scss/global/mixins';

.bem-header {
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 25;
  min-height: 56px;
  background-color: var(--headerBG);
  box-shadow: var(--shadowHeader);

  &__inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0 15px;
    //gap: 0 20px;
    @include gapFlexSupportAlternative(0, 20, 100%, 10);
    justify-content: space-between;
    align-items: center;
  }
}

.bem-logo-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  @include gapFlexSupportAlternative(0, 40);
  width: 320px;

  &__guests {
    display: inline-flex;
    align-items: center;
    @include gapFlexSupportAlternative(0, 5);
  }
}
.add-driver-button {
  cursor: pointer;
  display: flex;
  box-shadow: 0 0 5px rgba(114, 114, 114, 0.5);
  border-radius: 20px;
  width: 100px;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: space-evenly;
  align-items: center;
  height: 32px;
}

.add-driver-button-text {
  font-size: 16px;
  color: #3a3a3a;
}
