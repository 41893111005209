// #4285f4 border top
// #ddd all grey borders

$border-gray: #ddd;
$blue: #4285f4;
$light-blue: #8eb9ff;

@mixin border-grey {
  border: 1px solid $border-gray;
}

@mixin font-family-roboto {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: #ffffff;
  border-top: 2px solid $blue;
  max-width: 400px;

  .sign-in-header {
    @include border-grey;
    @include font-family-roboto;
    padding: 40px 20px 10px;
    text-align: center;

    .company-name {
      font-size: 4rem;
    }

    .cta-intent {
      font-size: 2rem;
      font-weight: 300;
    }
  }
}

.auth-input-container {
  @include border-grey;
  padding: 40px 30px;

  .auth-input {
    display: block;
    width: 48px !important;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-width: 1px;
    border-top-color: #bdc0c7;
    box-shadow: none;
    padding: 10px 12px;
    font-size: 15px;
    transition: none;
    height: 48px;
    margin-right: 10px;
  }

  .auth-input-disabled {
    background-color: lightgrey;
  }

  .auth-input-focused {
    border: 2px solid $blue;
  }

  .auth-input-error {
    border: 2px solid red;
  }

  .user-error {
    margin: 15px 0 0 0;
    color: red;
    text-align: center;
  }

  .login-cta-container {
    margin: 40px 0 20px 0;

    .login-cta {
      @include font-family-roboto;

      font-size: 1.6rem;
      background: $blue;
      width: 100%;
      padding: 15px;
      color: white;
      font-weight: bold;

      &:disabled {
        background: lightgrey;
      }

      &:disabled:hover {
        background: lightgrey;
      }

      &:hover {
        background: $light-blue;
      }
    }
  }
}
