.order-bar {
  flex-basis: 31.95%;
  max-width: 31.95%;
  min-width: 31.95%;
  width: 31.95%;
  overflow: hidden;
  position: relative;
  z-index: 6;
  background-color: $color-white;
  border-left: 0.5px solid #d2d5db;
  border-color: rgba(210, 213, 219, 0.8);
  box-shadow: -0.825px 3px 8px 0px rgba(0, 0, 0, 0.06);

  & > div {
    z-index: 3;
  }

  .delivery-page-style &,
  .pickup-page-style & {
    overflow: visible;
    width: 327px;
    border: none;
    box-shadow: none;

    &:after {
      position: absolute;
      content: '';
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
    }

    &:before {
      position: absolute;
      content: '';
      z-index: 1;
      top: 0;
      left: 4px;
      width: 100%;
      height: 100%;
      background: transparent;
      box-shadow: -4px 5px 12px 2px rgba(0, 0, 0, 0.5);
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }

  .pickup-details-list {
    > li {
      border-top: 1px solid rgba(210, 210, 210, 0.4);
    }
  }

  .guest-name-contianer {
    display: flex;
    align-items: center;
  }

  .on-Call-container {
    padding-left: 13px;
    display: flex;
    align-items: center;
  }

  .on-call-icon-container {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #6bb81a;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: glowing 1s ease-in-out infinite alternate;
    -moz-animation: glowing 1s ease-in-out infinite alternate;
    animation: glowing 1s ease-in-out infinite alternate;
  }

  @keyframes glowing {
    from {
      box-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #6bb81a, 0 0 10px #0ff,
        0 0 10px #6bb81a, 0 0 20px #6bb81a, 0 0 10px #6bb81a;
    }
    to {
      box-shadow: 0 0 5px #fff, 0 0 5px #6bb81a, 0 0 5px #6bb81a,
        0 0 5px #6bb81a, 0 0 5px #6bb81a, 0 0 5px #6bb81a, 0 0 5px #6bb81a;
    }
  }

  .phone-icon {
    width: 20px;
    height: 20px;
    color: white;
  }

  .single-pickup-details {
    position: relative;
    display: block;
    padding: 13px 48px 14px 14px;
    min-height: 52px;
    flex: 1;

    .text {
      overflow: hidden;
      display: block;
      font-size: rem(16);
      letter-spacing: -0.02em;
      color: #3a3a3a;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .grey {
      color: #727272;
    }

    .icon-box {
      position: absolute;
      top: 50%;
      right: 7px;
      width: 42px;
      height: 42px;
      font-size: 22px;
      transform-origin: 50% 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      [class^='dine_icon-'],
      [class*=' dine_icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%);
      }

      .dine_icon-note {
        margin-left: 1px;
        margin-top: -1px;
        font-size: 24px;
      }
    }

    &.input-box {
      padding: 10px 48px 10px 3px;
    }

    .input-style {
      font-size: rem(16);
      color: #3a3a3a;
      background-color: transparent;
      border-color: transparent;

      &::-webkit-input-placeholder {
        color: #727272;
        font-size: rem(16);
      }

      &::-moz-placeholder {
        color: #727272;
        font-size: rem(16);
      }

      &:-ms-input-placeholder {
        color: #727272;
        font-size: rem(16);
      }

      &:-moz-placeholder {
        color: #727272;
        font-size: rem(16);
      }
    }
  }

  .order-bar-top {
    z-index: 5;
    box-shadow: 0 -4.5px 9px -0 rgba(0, 0, 0, 0.5);
  }

  .order-bar-header {
    position: relative;
  }
}

.order-bar-top {
  position: relative;
  z-index: 5;

  &.dine-in {
    padding-bottom: 15px;
  }

  background-color: #ffffff;

  .order-bar-header {
    padding: 18px 60px 18px 14px;
  }

  .title {
    display: block;
    color: #3a3a3a;
    font-size: 22px;
    font-weight: $medium;
    line-height: 1.25;
  }

  a.order-burger-btn,
  .order-burger-btn {
    position: absolute;
    top: 50%;
    right: 8px;
    transform-origin: center;
    transform: translate(0, -50%);

    &:before {
    }

    .inner-btn-box {
      display: block;
      width: 42px;
      height: 42px;
    }

    .pos_icon-burger {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 23px;
      height: 14px;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }
  }

  .btn-box {
    padding: 14px 16px 10px;

    .btn-top-list {
      overflow: hidden;
      display: block;
      width: 100%;

      > li {
        float: left;
        display: block;
        width: 50%;
        text-align: left;

        &:nth-child(even) {
          text-align: right;
        }

        &:nth-child(odd) {
        }
      }

      a.btn,
      .btn {
        font-size: 1.5rem;

        .inner-btn-box {
          padding: rem(6) rem(12);
          border-radius: rem(5);
        }

        &:before {
          border-radius: rem(5);
        }
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.table-seats-box {
  margin-bottom: rem(18);
  padding: rem(52) rem(58);
  background-color: $color-white;

  .table-box {
    position: relative;
    display: table;
    width: rem(130);
    height: rem(94);

    margin: 0 auto;
    padding: 5px;
    text-align: center;
    color: #565859;
    border: rem(1.5) solid #dbdbdb;
    border-radius: rem(11);
    background-color: $color-white;
    box-shadow: 0 0 rem(6.27) rem(0.063) rgba(0, 0, 0, 0.17);

    .inner-box {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }

    .table-name {
      margin-bottom: 5px;
      font-size: rem(13.75);
      font-weight: $medium;
    }

    .table-text {
      display: inline-block;
      margin-right: rem(8);
      font-size: rem(10.786);
      font-weight: $medium;

      .pos_icon-wallet-cash,
      .pos_icon-clock {
        position: relative;
        top: 3px;
        width: 17px;
        height: 17px;
        margin-right: 4px;
      }

      .pos_icon-wallet-cash {
        width: 17px;
        height: 16px;
        margin-right: 4px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .table-seat-places-box {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 250px;
      height: 250px;
      transform: translate(-50%, -50%);
      border: 1px solid transparent;
      border-radius: 50%;

      &.total-seats-1,
      &.total-seats-2,
      &.total-seats-3,
      &.total-seats-4 {
        .seat-place-wrap {
          &:nth-child(1) {
            transform: rotate(0deg);

            .seat-place {
              transform: rotate(0deg);
            }
          }

          &:nth-child(2) {
            padding-left: 23px;
            transform: rotate(90deg);

            .seat-place {
              transform: rotate(-90deg);
            }
          }

          &:nth-child(3) {
            transform: rotate(180deg);

            .seat-place {
              transform: rotate(-180deg);
            }
          }

          &:nth-child(4) {
            padding-left: 28px;
            transform: rotate(270deg);

            .seat-place {
              transform: rotate(-270deg);
            }
          }
        }
      }
    }

    .seat-place-wrap {
      position: absolute;
      border: 1px dashed transparent;
      height: 42px;
      width: 125px;
      top: 103px;
      left: 0;
      text-align: left;
      transform-origin: 100% 22px;
      transform: rotate(0deg);
    }

    .seat-place {
      position: relative;
      display: block;
      width: rem(40);
      height: rem(40);
      font-family: $font-main;
      font-size: rem(18);
      font-weight: $medium;
      line-height: 1;
      box-shadow: inset 0 0 2.5px 0 rgba(0, 0, 0, 0.38);
      color: $color-white;
      border: rem(1.5) solid #dbdbdb;
      border-radius: 20rem;
      background-color: #cd3f34;

      &:before {
        border-radius: 50%;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        line-height: 1;
      }

      &.red {
        background-color: #cd3f34;
      }

      &.green {
        background-color: #7aac4a;
      }

      &.green-light {
        background-color: #5eb602;
      }

      &.yellow {
        background-color: #fac03b;
      }

      &.blue {
        background-color: #2e70a2;
      }

      &.purple {
        background-color: #b620e0;
      }

      &.blue-light {
        background-color: #0686f6;
      }

      &.pink {
        background-color: #eb4469;
      }

      &.orange {
        background-color: #fd6c00;
      }

      &.golden {
        background-color: #f7b500;
      }

      &.grey {
        background-color: #6d7278;
      }

      &.active {
        border: none;
        box-shadow: inset 0 0 2.5px 0 rgba(0, 0, 0, 0.38), 0 0 0 2px white,
          0 0 8px 2px rgba(0, 0, 0, 0.3);
      }

      &.seats-id-1,
      &.seats-id-4 {
        span {
          margin-left: -1px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-2,
      &.seats-id-3 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-5 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-6 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-7 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-8 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-9 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-10 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-11 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-12 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-13 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-14 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-15 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-16 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-17 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-18 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-19 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-20 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-21 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-22 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-23 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }

      &.seats-id-24 {
        span {
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }
    }

    &.table-circle-style {
      width: rem(130);
      height: rem(130);
      border-radius: rem(100);
    }
  }
}

.seats-list-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: auto;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;

  .single-seat-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;

    .seat-title {
      display: block;
      overflow: hidden;
      padding: 6px 10px;
      font-size: rem(16);
      font-weight: $medium;
      line-height: $line-height-heading-2;
      letter-spacing: -0.02em;
      color: $color-white;
      text-overflow: ellipsis;
    }

    &.red {
      background-color: #de2d29;
    }

    &.green {
      background-color: #7aac4a;
    }

    &.green-light {
      background-color: #5eb602;
    }

    &.yellow {
      background-color: #fac03b;
    }

    &.blue {
      background-color: #2e70a2;
    }

    &.purple {
      background-color: #b620e0;
    }

    &.blue-light {
      background-color: #0686f6;
    }

    &.pink {
      background-color: #eb4469;
    }

    &.orange {
      background-color: #fd6c00;
    }

    &.golden {
      background-color: #f7b500;
    }

    &.grey {
      background-color: #6d7278;
    }
  }

  .seat-items-wrap {
    overflow: hidden;
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

  &.single {
    .order-bar & {
      .seat-items-wrap {
        padding-top: 5px;
      }
    }
  }

  .cart-new-items-divider {
    background-color: #666666;
    color: #ffffff;
    padding: 0 10px;
  }

  .single-item {
    background-color: white;
    border-bottom: 1px solid #d3d2d2;
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    transition: all 0.35s ease-in-out;
    cursor: pointer;

    &.add-item {
      opacity: 0;
      transform: translateX(-100%);
      transition: all 0s;
    }

    &.delete-item {
      opacity: 0;
      max-height: 0;
      transform: translateX(100%);
    }

    .single-item-table {
      display: table;
      width: 100%;
      min-height: 56px;
      padding: 5px 32px 5px 3px;

      &.voided {
        opacity: 0.3;
        text-decoration: line-through;
      }
    }

    .single-img-wrap {
      overflow: hidden;
      position: relative;
      display: inline-block;
      width: rem(46);
      height: rem(46);
      text-align: center;
      background-color: $color-white;
      line-height: rem(46);
      padding: 2px;
      box-shadow: $default-box-shadow;
      border-radius: rem(3.666);
      vertical-align: middle;

      img {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        max-width: 102%;
        width: 102%;
        height: auto;
        transform: translate(-50%, -50%);
        font-size: 12px;

        .demo-app & {
          max-width: 46px;
          max-height: 40px;
          width: auto;
          height: auto;
        }
      }
    }

    .single-body {
      > :last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }

      .single-body {
        &:after {
          content: none;
        }
      }
    }

    .single-title {
      margin-bottom: 4px;
      font-size: rem(16);
      font-weight: $normal;
      letter-spacing: -0.02em;
      line-height: $line-height-heading-2;
      text-transform: capitalize;
    }

    .single-actions {
      display: block;

      a,
      button,
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
        padding: 0;
        margin-bottom: 4px;
        text-align: left;
        font-family: $font-main;
        font-size: rem(13);
        line-height: 1.2;
        letter-spacing: -0.01em;
        color: $color-bg-grey;
        background-color: transparent;
        box-shadow: none;
        appearance: none;
        border: none;

        .order-bar & {
          margin-bottom: 0;
        }
      }
    }

    .single-modify-btn {
      position: absolute;
      z-index: 5;
      top: 50%;
      right: 0;
      width: 31px;
      height: 42px;
      text-align: center;
      line-height: 1;
      font-size: rem(20);
      background-color: transparent;
      border: none;
      box-shadow: none;
      appearance: none;
      transform: translateY(-50%);

      .pos_icon-apper-circle {
        @include centered-pos-icon();
        height: 21px;
        width: 14px;
      }
    }
  }
}

.order-actions-btn-box {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-white;
  box-shadow: $default-box-shadow;

  .btn-box {
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    a,
    button {
      margin: 0;
    }
  }

  a.btn-action,
  .btn-action {
    display: block;
    width: 100%;
    font-family: $font-main;
    font-weight: 400;
    font-size: rem(16);
    text-align: center;
    color: #494b4c;
    line-height: $line-height-heading-2;
    text-decoration: none;
    border: none;
    box-shadow: none;
    appearance: none;
    margin: 0;

    &:before {
      border-radius: 0;
    }

    &:not(:last-child) {
      border-right: rem(1) solid #d2d5db;
    }

    .inner-btn-box {
      display: block;
      padding: rem(12) rem(8);
      background-color: $color-white;
    }

    &.width-100 {
      width: 100%;
    }

    &.blue {
      color: $color-white;
      border: none;

      .inner-btn-box {
        background-color: #067aff;
        border: none;
      }
    }

    &.green {
      color: $color-white;
      border: none;

      .inner-btn-box {
        background-color: #5eb602;
        border: none;
      }
    }

    &.big {
      font-size: 18px;
      letter-spacing: -0.02em;

      .inner-btn-box {
        padding: rem(15) rem(20);
      }

      .pos_icon-loading {
        margin: 0 0 -3px 10px;
      }
    }
  }
}

.test {
  .block {
    border: 1px dashed black;
    width: 150px;
    height: 50px;
    transform-origin: 100% 26px;
    transform: rotate(0deg);
    position: absolute;
  }

  .block:nth-child(1) {
    transform: rotate(45deg);
  }

  .block:nth-child(2) {
    transform: rotate(90deg);
  }

  .block:nth-child(3) {
    transform: rotate(135deg);
  }

  .block:nth-child(4) {
    transform: rotate(180deg);
  }

  .square {
    background: yellow;
    border: 1px solid black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.seat-items-empty {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  text-align: center;
  transform-origin: center;
  // transform: translate(-50%, calc(-50% - 36px));

  .pos_icon-bag-inverse {
    display: inline-block;
    width: 54px;
    height: 63px;
    margin-bottom: 10px;
  }

  .text {
    display: block;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    color: #363636;
    opacity: 0.39;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.pulse-anim .card-img-wrap {
  animation: fade-in-keyframes 0.75s;
}

@keyframes fade-in-keyframes {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    transform: scale(0.9);
    opacity: 0.9;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.dark-mode {
  .seat-items-empty {
    .text {
      color: #fff;
      opacity: 1;
    }
  }
}

.edit-btn {
  margin-left: 10px;
  color: #067aff;
  font-weight: 500;
}

.delivery-btn-container {
  width: 100%;
}
