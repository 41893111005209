.money-count-main {
  position: relative;
  z-index: 1;
  height: 100%;
  width: calc(100% - 379px);
}

.denomination-table-wrap {
  height: 100%;
  height: calc(100% - 48px);
  background-color: #ffffff;
}

.denomination-table {
  position: relative;
  background-color: #ffffff;
  width: 100%;

  thead {
    background-color: #5eb602;

    tr {
      th {
        z-index: 10;
        padding: 14px 20px;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 11px 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.25;
        color: #363636;
        background-color: #ffffff;
        border-bottom: 2px solid #e9e9e9;
        border-bottom-color: rgba(#e9e9e9, 0.43);
        vertical-align: middle;
      }

      &:nth-child(even) {
        td {
          background-color: rgba(#f7f7f7, 0.49);
        }
      }
    }
  }

  .denomination-denomination {
    width: 42%;
    padding-left: 21px;
    text-align: left;
  }

  .denomination-quantity {
    width: 125px;
    text-align: center;
  }

  .denomination-total {
    width: calc(58% - 125px);
    padding-right: 32px;
    text-align: right;
  }

  .denomination-quantity-box {
    .input-style {
      min-height: 42px;
      font-size: 18px;
      text-align: center;
      color: #3a3a3a;
      border: 2px solid #e9e9e9;
      border-radius: 9px;
      transition: border-color 0.25s ease-in-out;

      &:focus,
      &.active {
        border-color: #5eb602;
      }

      &:disabled {
        -webkit-text-fill-color: #3a3a3a;
        color: #3a3a3a;
        opacity: 1;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.dark-mode {
  .denomination-table-wrap {
    background-color: #000;
  }

  .denomination-table {
    background-color: #111213;

    thead {
      background-color: #5f8f2e;
    }

    tbody {
      tr {
        td {
          color: #ffffff;
          background-color: #2b2c2e;
          border-bottom-color: #2b2c2e;
        }

        &:nth-child(even) {
          td {
            background-color: #111213;
            border-bottom-color: #111213;
          }
        }
      }
    }

    .denomination-quantity-box {
      .input-style {
        color: #ffffff;
        border-color: #444444;

        &:focus,
        &.active {
          border-color: #5eb602;
        }

        &:disabled {
          -webkit-text-fill-color: #ffffff;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
  }
}
