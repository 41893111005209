@import '../../assets/scss/global/variables.module';

.orders-list {
  &__header {
    position: relative;
    z-index: 5;
    display: flex;
    // flex-wrap: nowrap;
    // gap: 0 20px;
    align-items: center;
    justify-content: space-between;
    padding: 9px 27px;
    background-color: #5eb602;

    &--sticky {
      position: sticky;
      top: 0;
    }

    &--theme-green {
      background-color: #5eb602;
    }
    &--theme-orange {
      background-color: #fd6c00;
    }
    &--theme-pink {
      background-color: #b620e0;
    }
  }

  &__title {
    color: $color-white;
    font-size: rem(15);
    font-weight: $medium;
    letter-spacing: -0.33px;
    line-height: $line-height-heading-2;

    &--size-big {
      font-size: rem(17.1);
    }
    
    &:first-letter {
      text-transform: uppercase;
    }
    
    + .pos-icon {
      margin-left: 13px;
    }
  }

  &__repeat-order {
    display: inline-flex;
    align-items: center;
    justify-content: end;

    .pos-icon {
      cursor: pointer;
    }
  }
}
