.test-icons-wrap {
  max-width: 1024px;
  height: 100%;
  overflow: auto;

  h1 {
    margin: 10px 20px 12px;
    text-align: center;
  }
}

.test-icons {
  display: grid;
  padding: 2px 2px 0;
  grid-template-columns: repeat(6, 144px);
  gap: 2px;
  background-color: transparent;

  .test-icon-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px 0;
    padding: 10px;
    width: 100%;
    height: 142px;
    text-align: center;
    background-color: #ffffff;
    transition: 0.35s ease-in-out;

    .dark-mode & {
      background-color: #000000;
    }

    .pos-icon {
      height: 50px;
    }

    .test-name {
      display: block;
      color: #000000;
      font-size: 12px;

      .dark-mode & {
        color: #ffffff;
      }
    }
  }
}
