.bem-withdrawalDetail-modal {
  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: var(--lineHeightTitle);
    letter-spacing: -0.025rem;
    text-align: center;
  }
  .bem-base-part {
    &__header {
      padding: 32px 22px 30px 21px;
      box-shadow: none;
    }
    &__body-inner {
      display: block;
      width: 100%;
      padding: 10px 22px 20px;
    }
  }
}

.bem-amount {
  height: 25px;
  font-family: HelveticaNowText;
  font-size: 17.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #3a3a3a;
}

.item-border {
  width: 389px;
  height: 55px;
  margin: 15.5px 0 0 0.5px;
  padding: 14px 224.5px 16px 13.5px;
  border-radius: 9px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border: solid 1.5px #d1d5db;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.authorisedby-container {
  padding-top: 20px;
}
.authorisedby-avatar {
  width: 33.1px;
  height: 33px;
  margin: 0 10.4px 0 0;
  background-color: #b620e0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
