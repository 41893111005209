@import '../../assets/scss/global/variables.module';

.order-menu-item {
  display: grid;
  grid-template-columns: 1fr 23px;
  align-items: center;
  gap: 0 10px;
  padding: 10px 25px 10px 30px;
  min-height: 55px;
  background-color: #ffffff;
  border-top: 1px solid #979797;
  border-top-color: rgba(#979797, 0.2);

  .dark-mode & {
    border-top-color: #2a2a2a;
    background-color: #000000;
  }

  &:nth-child(odd) {
    background-color: #f7f7f7;

    .dark-mode & {
      background-color: #212121;
    }
  }

  &:last-child {
    border-bottom: 1px solid #979797;
    border-bottom-color: rgba(#979797, 0.2);

    .dark-mode & {
      border-bottom-color: #2a2a2a;
    }
  }

  &--inactive {
    background-color: #cbcbcb;
    opacity: 0.4;
  }

  &__info-box {
    display: grid;
    gap: 4px 0;
    max-width: 90%;
  }

  &__title {
    font-size: rem(14.4);
    font-weight: $normal;
    letter-spacing: -0.32px;
    line-height: $line-height-heading-2;

    .dark-mode & {
      color: #ffffff;
    }

    &--size-big {
      font-size: rem(16.4);
      letter-spacing: -0.37px;
    }
  }

  &__ingredients {
    display: block;
    font-size: rem(11);
    letter-spacing: -0.24px;
    color: #838383;

    .dark-mode & {
      color: rgba(255, 255, 255, 0.77);
    }

    &--size-big {
      font-size: rem(12.3);
      letter-spacing: -0.27px;
    }
  }

  &__cta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 28px;
    border: 0;
    background: none;
  }

  &__icon {
    width: 17px;
    height: 17px;
  }
}